import {
  useGetRoutesForDayQuery,
  useGetTrucksQuery,
  useGetUsersQuery,
} from "../api";
import { useFirestore } from "react-redux-firebase";
import { useIsAuthenticated } from "./useIsAuthenticated";
import { useEffect, useState } from "react";
import notificationWarning from "../../system-components/toasters/notificationWarning";
import { StopPayload } from "../../../domains/routes/map/helpers/stopPayload";
import { RoutePayload } from "../../../domains/routes/map/helpers/routePayload";
import { FetchPolyline } from "./helpers/route/fetchPolyline";
import { RoutesHelper } from "../../../domains/routes/map/helpers/routesHelper";
import { ROUTES_COLLECTION } from "../../utils/models/collections/collectionConstants";
import { useFirestoreWrite } from "./fetch/useFirestoreWrite";
import notificationError from "../../system-components/toasters/notificationError";
import { formatDateOnWrite } from "../../utils/time/formatTimezoneOffset";
import moment from "moment";
export function useRouteAssignments({ date }) {
  /**
   * state
   *
   * selected route (id, details)
   */
  const [loadingInitialFetch, setLoadingInitialFetch] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState({
    id: null,
    routeData: null,
  });

  const [selectedTruck, setSelectedTruck] = useState({
    id: null,
    routeData: null,
  });
  const [notes, setNotes] = useState("");
  const [sortedUsers, setSortedUsers] = useState([]);
  /**
   * initial load
   *
   * routes for day
   * users
   * trucks
   *
   */

  const firestore = useFirestore();
  const { fsOrgPrefix, orgData } = useIsAuthenticated();

  const {
    data: fetchedRoutes,
    isFetching: fetchingRoutes,
    refetch: refetchRoutes,
  } = useGetRoutesForDayQuery({
    orgPrefix: fsOrgPrefix,
    date,
    db: { firestore },
    orgTimezone: orgData?.orgTimezone,
  });
  const { data: users, isFetching: fetchingUsers } = useGetUsersQuery({
    orgPrefix: fsOrgPrefix,
    db: { firestore },
  });
  const { data: trucks, isFetching: fetchingTrucks } = useGetTrucksQuery({
    orgPrefix: fsOrgPrefix,
    db: { firestore },
  });

  const { writing, updateDocument } = useFirestoreWrite();
  useEffect(() => {
    if (fetchingUsers || fetchingRoutes || fetchingTrucks) {
      return setLoadingInitialFetch(true);
    } else {
      setRoutes(fetchedRoutes);
      if (fetchedRoutes.length > 0) {
        setSelectedRoute({
          id: fetchedRoutes[0]?.id,
          routeData: fetchedRoutes[0] ?? null,
        });
        setNotes(fetchedRoutes[0]?.notes ?? "");
      }
      setSortedUsers(users);
      return setLoadingInitialFetch(false);
    }
  }, [fetchingRoutes, fetchingUsers, fetchingTrucks]);

  useEffect(() => {
    refetchRoutes();
  }, []);

  const onSelectedRoute = ({ id }) => {
    const route = routes.find((r) => r.id === id);
    setSelectedRoute({
      id: id,
      routeData: route,
    });
    setNotes(route?.notes ?? "");
  };

  const addTruck = async ({ id }) => {
    const truck = trucks.find((t) => t.id === id);
    if (!truck || !selectedRoute.routeData || !selectedRoute.id)
      return notificationError(
        "Truck could not be added",
        "Missing required data"
      );
    try {
      const uptdRoute = RoutePayload(selectedRoute.routeData).updateValues({
        list: [
          { key: "vehiclesQueryHook", value: [truck?.id] },
          { key: "assignedVehicles", value: [truck] },
        ],
      });
      const uptdRoutes = RoutesHelper(routes).updateRoute(uptdRoute);
      setSelectedRoute({
        id: uptdRoute?.id,
        routeData: uptdRoute,
      });
      setRoutes([...uptdRoutes]);
      updateDocument({
        data: {
          collection: ROUTES_COLLECTION,
          payload: {
            vehiclesQueryHook: uptdRoute?.vehiclesQueryHook,
            assignedVehicles: uptdRoute?.assignedVehicles,
          },
          id: selectedRoute?.id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onNotesChanged = (v) => {
    setNotes(v.target.value);
  };

  const updateUserRoles = ({ id, roles }) => {
    const payload = RoutePayload(selectedRoute?.routeData).updateUserAssignment(
      {
        id,
        roles,
        users: sortedUsers,
      }
    );
    setSelectedRoute({
      id: payload.id,
      routeData: payload,
    });
    const uptdRoutes = RoutesHelper(routes).updateRoute(payload);
    setRoutes(uptdRoutes);
    // firestore - create diff payload with just assigned employees and employees query hook.
    // mitigates error with mobileRouteDate timestamp
    const { assignedEmployees, employeesQueryHook } = payload;
    const firestorePayload = {
      assignedEmployees,
      employeesQueryHook,
    };
    try {
      updateDocument({
        data: {
          collection: ROUTES_COLLECTION,
          payload: firestorePayload,
          id: payload?.id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const filterUsers = (e) => {
    const value = e.target.value;
    const filteredUsers = users.filter((u) => {
      const str = `${u?.firstName}${u?.lastName}`;
      return str.toLowerCase().includes(value);
    });
    setSortedUsers(filteredUsers);
  };

  const updateLoadDate = (momentDate, firestore, orgData) => {
    const payload = RoutePayload(selectedRoute?.routeData).updateLoadDate(
      momentDate,
      firestore,
      orgData
    );
    setSelectedRoute({
      id: payload.id,
      routeData: payload,
    });
    const uptdRoutes = RoutesHelper(routes).updateRoute(payload);
    setRoutes(uptdRoutes);
    // firestore
    const loadDate = moment(momentDate).startOf("day").toDate();
    const convertedOrgTime = formatDateOnWrite({
      date: loadDate,
      orgTimezone: orgData?.orgTimezone,
    });
    const ts = firestore.Timestamp.fromDate(convertedOrgTime);

    try {
      updateDocument({
        data: {
          collection: ROUTES_COLLECTION,
          payload: {
            loadDate: ts,
            mobileLoadDate: loadDate.toISOString().substring(0, 10),
          },
          id: payload?.id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const saveNotes = () => {
    const payload = RoutePayload(selectedRoute?.routeData).updateNotes(notes);
    const updatedRoutes = RoutesHelper(routes).updateRoute(payload);
    setRoutes(updatedRoutes);
    setSelectedRoute({
      id: payload?.id,
      routeData: payload,
    });
    try {
      updateDocument({
        data: {
          collection: ROUTES_COLLECTION,
          payload: { notes: notes },
          id: selectedRoute?.id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const markComplete = (bool) => {
    const payload = RoutePayload(selectedRoute?.routeData).updateComplete(bool);
    const updatedRoutes = RoutesHelper(routes).updateRoute(payload);
    setRoutes(updatedRoutes);
    setSelectedRoute({
      id: payload?.id,
      routeData: payload,
    });
    try {
      updateDocument({
        data: {
          collection: ROUTES_COLLECTION,
          payload: { isComplete: payload?.isComplete },
          id: selectedRoute?.id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clearTruck = () => {
    try {
      const uptdRoute = RoutePayload(selectedRoute.routeData).updateValues({
        list: [
          { key: "vehiclesQueryHook", value: [] },
          { key: "assignedVehicles", value: [] },
          { key: "isComplete", value: false },
        ],
      });
      const uptdRoutes = RoutesHelper(routes).updateRoute(uptdRoute);
      setSelectedRoute({
        id: uptdRoute?.id,
        routeData: uptdRoute,
      });
      setRoutes([...uptdRoutes]);
      updateDocument({
        data: {
          collection: ROUTES_COLLECTION,
          payload: {
            vehiclesQueryHook: uptdRoute?.vehiclesQueryHook,
            assignedVehicles: uptdRoute?.assignedVehicles,
          },
          id: selectedRoute?.id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * actions
   *
   * select route
   *
   * add user (to one of 3 assignments)
   * remove user
   * change user assignment
   *
   * update note
   *
   * add truck
   * remove truck
   *
   * add load date
   * remove load date
   *
   */
  return {
    state: {
      trucks,
      sortedUsers,
      routes,
      notes,
      selectedRoute,
      selectedTruck,
      loadingInitialFetch,
    },
    actions: {
      onSelectedRoute,
      addTruck,
      onNotesChanged,
      updateUserRoles,
      filterUsers,
      updateLoadDate,
      saveNotes,
      markComplete,
      clearTruck,
    },
  };
}
