import React from "react";
import { Typography } from "antd";

const { Title } = Typography;
const HeadingSecondary = ({ text, ...props }) => {
  return (
    <Title level={2} {...props}>
      {text}
    </Title>
  );
};

export default HeadingSecondary;
