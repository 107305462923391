/**
 * Global helper functions
 * Sort, Find, Etc
 */

/**
 * Find object in array with matching value
 * Default fallback is null
 */
export const FindObject = (array, key, matchValue, fallback) => {
  const defaultValue = fallback ? fallback : null;
  return array?.find((i) => i[key] === matchValue) ?? defaultValue;
};

export const FilterObjects = (array, key, matchValue, fallback) => {
  const defaultValue = fallback ? fallback : null;
  return array?.filter((i) => i[key] === matchValue) ?? defaultValue;
};
export const FilterObjectsNotMatching = (array, key, isNotValue, fallback) => {
  const defaultValue = fallback ? fallback : null;
  return array?.filter((i) => i[key] !== isNotValue) ?? defaultValue;
};

/**
 * Sort array by number value
 * Default descending
 */
export const SortArrByNumber = (arr, key, order) => {
  return arr.sort(function (a, b) {
    let na = a[key];
    let ba = b[key];
    if (order === "descending" || !order)
      return parseFloat(ba) - parseFloat(na);
    return parseFloat(na) - parseFloat(ba);
  });
};

export const SortByString = (arr, key, order) => {
  return arr.sort(function (a, b) {
    let nameA = a[key] ? a[key].toLowerCase() : "";
    let nameB = b[key] ? b[key].toLowerCase() : "";
    if (order === "descending" || !order) {
      if (nameA > nameB)
        //sort string descending
        return -1;
      if (nameA < nameB) return 1;
      return 0; //default return value (no sorting)
    } else {
      if (nameA < nameB)
        //sort string ascending
        return -1;
      if (nameA > nameB) return 1;
      return 0; //default return value (no sorting)
    }
  });
};

export const NumberToCurrencyStr = (num) => {
  const amt = num ? num : 0;
  return amt.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};
export const NumberToPercentStr = (num, fractionDigits) => {
  const amt = num ? num : 0;
  return amt.toLocaleString("en-US", {
    style: "percent",
    maximumFractionDigits: fractionDigits,
  });
};

/**
 * Round Number to 2 decimals
 */
export const RoundToTwoDecimals = (number) => {
  const n = number ? number : 0;
  return Math.round(n * 100) / 100;
};

export const RoundCentsToTwoDecimals = (number) => {
  const rounded = RoundToTwoDecimals(number);
  return parseFloat((rounded / 100).toFixed(2));
};

export const SumObjectValuesInArray = (array, key) => {
  let total = 0;
  array.map((i) => {
    const add = i[key];
    if (isNaN(add)) return;
    total = total + i[key];
  });
  return total;
};

/**
 * Returns false if any items are undefined
 */
export const RequireValues = (array, optionalLog) => {
  if (typeof array === "undefined") return false;
  let result = true;
  let index = -1;
  array.map((i, ind) => {
    if (!result) return;
    if (typeof i === "undefined") {
      result = false;
      index = ind;
    }
  });
  if (!result) {
    console.log(
      `${
        optionalLog ? optionalLog : ""
      }: MISSING REQUIRED VALUES AT INDEX ${index}`
    );
  }
  return result;
};
/**
 * Use Validate Model + ValidateNestedModel are to be used together
 * Takes an object to check, the model to check against, and if you want to allow errors
 * if allow errors is true, any missing props will be overridden by default values in the model
 * if allow errors is false, any errors will make the function return undefined
 */
export const ValidateModel = (object, model, allowErrors, bypass) => {
  // matches object props with model. if undefined will revert to fallback
  if (!RequireValues([object, model]))
    return { payload: {}, isValid: false, error: "Missing required params" };

  let payload = {};
  let isValid = true;
  let error = "";
  for (const [key, value] of Object.entries(model)) {
    const ignore = bypass?.includes(key);
    if (ignore) {
      console.log("Ignoring:", `${key}: ${object[key]}`);
      payload[key] = object[key];
    }

    const isNested = IsLiteralObject(value);
    if (isNested && !ignore) {
      const cleanNestedObj = CheckUndefined(object[key], value);
      const validNestedObj = ValidateNestedModel(
        cleanNestedObj,
        value,
        allowErrors
      );
      if (!validNestedObj.isValid) {
        isValid = false;
      }
      payload[key] = validNestedObj.payload;
    } else {
      const cleanValue = CheckUndefined(object[key], value);
      if (typeof object[key] === "undefined" && !ignore) {
        isValid = false;
        console.log(
          `MISSING PROP: ${key}, USING FALLBACK: ${value}. PROCEEDING: ${allowErrors}`
        );
        error = "MISSING PROPS";
      }
      payload[key] = cleanValue;
    }
  }
  if (allowErrors) {
    return payload;
  } else {
    if (!isValid) return;
    return payload;
  }
};
const ValidateNestedModel = (object, model, allowErrors) => {
  // matches object props with model. if undefined will revert to fallback
  if (!RequireValues([object, model]))
    return { payload: {}, isValid: false, error: "Missing required params" };

  let payload = {};
  let isValid = true;
  let error = "";
  for (const [key, value] of Object.entries(model)) {
    const isNested = IsLiteralObject(value);
    if (isNested) {
      if (typeof object[key] === "undefined") {
        isValid = false;
        console.log(
          `MISSING PROP: ${key}, USING FALLBACK: ${value}. PROCEEDING: ${allowErrors}`
        );
        error = "MISSING PROPS";
      }
      const cleanNestedObj = CheckUndefined(object[key], value);
      const validNestedObj = ValidateNestedModel(
        cleanNestedObj,
        value,
        allowErrors
      );
      if (!validNestedObj.isValid) {
        isValid = false;
      }
      payload[key] = validNestedObj.payload;
    } else {
      const cleanValue = CheckUndefined(object[key], value);
      if (typeof object[key] === "undefined") {
        isValid = false;
        console.log(
          `MISSING PROP: ${key}, USING FALLBACK: ${value}. PROCEEDING: ${allowErrors}`
        );
        error = "MISSING PROPS";
      }
      payload[key] = cleanValue;
    }
  }
  return { payload, isValid, error };
};

export const CheckUndefined = (i, fallbackValue) => {
  if (typeof i === "undefined") {
    return fallbackValue;
  }
  return i;
};

export const OnlyUniqueValues = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const IsLiteralObject = (i) => {
  return !!i && i.constructor === Object;
};

export const FsTimestampNow = (firestore) => {
  return firestore.Timestamp.fromDate(new Date());
};

export const ReplaceObjectInArray = (
  array,
  findKey,
  matchValue,
  replaceWith
) => {
  if (!RequireValues(array, findKey)) return;
  return array.map((i) => {
    if (i[findKey] === matchValue) return replaceWith;
    return i;
  });
};
/**
 * Returns true if array contains object matching conditions
 * conditions array should be [{key, condition, value}]
 * conditions: 'equal' | 'notEqual' | 'contains'
 */
export const ArrayContainsObjectWithMatchingConditions = (
  array,
  conditionsArray,
  type
) => {
  if (!RequireValues([array, conditionsArray])) return;
  let conditionMet = false;
  array.map((i) => {
    if (conditionMet) return;
    conditionMet = conditionsMet(i, conditionsArray, type);
  });
  return conditionMet;
};
export const FindObjectsWithMatchingConditions = (
  array,
  conditionsArray,
  type
) => {
  if (!RequireValues([array, conditionsArray])) return;
  let meetsConditions = [];
  array.map((i) => {
    if (conditionsMet(i, conditionsArray, type)) {
      meetsConditions.push(i);
    }
  });
  return meetsConditions;
};
const conditionsMet = (arrayItem, conditions, type) => {
  let result = [];
  conditions.map((c) => {
    if (!RequireValues([c?.key, c?.condition, c?.value]))
      return result.push(false);

    const { key, condition, value } = c;
    switch (condition) {
      case "==": {
        return result.push(arrayItem[key] === value);
      }
      case "!=": {
        return result.push(arrayItem[key] !== value);
      }
      case ">": {
        return result.push(arrayItem[key] > value);
      }
      case "<": {
        return result.push(arrayItem[key] < value);
      }
      case ">=": {
        return result.push(arrayItem[key] >= value);
      }
      case "<=": {
        return result.push(arrayItem[key] <= value);
      }
      case "in-array": {
        return result.push(value?.includes(arrayItem[key]));
      }
      case "value-includes": {
        return result.push(arrayItem[key].includes(value));
      }
      case "array-length-greater-than": {
        return result.push(arrayItem[key]?.length > value);
      }
      default:
        return result.push(false);
    }
  });
  return type && type === "and"
    ? !result.includes(false)
    : result.includes(true);
};

export const GroupArray = (array, key, customResultFn) => {
  const groups = array.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );
  if (!customResultFn) return groups;
  let result = {};
  for (const [key, value] of Object.entries(groups)) {
    result[key] = customResultFn(value);
  }
  return result;
};
