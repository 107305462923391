import React, { useState } from "react";
import { Popover, Space, Tag, Tooltip } from "antd";
import {
  INV_BUNDLE_ITEM,
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { CategoryTag } from "../../../app/system-components/tags/Tags";
import {
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
} from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { capitalizeFirstLetter } from "../../../app/utils/casing/capitalizeFirstLetter";
import { ExclamationCircleFilled } from "@ant-design/icons";

const TagTypeWithTooltip = ({ data, availableInventory }) => {
  const [bundleContainsUnavail, setBundleContainsUnavail] = useState(false);
  const bundleItems = (record) => {
    return (
      <Popover
        placement="right"
        title={"Items in bundle"}
        content={() => bundlePopover(record)}
        trigger="hover"
      >
        <CategoryTag
          style={{ margin: 0, fontSize: "13px", cursor: "pointer" }}
          text={
            <span>
              {bundleContainsUnavail && (
                <ExclamationCircleFilled
                  style={{ color: COLOR_RED_0, marginRight: "8px" }}
                />
              )}
              Bundle
            </span>
          }
        />
      </Popover>
    );
  };
  const bundlePopover = (record) => {
    let containsUnavail = false;
    return (
      <div>
        {record.bundleItems.map((b) => {
          const bundleItem = availableInventory?.find((i) => {
            return i.id === b.bundleItemId;
          });
          const bundleItemQty = b?.bundleItemQty ?? 0;
          const item = bundleItem?.availableStock ?? 0;
          if (item < bundleItemQty) {
            setBundleContainsUnavail(true);
          }
          return (
            <div>
              <Space>
                <CategoryTag
                  style={{
                    backgroundColor: !item
                      ? COLOR_LIGHT_GRAY
                      : item >= bundleItemQty
                      ? COLOR_LIGHT_GRAY
                      : COLOR_RED_0,
                    margin: "2px 4px 2px 0px",
                    color: !item
                      ? COLOR_DARK_BG_0
                      : item >= bundleItemQty
                      ? COLOR_DARK_BG_0
                      : "#FFF",
                    borderColor: "transparent",
                  }}
                  text={`${b[INV_BUNDLE_ITEM_QTY]}x`}
                />
                <TextBody text={b[INV_BUNDLE_ITEM_NAME]} />
                <TextSubtitle text={b[INV_BUNDLE_ITEM_DESC]} />
              </Space>
            </div>
          );
        })}
        <br />
        {containsUnavail && (
          <Space>
            <TextSubtitle
              text={"Items are unavailable during the selected range"}
              style={{ color: COLOR_RED_0, fontSize: "13px" }}
            />
          </Space>
        )}
      </div>
    );
  };
  const regularTag = () => {
    return <Tag>{capitalizeFirstLetter(data.type)}</Tag>;
  };
  return (
    <div>
      {data?.type === INV_BUNDLE_ITEM ? bundleItems(data) : regularTag()}
    </div>
  );
};

export default TagTypeWithTooltip;
