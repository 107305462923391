import { useEffect } from "react";
import "../../App.less";
import { Affix, Badge, Button, Input, PageHeader, Space } from "antd";
import { ROUTE_CREATE_INVOICE } from "../../app/routes";
import { authSelector, refreshOrgState } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { usePaymentConfirmed } from "../../app/services/hooks/usePaymentConfirmed";
import { useInvoicesFilter } from "./hooks/useInvoicesFilter";
import InvoiceFilterModal from "./components/InvoiceFilterModal";
import InvoicesDataGrid from "./InvoicesDataGrid";
import {
  COLOR_LIGHT_GRAY,
  COLOR_TEXT_GRAY_0,
} from "../../app/ui/colorConstants";
import { invoiceFilterSelector, saveFilterData } from "./invoiceSlice";
import { FilterFilled } from "@ant-design/icons";
import { i18n } from "../../i18n";

const { Search } = Input;

const Invoices = ({ history }) => {
  const { orgData } = useSelector(authSelector);

  // redux
  const initialFilterState = useSelector(invoiceFilterSelector);
  const storeFilterData = (data) => {
    dispatch(saveFilterData(data));
  };
  const filters = useInvoicesFilter({
    initialFilters: {
      range: initialFilterState?.range,
      statuses: initialFilterState?.statuses,
      types: initialFilterState?.types,
    },
    initialSearch: initialFilterState?.searchValue,
    callbackFn: storeFilterData,
  });

  const firestore = useFirestore();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!orgData) return;
    orgData && dispatch(refreshOrgState({ firestore }, orgData));
    showPaymentConfirmation();
  }, []);

  const { showPaymentConfirmation } = usePaymentConfirmed();
  const onDeleteComplete = () => {
    //delete complete
    filters.actions.fetchInvoicesInRange();
  };

  const onDuplicateComplete = async () => {
    //duplicate complete
    dispatch(refreshOrgState({ firestore }, orgData));
    filters.actions.fetchInvoicesInRange();
  };

  return (
    <div>
      <Affix>
        <PageHeader
          className="PageHeader"
          title={i18n("invoices.sales")}
          extra={[
            <div>
              <Space size={12} className="make-the-sale-header">
                <Button
                  onClick={() => filters.actions.setShowModal(true)}
                  style={{
                    background: COLOR_LIGHT_GRAY,
                    borderColor: "transparent",
                  }}
                >
                  <FilterFilled style={{ color: COLOR_TEXT_GRAY_0 }} />
                  {filters.state.rangeText}
                  {filters.state.isActive && <Badge dot offset={[-2, -5]} />}
                </Button>
                <Search
                  defaultValue={initialFilterState?.searchValue ?? ""}
                  allowClear={true}
                  key="Searchbar"
                  placeholder={i18n("invoices.invoiceSearchPlaceholder")}
                  onSearch={(value) => filters.actions.setSearchValue(value)}
                  style={{
                    width: 320,
                    fontWeight: "bold",
                  }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(ROUTE_CREATE_INVOICE);
                  }}
                >
                  {i18n("invoices.newOrder")}
                </Button>
              </Space>
            </div>,
          ]}
        />
      </Affix>
      <div className="make-the-sale-invoices">
        <InvoicesDataGrid
          fetching={filters.state.fetching}
          filteredInvoices={filters.state.invoiceData}
          data={filters.state.invoiceData}
          onDeleteComplete={onDeleteComplete}
          onDuplicateComplete={onDuplicateComplete}
          history={history}
        />
      </div>
      <InvoiceFilterModal filters={filters} />
    </div>
  );
};

export default Invoices;
