import React, { useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Space,
  Tooltip,
  Progress,
  List,
} from "antd";
import TextBody from "../../app/system-components/typography/text/TextBody";
import { PictureFilled } from "@ant-design/icons";
import {
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
} from "../../app/ui/colorConstants";
import TextSubtitle from "../../app/system-components/typography/text/TextSubtitle";
import QuickAdd from "./components/QuickAdd";
import InvMetaDataList from "./components/InvMetaDataList";
import TagTypeWithTooltip from "./components/TagTypeWithTooltip";
import { i18n } from "../../i18n";
import { useAvailableInventoryPaginated } from "../../app/services/hooks/useAvailableInventoryPaginated";

const AvailableInventory = ({
  rentalDates,
  inventory,
  searchStr,
  cart,
  setCart,
  setEmptyState,
}) => {
  // const {
  //   data: availableInventory,
  //   fetching: fetchingInvoices,
  //   refresh: refreshInvoices,
  // } = useInvoicesInRange({ useTurnaround: true, ...rentalDates }, inventory);
  useEffect(() => {
    let unmounted = false;
    !unmounted && refreshInvoices();
    return () => {
      unmounted = true;
    };
  }, [rentalDates, searchStr, inventory]);

  const {
    availableInventory,
    fetchingInvoices,
    refreshInvoices,
    fullInventory,
  } = useAvailableInventoryPaginated({
    startEndOpts: { useTurnaround: true, ...rentalDates },
    filterInventoryIds: inventory?.map((i) => i.id),
    invoiceItems: [],
    ignoreInvoicesForAvailability: [],
  });

  const configurePercent = (i) => {
    const { availableStock, stock, unavailableStock, unavailableEstStock } = i;
    return stock ? (availableStock / stock) * 100 : 0;
  };

  const configureSuccessPercent = (i) => {
    const { availableStock, stock, unavailableStock, unavailableEstStock } = i;
    return stock ? ((availableStock - unavailableEstStock) / stock) * 100 : 0;
  };

  const handleEmptyState = () => {
    setEmptyState(!hasRentalDates());
    return (
      <TextBody
        style={{ color: COLOR_TEXT_GRAY_1 }}
        text={i18n("availabilityPage.selectDates")}
      />
    );
  };

  const hasRentalDates = () => {
    return (
      rentalDates.rentalDateStart !== null && rentalDates.rentalDateEnd !== null
    );
  };
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 217px)",
        overflowY: "auto",
      }}
    >
      {hasRentalDates() ? (
        <List
          locale={{ emptyText: fetchingInvoices ? "Loading.." : "No items" }}
          itemLayout="horizontal"
          dataSource={availableInventory}
          renderItem={(i) => (
            <List.Item>
              <Card style={{ width: "100%" }} size="small" key={i.id}>
                <Row align="middle" gutter={12} justify={"space-between"}>
                  <Col style={{ marginRight: "24px" }}>
                    <Space>
                      <div>
                        {i.image ? (
                          <img
                            src={i.image && i.image.downloadURL}
                            style={{
                              height: "76px",
                              width: "76px",
                              objectFit: "contain",
                            }}
                            alt={i.image && i.image.fileName}
                          />
                        ) : (
                          <PictureFilled
                            style={{
                              color: COLOR_TEXT_GRAY_2,
                              fontSize: "76px",
                            }}
                          />
                        )}
                      </div>
                      <div style={{ width: "100%" }}>
                        <Space>
                          <TextBody
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                            text={i.name}
                          />
                          <TextSubtitle
                            style={{ fontSize: "14px" }}
                            text={i.description}
                          />
                          <TagTypeWithTooltip
                            data={i}
                            availableInventory={fullInventory}
                          />
                        </Space>
                        <div style={{ width: "200px", marginTop: "4px" }}>
                          {i.stock ? (
                            <Tooltip
                              title={`Available: ${
                                i.availableStock
                              } | Pending: ${i.unavailableEstStock} | Total: ${
                                i.stock ? i.stock : "NA"
                              }`}
                            >
                              <Progress
                                strokeColor={"#BAE7CB"}
                                format={(p) => (
                                  <TextSubtitle
                                    text={`${i.availableStock} Left`}
                                    color={
                                      i.availableStock < 0
                                        ? "#FF0000"
                                        : "#4A4A4D"
                                    }
                                  />
                                )}
                                percent={configurePercent(i)}
                                success={{
                                  percent: configureSuccessPercent(i),
                                  strokeColor: "#38BB69",
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <TextSubtitle text={`No stock info available`} />
                          )}
                        </div>
                      </div>
                    </Space>
                  </Col>
                  <Col>
                    <QuickAdd
                      itemMax={i.availableStock}
                      setCart={setCart}
                      cart={cart}
                      itemId={i.id}
                      item={i}
                    />
                  </Col>
                </Row>
                {i.invoices && (
                  <InvMetaDataList
                    key={i.selectedItemId}
                    invoices={i.invoices}
                    itemId={i.id}
                  />
                )}
              </Card>
            </List.Item>
          )}
        />
      ) : (
        handleEmptyState()
      )}
    </div>
  );
};

export default AvailableInventory;

export const useAvailableInventoryDbWrapper = () => {};
