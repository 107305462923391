import { useIsAuthenticated } from "../useIsAuthenticated";
import notificationError from "../../../system-components/toasters/notificationError";
import moment from "moment";

import {
  INV_BUNDLE_ITEM,
  INV_BUNDLE_ITEM_ID,
  INVOICE,
  INVOICE_ESTIMATE,
  INVOICE_INV_ITEM,
  ROW_TYPE_SUBTOTAL,
} from "../../../utils/models/modelConstants/modelConstants";
import { invoices } from "../../firestore/queries/invoiceQueries";
import { DetermineApplyTurnaround } from "../helpers/determineApplyTurnaround";
import { DetermineApplyBundleTurnaround } from "../helpers/determineBundleApplyTurnaround";
import { ConfigureInvoiceEndDate } from "../helpers/configureInvoiceEndDate";
import onlyUnique from "../../../utils/filters/filterOnlyUnique";
import { useGetInventoryQuery } from "../../api";

const { useState, useEffect } = require("react");
const { useFirestore } = require("react-redux-firebase");

export function useInvoicesInRange(
  startEndOpts,
  inventory,
  invoiceItems,
  ignoreInvoicesForAvailability
) {
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [invoicesInRange, setInvoicesInRange] = useState([]);
  const [error, setError] = useState({
    hasError: false,
    error: null,
  });
  const [refresh, setRefresh] = useState({
    refresh: false,
    count: 0,
  });
  const { isAuthenticated, fsOrgPrefix, orgData } = useIsAuthenticated();
  const firestore = useFirestore();

  const refreshData = () => {
    setRefresh({
      refresh: true,
      count: 0,
    });
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    if (refresh.count > 0) return;
    if (!startEndOpts.rentalDateStart || !startEndOpts.rentalDateEnd) return;
    if (fetching) return;
    try {
      setFetching(true);
      setData([]);
      setInvoicesInRange([]);
      invoices(orgData?.orgTimezone)
        .fetchInvoicesInRange({ firestore }, fsOrgPrefix, startEndOpts)
        .then((res) => {
          setInvoicesInRange(res);
          setFetching(false);
          setRefresh({ refresh: false, count: refresh.count + 1 });
        });
    } catch (err) {
      console.log(err);
      notificationError(
        "Something went wrong",
        "Please try again or refresh the page"
      );
      setFetching(false);
      setError({
        hasError: true,
        error: err,
      });
      setRefresh({ refresh: false, count: refresh.count + 1 });
    }
  }, [
    isAuthenticated,
    refresh,
    startEndOpts.rentalDateStart,
    startEndOpts.rentalDateEnd,
    inventory,
  ]);

  useEffect(() => {
    let useTurnaround = startEndOpts.useTurnaround;
    let reduceStock = [];
    let updatedInventory = inventory.map((x) => {
      const { stock, availableStock, ...rest } = x;
      return {
        ...rest,
        stock: stock,
        availableStock: stock,
        unavailableStock: 0,
        unavailableEstStock: 0,
      };
    });
    invoicesInRange &&
      invoicesInRange.map((i) => {
        if (ignoreInvoicesForAvailability?.includes(i?.id)) return;
        // sum up inventory items to be filtered
        const { selectedItems, type, ...rest } = i;
        selectedItems.items.map((item) => {
          const stillApplicable = DetermineApplyTurnaround({
            useTurnaround: useTurnaround,
            invoice: i,
            item: item,
            queryRentalStartDate: startEndOpts.rentalDateStart,
          });
          const isInvoice = type === INVOICE;
          const isEstimate = type === INVOICE_ESTIMATE;
          if (item.rowType === ROW_TYPE_SUBTOTAL) return; // esc subtotals
          if (item.type === INV_BUNDLE_ITEM) {
            item.bundleItems?.map((bi) => {
              const bundleApplicable = DetermineApplyBundleTurnaround({
                useTurnaround: useTurnaround,
                invoice: i,
                bundleItem: bi,
                queryRentalStartDate: startEndOpts.rentalDateStart,
                inheritParentTurnaround: item.inheritParentTurnaround,
                parent: item,
              });
              const exists = reduceStock.find(
                (e) => e.id === bi[INV_BUNDLE_ITEM_ID]
              );
              if (exists && bundleApplicable) {
                const invoiceArr = exists.invoices
                  ? exists.invoices.filter((inv) => inv.id !== i.id)
                  : [];
                const obj = {
                  id: exists.id,
                  invoiceQty: isInvoice
                    ? exists.invoiceQty + bi.bundleItemQty * item.selectedQty
                    : exists.invoiceQty,
                  estimateQty: isEstimate
                    ? exists.estimateQty + bi.bundleItemQty * item.selectedQty
                    : exists.estimateQty,
                  invoices: isInvoice ? [i, ...invoiceArr] : exists.invoices,
                };
                reduceStock = reduceStock.filter(
                  (i) => i.id !== bi.bundleItemId
                );
                reduceStock.push(obj);
              } else {
                if (!bundleApplicable) return;
                const qty = bi.bundleItemQty * item.selectedQty;
                reduceStock.push({
                  id: bi.bundleItemId,
                  invoiceQty: isInvoice
                    ? bi.bundleItemQty * item.selectedQty
                    : 0,
                  estimateQty: isEstimate
                    ? bi.bundleItemQty * item.selectedQty
                    : 0,
                  invoices: isInvoice ? [i] : [],
                });
              }
            });
          }

          const exists = reduceStock.find((i) => i.id === item.id);
          if (exists && stillApplicable) {
            const invoiceArr = exists.invoices
              ? exists.invoices.filter((inv) => inv.id !== i.id)
              : [];
            const obj = {
              id: exists.id,
              invoiceQty: isInvoice
                ? exists.invoiceQty + item.selectedQty
                : exists.invoiceQty,
              estimateQty: isEstimate
                ? exists.estimateQty + item.selectedQty
                : exists.estimateQty,
              invoices: isInvoice ? [i, ...invoiceArr] : exists.invoices,
            };
            reduceStock = reduceStock.filter((i) => i.id !== item.id);
            reduceStock.push(obj);
          } else {
            if (!stillApplicable) return;
            reduceStock.push({
              id: item.id,
              invoiceQty: isInvoice ? item.selectedQty : 0,
              estimateQty: isEstimate ? item.selectedQty : 0,
              invoices: isInvoice ? [i] : [],
            });
          }
        });
      });
    reduceStock.map((s) => {
      // // reduce items in inventory
      const item = inventory.find((ui) => ui.id === s.id);
      if (item) {
        const {
          stock,
          availableStock,
          unavailableStock,
          unavailableEstStock,
          ...rest
        } = item;
        const newInvoiceStock = stock ? stock - s.invoiceQty : null;
        const updated = {
          ...rest,
          stock: stock,
          availableStock: newInvoiceStock,
          unavailableStock: s.invoiceQty ? s.invoiceQty : 0,
          unavailableEstStock: s.estimateQty ? s.estimateQty : 0,
          invoices: s.invoices ? s.invoices : [],
        };
        updatedInventory = [...updatedInventory].filter((i) => i.id !== s.id);
        updatedInventory.push(updated);
      }
    });
    setData([...updatedInventory]);
  }, [invoicesInRange]);

  return { data: data, fetching: fetching, error: error, refresh: refreshData };
}
