import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";

import { SendInvoiceContext } from "../InvoiceDetail";
import {
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Checkbox,
  InputNumber,
  Select,
  Space,
} from "antd";
import { useSendInvoice } from "../../../app/services/hooks/useSendInvoice";
import { useSelector } from "react-redux";

import InvoicePdfViewer from "./InvoicePdfViewer";
import { emailInvoice } from "../../../app/services/cloud-functions/emailInvoice";
import { useIsAuthenticated } from "../../../app/services/hooks/useIsAuthenticated";
import { useFirebase, useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { ROUTE_EDIT_INVOICE, ROUTE_INVOICES } from "../../../app/routes";
import { useHistory } from "react-router-dom";
import {
  INVOICE,
  INVOICE_ESTIMATE,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { emailSettingsQuery } from "../../../app/services/firestore/queries/orgQueries";
import { authSelector } from "../../auth/authSlice";
import {
  INVOICES_COLLECTION,
  SEND_RECORDS,
} from "../../../app/utils/models/collections/collectionConstants";
import { dollarToCents } from "../../../app/utils/models/configure/configureCurrencyToCents";
import { Check } from "@mui/icons-material";
import { UserAddOutlined } from "@ant-design/icons";
import { COLOR_TEXT_GRAY_1 } from "../../../app/ui/colorConstants";
const { Option } = Select;

const SendInvoiceModal = ({ venues }) => {
  const modal = useContext(SendInvoiceContext);
  const { fetching, customerEmail, customer } = useSendInvoice(
    modal?.data?.customerId
  );

  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);

  const firebase = useFirebase();
  const firestore = useFirestore();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [emailSettings, setEmailSettings] = useState(null);
  const [emailRecipients, setEmailRecipients] = useState("");

  const [form] = Form.useForm();

  //onlinePayments Checkbox Toggle Settings
  const [includePaymentLinkChecked, setIncludePaymentLinkCheckedChecked] =
    useState(false);
  const [useDefaultDeposit, setUseDefaultDeposit] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const toggleChecked = () => {
    setIncludePaymentLinkCheckedChecked(!includePaymentLinkChecked);
  };
  const toggleDisable = () => {
    setDisabled(!disabled);
  };
  const onlinePaymentsChecked = (e) => {
    setIncludePaymentLinkCheckedChecked(e.target.checked);
    toggleDisable();
  };

  const handleAddEmail = (e) => {
    console.log(e);
    const newRecipients = emailRecipients + `,${e}`;
    setEmailRecipients(newRecipients);
    form.setFieldsValue({
      recipients: newRecipients,
    });
  };

  const balanceRemaining =
    Math.round(modal.data?.invoice?.balanceRemaining * 100) / 100;
  const stripeAccount = modal.data?.orgData?.stripeAccount;
  const [paymentAmt, setPaymentAmt] = useState(0);

  useEffect(() => {
    if (!orgData) return;
    // if (!modal.visible) return;
    setLoading(true);
    console.log(modal.data);
    setPaymentAmt(balanceRemaining);
    emailSettingsQuery({ firestore }, fsOrgPrefix)
      .fetchEmailSettings(orgData.id, modal.data?.settingsId)
      .then((settings) => {
        setLoading(false);
        if (settings) {
          setEmailSettings(settings);
          setEmailRecipients(customerEmail ?? null);
          form.setFieldsValue({
            recipients: customerEmail ?? null,
            emailBody: settings?.emailBody,
            emailSubject: settings?.emailSubject,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [
    customerEmail,
    firestore,
    form,
    fsOrgPrefix,
    modal.data?.settingsId,
    orgData?.id,
  ]);

  return (
    <Modal
      title={`${
        modal.data?.sendType === "signatureRequest"
          ? "Send Signature Request"
          : modal.data?.invoice?.type === INVOICE
          ? "Send Invoice"
          : "Send Estimate"
      }`}
      open={modal.visible}
      destroyOnClose
      width={1200}
      cancelButtonProps={{ disabled: modal.loading }}
      onCancel={() => {
        if (modal.data?.isNew) {
          history.push(`${ROUTE_EDIT_INVOICE}${modal.data?.invoice?.id}`);
        } else {
          modal.hide();
        }
      }}
      onOk={async () => {
        // validate, hit cf then hide
        form.validateFields().then((fields) => {
          modal.ok(async () => {
            const replyToEmail = orgData?.useAdminEmailReply
              ? orgData?.adminEmail
              : userData?.email ?? null;
            const name = `${userData?.firstName} ${userData?.lastName}`;
            const sendRecordPayload = {
              createdOn: new Date(),
              createdBy: userData.id,
              paymentAmt: includePaymentLinkChecked
                ? dollarToCents(paymentAmt)
                : dollarToCents(balanceRemaining),
              sendPaymentLink: includePaymentLinkChecked,
            };
            const ref = firestore
              .collection(
                `${fsOrgPrefix}${INVOICES_COLLECTION}/${modal.data?.invoice?.id}/${SEND_RECORDS}`
              )
              .doc();
            await ref.set({
              id: ref.id,
              ...sendRecordPayload,
            });

            try {
              await emailInvoice(
                { firebase },
                {
                  sendType: modal.data?.sendType,
                  settingsId: modal.data?.settingsId,
                  orgId: orgData?.id,
                  invoiceId: modal.data?.invoice?.id,
                  emails: fields.recipients
                    .toString()
                    .trim()
                    .split(",")
                    .map((e) => e.trim()),
                  subjectLine: fields.emailSubject,
                  emailBody: fields.emailBody,
                  replyTo: {
                    email: replyToEmail,
                    name,
                  },
                  sendPaymentLink: includePaymentLinkChecked,
                  sendRecordId: ref.id,
                }
              );
              notificationConfirm(
                modal.data?.invoice?.type === INVOICE
                  ? "Invoice sent"
                  : "Estimate sent"
              );
              history.push(ROUTE_INVOICES);
            } catch (err) {
              console.log(err);
              notificationError(
                "Something went wrong",
                "Please try again later"
              );
            }
          });
        });
      }}
      okText={"Send"}
      confirmLoading={modal?.loading}
    >
      <Row wrap={false} gutter={14}>
        <Col span={8} className="send-invoice-email-fields">
          <Form form={form} layout={"vertical"}>
            <Form.Item
              label={
                <span>
                  Send to: {customer?.customerDisplayName}. <br /> You can CC
                  more emails by comma separation, or select from customers
                  additional contacts.
                </span>
              }
              name={"recipients"}
              rules={[
                {
                  required: true,
                  message: "Email required",
                },
              ]}
            >
              <Input placeholder={"email"} value={emailRecipients} />
            </Form.Item>

            {customer &&
              customer.additionalContacts &&
              customer.additionalContacts.length > 0 && (
                <>
                  <Space size={4} align="center">
                    <UserAddOutlined
                      style={{
                        marginRight: "4px",
                        color: COLOR_TEXT_GRAY_1,
                        fontSize: "18px",
                      }}
                    />
                    <Form.Item
                      name="additionalContacts"
                      className="NewInvoiceCustomerForm"
                    >
                      <Select
                        menuItemSelectedIcon=<Check fontSize="10px" />
                        loading={loading}
                        onSelect={handleAddEmail}
                        placeholder={"Select contact"}
                        className={"customer-picker"}
                      >
                        {customer &&
                          customer.additionalContacts &&
                          customer.additionalContacts
                          .filter((c) => c.email)
                          .map((c) => (
                            <Option value={c.email} key={c.id}>
                              {c.email}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Space>
                  <br />
                </>
              )}

            <Form.Item
              label={"Email subject"}
              name={"emailSubject"}
              rules={[
                {
                  required: true,
                  message: "Subject required",
                },
              ]}
            >
              <Input placeholder={"Subject"} />
            </Form.Item>
            <Form.Item label={"Email body"} name={"emailBody"}>
              <ReactQuill
                name={"emailBody"}
                modules={{
                  toolbar: [["bold", "italic", "underline"], ["link"]],
                }}
                placeholder="Email Body"
                theme="snow"
                style={{
                  height: "100px",
                  marginBottom: "24px",
                }}
              />
            </Form.Item>
            <br />
            <Form.Item
              label={"Online Payments"}
              name={"onlinePayments"}
              style={
                modal.data?.sendType === "signatureRequest" ||
                stripeAccount === null
                  ? { display: "none" }
                  : { display: "contents" }
              }
            >
              <Checkbox
                onChange={onlinePaymentsChecked}
                defaultChecked={false}
                checked={includePaymentLinkChecked}
              >
                Include a payment link with this email?{" "}
              </Checkbox>
              <br />
              <br />

              <InputNumber
                value={paymentAmt}
                addonBefore="Payment Amount"
                onChange={setPaymentAmt}
                defaultValue={ Math.round(modal.data?.invoice?.balanceRemaining * 100) / 100}
                disabled={disabled}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
              <br />
              <br />

              {!disabled && (
                <Checkbox
                  onChange={(e) => {
                    console.log(e.target.checked);
                    const checked = e.target.checked;
                    if (checked) {
                      const depositAmount = (
                        balanceRemaining *
                        (orgData.defaultDepositPercent / 100)
                      ).toFixed(2);
                      setPaymentAmt(depositAmount);
                    } else {
                      setPaymentAmt(balanceRemaining);
                    }
                  }}
                  defaultChecked={false}
                >
                  Apply default security deposit amount
                  {`: ${orgData.defaultDepositPercent}%`}
                </Checkbox>
              )}

              {modal.data?.invoice?.type === INVOICE_ESTIMATE &&
                !orgData.automaticallyConvertEstimate && (
                  <div>
                    <br></br>
                    Note: Estimates will not automatically convert to Invoices
                    until payments are successful. ACH payments may take several
                    days, which means the inventory will not be considered
                    rented until the payment is finalized.
                  </div>
                )}
            </Form.Item>
          </Form>
        </Col>
        <Col
          span={16}
          style={{ paddingLeft: "12px" }}
          className="invoice-pdf-preview"
        >
          <Card
            size={"small"}
            style={{
              overflowY: "scroll",
              maxHeight: 1000,
              boxShadow: "0px 4px 16px rgba(26, 26, 53, 0.15)",
            }}
          >
            {emailSettings && modal.data?.invoice && (
              <InvoicePdfViewer
                invoice={modal.data?.invoice}
                emailSettings={emailSettings}
                venues={venues}
              />
            )}
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default SendInvoiceModal;
