import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Divider,
  Space,
  Tooltip,
} from "antd";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import {
  VENUE_CONTACT_NAME,
  VENUE_NAME,
  VENUE_ADDRESS,
  VENUE_CITY,
  VENUE_STATE,
  VENUE_ZIP,
  VENUE_NOTES,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { configureVenue } from "../../../app/utils/models/configure/configureVenue";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { venueWrites } from "../../../app/services/firestore/writes/venueWrites";
import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";

const AddVenueModal = ({ visible, onCancel, onComplete }) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const firestore = useFirestore();
  const handleFinish = () => {
    form.validateFields().then(() => {
      setSaving(true);
      const values = form.getFieldsValue();
      const data = {
        ...values,
      };
      const payload = configureVenue().newVenue(data, userData.id, orgData);
      venueWrites({ firestore }, fsOrgPrefix)
        .createVenue(payload)
        .then((res) => {
          clearBeforeClose();
          onComplete(res);
          notificationConfirm("Venue created", "");
        })
        .catch((err) => {
          clearBeforeClose();
          console.log(err);
          notificationError(
            "Something went wrong",
            "Venue could not be created"
          );
        });
    });
  };

  const clearBeforeClose = () => {
    setSaving(false);
    form.resetFields();
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      [VENUE_CONTACT_NAME]: values[VENUE_CONTACT_NAME],
      [VENUE_NAME]: values[VENUE_NAME],
      [VENUE_ADDRESS]: values[VENUE_ADDRESS],
      [VENUE_CITY]: values[VENUE_CITY],
      [VENUE_STATE]: values[VENUE_STATE],
      [VENUE_ZIP]: values[VENUE_ZIP],
    });
  });

  const handleValuesChanged = (e, all) => {
    console.log("handle values changed");
    const co =
      all[VENUE_NAME] !== ""
        ? ` - ${all[VENUE_NAME]}`
        : all[VENUE_NAME] !== ""
        ? `${all[VENUE_NAME]}`
        : "";
    const currDisplay = all[VENUE_NAME];
  };
  const { TextArea } = Input;

  return (
    <Modal
      destroyOnClose
      width={1000}
      visible={visible}
      closeIcon={<></>}
      title="Add Venue"
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
          disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={saving}
          loading={saving}
        >
          Add Venue
        </Button>,
      ]}
    >
      <div>
        <Form form={form} onValuesChange={handleValuesChanged}>
          <div style={{ marginBottom: "12px" }}>
            <TextSubtitle text={"VENUE INFO"} />
          </div>
          <Row gutter={24} wrap={false}>
            <Col span={12}>
              <Form.Item
                name={VENUE_NAME}
                initialValue=""
                style={{ marginBottom: "12px" }}
              >
                <Input placeholder="Venue name" />
              </Form.Item>
              <Space
                style={{ width: "100%", display: "inline-block" }}
                direction={"vertical"}
                align={"start"}
              >
                <Form.Item
                  name={VENUE_CONTACT_NAME}
                  initialValue=""
                  style={{ marginBottom: "12px" }}
                >
                  <Input placeholder="Venue contact name" />
                </Form.Item>
              </Space>
            </Col>
            <Col span={12}>
              <Tooltip
                trigger={["hover"]}
                title="Text entered here will show up in the notes section of an estimate or invoice if this venue is added as either the receive or return location."
                placement="topLeft"
              >
                <Form.Item
                  name={VENUE_NOTES}
                  initialValue=""
                  style={{ marginBottom: "12px" }}
                >
                  <TextArea rows={3} placeholder="Venue notes" />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
          <Row gutter={12} wrap={false}>
            <Col span={12}></Col>
          </Row>
          <Divider />
          <Row wrap={false} justify="space-between" gutter={24}>
            <Col>
              <Row justify={"space-between"} style={{ marginBottom: "2px" }}>
                <Col>
                  <TextSubtitle text={"VENUE ADDRESS"} />
                </Col>
                <Col style={{ textAlign: "top" }}>
                  <Form.Item
                    name={VENUE_ADDRESS}
                    style={{ marginBottom: 0 }}
                  ></Form.Item>
                </Col>
              </Row>
              <Form.Item
                initialValue=""
                name={VENUE_ADDRESS}
                style={{ marginBottom: "12px" }}
              >
                <Input placeholder="Address1" />
              </Form.Item>
              <Row wrap={false} justify="space-between">
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={VENUE_CITY}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={VENUE_STATE}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="State" />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    initialValue=""
                    name={VENUE_ZIP}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input placeholder="ZIP" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddVenueModal;
