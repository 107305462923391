import { DetermineApplyTurnaround } from "./determineApplyTurnaround";
import {
  INV_BUNDLE_ITEM,
  INV_BUNDLE_ITEM_ID,
  INVOICE,
  INVOICE_ESTIMATE,
  ROW_TYPE_SUBTOTAL,
} from "../../../utils/models/modelConstants/modelConstants";
import { DetermineApplyBundleTurnaround } from "./determineBundleApplyTurnaround";
import moment from "moment";
export const ReduceStockByDay = ({
  useTurnaround,
  inventory,
  invoicesInRange,
  startDate,
}) => {
  let reduceStock = [];
  let finalStockList = [];
  let sameDayItems = [];
  let updatedInventory =
    inventory &&
    inventory.map((x) => {
      const { stock, availableStock, ...rest } = x;
      return {
        name: x.name,
        id: x.id,
        stock: stock,
        availableStock: stock,
        unavailableStock: 0,
        isOverbooked: false,
      };
    });
  invoicesInRange &&
    invoicesInRange.map((i) => {
      // sum up inventory items to be filtered
      const { selectedItems, type, ...rest } = i;
      const sameDayStart = moment(startDate).startOf("day");
      const sameDayEnd = moment(startDate).endOf("day");

      const invoiceStart = moment(i.rentalDateStart.seconds * 1000);
      const isSameDayStart =
        invoiceStart.toDate() >= sameDayStart.toDate() &&
        invoiceStart.toDate() <= sameDayEnd.toDate();
      selectedItems.items?.map((item) => {
        const stillApplicable = DetermineApplyTurnaround({
          useTurnaround: useTurnaround,
          invoice: i,
          item: item,
          queryRentalStartDate: startDate,
        });

        if (item.rowType === ROW_TYPE_SUBTOTAL) return; // esc subtotals
        if (item.type === INV_BUNDLE_ITEM) {
          item.bundleItems?.map((bi) => {
            const bundleApplicable = DetermineApplyBundleTurnaround({
              useTurnaround: useTurnaround,
              invoice: i,
              bundleItem: bi,
              queryRentalStartDate: startDate,
              inheritParentTurnaround: item.inheritParentTurnaround,
              parent: item,
            });
            const exists = reduceStock.find(
              (e) => e.id === bi[INV_BUNDLE_ITEM_ID]
            );
            const existsSameDay = sameDayItems.find(
              (e) => e.id === bi[INV_BUNDLE_ITEM_ID]
            );
            if (exists && bundleApplicable) {
              const obj = {
                id: exists.id,
                invoiceQty:
                  exists.invoiceQty + bi.bundleItemQty * item.selectedQty,
              };
              reduceStock = reduceStock.filter((i) => i.id !== bi.bundleItemId);
              reduceStock.push(obj);
            } else {
              if (!bundleApplicable) return;
              reduceStock.push({
                id: bi.bundleItemId,
                invoiceQty: bi.bundleItemQty * item.selectedQty,
              });
            }
            if (isSameDayStart && existsSameDay && bundleApplicable) {
              const obj = {
                id: existsSameDay.id,
                invoiceQty:
                  existsSameDay.invoiceQty +
                  bi.bundleItemQty * item.selectedQty,
              };
              sameDayItems = sameDayItems.filter(
                (i) => i.id !== bi.bundleItemId
              );
              sameDayItems.push(obj);
            } else if (isSameDayStart) {
              if (!bundleApplicable) return;
              sameDayItems.push({
                id: bi.bundleItemId,
                invoiceQty: bi.bundleItemQty * item.selectedQty,
              });
            }
          });
        }
        const exists = reduceStock.find((i) => i.id === item.id);
        const existsSameDay = sameDayItems.find((i) => i.id === item.id);
        if (exists && stillApplicable) {
          const obj = {
            id: exists.id,
            invoiceQty: exists.invoiceQty + item.selectedQty,
          };
          reduceStock = reduceStock.filter((i) => i.id !== item.id);
          reduceStock.push(obj);
        } else {
          if (!stillApplicable) return;
          reduceStock.push({
            id: item.id,
            invoiceQty: item.selectedQty,
          });
        }
        if (isSameDayStart && existsSameDay && stillApplicable) {
          const obj = {
            id: existsSameDay.id,
            invoiceQty: existsSameDay.invoiceQty + item.selectedQty,
          };
          sameDayItems = sameDayItems.filter((i) => i.id !== item.id);
          sameDayItems.push(obj);
        } else if (isSameDayStart) {
          if (!stillApplicable) return;
          sameDayItems.push({
            id: item.id,
            invoiceQty: item.selectedQty,
          });
        }
      });
    });

  reduceStock.map((s) => {
    // reduce items in inventory
    const item = updatedInventory.find((ui) => ui.id === s.id);
    const sameDay = sameDayItems.find((i) => i.id === s.id);
    if (item) {
      const { stock, availableStock, unavailableStock, ...rest } = item;
      const newInvoiceStock = stock ? stock - s.invoiceQty : null;
      const unavail = s.invoiceQty ? s.invoiceQty : 0;
      const availStock = newInvoiceStock + (sameDay ? sameDay.invoiceQty : 0);
      const isOverbooked = unavail > stock;
      const updated = {
        id: item.id,
        name: item.name,
        stock: stock,
        availableStock: availStock,
        unavailableStock: unavail,
        isOverbooked: isOverbooked,
      };
      finalStockList.push(updated);
      updatedInventory = [...updatedInventory].filter((i) => i.id !== s.id);
      updatedInventory.push(updated);
    }
  });
  
  return finalStockList;
};
