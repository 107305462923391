import { useEffect, useState } from "react";
import { useInvoicesInRange } from "./fetch/useInvoicesInRange";
import moment from "moment";
import Nifty from "../../utils/Nifty";
import {
  INV_BUNDLE_ITEM,
  INV_SALE_ITEM,
  INVOICE,
  ROW_TYPE_SUBTOTAL,
} from "../../utils/models/modelConstants/modelConstants";

export function useAvailableInvInvoice({
  startEndOps,
  inventory,
  invoiceItems,
  initialInvoice,
}) {
  const optsStart = startEndOps?.rentalDateStart ?? null;
  const optsEnd = startEndOps?.rentalDateEnd ?? null;
  const start = optsStart
    ? moment(optsStart, "MMM Do YYYY").startOf("day").toDate()
    : null;
  const end = optsEnd
    ? moment(optsEnd, "MMM Do YYYY").endOf("day").toDate()
    : null;
  const stock = useInvoicesInRange(
    {
      useTurnaround: true,
      rentalDateStart: start,
      rentalDateEnd: end,
    },
    inventory,
    invoiceItems,
    initialInvoice?.id ? [initialInvoice.id] : []
  );

  const [availableStock, setAvailableStock] = useState([]);
  const [showOverbookedError, setShowOverbookedError] = useState(false);

  useEffect(() => {
    stock.refresh();
  }, [optsStart, optsEnd]);
  useEffect(() => {
    if (stock.fetching) return;
    // flatten and sum current invoice items
    const flatInvoiceItems = flattenCurrentInvoiceItems(invoiceItems);
    const flatStockFromUnavailable = flattenStockFromInvoices(stock?.data);
    const allItems = sumGroupedArrayQtyById(
      flatInvoiceItems.concat(flatStockFromUnavailable)
    );
    setAvailableStock(allItems.totals);

    // overbooked
    const hasOverbooked = containsOverbooked(allItems.totals, flatInvoiceItems);
    setShowOverbookedError(hasOverbooked);
  }, [initialInvoice, stock.data, invoiceItems, stock.fetching]);

  return {
    state: { availableStock, showOverbookedError },
    actions: {},
  };
}

const flattenCurrentInvoiceItems = (invoiceItems) => {
  /**
   * Flatten all items (bundle, regular, and bundle parents) and sum up quantities
   * Runs everytime invoice items change
   */

  // for each item, if bundle create list of objects with id and qty * bundle qty
  const itemsRemovedSubtotals = invoiceItems?.filter(
    (i) => i.type !== ROW_TYPE_SUBTOTAL
  );
  let items = [];
  itemsRemovedSubtotals?.map((i) => {
    if (i.type === INV_BUNDLE_ITEM) {
      const multiplier = i?.selectedQty ?? 0;
      i?.bundleItems?.map((bi) => {
        items.push({
          id: bi?.bundleItemId ?? "",
          qty: -(bi?.bundleItemQty * multiplier),
        });
      });
    }
    // other types + bundle parents
    items.push({
      id: i?.id,
      qty: -(i?.selectedQty ?? 0),
    });
  });
  return items;
};

const flattenStockFromInvoices = (stock) => {
  return stock?.map((s) => {
    return {
      id: s.id,
      qty: s?.availableStock ?? 0,
    };
  });
};

const sumGroupedArrayQtyById = (items) => {
  const totals = Nifty.GroupArray(items, "id", (arr) => {
    let total = 0;
    arr?.map((i) => {
      total = total + i?.qty;
    });
    return total;
  });
  return { totals };
};

const containsOverbooked = (groupedArray, flatInvoiceItems) => {
  // determines which flat invoices items are overbooked and returns true if so
  let hasOverbookedItems = false;
  flatInvoiceItems?.map((i) => {
    const isNegative = groupedArray[i.id] < 0;
    if (!isNegative) return;
    if (hasOverbookedItems) return;
    hasOverbookedItems = true;
  });
  return hasOverbookedItems;
};
