import { dollarToCents } from "./configureCurrencyToCents";
import { formatDateOnWrite } from "../../time/formatTimezoneOffset";

export const ConfigurePayment = ({ payload, stripeIntentId }) => {
  const {
    customer,
    paymentAmt,
    creditAmt,
    fulfilled,
    paymentDate,
    method,
    refNo,
  } = payload;

  const invoicesQueryHook = () => {
    return fulfilled.map((i) => i.id);
  };

  const convertedFulfilled = (fulfilled) => {
    return fulfilled.map((i) => {
      const { fulfilled, id, ...rest } = i;
      return {
        amount: dollarToCents(fulfilled),
        invoiceId: id,
        ...rest,
      };
    });
  };

  return {
    customer: customer,
    customerQueryHook: customer.id,
    invoicesQueryHook: invoicesQueryHook(),
    total: dollarToCents(paymentAmt),
    credit: dollarToCents(creditAmt),
    invoices: convertedFulfilled(fulfilled),
    paymentDate: paymentDate.toDate(),
    paymentMethod: method,
    refNo: refNo,
    status: "pending",
    stripeIntentId: stripeIntentId ? stripeIntentId : null,
  };
};
