import React, { useEffect, useMemo, useState } from "react";
import { DatePicker, Tag, Typography, Form, Space, Switch, Select } from "antd";
import { COLOR_GREEN_0 } from "../../../app/ui/colorConstants";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import moment from "moment";
import EventDateField from "./EventDateField";

const { Text } = Typography;
const { Option } = Select;

const RentalRange = ({
  users,
  currentInvoice,
  onSalesInvoiceToggle,
  rentalRange,
  setRentalRange,
  setRentalDates,
  invoiceTotal,
  form,
  orgData,
}) => {
  const [isSalesInvoice, setIsSalesInvoice] = useState(false);
  const [salesRep, setSalesRep] = useState(null);

  useEffect(() => {
    currentInvoice && setIsSalesInvoice(currentInvoice.isSalesInvoice);
  }, [currentInvoice]);

  const handleRange = (e) => {
    if (e) {
      const isSameDay = e[1] - e[0] === 0;
      let range = e[1].diff(e[0], "days");
      setRentalRange(isSameDay ? 1 : range);
      setRentalDates({
        receiveDate: e[0].startOf("day").format("MMM Do YYYY"),
        returnDate: e[1].endOf("day").format("MMM Do YYYY"),
      });
      // form.setFieldsValue({ invoicePaymentDueDate: e[0].endOf("day") });
      // orgData?.eventDateEnabled &&
      //   form.setFieldsValue({ eventDate: e[0].startOf("day") });
    } else {
      setRentalRange(0);
      setRentalDates({
        receiveDate: null,
        returnDate: null,
      });
      form.setFieldsValue({ invoicePaymentDueDate: null, eventDate: null });
    }
  };

  const handleSalesRepSelection = (user) => {
    setSalesRep(user);
    form.setFieldsValue({
      salesRepName: user,
    });
  };

  return (
    <div class="sales-toggle">
      <Space>
        <span>Sales Rep:</span>

        <Form.Item name="salesRepName" className="NewInvoiceCustomerForm">
          <Select
            allowClear
            onClear={() => {
              setSalesRep(null);
            }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            onSelect={handleSalesRepSelection}
            showSearch
            placeholder={"Select sales representative"}
            className={"customer-picker"}
          >
            {users.map((u) => (
              <Option value={u.name} key={u.id}>
                {u.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        { orgData && orgData.salesInvoiceEnabled && (
          <Form.Item
            name="isSalesInvoice"
            style={{
              marginBottom: 0,
              marginRight: isSalesInvoice ? "0px" : "30px",
            }}
            initialValue={
              currentInvoice && currentInvoice.isSalesInvoice
                ? currentInvoice.isSalesInvoice
                : false
            }
            valuePropName="checked"
          >
            <span style={{ marginRight: "10px", marginLeft: "10px" }}>
              Sales Invoice:
            </span>
            <Switch
              defaultChecked={
                currentInvoice && currentInvoice.isSalesInvoice
                  ? currentInvoice.isSalesInvoice
                  : false
              }
              onChange={(e) => {
                setIsSalesInvoice(e);
                form.setFieldsValue({ isSalesInvoice: e });
                onSalesInvoiceToggle(e);
                if (e === true) {
                  const start = new moment(new Date());
                  const end = new moment(new Date());

                  handleRange([start, end]);
                  form.setFieldsValue({
                    rentalDates: [start, end],
                  });
                }
              }}
            />{" "}
          </Form.Item>
        )}

        <Space style={{ display: isSalesInvoice ? "none" : "inline-flex" }}>
          {" "}
          Rental Range:{" "}
          <Form.Item name="rentalDates" style={{ margin: 0, display: "block" }}>
            <DatePicker.RangePicker
              onChange={handleRange}
              className={"date-range-picker"}
              format={(d) => d.format("MMM Do YYYY")}
            />
          </Form.Item>
        </Space>
      </Space>
      {orgData?.eventDateEnabled && (
        <EventDateField isSalesInvoice={isSalesInvoice} />
      )}
      <div className="payment-due-date">
        <Space>
          Payment Due Date:{" "}
          <Form.Item name={"invoicePaymentDueDate"} style={{ margin: "6px 0" }}>
            <DatePicker
              placeholder={"Invoice Due Date"}
              style={{ width: "150px" }}
              format={(d) => d.format("MMM Do YYYY")}
            />
          </Form.Item>
        </Space>
      </div>
      <div
        style={{
          marginRight: "12px",
        }}
      >
        {!isSalesInvoice && (
          <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
            {rentalRange} day rental
          </Text>
        )}

        <br />
        <Text
          style={{ fontSize: "20px", fontWeight: "bold", color: COLOR_GREEN_0 }}
        >
          {orgData &&
            FormatToLocalCurrency(
              invoiceTotal.total,
              orgData.countryCode,
              orgData.languageCode,
              orgData.currencyCode
            )}
        </Text>
      </div>
    </div>
  );
};

export default RentalRange;
