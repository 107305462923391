import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { useFirebase } from "react-redux-firebase";
import { useFirestoreWrite } from "../../../app/services/hooks/fetch/useFirestoreWrite";

import { Button, Divider, Space, Spin, Select, Typography } from "antd";
import { ReceivePaymentModalContext } from "../ReceivePayment";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import { useStripePaymentHandler } from "../../../app/services/hooks/useStripePaymentHandler";
import { checkUndefinedOrNull } from "../../../app/utils/models/checkers/checkUndefined";
import { useIsAuthenticated } from "../../../app/services/hooks/useIsAuthenticated";
import { PAYMENT_COLLECTION } from "../../../app/utils/models/collections/collectionConstants";

const { Text } = Typography;

const StripePaymentForm = () => {
  const modal = useContext(ReceivePaymentModalContext);

  const { onPaymentSubmit, errorMessage, processing, stripe } = useStripePaymentHandler();
  const { newDocument } = useFirestoreWrite();

  const firebase = useFirebase();
  const history = useHistory();
  
  const { orgData } = useIsAuthenticated();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedProcessing, setSelectedProcessing] = useState(false);

  const paymentMethods = modal.data?.intentData.stripePaymentMethods ?? null;
  let paymentData = modal?.data?.paymentData;

  console.log(paymentData)

  function CreditCard(props) {
    const { last4, brand, expMonth, expYear } = props.card;
    return `${brand.toUpperCase()} Ending in ${last4} Exp: ${expMonth}/${expYear}`;
  }

  const TextBody = ({ text, ...props }) => {
    return <Text {...props}>{text}</Text>;
  };

  async function handlePayment(e) {
    
    setSelectedProcessing(true);

    if (selectedPaymentMethod && paymentData.customer.stripeAccount) {
      // Pay with saved Payment Method

      setSelectedProcessing(true);

      const call = firebase.functions().httpsCallable(
        "createPaymentIntentWithMethod"
      );

      return call({
        paymentMethod: selectedPaymentMethod,
        orgId: orgData.id,
        total: paymentData.total,
        currency: checkUndefinedOrNull(orgData.currency, "usd"),
        customerStripeId: paymentData.customer.stripeAccount
      })
        .then(async (res) => {
          const intent = res.data.intent;
          const intentId = intent.client_secret.split("_secret_");
          console.log('New intent id', intentId)
          paymentData.stripeIntentId = intentId[0];
          // First create the payment object in pending.
          try {
            // Create the payment document.
              console.log('Attemptting to add payment doc.', paymentData)
              await newDocument({
                data: {
                  collection: PAYMENT_COLLECTION,
                  payload: paymentData,
                },
              });

              const docId = res.data.id;

              const result = await stripe.confirmCardPayment(intent.client_secret, {
                payment_method: selectedPaymentMethod,
              });
    
              if (result.error) {
                console.log('Unable to confirm payment.', result.error.message);
                setSelectedProcessing(false);
              } else {
                const status = result.paymentIntent.status;
                setSelectedProcessing(false)
                notificationConfirm("Payment submitted");
                history.push(`/invoices`)
              }

          } catch (err) {
            console.log('Unable to create new Payment Document', err);
            setSelectedProcessing(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setSelectedProcessing(false);
        });
    } else {
      // Pay with the new card data.
      onPaymentSubmit({
        event: e,
        payload: modal?.data?.paymentData,
        returnUrl: `${window.location.origin}/invoices`,
      })
    }
  }

  return (
    <div>
      {paymentMethods && paymentMethods.length > 0 && (
        <div>
          <TextBody
            style={{ fontSize: "16px", fontWeight: "regular" }}
            text={"Pay with saved payment method: "}
          />
          <br />
          <br />
          <Select
            disabled={selectedProcessing}
            value={selectedPaymentMethod}
            style={{ width: "50%" }}
            placeholder={"Select Payment Method"}
            onSelect={(id) => setSelectedPaymentMethod(id)}
          >
            {paymentMethods.map((paymentSource) => (
              <Select.Option key={paymentSource.id} value={paymentSource.id}>
                <CreditCard card={paymentSource} />
              </Select.Option>
            ))}
          </Select>
        </div>
      )}

      <br />
      <br />

      {selectedPaymentMethod && (
        <div>
          <Button
            type={"primary"}
            disabled={selectedProcessing}
            onClick={() => {
              setSelectedPaymentMethod(null);
            }}
          >
            Add New Payment Method
          </Button>
        </div>
      )}

      <Spin spinning={processing || selectedProcessing}>
        <form>
          {(!paymentMethods || paymentMethods.length === 0 || !selectedPaymentMethod) && (    
            <PaymentElement style={{ width: "30em" }} />
          )}

          <Divider />

          <Space>
            <Button
              type={"primary"}
              onClick={(e) => handlePayment(e)}
              disabled={!stripe}
            >
              Process
            </Button>
            <Button onClick={() => modal.hide()}>Cancel</Button>
          </Space>
        </form>
      </Spin>

      {<div>{errorMessage}</div>}

    </div>
  );
};

export default StripePaymentForm;
