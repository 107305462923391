import { useEffect, useState } from "react";

export function useRouteSelect(route, currentRouteId, hiddenRoutes) {
  const [tagStyle, setTagStyle] = useState({
    backgroundColor: route?.color,
    opacity: 0.25,
  });
  const [count, setCount] = useState(0);
  const [hidden, setHidden] = useState(false);
  const [iconStyle, setIconStyle] = useState({
    color: "#FFF",
  });
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setHidden(hiddenRoutes?.includes(route?.id));
  }, [route, hiddenRoutes]);

  useEffect(() => {
    if (!route) return;
    const selected = route?.id === currentRouteId;
    if (selected) {
      setTagStyle({
        backgroundColor: route?.color,
        opacity: 1,
      });
      setSelected(true);
    } else {
      setTagStyle({
        backgroundColor: route?.color,
        opacity: 0.25,
      });
      setSelected(false);
    }
  }, [currentRouteId, route]);
  return {
    tagStyle,
    tooltip: route?.name ?? "",
    selected,
    count,
    hidden,
    route,
    routeId: route?.id ?? null,
    color: route?.color ?? null,
  };
}
