import React, { useContext } from "react";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { Col, Select } from "antd";
import { RouteAssignmentsContext } from "../RouteAssignments";

const AssignTruck = () => {
  const { state, actions } = useContext(RouteAssignmentsContext);
  console.log("the trucks: ", state.trucks);
  return (
    <Col span={24} style={{ marginBottom: "16px" }}>
      <TextSubtitle text={"ASSIGN TRUCK"} />
      <Select
        allowClear={true}
        onClear={() => {
          actions.clearTruck();
        }}
        value={state.selectedRoute?.routeData?.vehiclesQueryHook?.find(
          (truck) => truck.isAvailable
        )}
        placeholder={"Truck"}
        style={{ width: "100%" }}
        onSelect={(id) => actions.addTruck({ id })}
      >
        {state?.trucks?.map((truck) =>
          truck.isAvailable ? (
            <Select.Option value={truck.id}>
              {truck.name}: {truck.description}
            </Select.Option>
          ) : (
            ""
          )
        )}
      </Select>
    </Col>
  );
};

export default AssignTruck;
