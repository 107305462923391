import { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { checkCustomer } from "../../../../utils/models/checkers/checkCustomer";
import { useLazyGetInitialPaginatedCustomersQuery } from "../../../api";
import { useIsAuthenticated } from "../../useIsAuthenticated";

export function useCustomerPagination(collection, orderBy, recordsPerFetch) {
  const { fsOrgPrefix } = useIsAuthenticated();
  const firestore = useFirestore();

  // state
  const [data, setData] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(false);
  const [allResultsLoaded, setAllResultsLoaded] = useState(false);

  const [lazyTrigger, lazyState] = useLazyGetInitialPaginatedCustomersQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    recordsPerFetch,
    orderBy,
  });
  const {
    data: initialCustomers,
    isFetching: fetchingInitialCustomers,
    isSuccess,
    status,
  } = lazyState;

  // loading state
  useEffect(() => {
    return setBusy(fetchingInitialCustomers || loading);
  }, [fetchingInitialCustomers, loading]);

  const refreshInitialLoad = () => {
    fetchAndStoreResults(false);
    setPage(1);
  };

  const getInitialCustomers = () => {
    fetchAndStoreResults(true);
  };

  const fetchAndStoreResults = (useCachedResults) => {
    lazyTrigger(
      {
        db: { firestore },
        orgPrefix: fsOrgPrefix,
        recordsPerFetch,
        orderBy,
      },
      useCachedResults
    ).then((res) => {
      setData([
        {
          page: 1,
          results: res.data.docs,
        },
      ]);
      setLastVisible(res.data.lastVisible);
      // Handle if results are 0 or less than recordsPerFetch
      const endOnFirstPage = res.data.docs?.length < recordsPerFetch;
      if (endOnFirstPage) return setAllResultsLoaded(true);
    });
  };

  const loadNext = async () => {
    if (allResultsLoaded) return;
    setLoading(true);
    const res = await fetchNext({
      firestore,
      collection,
      recordsPerFetch,
      orderBy,
      lastVisible,
    });
    setLoading(false);
    if (!res.lastVisible) return setAllResultsLoaded(true);
    const newCurrentPage = page + 1;
    const set = {
      page: newCurrentPage,
      results: res.docs,
    };
    setData([...data, set]);
    setLastVisible(res.lastVisible);
    setPage(newCurrentPage);
  };

  /**
   * RETURN STATE AND ACTIONS
   * ***
   * **
   * *
   */

  return {
    state: {
      data: flattenLoadedPages(data),
      lastVisible,
      page,
      busy,
      allResultsLoaded,
    },
    actions: {
      refreshInitialLoad,
      loadNext,
      getInitialCustomers,
    },
  };
}

/**
 * Helper functions
 * ***
 * **
 * *
 */

const checkDocs = (docs) => {
  let data = [];
  if (!docs) return data;
  docs.forEach((s) => {
    data.push(checkCustomer(s));
  });
  return data;
};

const fetchNext = async ({
  firestore,
  collection,
  recordsPerFetch,
  orderBy,
  lastVisible,
}) => {
  if (!orderBy) return [];
  const limit = recordsPerFetch ? recordsPerFetch : 50;
  const ref = firestore
    .collection(collection)
    .orderBy(orderBy)
    .startAfter(lastVisible)
    .limit(limit);

  try {
    const data = await ref.get();
    const docs = checkDocs(data);
    return {
      docs,
      lastVisible: data.docs[data.docs.length - 1],
    };
  } catch (err) {
    console.log(err);
    return {
      docs: [],
      lastVisible: null,
    };
  }
};

const flattenLoadedPages = (resultsList) => {
  return resultsList.map((r) => r.results).flat();
};
