import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { authSelector } from "../../auth/authSlice";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Input,
  Menu,
  Dropdown,
  Collapse,
  Modal,
  Tooltip,
  Space,
} from "antd";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { emailSettingsQuery } from "../../../app/services/firestore/queries/orgQueries";
import { emailSettingsWrites } from "../../../app/services/firestore/writes/orgWrites";
import { useFirestore } from "react-redux-firebase";
import { configureEmailSettingsUpdate } from "../../../app/utils/models/configure/configureOrgUpdate";

import notificationError from "../../../app/system-components/toasters/notificationError";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";

import {
  TeamOutlined,
  MinusCircleFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import EmailInvoiceExample from "./EmailInvoiceExample";
import TermsConditionsModal from "./TermsConditionsModal";
import "../../../index.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

import {
  COLOR_BLUE_0,
  COLOR_PURPLE_0,
  COLOR_RED_0,
} from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import AddCcModal from "./AddCcModal";
import { useFirestoreWrite } from "../../../app/services/hooks/fetch/useFirestoreWrite";

const { Panel } = Collapse;

const CustomizeEmail = ({ settings, settingsId, title }) => {
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);

  const [form] = Form.useForm();
  const firestore = useFirestore();

  const [tcModalData, setTcModalData] = useState({ visible: false, text: "" });
  const [ccModal, setCcModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const signatureEditor = React.useRef();
  const emailBodyEditor = React.useRef();
  const customNoteEditor = React.useRef();

  const Update = useFirestoreWrite();

  const [pageValues, setPageValues] = useState({
    emailSubject: "",
    emailFrom: "",
    emailBody: "",
    invoiceNotes: "",
    emailSignature: "",
    termsConditions: "",
    invoiceCopyEmails: [],
  });

  const onUpdate = (e) => {
    // console.log('page values', ...pageValues)
    setPageValues({
      ...pageValues,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    orgData && settings && loadSettings();
  }, [form, orgData, settings]);

  const loadSettings = async () => {
    setLoading(true);
    orgData &&
      emailSettingsQuery({ firestore }, fsOrgPrefix)
        .fetchEmailSettings(orgData.id, settingsId)
        .then((settings) => {
          setLoading(false);
          if (settings) {
            setPageValues({
              emailSubject: settings.emailSubject,
              emailFrom: settings.emailFrom,
              emailBody: settings.emailBody,
              invoiceNotes: settings.invoiceNotes,
              emailSignature: settings.emailSignature,
              termsConditions: settings.termsConditions,
              invoiceCopyEmails: settings.invoiceCopyEmails,
            });
            form.setFieldsValue({
              emailSubject: settings.emailSubject,
              emailFrom: settings.emailFrom,
              emailBody: settings.emailBody,
              invoiceNotes: settings.invoiceNotes,
              emailSignature: settings.emailSignature,
              termsConditions: settings.termsConditions,
              invoiceCopyEmails: settings.invoiceCopyEmails,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
  };

  const onSaveClicked = async () => {
    if (!userData.id || !fsOrgPrefix || !orgData.id)
      return notificationError(
        "Invalid permissions",
        "Unable to update at this time."
      );
    const payload = configureEmailSettingsUpdate(
      pageValues,
      userData.id,
      orgData?.orgTimezone
    );
    console.log("the payload: ", payload);
    emailSettingsWrites({ firestore }, fsOrgPrefix)
      .updateEmailSettings(orgData.id, settingsId, payload)
      .then(() => {
        notificationConfirm("Settings saved", "");
        loadSettings();
      })
      .catch((err) => {
        console.log(err);
        notificationError("Something went wrong", "Please try again later");
      });
  };

  const confirmSave = async (e) => {
    e.stopPropagation();

    try {
      return await new Promise(async (resolve, reject) => {
        onSaveClicked().then(() => {
          resolve();
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateCc = (payload) => {
    const { invoiceCopyEmails, ...rest } = pageValues;
    if (payload.action === "delete") {
      setPageValues({
        invoiceCopyEmails: invoiceCopyEmails.filter((e) => e !== payload.email),
        ...rest,
      });
    } else {
      setPageValues({
        invoiceCopyEmails: [payload.email, ...invoiceCopyEmails],
        ...rest,
      });
    }
  };

  const signatureChange = (content, delta, source, editor) => {
    pageValues.emailSignature = editor.getHTML();
    onUpdate({
      target: {
        id: "invoiceSignature",
        value: editor.getHTML(),
      },
    });
  };

  const emailBodyChange = (content, delta, source, editor) => {
    pageValues.emailBody = editor.getHTML();
    onUpdate({
      target: {
        id: "invoiceSignature",
        value: editor.getHTML(),
      },
    });
  };

  const customNoteChange = (content, delta, source, editor) => {
    pageValues.invoiceNotes = editor.getHTML();
    onUpdate({
      target: {
        id: "invoiceNotes",
        value: editor.getHTML(),
      },
    });
  };

  const toggleTcModal = (bool) => {
    setTcModalData({
      visible: bool,
      text: pageValues.termsConditions,
    });
  };

  const updateTc = async (text) => {
    console.log(text);
    setTcModalData({
      visible: false,
      text: text,
    });
    const payload = {
      termsConditions: text,
    };
    await emailSettingsWrites({ firestore }, fsOrgPrefix)
      .updateEmailSettings(orgData.id, settingsId, payload)
      .then(() => {
        notificationConfirm("Terms and conditions saved", "");
        loadSettings();
      })
      .catch((err) => {
        console.log(err);
        notificationError("Something went wrong", "Please try again later");
      });
    updateTcValues(text);
  };

  const updateTcValues = (text) => {
    setPageValues({
      ...pageValues,
      termsConditions: text,
    });
  };

  const invoiceCopyEmailsOverlay = () => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={() => setCcModal(true)}>
          <TextBody text="Add Email" style={{ color: COLOR_PURPLE_0 }} />
        </Menu.Item>
        {settings &&
          pageValues.invoiceCopyEmails.map((e) => (
            <Menu.Item key={e}>
              <Row justify="space-between">
                <Col>{e}</Col>
                <Col>
                  <Button
                    type="link"
                    size="small"
                    onClick={() =>
                      updateCc({
                        action: "delete",
                        email: e,
                      })
                    }
                  >
                    <MinusCircleFilled style={{ color: COLOR_RED_0 }} />
                  </Button>
                </Col>
              </Row>
            </Menu.Item>
          ))}
      </Menu>
    );
  };

  const onCloseCc = () => {
    setCcModal(false);
  };

  if (settings) {
    return (
      <div>
        <Tooltip
          placement="topLeft"
          trigger={"hover"}
          title="Click to expand/collapse section."
          autoAdjustOverflow="false"
        >
          <Form form={form}>
            <Collapse>
              <Panel
                header={title}
                key={settingsId}
                extra={
                  <Button type="primary" onClick={confirmSave}>
                    Save Changes
                  </Button>
                }
              >
                <Row justify="space-between">
                  <Col span={10} className="customize-invoice-email-fields">
                    <Space>
                      <Dropdown
                        overlay={invoiceCopyEmailsOverlay}
                        trigger="click"
                      >
                        <Button size="small">
                          Send Invoice Copies
                          <TeamOutlined style={{ marginLeft: "8px" }} />(
                          {pageValues.invoiceCopyEmails.length})
                        </Button>
                      </Dropdown>

                      <Tooltip title="Add any other email addresses you want to also receive this email.">
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Space>

                    <br />
                    <br />
                    <TextSubtitle text={"EMAIL SUBJECT"} />
                    <br />
                    <Form.Item name="emailSubject">
                      <Input
                        placeholder="Subject of the invoice email"
                        onChange={onUpdate}
                      />
                    </Form.Item>
                    <TextSubtitle text={"EMAIL FROM (NAME)"} />
                    <br />
                    <Form.Item name="emailFrom">
                      <Input
                        placeholder="From email name"
                        onChange={onUpdate}
                      />
                    </Form.Item>
                    <TextSubtitle text={"EMAIL BODY"} />
                    <br />

                    <Form.Item name="emailBody">
                      <ReactQuill
                        name={"emailBody"}
                        ref={emailBodyEditor}
                        onChange={emailBodyChange}
                        modules={{
                          toolbar: [["bold", "italic", "underline"], ["link"]],
                        }}
                        placeholder="Email Body"
                        theme="snow"
                        style={{
                          height: "200px",
                          marginBottom: "24px",
                        }}
                      />
                    </Form.Item>

                    <br />
                    <TextSubtitle text={"CUSTOM NOTE ON INVOICE"} />
                    <br />

                    <Form.Item name="invoiceNotes">
                      <ReactQuill
                        name={"invoiceNotes"}
                        ref={customNoteEditor}
                        onChange={customNoteChange}
                        modules={{
                          toolbar: [["bold", "italic", "underline"], ["link"]],
                        }}
                        placeholder="Custom Note"
                        theme="snow"
                        style={{
                          height: "100px",
                          marginBottom: "24px",
                        }}
                      />
                    </Form.Item>
                    <br />
                    <br />
                    <TextSubtitle text={"EMAIL SIGNATURE"} />
                    <br />
                    <Form.Item name={"emailSignature"}>
                      <ReactQuill
                        name={"emailSignature"}
                        ref={signatureEditor}
                        onChange={signatureChange}
                        modules={{
                          toolbar: [["bold", "italic", "underline"], ["link"]],
                        }}
                        placeholder="Email Signature"
                        theme="snow"
                        style={{
                          height: "100px",
                          marginBottom: "24px",
                        }}
                      />
                    </Form.Item>

                    <TextSubtitle text={"TERMS & CONDITIONS"} />
                    <br />
                    <Button
                      style={{ marginTop: "12px" }}
                      onClick={() => toggleTcModal(true)}
                    >
                      Edit
                    </Button>
                    <br />
                    <br />
                  </Col>
                  <Col
                    span={12}
                    flex={"auto"}
                    style={{
                      textAlign: "center",
                      padding: "20px",
                    }}
                    className="email-invoice-preview"
                  >
                    <EmailInvoiceExample pageValues={pageValues} />
                  </Col>
                </Row>
                <TermsConditionsModal
                  visible={tcModalData.visible}
                  defaultText={tcModalData.text}
                  onCancel={() => toggleTcModal(false)}
                  onUpdateClicked={updateTc}
                />
                <AddCcModal
                  visible={ccModal}
                  onCancel={onCloseCc}
                  onAddClicked={(e) => {
                    updateCc({
                      action: "add",
                      email: e,
                    });
                    setCcModal(false);
                  }}
                />
              </Panel>
            </Collapse>
          </Form>
        </Tooltip>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default CustomizeEmail;
