import React, { useContext } from "react";
import { Button, Divider, Space } from "antd";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { COLOR_DARK_BG_0 } from "../../../../app/ui/colorConstants";
import { RouteContext } from "../../NewRoute";
import { ConfigureStopTooltipTypeLabel } from "../helpers/configureStopTooltipTypeLabel";

const MultiStopTooltip = ({
  sharedStops,
  resetTooltips,
}) => {
  const { actions, state } = useContext(RouteContext);
  return (
    <div style={{ padding: "4px", borderRadius: "8px" }}>
      <Space direction={"vertical"}>
        {sharedStops?.map((s) => {
          return (
            <div
              style={{
                height: "auto",
                borderColor: "transparent",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                actions.updateSelectedStop({
                  invoiceId: s?.stop?.invoiceId,
                  type: s?.stop?.type,
                });
              }}
            >
              {s?.stop?.invoice?.invoiceNumber
                ? `#${s?.stop?.invoice?.invoiceNumber}`
                : ""}{" "}
              ({`${ConfigureStopTooltipTypeLabel(s?.stop?.type ?? null)}`})
              <br />
              {s?.stop?.displayName}
              <br />
              <TextBody
                text={s?.stop?.address}
                style={{
                  color: COLOR_DARK_BG_0,
                  fontSize: "11px",
                }}
              />
              <br />
              <br />
              <Button
                key={`multi_btn_${s?.stop?.invoiceId}_${s?.stop?.type}`}
                disabled={state.updating}
                type={s?.isAssigned ? "danger" : "secondary"}
                onClick={() => {
                  if (s?.isAssigned) {
                    actions.removeStop({
                      stop: s?.stop,
                      routeId: state.currentRouteId,
                    });
                  } else {
                    actions.addStop({
                      stop: s?.stop,
                      routeId: state.currentRouteId,
                    });
                  }
                  resetTooltips();
                }}
              >
                {s?.isAssigned ? "Remove from route" : "Add to route"}
              </Button>
              <Divider style={{ margin: "12px 0" }} />
            </div>
          );
        })}
      </Space>
    </div>
  );
};

export default MultiStopTooltip;
