import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { useMarker } from "../../../../app/services/hooks/useMarker";
import { RouteContext } from "../../NewRoute";
import MultiStopTooltip from "./MultiStopTooltip";

const RouteMarker = ({ stop }) => {
  const { actions, state } = useContext(RouteContext);
  const {
    icon,
    lat,
    lng,
    groupedCount,
    sharedStops,
    stopNumber,
    showTooltip,
  } = useMarker({
    stop,
    stops: state.initialStops,
    routes: state.routes,
    selectedRouteId: state.currentRouteId,
    currentTooltip: state.currentTooltip,
  });
  const [multiStopTooltip, setMultiStopTooltip] = useState([]);
  const [_stopTooltip, setStopTooltip] = useState(null);
  const updateTooltip = () => {
    if (!stop) return;
    if (!groupedCount) {
      actions.updateCurrentTooltip({
        invoiceIds: [stop?.invoiceId],
        routeId: state.currentRouteId,
      });
      stop?.invoiceId &&
        actions.updateSelectedStop({
          invoiceId: stop?.invoiceId,
          type: stop?.type,
        });
      setStopTooltip(stop);
    } else {
      // throw tooltip select
      actions.updateCurrentTooltip({
        invoiceIds: [...sharedStops?.map((s) => s.stop?.invoiceId)],
        routeId: state.currentRouteId,
      });
      if (!multiStopTooltip) {
        setMultiStopTooltip(sharedStops);
      } else {
        setMultiStopTooltip([]);
      }
    }
  };
  const handleClick = () => {
    updateTooltip();
  };

  useEffect(() => {
    if (!stop) return;
    if (!groupedCount) {
      setStopTooltip(stop);
    } else {
      setMultiStopTooltip(sharedStops);
    }
  }, [state.currentTooltip]);

  const resetTooltips = () => {
    setMultiStopTooltip([]);
    setStopTooltip(null);
  };
  useEffect(() => {
    resetTooltips();
    actions.clearCurrentTooltip();
    actions.clearSelectedStop();
  }, [state.currentRouteId]);

  const configureText = (groupedCount, stopNumber) => {
    if (groupedCount) {
      return "...";
    } else {
      if (!stopNumber) return null;
      return stopNumber;
    }
  };
  return (
    <Marker
      key={`${state.currentRouteId}_${stop?.invoiceId}`}
      label={{
        className: "Marker-Stop-Number",
        fontSize: "11px",
        text: stopNumber && configureText(groupedCount, stopNumber),
        color: "#FFF",
      }}
      onClick={handleClick}
      icon={icon}
      position={{ lat, lng }}
    >
      {showTooltip.multiStop && multiStopTooltip && (
        <InfoWindow
          onCloseClick={() => {
            actions.clearSelectedStop();
            actions.clearCurrentTooltip();
          }}
        >
          <MultiStopTooltip
            resetTooltips={resetTooltips}
            setMultiStopTooltip={setMultiStopTooltip}
            sharedStops={sharedStops}
          />
        </InfoWindow>
      )}
    </Marker>
  );
};

export default RouteMarker;
