import { TimeMachine } from "../../time/timeMachine";
import { SortArrByNumber } from "../../Nifty/nifty";
import { formatDateOnRead } from "../../time/formatTimezoneOffset";
import {
  EmbeddedCheckoutBlackoutDate,
  EmbeddedCheckoutQuestion,
  EmbeddedSettingsState,
} from "../../../../domains/admin-embedded-checkout/adminEmbeddedContext";
import {
  QueryDocumentSnapshot,
  DocumentData,
  DocumentSnapshot,
} from "@firebase/firestore-types";

export const CheckCheckoutSettings = (
  snap: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>,
  orgId: string,
  orgTimezone: string
): EmbeddedSettingsState => {
  const data = snap.data();
  const state: EmbeddedSettingsState = {
    orgId: orgId ?? "",
    checkoutQuestions: data?.checkoutQuestions
      ? sortCheckoutQuestions(data.checkoutQuestions)
      : [],
    checkoutSettings: data?.checkoutSettings ?? {
      type: "wishlist",
      allowOverbooked: false,
    },
    blackoutDates: data?.blackoutDates
      ? convertDatesToMoment(data.blackoutDates, orgTimezone)
      : [],
    taxRateId: data?.taxRateId ?? null,
    daysOfOperation: {
      Sunday: !!data?.daysOfOperation?.Sunday,
      Monday: !!data?.daysOfOperation?.Monday,
      Tuesday: !!data?.daysOfOperation?.Tuesday,
      Wednesday: !!data?.daysOfOperation?.Wednesday,
      Thursday: !!data?.daysOfOperation?.Thursday,
      Friday: !!data?.daysOfOperation?.Friday,
      Saturday: !!data?.daysOfOperation?.Saturday,
    },
    maxCheckoutDays: data?.maxCheckoutDays ?? 0,
    primaryColor: data?.primaryColor ?? "000000",
    currencySymbol: data?.currencySymbol ?? "$",
    closedDateSelectedMessage: data?.closedDateSelectedMessage ?? "",
    displayInventoryAvailability: data?.displayAvailableInventory ?? true,
  };
  return state;
};

const convertDatesToMoment = (
  blackoutDates: EmbeddedCheckoutBlackoutDate[],
  orgTimezone: string
) => {
  return blackoutDates?.map((dates) => {
    return {
      blackoutStart: TimeMachine(
        formatDateOnRead({ date: dates.blackoutStart, orgTimezone })
      ).moment,
      blackoutEnd: TimeMachine(
        formatDateOnRead({ date: dates.blackoutEnd, orgTimezone })
      ).moment,
      willCallAllowed: dates.willCallAllowed,
    };
  });
};

const sortCheckoutQuestions = (questions: EmbeddedCheckoutQuestion[]) => {
  return SortArrByNumber(questions, "index", "ascending");
};
