import { ORG_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import notificationError from "../../../system-components/toasters/notificationError";
import checkOrg from "../../../utils/models/checkers/checkOrg";
import checkEmailSettings from "../../../utils/models/checkers/checkEmailSettings";

export function orgQueries({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(ORG_COLLECTION);

  async function fetchOrgDoc(id) {
    const snap = await ref.doc(id).get();
    return checkOrg(snap);
  }
  return {
    fetchOrgDoc: fetchOrgDoc, // fetch org document
  };
}

export function emailSettingsQuery({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(ORG_COLLECTION);

  async function fetchEmailSettings(orgId, settingsId) {
    const snap = await ref.doc(orgId).collection('settings').doc(settingsId).get();
    return checkEmailSettings(snap);
  }
  return {
    fetchEmailSettings: fetchEmailSettings, // update settings document
  };
}
