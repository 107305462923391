import { checkInventory } from "./checkInventory";
import { centsToDollars } from "./checkCurrencyToDollars";
import { ROW_TYPE_SUBTOTAL } from "../modelConstants/modelConstants";
import Nifty from "../../Nifty";

export const checkUndefined = (i, fallbackValue) => {
  if (typeof i === "undefined") {
    return fallbackValue;
  }
  return i;
};

export const checkUndefinedCurrency = (i, fallbackValue) => {
  if (typeof i === "undefined") {
    return fallbackValue;
  }
  return Nifty.RoundCentsToTwoDecimals(i);
};

export const checkUndefinedSelectedItems = (i, fallbackValue) => {
  if (typeof i === "undefined") {
    return fallbackValue;
  }
  const { items, count } = i;

  return {
    items:
      items &&
      items.map((item) => {
        if (item.rowType === ROW_TYPE_SUBTOTAL)
          return {
            ...item,
            rowTotal: item.rowTotal ? centsToDollars(item.rowTotal) : 0,
          };
        const { rowTotal, rates, selectedRateMeta, ...rest } = item;
        const convertedRates = rates?.map((r) => {
          return {
            rateName: r.rateName ? r.rateName : "",
            rateRange: r.rateRange ? r.rateRange : "day",
            rate: centsToDollars(r.rate),
          };
        });
        if (!selectedRateMeta)
          return {
            rates: convertedRates ?? [],
            rowTotal: centsToDollars(rowTotal),
            selectedRateMeta: null,
            ...rest,
          };
        const { rate, ...metaRest } = selectedRateMeta;
        return {
          rowTotal: rowTotal ? centsToDollars(rowTotal) : 0,
          rates: convertedRates ?? [],
          selectedRateMeta: {
            rate: rate ? centsToDollars(rate) : 0,
            ...metaRest,
          },
          ...rest,
        };
      }),
    count: count ? count : 0,
  };
};

export const checkUndefinedOrNull = (i, fallbackValue) => {
  if (typeof i === "undefined" || i === null) {
    return fallbackValue;
  }
  return i;
};
