import { createSlice } from "@reduxjs/toolkit";
import notificationError from "../../app/system-components/toasters/notificationError";

export const initialState = {
  range: {
    startDate: null,
    endDate: null,
  },
  statuses: null,
  types: null,
  searchValue: "",
};

const invoiceFilterSlice = createSlice({
  name: "invoiceFilter",
  initialState,
  reducers: {
    storeFilterData(state, { payload }) {
      state.range = payload?.range;
      state.statuses = payload?.statuses;
      state.types = payload?.types;
      state.searchValue = payload?.searchValue;
    },
  },
});

// Export keys as actions
export const { storeFilterData } = invoiceFilterSlice.actions;

// Export selector as name from slice
export const invoiceFilterSelector = (state) => state.invoiceFilter;

// Default export for reducer to get referenced in root reducer
export default invoiceFilterSlice.reducer;

// Thunk actions
export const saveFilterData = (payload) => async (dispatch) => {
  try {
    dispatch(storeFilterData(payload));
  } catch (err) {
    console.log(err);
  }
};
