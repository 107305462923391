import notificationError from "../../../system-components/toasters/notificationError";
import { INVENTORY_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { checkInventory } from "../../../utils/models/checkers/checkInventory";
import { fsMultiArrayQuery } from "./fsMultiArrayQuery";
import { ExtendedFirestoreInstance } from "react-redux-firebase";
import { InventoryItem } from "../../../interfaces/inventoryItem";

export function inventoryQueries(
  { firestore }: { firestore: ExtendedFirestoreInstance },
  orgPrefix: string
) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${INVENTORY_COLLECTION}`);

  async function fetchInventory(): Promise<InventoryItem[]> {
    const snap = await ref.get();
    return snap.docs.map((s) => checkInventory(s));
  }

  async function fetchInventoryListByIds(
    { firestore }: { firestore: ExtendedFirestoreInstance },
    ids: string[]
  ) {
    const ref = firestore
      .collection(`${orgPrefix}${INVENTORY_COLLECTION}`)
      .where("id", "in", ids);
    return await ref.get();
  }

  async function inventoryByIdList(ids: string[]) {
    return fsMultiArrayQuery(
      { firestore },
      ids,
      fetchInventoryListByIds,
      checkInventory
    );
  }

  return {
    fetchInventory: fetchInventory, // fetch inventory items
    inventoryByIdList: inventoryByIdList,
  };
}
