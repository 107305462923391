import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid-pro";
import { FC } from "react";
import { COLOR_TEXT_GRAY_2 } from "../../../../app/ui/colorConstants";
import { PictureFilled } from "@ant-design/icons";
import { InventoryItem } from "../../../../app/interfaces/inventoryItem";

export const ImageCell: FC<
  GridRenderCellParams<InventoryItem, any, any, GridTreeNodeWithRender>
> = ({ row }) => {
  return (
    <div style={{ overflowWrap: "break-word", width: "100%" }}>
      {row.image ? (
        <img
          src={row.image && row.image.downloadURL}
          style={{
            height: "32px",
            width: "32px",
            objectFit: "contain",
          }}
          alt={row.image && row.image.fileName}
        />
      ) : (
        <PictureFilled style={{ color: COLOR_TEXT_GRAY_2, fontSize: "32px" }} />
      )}
    </div>
  );
};
