import React, { useContext } from "react";
import { Button, Card, Col, Input, InputNumber, Row, Space } from "antd";
import { AdminEmbeddedContext } from "../adminEmbeddedContext";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import { COLOR_LIGHT_GRAY } from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";

const header = `<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous" />
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.3.3/dist/css/datepicker-bs5.min.css" />
<link rel="stylesheet" href="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-dev-52205.appspot.com/o/embeddedShop%2Fadelie-cart.css?alt=media&token=df3ca61d-65b1-4c5a-ba48-437b7b2b5345" />
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.3.3/dist/js/datepicker-full.min.js"></script>
<script src="https://firebasestorage.googleapis.com/v0/b/adelie-logistics-dev-52205.appspot.com/o/embeddedShop%2Fmin.js?alt=media&token=af1cf2eb-7d31-49fe-bc8a-093cf1e55dc8"></script>`;

const body = `<div
  id="adelie"
  orgId="1EFnp9dnRUnAWQTPO2U4"
></div>`;

export const EmbeddedCodeSnippet = () => {
  const { actions, state } =
    useContext(AdminEmbeddedContext);
  return (
    <Card size={"small"} style={{ marginTop: "24px" }} title={"Configuration"}>
      <Row gutter={24}>
        <Col span={12}>
          <Card
            size={"small"}
            bordered={false}
            style={{ background: COLOR_LIGHT_GRAY, padding: 0 }}
          >
            Copy and paste this code snippet into the header of your website.
          </Card>
          <br />
          <Card
            title={
              <TextBody
                text={"HTML Header Snippet"}
                style={{ fontWeight: "400", fontSize: "16px", color: "#FFF" }}
              />
            }
            headStyle={{
              borderBottom: 0,
              background: "#1F1E24",
              borderRadius: "12px",
            }}
            size={"small"}
            style={{
              background: "#363343",
              padding: "12px",
              borderRadius: "12px",
            }}
            bordered={false}
            extra={
              <Space>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(header).then(() => {
                      notificationConfirm("Copied!");
                    });
                  }}
                >
                  Copy
                </Button>
              </Space>
            }
          >
            <pre style={{ color: "#55E6F0" }}>{header}</pre>
          </Card>
          <br />
          <Card
            title={
              <TextBody
                text={"HTML Body Snippet"}
                style={{ fontWeight: "400", fontSize: "16px", color: "#FFF" }}
              />
            }
            headStyle={{
              borderBottom: 0,
              background: "#1F1E24",
              borderRadius: "12px",
            }}
            size={"small"}
            style={{
              background: "#363343",
              padding: "12px",
              borderRadius: "12px",
            }}
            bordered={false}
            extra={
              <Space>
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(body).then(() => {
                      notificationConfirm("Copied!");
                    });
                  }}
                >
                  Copy
                </Button>
              </Space>
            }
          >
            <pre style={{ color: "#55E6F0" }}>{body}</pre>
          </Card>
        </Col>
        <Col span={12}>
          <div style={{ padding: "12px" }}>
            <TextSubtitle text={"PRIMARY COLOR"} />
            <br />
            <Input
              value={state.primaryColor ?? "000000"}
              style={{ width: 120 }}
              placeholder={"HEX"}
              prefix={"#"}
              onChange={(v) => actions.changePrimaryColor(v.target.value)}
            />
            <br />
            <br />
            <TextSubtitle text={"CURRENCY SYMBOL"} />
            <br />
            <Input
              value={state.currencySymbol}
              style={{ width: 120 }}
              placeholder={"$"}
              onChange={(v) => actions.changeCurrencySymbol(v.target.value)}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};
