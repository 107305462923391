import { useContext } from "react";
import { AdminEmbeddedContext } from "../adminEmbeddedContext";
import { Card, Checkbox, InputNumber, Radio, Space, Switch } from "antd";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";

export const EmbeddedCheckoutType = () => {
  const { actions, state } = useContext(AdminEmbeddedContext);
  return (
    <Card size={"small"} title={"Checkout"} style={{ marginTop: "24px" }}>
      <Space direction={"horizontal"} size={8}>
        <Radio.Group
          buttonStyle="solid"
          value={state.checkoutSettings.type}
          onChange={(v) => actions.checkoutSettings.changeType(v.target.value)}
        >
          <Radio.Button value={"wishlist"}>Wishlist</Radio.Button>
          <Radio.Button value={"order"}>Order</Radio.Button>
        </Radio.Group>
        <Space>
          <Checkbox
            checked={state.checkoutSettings.allowOverbooked}
            onChange={(v) =>
              actions.checkoutSettings.toggleOverbooked(v.target.checked)
            }
          />
          Allow overbooked items
        </Space>
      </Space>
      <br />
      <br />
      *Wishlist items get processed as estimates. Orders will be processed as
      invoices.
      <br />
      <br />
      <TextSubtitle text={"MAX CHECKOUT DAYS"} />
      <br />
      <InputNumber
        min={0}
        placeholder={"0"}
        style={{ width: "120px" }}
        value={state.maxCheckoutDays}
        onChange={(v) => {
          actions.setMaxCheckoutDays(v ?? 0);
        }}
      />
      <br />
      <br />
      <div>
        <Switch
          checked={state.displayInventoryAvailability}
          onChange={(value) => actions.setDisplayInventory(value)}
        />{" "}
        <TextSubtitle text={"DISPLAY INVENTORY AVAILABILITY"} />
      </div>
    </Card>
  );
};
