import React from "react";
import NumberFormat from "react-number-format";
import { Card } from "antd";

const ItemTooltip = ({ payload }) => {
  const configureAmt = () => {
    if (!payload) return 0;
    return payload?.[0]?.payload?.filteredTotal ?? 0;
  };
  const amt = configureAmt();
  return (
    <Card size={"small"}>
      <NumberFormat
        value={amt.toFixed(2)}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    </Card>
  );
};

export default ItemTooltip;
