import { useCallback, useEffect, useState } from "react";
import {
  ROUTE_ADMIN_CUSTOMERS,
  ROUTE_ADMIN_INTEGRATIONS,
  ROUTE_ADMIN_INVENTORY,
  ROUTE_ADMIN_SETTINGS_EMAIL,
  ROUTE_ADMIN_SETTINGS_GENERAL,
  ROUTE_ADMIN_USERS,
  ROUTE_CREATE_INVOICE,
  ROUTE_CREATE_INVOICE_FROM_CART,
  ROUTE_EDIT_INVOICE,
  ROUTE_INVENTORY,
  ROUTE_INVOICES,
  ROUTE_QUICK_REPORT,
  ROUTE_REPORTS,
  ROUTE_ROUTES,
} from "../../routes";
import { useLocation } from "react-router-dom";

export const useSidebar = () => {
  const locationPath = useLocation().pathname;
  const [highlighted, setHighlighted] = useState([]);
  const [blockSidebar, setBlockSidebar] = useState(false);

  const safeBlockUrls = [
    ROUTE_CREATE_INVOICE_FROM_CART,
    ROUTE_CREATE_INVOICE,
    ROUTE_EDIT_INVOICE,
  ];

  useEffect(() => {
    const configureHighlighted = (locationPath) => {
      if (locationPath.includes(ROUTE_INVENTORY)) return [ROUTE_INVENTORY];
      if (locationPath.includes(ROUTE_INVOICES)) return [ROUTE_INVOICES];
      if (locationPath.includes(ROUTE_ROUTES)) return [ROUTE_ROUTES];
      if (locationPath.includes(ROUTE_QUICK_REPORT))
        return [ROUTE_QUICK_REPORT];
      if (locationPath.includes(ROUTE_REPORTS)) return [ROUTE_REPORTS];
      if (locationPath.includes(ROUTE_ADMIN_USERS)) return [ROUTE_ADMIN_USERS];
      if (locationPath.includes(ROUTE_ADMIN_INVENTORY))
        return [ROUTE_ADMIN_INVENTORY];
      if (locationPath.includes(ROUTE_ADMIN_INTEGRATIONS))
        return [ROUTE_ADMIN_INTEGRATIONS];
      if (locationPath.includes(ROUTE_ADMIN_SETTINGS_GENERAL))
        return [ROUTE_ADMIN_SETTINGS_GENERAL];
        if (locationPath.includes(ROUTE_ADMIN_SETTINGS_EMAIL))
        return [ROUTE_ADMIN_SETTINGS_EMAIL];
      if (locationPath.includes(ROUTE_ADMIN_CUSTOMERS))
        return [ROUTE_ADMIN_CUSTOMERS];
      return [locationPath];
    };
    setHighlighted(configureHighlighted(locationPath));
  }, [locationPath]);

  useEffect(() => {
    let block = false;
    safeBlockUrls.map((url) => {
      if (locationPath.includes(url)) {
        block = true;
      }
    });
    setBlockSidebar(block);
  }, [locationPath]);

  return { highlighted, blockSidebar };
};
