import { checkUndefined } from "../../../utils/models/checkers/checkUndefined";
import {
    INVENTORY_COLLECTION,
  } from "../../../utils/models/collections/collectionConstants";
import { formatDateOnRead } from "../../../utils/time/formatTimezoneOffset";

  
  export const MaintenanceQueries = (orgTimezone) => {
   
    async function fetchMaintenanceRecordsByItemId({ firestore }, orgPrefix, itemId) {
      const ref = firestore
        .collection(`${orgPrefix}${INVENTORY_COLLECTION}`)
        .doc(itemId)
        .collection("maintenanceRecords")
        .orderBy("servicedOn", "desc");
      let data = [];
      const snap = await ref.get();
      snap.forEach((s) => {
        data.push(CheckMaintenanceRecord(s, orgTimezone));
      });
      return data;
    }
    return {
      fetchMaintenanceRecordsByItemId    
    };
  };

 export const CheckMaintenanceRecord = (snap, orgTimezone) => {
  if (!snap) return;
  const data = snap.data();

  return {
    id: checkUndefined(data.id, ""),
    itemId: checkUndefined(data.itemId, ""),
    servicedOn: formatDateOnRead({
      date: checkUndefined(data.servicedOn, null),
      orgTimezone,
    }), // Convert DatePicker value to string
    servicedBy: checkUndefined(data.servicedBy, ""),
    costOfService: checkUndefined(data.costOfService, 0),
    costOfParts: checkUndefined(data.costOfParts, 0),
    costOfLabor: checkUndefined(data.costOfLabor, 0),
    notes: checkUndefined(data.notes, ""),
    createdBy: checkUndefined(data.userId, ""),
    createdOn: formatDateOnRead({
        date: checkUndefined(data.createdOn, null),
        orgTimezone,
      }),
    attachments: checkUndefined(data, [])
  };
};

  