import React, { useContext } from "react";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { Col, Input } from "antd";
import { RouteAssignmentsContext } from "../RouteAssignments";

const RouteNotes = () => {
  const { state, actions } = useContext(RouteAssignmentsContext);
  return (
    <Col span={24} style={{ marginBottom: "16px" }}>
      <TextSubtitle text={"ROUTE NOTES"} />
      <Input.TextArea
        value={state.notes}
        onBlur={() => actions.saveNotes()}
        onChange={(v) => actions.onNotesChanged(v)}
      />
    </Col>
  );
};

export default RouteNotes;
