import React, { useContext } from "react";
import { Card, Col, Progress, Row, Space, Statistic, Typography } from "antd";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { SalesReportContext } from "../SalesReport";
import { COLOR_BLUE_0 } from "../../../../app/ui/colorConstants";
import SalesReportFilterTags from "./SalesReportFilterTags";

const { Text } = Typography;
const SalesStatistic = () => {
  const { state, actions } = useContext(SalesReportContext);
  const calcPercent = () => {
    const amt = (state.selectedInvAmt / state.totalSales) * 100;
    return Math.round(amt);
  };
  const showProgressCircle = () => {
    return !!state.selectedInvItem || state.itemFilters?.length !== 0;
  };
  return (
    <Card size={"small"} style={{ width: "100%" }}>
      <Row justify={"space-between"} align={"top"}>
        <Col>
          <Space>
            {state.selectedInvAmt ? (
              <Space>
                <Statistic
                  prefix={"$"}
                  value={state.selectedInvAmt}
                  precision={2}
                  valueStyle={{ color: COLOR_BLUE_0 }}
                />
                <Text style={{ fontSize: "20px" }}>/</Text>
              </Space>
            ) : (
              <></>
            )}
            <Statistic prefix={"$"} value={state.totalSales} precision={2} />
          </Space>
          <br />
          <TextSubtitle
            text={`Sales from: ${state.dateRange?.start?.format(
              "MM/DD/yyyy"
            )} - ${state.dateRange?.end?.format("MM/DD/yyyy")}`}
          />
        </Col>
        <Col>
          {showProgressCircle() ? (
            <Progress type="circle" percent={calcPercent()} width={72} />
          ) : null}
        </Col>
      </Row>
      <SalesReportFilterTags />
    </Card>
  );
};

export default SalesStatistic;
