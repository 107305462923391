import React, { FC, useEffect, useState } from "react";
import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
} from "antd";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { InventoryItem } from "../../../../app/interfaces/inventoryItem";

const NewItemAdditional: FC<{
  form: any;
  item?: InventoryItem;
  isEdit?: any;
  visible?: any;
  drawerView?: any;
  showMaintenanceSwitch?: boolean;
  showMaintenanceSwitchToggled?: (arg: boolean) => void;
}> = ({ form, item, isEdit, visible, drawerView, showMaintenanceSwitch, showMaintenanceSwitchToggled }) => {
  const [defaultToggle, setDefaultToggle] = useState<{
    isAvailable: boolean;
    isActive: boolean;
    isTaxable: boolean;
    hasMaintenanceDetails: boolean;
  }>({
    isAvailable: true,
    isActive: true,
    isTaxable: true,
    hasMaintenanceDetails: false,
  });

  useEffect(() => {
    if (isEdit && visible) {
      const toggleData = {
        isAvailable: !!item && item.isAvailable,
        isActive: !!item && item.isActive,
        isTaxable: !!item && item.isTaxable,
        hasMaintenanceDetails: !!item && item.hasMaintenanceDetails,
      };
      form.setFieldsValue(toggleData);
      setDefaultToggle(toggleData);
    }
  }, [visible]);

  return (
    <div>
      <Card
        size="small"
        title={
          <TextBody style={{ fontWeight: "bold" }} text={"Depreciation"} />
        }
        bordered={!drawerView}
      >
        <Row gutter={24}>
          <Col>
            <Form.Item
              name="initialValue"
              label={<TextSubtitle text={"ITEM VALUE"} />}
            >
              <InputNumber<number>
                min={0}
                placeholder={"0"}
                style={{ width: "120px" }}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) =>
                  parseFloat(value!.replace(/\$\s?|(,*)/g, ""))
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="depreciationYears"
              label={<TextSubtitle text={"LIFESPAN (YEARS)"} />}
            >
              <InputNumber
                min={0}
                placeholder={"0"}
                style={{ width: "120px" }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="initialDate"
              label={<TextSubtitle text={"DATE PURCHASED"} />}
            >
              <DatePicker style={{ width: "150px" }} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <br />
      <Card
        size="small"
        title={
          <TextBody style={{ fontWeight: "bold" }} text={"Additional Info"} />
        }
        bordered={!drawerView}
      >
        <Form.Item name="notes">
          <Input.TextArea placeholder="Additional Notes" rows={2} />
        </Form.Item>
        <Form.Item
          name="isAvailable"
          style={{ marginBottom: 0 }}
          initialValue={isEdit ? defaultToggle.isAvailable : true}
          valuePropName="checked"
        >
          <Switch
            size="small"
            defaultChecked={isEdit ? item?.isAvailable : true}
            onChange={(e) => form.setFieldsValue({ isAvailable: e })}
          />{" "}
          Is Available
        </Form.Item>
        <Form.Item
          name="isActive"
          style={{ marginBottom: 0 }}
          initialValue={isEdit ? defaultToggle.isActive : true}
          valuePropName="checked"
        >
          <Switch
            size="small"
            defaultChecked={isEdit ? item?.isActive : true}
            onChange={(e) => form.setFieldsValue({ isActive: e })}
          />{" "}
          Is Active
        </Form.Item>
        <Form.Item
          name="isTaxable"
          style={{ marginBottom: 0 }}
          initialValue={isEdit ? defaultToggle.isTaxable : true}
          valuePropName="checked"
        >
          <Switch
            size="small"
            defaultChecked={isEdit ? item?.isTaxable : true}
            onChange={(e) => form.setFieldsValue({ isTaxable: e })}
          />{" "}
          Taxable
        </Form.Item>

        {
          showMaintenanceSwitch && (
            <Form.Item
              name="hasMaintenanceDetails"
              style={{ marginBottom: 0 }}
              initialValue={isEdit ? defaultToggle.hasMaintenanceDetails : true}
              valuePropName="checked"
            >
              <Switch
                size="small"
                defaultChecked={isEdit ? item?.hasMaintenanceDetails : false}
                onChange={(e) => {
                  form.setFieldsValue({ hasMaintenanceDetails: e })
                  if (showMaintenanceSwitchToggled) {
                    showMaintenanceSwitchToggled(e)
                  }
                }
                }
              />{" "}
              Enable Maintenance Features
            </Form.Item>
          )
        }

      </Card>
    </div>
  );
};

export default NewItemAdditional;
