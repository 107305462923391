import "./App.less";
import { Layout, Spin } from "antd";
import { withRouter, Switch, Route, useHistory } from "react-router-dom";
import Sidebar from "./domains/sidebar/Sidebar";
import {
  ROUTE_ADMIN_CREATE_INVENTORY,
  ROUTE_ADMIN_CUSTOMERS,
  ROUTE_ADMIN_CUSTOMERS_PAYMENT,
  ROUTE_ADMIN_INTEGRATIONS,
  ROUTE_ADMIN_INVENTORY,
  ROUTE_ADMIN_SETTINGS_EMAIL,
  ROUTE_ADMIN_SETTINGS_GENERAL,
  ROUTE_ADMIN_TRUCKS,
  ROUTE_ADMIN_USERS,
  ROUTE_CREATE_INVOICE,
  ROUTE_CREATE_INVOICE_FROM_CART,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_INVENTORY,
  ROUTE_INVOICES,
  ROUTE_LOGIN,
  ROUTE_QUICK_REPORT,
  ROUTE_RECEIVE_INVOICE_PAYMENT,
  ROUTE_REPORTS,
  ROUTE_REPORTS_DEPRECIATION,
  ROUTE_REPORTS_SALES,
  ROUTE_REPORTS_USAGE,
  ROUTE_ROUTES,
  ROUTE_VIEW_EDIT_INVOICE,
  ROUTE_ADMIN_VENUES,
  ROUTE_REPORTS_TAX,
  ROUTE_ADMIN_EMBEDDED_CHECKOUT,
} from "./app/routes";
import Invoices from "./domains/invoices/Invoices";
import QuickReport from "./domains/quick-report/QuickReport";
import Reports from "./domains/reports/Reports";
import AdminUsers from "./domains/admin-users/AdminUsers";
import AdminInventory from "./domains/admin-inventory/AdminInventory";
import AdminIntegrations from "./domains/admin-integrations/AdminIntegrations";
import AdminSettingsEmail from "./domains/admin-settings/AdminSettings.Email";
import AdminSettingsGeneral from "./domains/admin-settings/AdminSettings.General";

import { useEffect, useState } from "react";
import Login from "./domains/login/Login";
import { authSelector, verifyAuth } from "./domains/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { Spinner } from "./app/ui/spinner";
import AddInventory from "./domains/admin-inventory/components/AddInventory";
import AdminCustomers from "./domains/admin-customers/AdminCustomers";
import AdminVenues from "./domains/admin-venues/AdminVenues";
import AddInvoice from "./domains/invoices/InvoiceDetail";
import EditInvoice from "./domains/invoices/EditInvoice";
import InventoryCartWrapper from "./domains/inventory/components/InventoryCartWrapper";
import CartInvoice from "./domains/invoices/CartInvoice";
import AdminCustomersReceivePayment from "./domains/admin-customers/AdminCustomersReceivePayment";
import InvoicesReceivePayment from "./domains/invoices/InvoicesReceivePayment";
import NewRoute from "./domains/routes/NewRoute";
import AdminTrucks from "./domains/admin-trucks/AdminTrucks";
import SalesReport from "./domains/reports/sales/SalesReport";
import UsageReport from "./domains/reports/usage/UsageReport";
import DepreciationReport from "./domains/reports/depreciation/DepreciationReport";
import ForgotPasswordPage from "./domains/auth/ForgotPasswordPage";
import { init as i18nInit } from "./i18n";
import TaxReport from "./domains/reports/tax/TaxReport";
import { LicenseInfo } from "@mui/x-license-pro";
import AdminEmbeddedCheckout from "./domains/admin-embedded-checkout/AdminEmbeddedCheckout";
LicenseInfo.setLicenseKey(
  "07043bd2f09e6a69fd9f2b073d17bae7Tz02MTMzNixFPTE3MDk2OTI0NDkwOTQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const { Content } = Layout;
function App({ history }) {
  const { authenticated, verifyingAuthInProgress } = useSelector(authSelector);
  const [sidebarFormat, setSidebarFormat] = useState(false);
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const windowHistory = useHistory();

  useEffect(() => {
    const initializeI18n = async () => {
      await i18nInit();
    };

    dispatch(verifyAuth({ firestore, firebase }, history));
    if (
      history.location.pathname === "/" ||
      history.location.pathname === ROUTE_FORGOT_PASSWORD
    ) {
      history.push(ROUTE_INVENTORY);
    }

    initializeI18n();
  }, []);

  useEffect(() => {
    const path = windowHistory.location.pathname;
    if (
      path !== ROUTE_LOGIN &&
      path !== ROUTE_ROUTES &&
      path !== ROUTE_FORGOT_PASSWORD
    ) {
      setSidebarFormat(true);
    } else {
      setSidebarFormat(false);
    }
  }, [windowHistory.location.pathname, authenticated]);
  return (
    <div>
      <Layout className="App">
        {sidebarFormat && authenticated ? <Sidebar history={history} /> : null}
        {!verifyingAuthInProgress ? (
          <Content
            style={{
              marginLeft: sidebarFormat ? "" : 0,
              overflowY: "auto",
            }}
          >
            <Switch>
              <Route exact path={ROUTE_LOGIN} component={Login} />
              <Route
                exact
                path={ROUTE_FORGOT_PASSWORD}
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path={ROUTE_INVENTORY}
                component={InventoryCartWrapper}
              />
              <Route exact path={ROUTE_INVOICES} component={Invoices} />
              <Route exact path={ROUTE_CREATE_INVOICE} component={AddInvoice} />
              <Route path={ROUTE_VIEW_EDIT_INVOICE} component={EditInvoice} />
              <Route
                exact
                path={ROUTE_CREATE_INVOICE_FROM_CART}
                component={CartInvoice}
              />
              <Route
                exact
                path={ROUTE_RECEIVE_INVOICE_PAYMENT}
                component={InvoicesReceivePayment}
              />
              <Route exact path={ROUTE_ROUTES} component={NewRoute} />
              <Route exact path={ROUTE_QUICK_REPORT} component={QuickReport} />
              <Route exact path={ROUTE_REPORTS} component={Reports} />
              <Route exact path={ROUTE_REPORTS_SALES} component={SalesReport} />
              <Route exact path={ROUTE_REPORTS_TAX} component={TaxReport} />
              <Route exact path={ROUTE_REPORTS_USAGE} component={UsageReport} />
              <Route
                exact
                path={ROUTE_REPORTS_DEPRECIATION}
                component={DepreciationReport}
              />
              <Route exact path={ROUTE_ADMIN_USERS} component={AdminUsers} />
              <Route
                exact
                path={ROUTE_ADMIN_INVENTORY}
                component={AdminInventory}
              />
              <Route
                exact
                path={ROUTE_ADMIN_CREATE_INVENTORY}
                component={AddInventory}
              />
              <Route
                exact
                path={ROUTE_ADMIN_CUSTOMERS}
                component={AdminCustomers}
              />
              <Route exact path={ROUTE_ADMIN_VENUES} component={AdminVenues} />
              <Route
                exact
                path={ROUTE_ADMIN_CUSTOMERS_PAYMENT}
                component={AdminCustomersReceivePayment}
              />
              <Route exact path={ROUTE_ADMIN_TRUCKS} component={AdminTrucks} />
              <Route
                path={ROUTE_ADMIN_INTEGRATIONS}
                component={AdminIntegrations}
              />
              <Route
                exact
                path={ROUTE_ADMIN_SETTINGS_GENERAL}
                component={AdminSettingsGeneral}
              />
              <Route
                exact
                path={ROUTE_ADMIN_SETTINGS_EMAIL}
                component={AdminSettingsEmail}
              />
              <Route
                exact
                path={ROUTE_ADMIN_EMBEDDED_CHECKOUT}
                component={AdminEmbeddedCheckout}
              />
            </Switch>
          </Content>
        ) : (
          <Spin indicator={Spinner} style={{ marginTop: "120px" }} />
        )}
      </Layout>
    </div>
  );
}

export default withRouter(App);
