import { useEffect, useState } from "react";
import { useIsAuthenticated } from "./useIsAuthenticated";
import { INV_BUNDLE_ITEM } from "../../utils/models/modelConstants/modelConstants";

export function useInventoryManager(item) {
  const { orgData } = useIsAuthenticated();
  const [disableEdit, setDisableEdit] = useState(false);

  useEffect(() => {
    if (!orgData || !item) return;
    if (orgData.bundleManagedBy && orgData.bundleManagedBy === "qbo") {
      if (item.type === INV_BUNDLE_ITEM && item.createdOnPlatform === "qbo") {
        setDisableEdit(true);
      } else {
        setDisableEdit(false);
      }
    } else {
      setDisableEdit(false);
    }
  }, [item]);

  return { disableEdit };
}
