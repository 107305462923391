import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Tooltip,
} from "antd";
import { useFirestore } from "react-redux-firebase";
import { configureVenue } from "../../../app/utils/models/configure/configureVenue";
import { venueWrites } from "../../../app/services/firestore/writes/venueWrites";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";
import {
  VENUE_CONTACT_NAME,
  VENUE_NAME,
  VENUE_ADDRESS,
  VENUE_CITY,
  VENUE_STATE,
  VENUE_ZIP,
  VENUE_NOTES,
} from "../../../app/utils/models/modelConstants/modelConstants";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { WarningFilled } from "@ant-design/icons";
import { COLOR_RED_0 } from "../../../app/ui/colorConstants";
import { CategoryTag } from "../../../app/system-components/tags/Tags";
import { useHistory } from "react-router-dom";

const EditVenueModal = ({
  onCancel,
  onComplete,
  visible,
  selected,
  hideDeactivate,
}) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();
  const firestore = useFirestore();

  const handleFinish = () => {
    form.validateFields().then(() => {
      setSaving(true);
      const values = form.getFieldsValue();
      const data = {
        ...values,
      };
      const payload = configureVenue().editVenue(data, userData.id, orgData);
      venueWrites({ firestore }, fsOrgPrefix)
        .editVenue(selected.id, payload)
        .then(() => {
          clearBeforeClose();
          onComplete();
          notificationConfirm("Venue saved", "");
        })
        .catch((err) => {
          clearBeforeClose();
          console.log(err);
          notificationError("Something went wrong", "Venue could not be saved");
        });
    });
  };
  const clearBeforeClose = () => {
    setSaving(false);
    form.resetFields();
  };

  useEffect(() => {
    if (!visible) return;
    const { ...rest } = selected;
    form.setFieldsValue({
      ...rest,
    });
  }, [form, selected, visible]);

  const handleDeactivate = () => {
    Modal.confirm({
      title: "Make Inactive?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "Continuing will remove the venue from showing as an option on invoices as a delivery location",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk() {
        return new Promise((resolve, reject) => {
          setSaving(true);
          venueWrites({ firestore }, fsOrgPrefix)
            .editVenue(selected && selected.id, { isActive: false })
            .then(() => {
              resolve();
              clearBeforeClose();
              onComplete();
              notificationConfirm("Venue made inactive", "");
            })
            .catch((err) => {
              console.log(err);
              notificationError(
                "Something went wrong",
                "Venue could not be made inactive"
              );
            });
        });
      },
      onCancel() {},
    });
  };

  const handleReactivate = () => {
    Modal.confirm({
      title: "Make Active?",
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      content:
        "This will restore the venue and make it available to use on invoices as a delivery location",
      maskClosable: true,
      okType: "danger",
      cancelButtonProps: { disabled: saving },
      onOk() {
        return new Promise((resolve, reject) => {
          setSaving(true);
          venueWrites({ firestore }, fsOrgPrefix)
            .editVenue(selected && selected.id, { isActive: true })
            .then(() => {
              resolve();
              clearBeforeClose();
              onComplete();
              notificationConfirm("Venue made active", "");
            })
            .catch((err) => {
              console.log(err);
              notificationError(
                "Something went wrong",
                "Venue could not be made active"
              );
            });
        });
      },
      onCancel() {},
    });
  };

  const handleValuesChanged = (e, all) => {
    const co =
      all[VENUE_NAME] !== ""
        ? ` - ${all[VENUE_NAME]}`
        : all[VENUE_NAME] !== ""
        ? `${all[VENUE_NAME]}`
        : "";
    const currDisplay = all[VENUE_NAME];
  };

  const configureDeactivateBtns = (selected) => {
    if (!selected) return null;
    if (hideDeactivate) return null;
    return (
      <Button
        style={{ float: "left" }}
        type={selected.isActive ? "danger" : ""}
        key="inactive"
        onClick={selected.isActive ? handleDeactivate : handleReactivate}
        disabled={saving}
      >
        {selected.isActive ? "Deactivate" : "Reactivate"}
      </Button>
    );
  };

  const configureDisabled = (selected) => {
    if (!selected) return false;
    return !selected.isActive;
  };

  const { TextArea } = Input;

  return (
    <Modal
      destroyOnClose
      width={1000}
      visible={visible}
      closeIcon={<></>}
      title={
        <div>
          <Space>
            Edit Venue
            {selected && !selected.isActive && (
              <CategoryTag text={"Deactivated"} />
            )}
          </Space>
        </div>
      }
      footer={[
        selected && configureDeactivateBtns(selected),
        <Button
          key="cancel"
          onClick={() => {
            form.resetFields();
            onCancel();
          }}
          disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={saving || configureDisabled(selected)}
          loading={saving}
        >
          Save
        </Button>,
      ]}
    >
      <div>
        <Form form={form} onValuesChange={handleValuesChanged}>
          <div style={{ marginBottom: "12px" }}>
            <TextSubtitle text={"VENUE INFO"} />
          </div>
          <Row gutter={24} wrap={false}>
            <Col span={12}>
              <Form.Item
                name={VENUE_NAME}
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Venue name required",
                  },
                ]}
                style={{ marginBottom: "12px" }}
              >
                <Input
                  placeholder="Venue name"
                  disabled={configureDisabled(selected)}
                />
              </Form.Item>
              <Space
                style={{ width: "100%", display: "inline-block" }}
                direction={"vertical"}
                align={"start"}
              >
                <Form.Item
                  name={VENUE_CONTACT_NAME}
                  style={{ marginBottom: "12px", width: "100%" }}
                >
                  <Input
                    disabled={configureDisabled(selected)}
                    placeholder="Venue contact name"
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col span={12}>
              <Tooltip
                trigger={["hover"]}
                title="Text entered here will show up in the notes section of an estimate or invoice if this venue is added as either the receive or return location."
                placement="topLeft"
              >
                <Form.Item
                  name={VENUE_NOTES}
                  initialValue=""
                  style={{ marginBottom: "12px" }}
                >
                  <TextArea
                    rows={3}
                    placeholder="Venue notes"
                    disabled={configureDisabled(selected)}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
          <Divider />
          <Row wrap={false} justify="space-between" gutter={24}>
            <Col>
              <div style={{ marginBottom: "12px" }}>
                <TextSubtitle text={"VENUE ADDRESS"} />
              </div>
              <Form.Item
                initialValue=""
                name={VENUE_ADDRESS}
                style={{ marginBottom: "12px" }}
              >
                <Input
                  disabled={configureDisabled(selected)}
                  placeholder="Address1"
                />
              </Form.Item>
              <Row wrap={false} justify="space-between">
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={VENUE_CITY}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      disabled={configureDisabled(selected)}
                      placeholder="City"
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    initialValue=""
                    name={VENUE_STATE}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      disabled={configureDisabled(selected)}
                      placeholder="State"
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    initialValue=""
                    name={VENUE_ZIP}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      disabled={configureDisabled(selected)}
                      placeholder="ZIP"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EditVenueModal;
