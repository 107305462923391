import React, { createContext, useContext, useEffect, useState } from "react";
import { Button, Card, Col, Input, Row, Space, Table } from "antd";
import { unassignedColumns } from "./unassignedColumns";
import { StopsHelper } from "../../../../../app/services/hooks/helpers/route/stopsHelper";
import { StopPayload } from "../../helpers/stopPayload";
import { GetStopInRoute } from "../../helpers/getStopInRoute";
import {
  DeliveryTag,
  PickupTag,
} from "../../../../../app/system-components/tags/RoutesTags";
import { RouteContext } from "../../../NewRoute";
import { COLOR_LIGHT_GRAY } from "../../../../../app/ui/colorConstants";
import StopListSearch from "./StopListSearch";
import WaypointModal, { WaypointScriptModal } from "./WaypointModal";
import { useJsApiLoader } from "@react-google-maps/api";
import { useModalMgr } from "../../../../../app/services/hooks/useModalMgr";
import { WaypointModalContext } from "../../CreateRoutes";

const StopList = ({ isLoaded }) => {
  const { actions, state } = useContext(RouteContext);
  const modal = useContext(WaypointModalContext);
  const [keywords, setKeywords] = useState("");
  const [stopList, setStopList] = useState([]);
  useEffect(() => {
    const list = state.initialStops.filter((s) => {
      const str = `#${s?.invoice?.invoiceNumber} ${s?.displayName}`;
      if (!keywords) return true;
      return str.toLowerCase().includes(keywords);
    });
    setStopList(list);
  }, [keywords]);
  return (
    <div>
      <div
        style={{
          padding: "8px",
          height: "auto",
          backgroundColor: "#FFF",
          borderBottom: "1px #dddddd solid",
        }}
      >
        <StopListSearch setKeywords={setKeywords} />
        <br />
        <Row
          wrap={false}
          align={"middle"}
          justify={"space-between"}
          style={{ width: "100%" }}
        >
          <Col>
            <Space direction={"horizontal"} size={8}>
              <Space direction={"horizontal"}>
                Deliveries:
                <DeliveryTag text={`${state.stopBreakout?.deliveryCount}`} />
              </Space>
              <Space direction={"horizontal"}>
                Pick-ups:
                <PickupTag text={`${state.stopBreakout?.pickupCount}`} />
              </Space>
            </Space>
          </Col>
          <Col>
            <Button
              onClick={() => {
                modal.show();
              }}
              style={{
                float: "right",
                borderColor: "transparent",
                backgroundColor: COLOR_LIGHT_GRAY,
              }}
              size={"small"}
            >
              + Waypoint
            </Button>
          </Col>
        </Row>
      </div>
      <Card size={"small"} bodyStyle={{ padding: 0 }}>
        <Table
          locale={{ emptyText: "No unassigned stops" }}
          style={{
            height: "calc(96vh - 152px)",
            overflowY: "auto",
          }}
          showHeader={false}
          size={"small"}
          pagination={false}
          dataSource={keywords ? stopList : state.initialStops}
          columns={unassignedColumns(state.selectedStop)}
          rowKey="invoiceId"
          rowClassName={"UnassignedRouteRow"}
          onRow={(record, index) => {
            return {
              onClick: (event) => {
                actions.updateSelectedStop({
                  invoiceId: record?.invoiceId ?? null,
                  type: record?.type ?? null,
                });
              },
            }; // click row
          }}
        />
      </Card>
      <WaypointModal visible={modal.visible} isLoaded={isLoaded} />
    </div>
  );
};

export default StopList;
