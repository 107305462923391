import { Button, Card, Col, Row, Space, Tooltip, Typography } from "antd";
import { EditOutlined, EyeInvisibleFilled, EyeFilled } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { useRouteSelect } from "../../../../../app/services/hooks/useRouteSelect";
import {
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
} from "../../../../../app/ui/colorConstants";
import EditRouteModal from "./EditRouteModal";
import { RouteContext } from "../../../NewRoute";

export const RouteSelect = ({ route }) => {
  const { actions, state } = useContext(RouteContext);
  const { tagStyle, routeId, color, hidden } = useRouteSelect(
    route,
    state.currentRouteId,
    state.hiddenRoutes
  );
  const [edit, setEdit] = useState({
    visible: false,
    data: null,
  });
  const closeModal = () => {
    setEdit({
      visible: false,
      data: null,
    });
  };
  const { Text } = Typography;
  return (
    <Card size={"small"} bodyStyle={{ padding: "8px 0" }} bordered={false}>
      <Row wrap={false} justify={"space-between"} style={{ width: "100%" }}>
        <Col span={16}>
          <Button
            type={"primary"}
            style={{
              marginRight: "4px",
              borderColor: "transparent",
              textAlign: "left",
              width: "100%",
              ...tagStyle,
            }}
            key={route.id}
            onClick={() => actions.setCurrentRouteId(route?.id)}
          >
            <Space direction={"horizontal"}>
              <img
                key={route.id}
                src="/images/route-icon.svg"
                alt={"route"}
                style={{
                  height: "12px",
                  paddingBottom: "2px",
                  paddingLeft: "1px",
                }}
              />
              <Text
                style={{
                  color: "#FFF",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                ellipsis={true}
              >
                {route?.name ?? ""}
              </Text>
            </Space>
          </Button>
        </Col>
        <Col span={8}>
          <Space
            direction={"horizontal"}
            style={{ float: "right", marginTop: "2px" }}
          >
            <Button
              onClick={() => {
                if (hidden) {
                  actions.unhideRoute({ routeId });
                } else {
                  actions.hideRoute({ routeId });
                }
              }}
              key={`showOnlyBtn${routeId}`}
              size={"small"}
              style={{
                backgroundColor: COLOR_LIGHT_GRAY,
                color: hidden ? COLOR_DARK_BG_0 : color,
                borderColor: "transparent",
                opacity: hidden ? 0.35 : 1,
              }}
            >
              {!hidden ? (
                <EyeFilled key={`eye${routeId}`} />
              ) : (
                <EyeInvisibleFilled key={`eyeinvisible${routeId}`} />
              )}
            </Button>
            <Button
              size={"small"}
              style={{
                borderColor: "transparent",
                backgroundColor: COLOR_LIGHT_GRAY,
              }}
              onClick={() => {
                setEdit({
                  visible: true,
                  data: route,
                });
              }}
            >
              <EditOutlined />
            </Button>
          </Space>
        </Col>
        <EditRouteModal
          visible={edit?.visible}
          data={edit?.data}
          handleFinish={({ name, color }) => {
            actions.updateNameColor({
              name,
              color,
              routeId,
            });
            closeModal();
          }}
          handleDelete={() => {
            actions.deleteRoute(routeId).then(() => {
              closeModal();
            });
          }}
          onCancel={() => closeModal()}
        />
      </Row>
    </Card>
  );
};
