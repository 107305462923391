import { Affix, Modal, PageHeader, Space, Tag } from "antd";
import React, { FC } from "react";
import InvoiceHeaderBtns from "./InvoiceHeaderBtns";
import { Customer } from "../../../app/interfaces/customer";
import { useHistory } from "react-router-dom";
import { ROUTE_INVOICES } from "../../../app/routes";
import { INVOICES_COLLECTION } from "../../../app/utils/models/collections/collectionConstants";
import { useFirestoreWrite } from "../../../app/services/hooks/fetch/useFirestoreWrite";

export const InvoiceEditHeader: FC<{
  title: string;
  tags: any[];
  selectedTags: any[];
  invoiceNumbers: {
    hasError: boolean;
    adelie: null;
    qbo: null;
    errorLabel: string;
  };
  handleBackClicked: () => void;
  id: string;
  customer: Customer;
  currentInvoice: any;
  handleComplete: (params: { type: any; redirect: any }) => Promise<any>;
}> = ({
  title,
  tags,
  selectedTags,
  invoiceNumbers,
  handleBackClicked,
  id,
  customer,
  currentInvoice,
  handleComplete,
}) => {
  const history = useHistory();

  const { newDocument, updateOrgDocument, updateDocument, deleteDocument } =
    useFirestoreWrite();

  const handleDelete = (id: string) => {
    Modal.confirm({
      title: "Delete Invoice?",
      content:
        "This cannot be undone! Please note, deleting an invoice will remove it from your account completely and it cannot be recovered. It will immediately affect all associated reporting tied to this transaction (ie. QuickReport, Available Inentory, Routes...etc.) All linked payments will remain and it may affect your books. Be sure to compare transactions in QuickBooks to ensure proper account balancing.",
      okText: `Delete`,
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => {
        deleteDocument({
          data: {
            collection: INVOICES_COLLECTION,
            id: id,
          },
        }).then(() => {
          history.push(ROUTE_INVOICES);
        });
      },
      onCancel: () => {},
    });
  };
  return (
    <Affix>
      <PageHeader
        className="PageHeader"
        title={
          <Space>
            {title}{" "}
            {invoiceNumbers.hasError && (
              <div style={{ fontSize: "12px", fontWeight: "500" }}>
                <Space>{invoiceNumbers.errorLabel}</Space>
              </div>
            )}
            {selectedTags &&
              selectedTags.length > 0 &&
              selectedTags.map((tagId) => {
                const tag = tags.find((tag) => tag.id === tagId);
                if (tag) {
                  return (
                    <Tag
                      color={tag.color}
                      key={tag.id}
                    >
                      {tag.name}
                    </Tag>
                  );
                } else {
                  return null;
                }
              })}
          </Space>
        }
        onBack={handleBackClicked}
        extra={[
          <div>
            <InvoiceHeaderBtns
              id={id}
              customerId={customer && customer.id}
              customer={customer}
              currentInvoice={currentInvoice}
              handleDelete={handleDelete}
              history={history}
              handleComplete={handleComplete}
            />
          </div>,
        ]}
      />
    </Affix>
  );
};
