import { useDispatch } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { useEffect, useState } from "react";
import { useIsAuthenticated } from "./useIsAuthenticated";
import { ORG_COLLECTION } from "../../utils/models/collections/collectionConstants";

export function useInvoiceNumberListener() {
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const [currentInvoiceNumber, setCurrentInvoiceNumber] = useState();

  const { userData } = useIsAuthenticated();
  const listener =
    userData &&
    firestore
      .collection(ORG_COLLECTION)
      .doc(userData?.orgId)
      .onSnapshot({}, (doc) => {
        const currentNumber = doc.data().currentInvoiceCount ?? null;
        if (currentNumber) return setCurrentInvoiceNumber(currentNumber);
      });

  useEffect(() => {
    return function cleanup() {
      listener && listener();
    };
  }, []);
  return currentInvoiceNumber;
}
