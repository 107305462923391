import React, { useEffect, useState } from "react";
import "../../App.less";
import { Affix, Button, Form, Modal, PageHeader, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";

import GeneralInfo from "./components/GeneralInfo";
import TagsView from "./components/TagsView";

import AttentionTag from "../../app/ui/AttentionTag/attentionTag";

const AdminSettingsGeneral = () => {
  const [tabType, setTabType] = useState("general"); // general , tags

  return (
    <div>
      <Affix>
        <PageHeader className="PageHeader" backIcon={false} title="Settings" />
      </Affix>
      <div style={{ paddingTop: "68px" }}>
        <div style={{ width: "100%", marginLeft: '14px' }}>
          <Tabs
            defaultActiveKey="general"
            activeKey={tabType}
            onChange={setTabType}
          >
            <TabPane tab="General" key="general" />
            <TabPane tab="Tags" key="tags" />
            <TabPane tab={<AttentionTag />} key="tags" />
          </Tabs>
        </div>

        {tabType === "general" && (<GeneralInfo />)}
        {tabType === "tags" && (< TagsView/>)}

      </div>
    </div>
  );
};

export default AdminSettingsGeneral;
