import { useEffect, useState } from "react";
import { ConfigureNewRoutePayload } from "../../utils/models/configure/configureNewRoutePayload";
import { useFirestoreWrite } from "./fetch/useFirestoreWrite";
import { ROUTES_COLLECTION } from "../../utils/models/collections/collectionConstants";
import { RoutePayload } from "../../../domains/routes/map/helpers/routePayload";
import { RoutesHelper } from "../../../domains/routes/map/helpers/routesHelper";
import { StopPayload } from "../../../domains/routes/map/helpers/stopPayload";
import { useFirebase } from "react-redux-firebase";
import { FetchPolyline } from "./helpers/route/fetchPolyline";
import { Modal } from "antd";
import { COLOR_RED_0 } from "../../ui/colorConstants";
import {
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
  STOP_TYPE_WAYPOINT,
} from "../../utils/models/modelConstants/modelConstants";
import { genKeyValueList } from "./helpers/route/genKeyValueList";
import moment from "moment";
import notificationError from "../../system-components/toasters/notificationError";
import notificationWarning from "../../system-components/toasters/notificationWarning";
import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";
import { invoiceUtcOffsetQuery } from "../../utils/models/configure/wrapper/invoiceUtcOffsetQuery";
import { formatDateOnWrite } from "../../utils/time/formatTimezoneOffset";

export function useRoute({ isNew }) {
  const firebase = useFirebase();
  const [step, setStep] = useState(0);
  const [routes, setRoutes] = useState([]);
  const [hiddenRoutes, setHiddenRoutes] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [stopBreakout, setStopBreakout] = useState({
    deliveryCount: 0,
    pickupCount: 0,
  });
  const [selectedStop, setSelectedStop] = useState({
    invoiceId: null,
    invoice: null,
    stop: null,
    assignedInDeselectedRoutes: [], // ids of routes the stop is also assigned to
  });
  const [currentRouteId, setCurrentRouteId] = useState(null);
  const [currentRoute, setCurrentRoute] = useState(null);
  const [date, setDate] = useState({
    string: "",
    moment: null,
  });
  const [initialStops, setInitialStops] = useState([]);
  const [coAddr, setCoAddr] = useState({
    isValid: false,
    startAddrStr: "",
    startLat: 0,
    startLng: 0,
  });
  const [currentTooltip, setCurrentTooltip] = useState({
    invoiceIds: [],
    routeId: null,
  });
  const { newDocument, updateDocument, deleteDocument, writing } =
    useFirestoreWrite();

  const { orgData } = useSelector(authSelector);
  const onSelectDay = () => {
    setStep(1);
  };

  useEffect(() => {
    const route =
      currentRouteId && routes?.find((r) => r?.id === currentRouteId);
    setCurrentRoute(route ?? null);
  }, [currentRouteId, routes]);

  const clearSelectedStop = () => {
    setSelectedStop({
      invoiceId: null,
      invoice: null,
      stop: null,
      assignedInDeselectedRoutes: [],
    });
  };

  /**
   * CONFIRMATION MODAL
   */
  function showConfirm({
    title,
    description,
    okClicked,
    cancelClicked,
    okButtonText,
    color,
  }) {
    Modal.confirm({
      title: title,
      icon: <></>,
      content: description,
      okText: okButtonText,
      okButtonProps: {
        style: { backgroundColor: color && color, borderColor: "transparent" },
      },
      onOk() {
        okClicked();
      },
      onCancel() {
        cancelClicked && cancelClicked();
      },
    });
  }

  /**
   * ROUTE ACTIONS
   */
  const addRoute = async () => {
    // Create route document and add to state
    const routeCount = routes.length;
    const next = routeCount + 1;

    const routeDate = moment(date?.moment).startOf("day").toDate();
    const convertedOrgTime = formatDateOnWrite({
      date: routeDate,
      orgTimezone: orgData?.orgTimezone,
    });
    // const startOfDayConverted = new moment(convertedOrgTime)
    //   .startOf("day")
    //   .toDate();

    const r = ConfigureNewRoutePayload({
      defaultName: `Route ${next} - ${date?.string ?? ""}`,
      routes,
      coAddr,
      routeDate: convertedOrgTime,
    });
    const id = await newDocument({
      data: { collection: ROUTES_COLLECTION, payload: r },
    });
    setCurrentRouteId(id);
    setRoutes([...routes, { id: id, ...r }]);
  };
  const deleteRoute = async (id) => {
    //delete route in firestore then unassign stops
    await deleteDocument({ data: { collection: ROUTES_COLLECTION, id: id } });
    const updatedRoutes = routes.filter((r) => r.id !== id);
    setCurrentRouteId(updatedRoutes?.[0]?.id ?? null);
    setRoutes(updatedRoutes);
  };

  /**
   * STOP ACTIONS
   */
  const removeStop = async ({ stop, routeId }) => {
    const route = routes.find((r) => r.id === routeId);
    if (!route) return;
    try {
      const uptdRoute = RoutePayload(route).removeStop(stop?.invoiceId, stop);
      const uptdRoutePoly = await FetchPolyline({
        route: uptdRoute,
        firebase,
        setUpdating,
      });
      const uptdRoutes = RoutesHelper(routes).updateRoute(uptdRoutePoly);
      setRoutes(uptdRoutes);
      clearSelectedStop();
      clearCurrentTooltip();
      updateDocument({
        data: {
          collection: ROUTES_COLLECTION,
          payload: {
            stops: uptdRoutePoly?.stops,
            invoicesQueryHook: uptdRoutePoly?.invoicesQueryHook,
          },
          id: uptdRoute.id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  const addStop = async ({ stop, routeId }) => {
    if (stop?.addrValidated === false)
      return notificationWarning(
        "Unable to add to route",
        "Address not validated"
      );
    const route = routes.find((r) => r.id === routeId);
    if (!route) return;
    try {
      console.log(route);
      const payload = StopPayload(stop).updateRouteId(routeId);
      const uptdRoute = RoutePayload(route).appendStop(payload);
      const uptdRoutePoly = await FetchPolyline({
        route: uptdRoute,
        firebase,
        setUpdating,
      });
      const uptdRoutes = RoutesHelper(routes).updateRoute(uptdRoutePoly);
      setRoutes(uptdRoutes);
      console.log(uptdRoutePoly, uptdRoute);

      updateDocument({
        data: {
          collection: ROUTES_COLLECTION,
          payload: {
            stops: uptdRoutePoly?.stops,
            invoicesQueryHook: uptdRoutePoly?.invoicesQueryHook,
          },
          id: routeId,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const sortStops = async ({ routeId, stops }) => {
    const route = routes?.find((r) => r.id === routeId);
    if (!route) return;
    const routePayload = RoutePayload(route).resortStops(stops);
    const uptdRoutePoly = await FetchPolyline({
      route: routePayload,
      firebase,
      setUpdating,
    });
    setRoutes(RoutesHelper(routes).updateRoute(uptdRoutePoly));
    updateDocument({
      data: {
        collection: ROUTES_COLLECTION,
        payload: { stops: routePayload?.stops },
        id: routeId,
      },
    });
  };

  const configureInitialRoutes = (fetchedRoutes) => {
    const resetInitialStops = initialStops.filter(
      (s) => s?.type !== STOP_TYPE_WAYPOINT
    );
    let waypoints = [];
    fetchedRoutes.map((route) => {
      const routeWaypoints = route?.stops?.filter(
        (stop) => stop.type === STOP_TYPE_WAYPOINT
      );
      routeWaypoints?.map((point) => {
        if (waypoints.find((i) => i.invoiceId === point?.invoiceId)) return;
        waypoints.push(point);
      });
    });
    setInitialStops([...resetInitialStops, ...waypoints]);
    setRoutes(fetchedRoutes);
  };

  const configureInitialStops = (stops) => {
    // group stops and append data, set to state
    // hasSharedStops
    // sharedStopInvoiceIds
    const data =
      stops &&
      stops?.map((s) => {
        const { hasSharedStops, sharedStopInvoiceIds, ...rest } = s;
        const sameLatLng = stops
          ?.filter((i) => i.invoiceId !== s.invoiceId)
          ?.filter((i) => i.lat === s.lat && i.lat !== 0)
          ?.filter((i) => i.lng === s.lng && i.lng !== 0);
        const sameCount = sameLatLng?.length ?? 0;
        const ids = sameLatLng?.map((i) => i.invoiceId) ?? [];
        return {
          hasSharedStops: !!sameCount,
          sharedStopInvoiceIds: ids,
          ...rest,
        };
      });
    const deliveries = data?.filter((i) => i.type === RECEIVE_METHOD_DELIVERY);
    const pickups = data?.filter((i) => i.type === RETURN_METHOD_ONSITE);

    setStopBreakout({
      deliveryCount: deliveries?.length ?? 0,
      pickupCount: pickups?.length ?? 0,
    });
    setInitialStops([...data, ...initialStops]);
  };

  const addPolylineData = async ({ routeId, stop, data }) => {
    const route = routes.find((r) => r.id === routeId);
    if (!route) return;
    try {
      const stopPayload = StopPayload(stop).updatePolyline(data);
      return RoutePayload(route).updateStop({ stop: stopPayload });
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const updateNameColor = ({ name, color, routeId }) => {
    const route = routes?.find((r) => r.id === routeId);
    if (!route) return;
    const list = [
      { key: "name", value: name },
      { key: "color", value: color },
    ];
    const uptdRoute = RoutePayload(route).updateValues({
      list,
    });
    setRoutes(RoutesHelper(routes).updateRoute(uptdRoute));
    updateDocument({
      data: {
        collection: ROUTES_COLLECTION,
        payload: { name: name, color: color },
        id: routeId,
      },
    });
  };

  /**
   * CONFIRM
   */

  const confirmAddStop = ({ stop, routeId }) => {
    const route = routes?.find((r) => r.id === routeId);
    showConfirm({
      title: "Add Stop?",
      description: `Adding to route: ${route?.name ?? ""}`,
      color: route?.color ?? null,
      okButtonText: "Add Stop",
      okClicked: () => {
        addStop({ stop, routeId });
      },
    });
  };
  const confirmRemoveStop = ({ stop, routeId }) => {
    const route = routes?.find((r) => r.id === routeId);
    showConfirm({
      title: "Remove Stop?",
      description: `Removing from route: ${route?.name ?? ""}`,
      color: COLOR_RED_0,
      okButtonText: "Remove",
      okClicked: () => {
        removeStop({ stop, routeId });
      },
    });
  };

  const confirmSortStops = ({ routeId, stops }) => {
    const route = routes?.find((r) => r.id === routeId);
    showConfirm({
      title: "Edit stop order?",
      description: `Re-sorting route: ${route?.name ?? ""}`,
      color: route?.color ?? null,
      okButtonText: "Ok",
      okClicked: () => {
        sortStops({ stops, routeId });
      },
    });
  };

  const resetRouteData = () => {
    setStep(0);
    setDate({
      string: "",
      moment: null,
    });
    setStopBreakout({
      deliveryCount: 0,
      pickupCount: 0,
    });
    setHiddenRoutes([]);
    setRoutes([]);
    setInitialStops([]);
    setCurrentRouteId(null);
    clearSelectedStop();
  };

  const stopAssignedInRoutes = ({ stopInvoiceId, routes, currentRouteId }) => {
    // get routes from stop invoice id from query hook
    const assigned = routes?.filter(
      (r) =>
        r?.invoicesQueryHook?.includes(stopInvoiceId) &&
        currentRouteId !== r?.id
    );
    if (!assigned) return [];
    return assigned?.map((a) => {
      return {
        name: a?.name,
        routeId: a?.id,
      };
    });
  };

  const updateSelectedStop = ({ invoiceId, type }) => {
    const i = initialStops?.find(
      (s) => s.invoiceId === invoiceId && s?.type === type
    );
    const assignedInDeselected = stopAssignedInRoutes({
      stopInvoiceId: invoiceId,
      routes,
      currentRouteId,
    });
    setSelectedStop({
      invoiceId,
      invoice: i?.invoice ?? null,
      stop: i ?? null,
      assignedInDeselectedRoutes: assignedInDeselected,
    });
    let ids = [];
    if (i?.hasSharedStops) {
      ids = [...i?.sharedStopInvoiceIds, i?.invoiceId];
    } else {
      ids.push(i?.invoiceId);
    }
    updateCurrentTooltip({
      invoiceIds: ids,
      routeId: currentRouteId,
    });
  };

  const updateCurrentTooltip = ({ invoiceIds, routeId }) => {
    setCurrentTooltip({ invoiceIds: invoiceIds, routeId: routeId });
  };

  const clearCurrentTooltip = () => {
    setCurrentTooltip({
      invoiceIds: [],
      routeId: null,
    });
  };

  const unhideRoute = ({ routeId }) => {
    setHiddenRoutes(hiddenRoutes?.filter((r) => r !== routeId));
  };

  const hideRoute = ({ routeId }) => {
    setHiddenRoutes([...hiddenRoutes, routeId]);
  };

  const createWaypoint = ({ payload }) => {
    const list = genKeyValueList(payload);
    const waypointStop = StopPayload().updateValues({ list });
    setInitialStops([...initialStops, waypointStop]);
  };

  const updateWaypoint = ({ waypoint, payload }) => {
    updateWaypointStops({ waypoint, payload });
  };

  const updateWaypointStops = async ({ waypoint, payload }) => {
    // update initial stop
    const list = genKeyValueList(payload);
    const waypointStop = StopPayload(waypoint).updateValues({ list });
    const filtered = initialStops.filter(
      (s) => s.invoiceId !== waypoint.invoiceId
    );
    // update in routes
    const inRoutes = routes.filter((r) =>
      r.invoicesQueryHook.includes(waypoint?.invoiceId)
    );
    if (inRoutes) {
      inRoutes?.map((route) => {
        const uptdRoute = RoutePayload(route).updateStop({
          stop: waypointStop,
        });
        setRoutes(RoutesHelper(routes).updateRoute(uptdRoute));
        updateDocument({
          data: {
            collection: ROUTES_COLLECTION,
            payload: { stops: uptdRoute?.stops },
            id: uptdRoute?.id,
          },
        });
      });
    }
    const updatedInitial = [...filtered, waypointStop];
    setInitialStops(updatedInitial);

    // update selected stop
    const i = updatedInitial?.find((s) => s.invoiceId === waypoint?.invoiceId);
    const assignedInDeselected = stopAssignedInRoutes({
      stopInvoiceId: waypoint?.invoiceId,
      routes,
      currentRouteId,
    });
    setSelectedStop({
      invoiceId: waypoint?.invoiceId,
      invoice: i?.invoice ?? null,
      stop: i ?? null,
      assignedInDeselectedRoutes: assignedInDeselected,
    });
    let ids = [];
    if (i?.hasSharedStops) {
      ids = [...i?.sharedStopInvoiceIds, i?.invoiceId];
    } else {
      ids.push(i?.invoiceId);
    }
    updateCurrentTooltip({
      invoiceIds: ids,
      routeId: currentRouteId,
    });
  };

  return {
    actions: {
      // routes
      addRoute,
      deleteRoute,
      updateNameColor,
      setCoAddr,
      unhideRoute,
      hideRoute,
      configureInitialRoutes,
      // stops
      addStop,
      removeStop: confirmRemoveStop,
      sortStops: confirmSortStops,
      configureInitialStops,
      addPolylineData,
      updateSelectedStop,
      updateCurrentTooltip,
      clearCurrentTooltip,
      clearSelectedStop,
      createWaypoint,
      updateWaypoint,
      // date
      setDate,
      onSelectDay,
      setCurrentRouteId,
      // general
      setStep,
      resetRouteData,
    },
    state: {
      // date
      date,
      // routes
      hiddenRoutes,
      currentRouteId,
      currentRoute,
      routes,
      initialStops,
      coAddr,
      // stops
      currentTooltip,
      selectedStop,
      stopBreakout,
      // general
      updating,
      writing,
      step,
    },
  };
}
