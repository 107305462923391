import React, { useContext } from "react";
import { Col, DatePicker } from "antd";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import { RouteAssignmentsContext } from "../RouteAssignments";
import moment from "moment";
import { TimeObjToDate } from "../../../../app/services/hooks/helpers/timeObjToDate";
import { useFirestore } from "react-redux-firebase";
import { TimeMachine } from "../../../../app/utils/time/timeMachine";
import { useIsAuthenticated } from "../../../../app/services/hooks/useIsAuthenticated";

const LoadDatePicker = () => {
  const { state, actions } = useContext(RouteAssignmentsContext);
  const { orgData } = useIsAuthenticated();
  const firestore = useFirestore();
  const date = state.selectedRoute?.routeData?.loadDate;
  const convertedTime = TimeMachine(date).moment;
  return (
    <Col span={24} style={{ marginBottom: "16px" }}>
      <TextSubtitle text={"LOAD DATE"} />
      <br />
      <DatePicker
        format={(d) => d.format("MMM Do YYYY")}
        onChange={(dateMoment) =>
          actions.updateLoadDate(dateMoment, firestore, orgData)
        }
        value={convertedTime}
        style={{ width: "100%" }}
      />
    </Col>
  );
};

export default LoadDatePicker;
