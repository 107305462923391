import { useEffect, useState } from "react";
import Actions from "./Actions";
import { useIsAuthenticated } from "../../app/services/hooks/useIsAuthenticated";
import { ORG_COLLECTION } from "../../app/utils/models/collections/collectionConstants";
import { CheckCheckoutSettings } from "../../app/utils/models/checkers/checkCheckoutSettings";
import { ExtendedFirestoreInstance, useFirestore } from "react-redux-firebase";
import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { formatDateOnWrite } from "../../app/utils/time/formatTimezoneOffset";
import { TimeMachine } from "../../app/utils/time/timeMachine";
import {
  EmbeddedSettingsActions,
  EmbeddedSettingsContextInterface,
  EmbeddedSettingsState,
  defaultSettingsState,
} from "./adminEmbeddedContext";

export function useEmbeddedSettings(): EmbeddedSettingsContextInterface {
  const { orgData } = useIsAuthenticated();
  const firestore = useFirestore();
  const db = useFirestoreWrite();
  const SettingsState = useSettingsState();

  useEffect(() => {
    if (!orgData?.id) return;
    getCheckoutSettings(firestore, orgData.id, orgData.orgTimezone).then(
      (res) => {
        SettingsState.loadInitialState(res);
      }
    );
  }, [orgData]);

  const save = async () => {
    const settings = convertMomentsInSettings(
      SettingsState.state,
      orgData.orgTimezone
    );
    try {
      await db.updateDocument({
        data: {
          collection: `settings`,
          payload: { ...settings },
          id: `embeddedCheckoutSettings`,
        },
      });
      notificationConfirm("Settings Saved");
    } catch (err) {
      console.log(err);
    }
  };
  const getCheckoutSettings = async (
    firestore: ExtendedFirestoreInstance,
    orgId: string,
    orgTimezone: string
  ) => {
    const snap = await firestore
      .collection(ORG_COLLECTION)
      .doc(orgId)
      .collection("settings")
      .doc("embeddedCheckoutSettings")
      .get();
    return CheckCheckoutSettings(snap, orgId, orgTimezone);
  };

  return {
    state: SettingsState.state,
    actions: SettingsState,
    save,
    busy: db.writing,
  };
}



const convertMomentsInSettings = (payload: any, orgTimezone: string) => {
  const convertedBlackoutDates = payload?.blackoutDates?.map((dates: any) => {
    return {
      blackoutStart: TimeMachine(
        formatDateOnWrite({ date: dates.blackoutStart, orgTimezone })
      ).date,
      blackoutEnd: TimeMachine(
        formatDateOnWrite({ date: dates.blackoutEnd, orgTimezone })
      ).date,
      willCallAllowed: dates.willCallAllowed,
    };
  });
  return {
    ...payload,
    blackoutDates: convertedBlackoutDates,
  };
};

export function useSettingsState() {
  const [stateData, setStateData] =
    useState<EmbeddedSettingsState>(defaultSettingsState);

  const action = (fn: any, props?: any) => {
    const update = fn(stateData, props);
    setStateData(update);
  };
  const loadInitialState = (data: EmbeddedSettingsState) => {
    setStateData(data);
  };

  const actions: EmbeddedSettingsActions = {
    loadInitialState,
    state: stateData,
    checkoutQuestions: {
      create: () => action(Actions.CheckoutQuestions.createNewQuestion),
      remove: (props: any) =>
        action(Actions.CheckoutQuestions.removeQuestion, props),
      toggleRequired: (props: any) =>
        action(Actions.CheckoutQuestions.updateRequiredQuestion, props),
      moveUp: (props: any) =>
        action(Actions.CheckoutQuestions.moveQuestionIndexUp, props),
      moveDown: (props: any) =>
        action(Actions.CheckoutQuestions.moveQuestionIndexDown, props),
      update: (props: any) =>
        action(Actions.CheckoutQuestions.updateQuestionText, props),
    },
    checkoutSettings: {
      changeType: (props: any) =>
        action(Actions.CheckoutOptions.onTypeChange, props),
      toggleOverbooked: (props: any) =>
        action(Actions.CheckoutOptions.toggleOverbooked, props),
    },
    blackoutDates: {
      onDateChange: (props: any) =>
        action(Actions.BlackoutDates.onDateChange, props),
      addBlackoutDate: (props: any) =>
        action(Actions.BlackoutDates.addBlackoutDate, props),
      removeBlackoutDate: (props: any) =>
        action(Actions.BlackoutDates.removeBlackoutDate, props),
    },
    changePrimaryColor: (newColor: string) =>
      setStateData({
        ...stateData,
        primaryColor: newColor,
      }),
    taxRate: {
      setRate: (rateId: string) => {
        setStateData({
          ...stateData,
          taxRateId: rateId,
        });
      },
    },
    setDayOfOperation: (
      dayOfWeek:
        | "Sunday"
        | "Monday"
        | "Tuesday"
        | "Wednesday"
        | "Thursday"
        | "Friday"
        | "Saturday",
      enabled: boolean
    ) => {
      setStateData({
        ...stateData,
        daysOfOperation: {
          ...stateData.daysOfOperation,
          [dayOfWeek]: enabled,
        },
      });
    },
    setMaxCheckoutDays: (d: number) => {
      setStateData({
        ...stateData,
        maxCheckoutDays: d,
      });
    },
    changeCurrencySymbol: (newSymbol: string) =>
      setStateData({
        ...stateData,
        currencySymbol: newSymbol,
      }),
    setClosedDateSelectedMessage: (newMessage: string) =>
      setStateData({
        ...stateData,
        closedDateSelectedMessage: newMessage,
      }),
    setDisplayInventory: (display: boolean) =>
      setStateData({
        ...stateData,
        displayInventoryAvailability: display,
      }),
  };
  return actions;
}
