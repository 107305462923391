import React, { useEffect, useState } from "react";
import "../../App.less";
import { Affix, PageHeader, Row } from "antd";

import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import { useIsAuthenticated } from "../../app/services/hooks/useIsAuthenticated";
import { useFirebase } from "react-redux-firebase";
import PaymentSetupComplete from "./PaymentSetupComplete";
import {
  ROUTE_ADMIN_INTEGRATIONS,
  ROUTE_ADMIN_PAYMENT_SETUP_COMPLETE,
  ROUTE_ADMIN_PAYMENT_SETUP_REFRESH,
} from "../../app/routes";
import PaymentSetupRefresh from "./PaymentSetupRefresh";
import QuickbooksCard from "./components/QuickbooksCard";
import { qboEndpoint } from "./helpers/qboEndpoint";
import StripeCard from "./components/StripeCard";
import { useHistory } from "react-router-dom";

const OAuthClient = require("intuit-oauth");

const AdminIntegrations = () => {
  const history = useHistory();
  const { writing, updateOrgDocument } = useFirestoreWrite();
  const { orgData, isAuthenticated } = useIsAuthenticated();
  const [qboAuth, setQboAuth] = useState(null);
  const [connectingStripe, setConnectingStripe] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [paymentRefreshVisible, setPaymentRefreshVisible] = useState(false);

  const firebase = useFirebase();

  useEffect(() => {
    /**
     * for handling return state for Qbo
     * @type {string}
     */
    const location = window.location.href;
    const hasCode = location.includes("?code=");
    if (hasCode && orgData && isAuthenticated) {
      console.log(process.env.REACT_APP_BASE_URL);
      const parseObj = new URL(location);
      const code = parseObj.searchParams.get("code");
      const newUrl = location.split("?code=");
      const realmId = newUrl[1].split("realmId=");
      const endpoint = `${process.env.REACT_APP_BASE_URL}/qbo/callback?code=${newUrl[1]}&orgId=${orgData.id}`;
      const payloadData = {
        payload: { qboRealmId: realmId[1] },
        id: orgData.id,
      };
      updateOrgDocument({
        data: payloadData,
      }).then(() => {
        qboEndpoint(endpoint);
      });
    }
  }, [window.location.href, orgData, isAuthenticated]);

  useEffect(() => {
    /**
     * For handling return state on Stripe connect
     * @type {string}
     */
    const location = window.location.href;
    const setupComplete = location.includes(ROUTE_ADMIN_PAYMENT_SETUP_COMPLETE);
    setupComplete && !paymentModalVisible && setPaymentModalVisible(true);

    const refreshSetup = location.includes(ROUTE_ADMIN_PAYMENT_SETUP_REFRESH);
    refreshSetup && !paymentRefreshVisible && setPaymentRefreshVisible(true);
  }, [window.location.href]);

  const closeAndRefreshComplete = () => {
    history.push(ROUTE_ADMIN_INTEGRATIONS);
    window.location.reload();
  };
  const closeAndRefreshIncomplete = () => {
    history.push(ROUTE_ADMIN_INTEGRATIONS);
    window.location.reload();
  };

  return (
    <div>
      <Affix>
        <PageHeader
          className="PageHeader"
          backIcon={false}
          title="Integrations"
        />
      </Affix>
      <div className="list-margin-top" style={{ marginTop: "68px" }}>
        <Row justify="start" style={{ margin: "16px 16px 16px 0" }} gutter={16}>
          <QuickbooksCard orgData={orgData} />
          <StripeCard
            orgData={orgData}
            setConnectingStripe={setConnectingStripe}
            connectingStripe={connectingStripe}
          />
        </Row>
      </div>
      <PaymentSetupComplete
        visible={paymentModalVisible}
        closeAndRefresh={closeAndRefreshComplete}
        setPaymentModalVisible={setPaymentModalVisible}
      />
      <PaymentSetupRefresh
        visible={paymentRefreshVisible}
        closeAndRefresh={closeAndRefreshIncomplete}
        setPaymentRefreshVisible={setPaymentRefreshVisible}
      />
    </div>
  );
};

export default AdminIntegrations;
