import { useIsAuthenticated } from "../useIsAuthenticated";
import notificationError from "../../../system-components/toasters/notificationError";
import { useState, useEffect } from "react";
import { ExtendedFirestoreInstance, useFirestore } from "react-redux-firebase";

export function useFirestoreFetch<T>(
  fetchFn: (
    {
      firestore,
    }: {
      firestore: ExtendedFirestoreInstance;
    },
    orgPrefix: string,
    opts?: any
  ) => Promise<T[]>,
  opts?: T
) {
  const [data, setData] = useState<T[]>([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState<{ hasError: boolean; error: any }>({
    hasError: false,
    error: null,
  });
  const { isAuthenticated, fsOrgPrefix } = useIsAuthenticated();
  const firestore = useFirestore();

  const fetch = async () => {
    if (!isAuthenticated) return;
    try {
      setFetching(true);
      fetchFn({ firestore }, fsOrgPrefix, opts).then((res) => {
        setData(res);
        setFetching(false);
      });
    } catch (err) {
      console.log(err);
      notificationError(
        "Something went wrong",
        "Please try again or refresh the page"
      );
      setFetching(false);
      setError({
        hasError: true,
        error: err,
      });
    }
  };
  const refetch = async () => {
    return new Promise((resolve) => {
      setData([]);
      fetch().then(() => {
        resolve([...data]);
      });
    });
  };
  useEffect(() => {
    fetch();
  }, [isAuthenticated, opts]);
  return { data: data, fetching: fetching, error: error, refresh: refetch };
}
