import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { createNewStripeConnect } from "../../../app/services/cloud-functions/createNewStripeConnect";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { newStripeAccountLink } from "../../../app/services/cloud-functions/newStripeAccountLink";
import { useIsAuthenticated } from "../../../app/services/hooks/useIsAuthenticated";

const StripeIntBtn = ({ connectingStripe, setConnectingStripe, firebase }) => {
  const { orgData } = useIsAuthenticated();
  const [enabled, setEnabled] = useState(false);
  const [complete, setComplete] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);

  useEffect(() => {
    const enable = orgData?.integrations?.stripe ?? false;
    const acc = orgData?.stripeAccount;
    setEnabled(!!enable);
    setComplete(orgData?.stripeDetailsSubmitted ?? false);
    setAccountCreated(!!acc);
  }, [orgData]);

  const btnText = {
    disabled: {
      text: "Enable Payments",
      disabled: true,
    },
    complete: {
      text: "Connected",
      disabled: true,
    },
    notStarted: {
      text: "Enable Payments",
      disabled: false,
    },
    incomplete: {
      text: "Complete Payment Setup",
      disabled: false,
    },
  };
  const text = () => {
    if (!enabled) return btnText.disabled;
    if (complete && accountCreated) return btnText.complete;
    if (enabled && !accountCreated) return btnText.notStarted;
    return btnText.incomplete;
  };
  return (
    <Button
      type="primary"
      style={{
        float: "right",
      }}
      loading={connectingStripe}
      disabled={connectingStripe || text().disabled}
      onClick={async () => {
        //handle stripe click
        setConnectingStripe(true);
        if (text().text === "Enable Payments") {
          createNewStripeConnect(
            { firebase },
            {
              orgId: orgData.id,
            }
          ).then((res) => {
            const url = res?.data?.accountLinkUrl ?? null;
            setConnectingStripe(false);
            if (!url) return notificationError("Could not connect");
            Modal.confirm({
              title:
                "You will be redirected to complete payment processor set up.",
              onOk: () => {
                window.open(url, "_self");
              },
            });
          });
        } else {
          newStripeAccountLink({ firebase }, { orgId: orgData.id }).then(
            (res) => {
              const url = res?.data?.accountLinkUrl ?? null;
              setConnectingStripe(false);
              if (!url) return notificationError("Could not connect");
              Modal.confirm({
                title:
                  "You will be redirected to complete payment processor set up.",
                onOk: () => {
                  window.open(url, "_self");
                },
              });
            }
          );
        }
      }}
    >
      {text().text}
    </Button>
  );
};

export default StripeIntBtn;
