import React, { useState } from "react";

import { CloseOutlined, WarningFilled } from "@ant-design/icons";
import { useFirestore } from "react-redux-firebase";
import { Button, Col, Modal, Row } from "antd";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { inventoryQueries } from "../../../app/services/firestore/queries/inventoryQueries";
import { COLOR_RED_0 } from "../../../app/ui/colorConstants";
import { Spinner } from "../../../app/ui/spinner";
import { InventoryItem } from "../../../app/interfaces/inventoryItem";
import { inventoryWrites } from "../../../app/services/firestore/writes/inventoryWrites";
import notificationError from "../../../app/system-components/toasters/notificationError";

export const AddAllInventoryToShop = () => {
  const { fsOrgPrefix } = useSelector(authSelector);
  const [addingAllInventory, setAddingAllInventory] = useState(false);
  const firestore = useFirestore();
  const [openModal, setOpenModal] = useState(false);
  const [allInventoryCount, setAllInventoryCount] = useState(0);
  const [curentInventoryIndex, setCurentInventoryIndex] = useState(0);

  const handleOk = async () => {
    setAddingAllInventory(true);
    const querier = inventoryQueries({ firestore }, fsOrgPrefix);
    if (querier) {
      const allInventory = await querier.fetchInventory();
      setAllInventoryCount(allInventory.length);
      loop: for (const inventoryItem of allInventory) {
        setCurentInventoryIndex((c) => c + 1);
        if (inventoryItem.embeddedShopRateId && inventoryItem.showInShop) {
          continue loop;
        }

        const dailyRates = inventoryItem.rates.filter(
          (r) => r.rateRange === "day"
        );
        if (dailyRates.length === 0) continue loop;

        if (!dailyRates[0].id) {
          console.log("rate did not have id for inventory item", inventoryItem);
          continue loop;
        }

        const payload: Partial<InventoryItem> = {
          showInShop: true,
          embeddedShopRateId: dailyRates[0].id,
        };

        const writer = inventoryWrites({ firestore }, fsOrgPrefix);
        if (writer) {
          try {
            await writer.updateInventory(inventoryItem.id, payload);
            console.log(payload);
          } catch (err) {
            console.error(err);
            console.log(inventoryItem, payload);

            notificationError("Something went wrong", "Please try again later");
          }
        }
      }
    }
    setAddingAllInventory(false);
    setOpenModal(false);
    setAllInventoryCount(0);
    setCurentInventoryIndex(0);
  };

  return (
    <>
      <Button type={"default"} onClick={() => setOpenModal(true)}>
        Add All Inventory to Embedded Shop
      </Button>
      <Modal
        title="Add All Inventory to Embedded Shop?"
        open={openModal}
        okType="danger"
        cancelText="Cancel"
        onOk={handleOk}
        onCancel={() => {
          console.log("canceled");
          setOpenModal(false);
        }}
        cancelButtonProps={{ disabled: addingAllInventory }}
        okButtonProps={{ disabled: addingAllInventory }}
        maskClosable={!addingAllInventory}
        keyboard={!addingAllInventory}
        closeIcon={<></>}
      >
        <>
          <Row justify="center" align="middle" gutter={36}>
            <Col>
              <WarningFilled style={{ color: COLOR_RED_0 }} />
            </Col>
            <Col span={20}>
              <p>
                This action will make all inventory available on the embedded
                shop. This will take several minutes.
              </p>
            </Col>
          </Row>
          {addingAllInventory && (
            <>
              <Row justify="center">
                <Col>{Spinner}</Col>
              </Row>

              <Row justify="center">
                <Col>
                  {curentInventoryIndex} / {allInventoryCount}
                </Col>
              </Row>
            </>
          )}
        </>
      </Modal>
    </>
  );
};
