import React from "react";
import { Modal } from "antd";
import { useFirestoreFetch } from "../../../../app/services/hooks/fetch/useFirestoreFetch";
import { PaymentQueries } from "../../../../app/services/firestore/queries/paymentQueries";
import PaymentList from "../../../sharedComponents/payments/PaymentList";
import { useIsAuthenticated } from "../../../../app/services/hooks/useIsAuthenticated";

const PaymentHistoryModal = ({
  customer,
}) => {
  const { orgData } = useIsAuthenticated();
  const { data, fetching } = useFirestoreFetch(
    PaymentQueries(orgData?.orgTimezone).fetchPaymentsByCustomer,
    customer.id
  );
  return (
      <div>
        <PaymentList fetching={fetching} data={data} />
      </div>
  );
};

export default PaymentHistoryModal;
