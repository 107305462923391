import React, { createContext, useContext, useEffect, useState } from "react";
import { Row, Col, Spin, Button, Card, Space } from "antd";
import {
  useGetInvoicesForDayQuery,
  useGetRoutesForMonthQuery,
} from "../../../app/services/api";
import { useFirestore } from "react-redux-firebase";
import { useIsAuthenticated } from "../../../app/services/hooks/useIsAuthenticated";
import RouteStopList from "./components/assignedList/RouteStopList";
import StopList from "./components/stopList/StopList";
import SelectRouteHeader from "./components/routeList/SelectRouteHeader";
import Map from "./Map";
import { RouteContext } from "../NewRoute";
import SelectedStopInvoice from "./components/stopPane/SelectedStopInvoice";
import { useJsApiLoader } from "@react-google-maps/api";
import { useModalMgr } from "../../../app/services/hooks/useModalMgr";
import moment from "moment";
import { ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import {
  COLOR_GREEN_0,
  COLOR_LIGHT_GRAY,
  COLOR_ORANGE_HIGHLIGHT,
} from "../../../app/ui/colorConstants";
export const WaypointModalContext = createContext();

const CreateRoutes = () => {
  const ModalMgr = useModalMgr();

  const { state, actions } = useContext(RouteContext);
  const firestore = useFirestore();
  const { fsOrgPrefix, orgData } = useIsAuthenticated();
  const { data: stops, isFetching } = useGetInvoicesForDayQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    moment: state.date?.moment,
    orgTimezone: orgData?.orgTimezone,
  });
  const {
    data: fetchedRoutes,
    isFetching: fetchingRoutes,
    refetch: refetchRoutes,
  } = useGetRoutesForMonthQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    monthStart: new moment(state.date?.moment),
    monthEnd: new moment(state.date?.moment),
    orgTimezone: orgData?.orgTimezone,
  });
  const apiKey = "AIzaSyCk8zR40fhVRwZjl7TrXS1XyS04q40xapw";
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
  });
  useEffect(async () => {
    if (isFetching) return;
    actions.configureInitialStops(stops);
  }, [stops]);
  useEffect(async () => {
    if (fetchingRoutes) return;
    actions.configureInitialRoutes(fetchedRoutes);
  }, [fetchedRoutes]);

  useEffect(() => {
    refetchRoutes();
  }, []);

  useEffect(() => {
    const validCoAddr =
      state.coAddr?.startLat !== 0 && state.coAddr?.startLng !== 0;
    if (isFetching || fetchingRoutes) return;
    if (state.routes.length === 0 && validCoAddr) {
      actions.addRoute();
    } else {
      actions.setCurrentRouteId(state.routes[0]?.id);
    }
  }, [isFetching, fetchingRoutes]);
  const selectedRoute = (routes, currentRouteId) => {
    const route = routes?.find((r) => r.id === currentRouteId);
    return route ? route : null;
  };

  return (
    <div>
      <WaypointModalContext.Provider value={ModalMgr}>
        <Spin
          spinning={isFetching || state.updating || fetchingRoutes}
          delay={800}
          tip={"Updating.."}
        >
          <Space className="route-elements-row">
            <Card
              className="routing-stops"
              span={5}
              style={{ height: "calc(96vh - 40px)" }}
            >
              <StopList isLoaded={isLoaded} />
            </Card>
            <Card
              className="routing-map"
              span={13}
              style={{
                borderLeft: "1px solid #dddddd",
                borderRight: "1px solid #dddddd",
                height: "calc(96vh - 40px)",
                width: "55vw",
              }}
            >
              <Map isLoaded={isLoaded} />
              {state.selectedStop?.invoiceId || state.selectedStop?.stop ? (
                <Card
                  className={"animated animatedFadeInUp fadeInUp"}
                  size={"small"}
                  bordered={"false"}
                  style={{
                    margin: "0 16px 16px",
                    height: "224px",
                    bottom: "240px",
                    zIndex: 100,
                    width: "90%",
                    boxShadow: "0px 2px 12px rgba(26, 26, 53, 0.1)",
                    padding: "10px",
                  }}
                >
                  <Row>
                    <Col flex={"auto"}>
                      <SelectedStopInvoice />
                    </Col>
                    <Col flex={"24px"}>
                      <Button
                        size={"small"}
                        onClick={() => {
                          actions.clearSelectedStop();
                        }}
                      >
                        <CloseOutlined />
                      </Button>
                    </Col>
                  </Row>
                </Card>
              ) : null}
            </Card>
            <Card
              className="routing-routes"
              span={6}
              style={{ height: "calc(96vh - 40px)" }}
            >
              <div
                style={{
                  height: "calc(40vh - 30px)",
                  overflowY: "auto",
                  whiteSpace: "nowrap",
                  backgroundColor: "#FFF",
                }}
              >
                <SelectRouteHeader />
              </div>
              <div
                style={{
                  height: "calc(60vh - 30px)",
                }}
              >
                {selectedRoute(state.routes, state.currentRouteId) && (
                  <RouteStopList
                    data={selectedRoute(state.routes, state.currentRouteId)}
                  />
                )}
              </div>
            </Card>
          </Space>
          <Button
            onClick={() => actions.setStep(2)}
            style={{
              boxShadow: "8px 12px 8px rgb(0,0,0, .15)",
              bottom: "50px",
              float: "right",
              marginRight: "16px",
              zIndex: 1000,
              width: "auto",
              borderColor: "transparent",
              backgroundColor: COLOR_GREEN_0,
              color: "#FFF",
              fontWeight: "bold",
            }}
          >
            Continue to Assignments <ArrowRightOutlined />
          </Button>
        </Spin>
      </WaypointModalContext.Provider>
    </div>
  );
};

export default CreateRoutes;
