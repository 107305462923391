import React, { useEffect, useState } from "react";
import { Space, Typography } from "antd";
import { FormatToLocalCurrency } from "../../app/utils/currency/formatToLocalCurrency";
import { useIsAuthenticated } from "../../app/services/hooks/useIsAuthenticated";

/**
 * Takes customer object and displays balance and credit
 */
const { Text } = Typography;
const DisplayCustomerBalance = ({ customer, direction }) => {

  const { orgData } = useIsAuthenticated();

  const spaceDirection = configureDirection(direction);
  const [credit, setCredit] = useState('');
  const [balance, setBalance] = useState('');

  useEffect(() => {
    setCredit(FormatToLocalCurrency(customer?.credit, orgData?.countryCode, orgData?.languageCode, orgData?.currencyCode))
    setBalance(FormatToLocalCurrency(customer?.balance, orgData?.countryCode, orgData?.languageCode, orgData?.currencyCode))
  }, [orgData, customer]);

  return (
    <Space direction={spaceDirection}>
      <Text>
        Balance: <span style={{ fontWeight: "bold" }}>{balance}</span>
      </Text>
      <Text>
        Credit: <span style={{ fontWeight: "bold" }}>{credit}</span>
      </Text>
    </Space>
  );
};

export default DisplayCustomerBalance;

const configureDirection = (direction) => {
  if (!direction || direction === "horizontal") return "horizontal";
  if (direction === "vertical") return "vertical";
  return "horizontal";
};
