import { ROW_TYPE_SUBTOTAL } from "../../../../utils/models/modelConstants/modelConstants";

export const FormatForSalesChart = ({
  item,
  categoryFilterIds,
  inventoryListItem,
}) => {
  // ignore
  const ignoreSubtotalRow = item?.rowType === ROW_TYPE_SUBTOTAL;
  const itemMatch = item?.id === inventoryListItem?.inventoryId;
  if (ignoreSubtotalRow || !itemMatch) return inventoryListItem; // ignore if subtotal row or not item

  // determine if we should include in calc
  const noFilter = categoryFilterIds?.length === 0;
  const filterCount = categoryFilterIds?.filter((i) =>
    item?.categoriesQueryHook?.includes(i)
  );
  const includeInFiltered = filterCount?.length > 0;
  const include = noFilter || includeInFiltered;

  // configure running totals
  const runningTotal = inventoryListItem?.total ?? 0;
  const runningFilteredTotal = inventoryListItem?.filteredTotal ?? 0;
  const add = item?.rowTotal ?? 0;
  const safeAdd = isNaN(add) ? 0 : add;
  return {
    inventoryId: inventoryListItem?.inventoryId,
    filteredTotal: include
      ? runningFilteredTotal + safeAdd
      : runningFilteredTotal,
    name: inventoryListItem?.name,
    total: include ? runningTotal : runningTotal + safeAdd,
  };
};
