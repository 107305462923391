export const ConfigureInvoiceEndDate = ({
  isInvoice,
  invoice,
  currentEndDateArray,
  itemQty,
  bundleQty,
}) => {
  const invoicesDates = currentEndDateArray ? currentEndDateArray : [];
  if (!isInvoice) return invoicesDates;
  const payload = {
    invoiceId: invoice.id,
    itemQty: itemQty,
    bundleQty: bundleQty,
    rentalDateEnd: invoice.rentalDateEnd,
  };
  return [payload, ...invoicesDates];
};
