import React, { useEffect, useState } from "react";
import "../../App.less";
import { Affix, Button, Form, Modal, PageHeader, Spin, Tooltip } from "antd";
import CustomizeEmail from "./components/CustomizeEmail";
import { configureEmailSettingsUpdate } from "../../app/utils/models/configure/configureOrgUpdate";
import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import notificationError from "../../app/system-components/toasters/notificationError";
import { orgWrites } from "../../app/services/firestore/writes/orgWrites";
import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { orgQueries } from "../../app/services/firestore/queries/orgQueries";
import { Spinner } from "../../app/ui/spinner";

const AdminSettingsEmail = () => {
  const {
    userData,
    orgData,
    invoiceEmailSettings,
    estimateEmailSettings,
    fsOrgPrefix,
  } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);

  const firestore = useFirestore();

  useEffect(() => {
    // load org data & set page state
    // orgData && loadSettings();
  }, [orgData]);

  return (
    <div>
      <Spin spinning={loading} indicator={Spinner}>
        <Affix>
          <PageHeader
            className="PageHeader"
            backIcon={false}
            title="Settings"
          />
        </Affix>
        <div style={{ paddingTop: "68px", margin: "20px" }}>
          <CustomizeEmail
            settings={invoiceEmailSettings}
            settingsId={"invoiceEmailSettings"}
            title={"Customize Invoice Email"}
          />
          <br />
          <CustomizeEmail
            settings={estimateEmailSettings}
            settingsId={"estimateEmailSettings"}
            title={"Customize Estimate Email"}
          />
        </div>
      </Spin>
    </div>
  );
};

export default AdminSettingsEmail;
