import React from "react";
import { DatePicker, Modal, Radio, Checkbox, Button } from "antd";
import moment from "moment";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";

const { RangePicker } = DatePicker;

const InvoiceFilterModal = ({ filters }) => {
  const { state, actions } = filters;
  return (
    <Modal
      closeIcon={<></>}
      visible={state.showModal}
      width={700}
      onOk={() => actions.setShowModal(false)}
      cancelText={"Reset Filters"}
      onCancel={() => actions.setShowModal(false)}
      footer={[
        <Button key="clear" onClick={() => actions.resetFilters()}>
          Reset Filters
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => actions.setShowModal(false)}
        >
          Ok
        </Button>,
      ]}
    >
      <TextSubtitle text={"RANGE"} />
      <br />
      <RangePicker
        // allowClear={false}
        value={[
          moment(state.filters.range.startDate),
          moment(state.filters.range.endDate),
        ]}
        defaultValue={[
          moment(state.filters.range.startDate),
          moment(state.filters.range.endDate),
        ]}
        format={(d) => d.format("MMM Do YYYY")}
        onChange={(v) => {
          actions.onRangeChanged(v);
        }}
      />
      <br />
      <Radio.Group
        style={{ marginTop: "12px" }}
        value={state.filters.range.id}
        options={[
          { label: "Yesterday", value: "yesterday" },
          { label: "Today", value: "today" },
          { label: "Tomorrow", value: "tomorrow" },
          { label: "This week", value: "currentWeek" },
          { label: "Next week", value: "nextWeek" },
          { label: "This month", value: "currentMonth" },
          { label: "Next month", value: "nextMonth" },
        ]}
        onChange={(e) => {
          actions.onButtonRangeChanged(e.target.value);
        }}
        optionType="button"
        buttonStyle="solid"
      />
      <br />
      <br />
      <TextSubtitle text={"STATUS"} />
      <br />
      <Checkbox.Group
        options={[
          { label: "Outstanding", value: "outstanding" },
          { label: "Complete", value: "complete" },
          {
            label: "Estimate Signed",
            value: "hasEstimateSignatures",
          },
          {
            label: "Payment Signed",
            value: "hasPaymentSignatures",
          },
        ]}
        defaultValue={state.filters.statuses}
        onChange={actions.onStatusChanged}
        value={state.filters.statuses}
      />
      <br />
      <br />
      <TextSubtitle text={"TYPE"} />
      <br />
      <Checkbox.Group
        options={[
          { label: "Invoice", value: "invoice" },
          { label: "Estimate", value: "estimate" },
          {
            label: "Draft",
            value: "draft",
          },
        ]}
        defaultValue={state.filters.types}
        onChange={actions.onTypesChanged}
        value={state.filters.types}
      />
    </Modal>
  );
};

export default InvoiceFilterModal;
