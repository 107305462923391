import React, { useCallback, useState } from "react";
import "../../App.less";
import {
  Affix,
  Badge,
  Calendar,
  Card,
  PageHeader,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  QR_STATUS_COMPLETE,
  QR_STATUS_HAS_ISSUE,
  QR_STATUS_IN_PROGRESS,
} from "../../app/utils/models/modelConstants/modelConstants";
import SelectedDayDrawer from "./components/SelectedDayDrawer";
import moment from "moment";
import { useInvoicesInMonth } from "../../app/services/hooks/fetch/useInvoicesInMonth";
import { Spinner } from "../../app/ui/spinner";
import {
  COLOR_GREEN_0,
  COLOR_RED_0,
  COLOR_YELLOW_0,
} from "../../app/ui/colorConstants";
import TextSubtitle from "../../app/system-components/typography/text/TextSubtitle";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  FlagFilled,
  WarningFilled,
} from "@ant-design/icons";
import QrSelectFilter from "./components/modules/QrSelectFilter";

const QuickReport = () => {
  const [activeDay, setActiveDay] = useState(moment());
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const {
    data,
    fetching,
    error,
    filters,
    dailyAvailable,
    setFilters,
    availableFilters,
    setAvailableFilters,
    filteredData,
    refresh,
  } = useInvoicesInMonth(activeDay);
  const [drawer, setDrawer] = useState({
    visible: false,
    data: [],
  });
  const [selectedDate, setSelectedDate] = useState({
    dateStr: "",
    date: null,
  });

  const handleDayClicked = (date) => {
    const invoices = getData(date);
    if (invoices.length === 0) return;
    setDrawer({
      visible: true,
      data: invoices,
    });
    const dateStr = moment(date).format("MMM Do YYYY");

    setSelectedDate({
      dateStr: dateStr,
      date: date,
    });
  };

  const getData = (value) => {
    if (fetching) return;
    const day = value.date();
    const mo = value.month();
    const year = value.year();
    return (
      filteredData &&
      filteredData.filter((i) => {
        const sameDay = moment(i.rentalDateStart.toDate()).date() === day;
        const sameMonth = i.rentalDateStart.toDate().getMonth() === mo;
        const sameYear = i.rentalDateStart.toDate().getFullYear() === year;
        return sameDay && sameMonth && sameYear;
      })
    );
  };

  const renderStatus = (qrStatus) => {
    if (qrStatus === QR_STATUS_COMPLETE) return COLOR_GREEN_0;
    if (qrStatus === QR_STATUS_HAS_ISSUE) return COLOR_RED_0;
    if (qrStatus === QR_STATUS_IN_PROGRESS) return COLOR_YELLOW_0;
    return COLOR_YELLOW_0;
  };

  const determineFilteredCell = (filter, listData) => {
    let ready = false;
    let hasIssue = false;
    let inProgress = false;
    listData &&
      listData.map((d) => {
        const qrItem = d.qrItems.filter((i) => i.id === filter);
        let itemReady = false;
        let itemHasIssue = false;
        let itemInProgress = false;
        qrItem.map((i) => {
          if (i.completed / i.qtyRented === 1) {
            itemReady = true;
          } else if (i.hasIssue) {
            itemHasIssue = true;
          } else {
            itemInProgress = true;
          }
        });
        if (itemReady && !itemHasIssue && !itemInProgress) {
          ready = true;
        } else if (itemHasIssue) {
          hasIssue = true;
        } else {
          inProgress = true;
        }
      });
    if (ready && inProgress === false && hasIssue === false) {
      return {
        status: "completed",
        statusText: "Ready",
        className: "qr-calendar-cell-ready",
      };
    } else if (hasIssue) {
      return {
        status: "hasIssue",
        statusText: "Flagged items",
        className: "qr-calendar-cell-hasIssue",
      };
    } else {
      return {
        status: "inProgress",
        statusText: "In Progress",
        className: "qr-calendar-cell-in-progress",
      };
    }
  };

  const determineFullCell = (listData, dayReady) => {
    let ready = false;
    let hasIssue = false;
    let inProgress = false;
    listData &&
      listData.map((d) => {
        if (d.qrStatus === QR_STATUS_COMPLETE) {
          ready = true;
        } else if (d.qrStatus === QR_STATUS_HAS_ISSUE) {
          hasIssue = true;
        } else {
          inProgress = true;
        }
      });
    if (dayReady)
      return {
        status: "completed",
        statusText: "Ready",
        className: "qr-calendar-cell-ready",
      };
    if (hasIssue)
      return {
        status: "hasIssue",
        statusText: "Flagged items",
        className: "qr-calendar-cell-hasIssue",
      };
    if (inProgress)
      return {
        status: "inProgress",
        statusText: "In Progress",
        className: "qr-calendar-cell-in-progress",
      };
    return {
      status: "inProgress",
      statusText: "In Progress",
      className: "qr-calendar-cell-in-progress",
    };
  };

  const configureStatusCell = (listData, dayReady, filter) => {
    let mainStatus = null;
    if (filter) {
      mainStatus = determineFilteredCell(filter, listData);
    } else {
      mainStatus = determineFullCell(listData, dayReady);
    }
    return mainStatus;
  };

  const configureOverbookedCell = (date, dailyAvailable, listData) => {
    const itemIds = [];
    listData &&
      listData.map((inv) => {
        inv.bundleItemsQueryHook &&
          inv.bundleItemsQueryHook.map((b) => {
            itemIds.push(b);
          });
        inv.selectedItemsQueryHook &&
          inv.selectedItemsQueryHook.map((i) => {
            itemIds.push(i);
          });
      });
    const day = date.startOf("day");
    const dailyInventory =
      dailyAvailable &&
      dailyAvailable.find((d) => {
        return new Date(d.date).getTime() === new Date(day).getTime();
      });
    const ob =
      dailyInventory &&
      dailyInventory.stockList.some((i) => {
        return i.isOverbooked === true && itemIds.includes(i.id);
      });
    return ob ? (
      <Tooltip title={"Contains overbooked items"}>
        <WarningFilled style={{ color: "#FFD54F", marginRight: "4px" }} />
      </Tooltip>
    ) : null;
  };

  function dateCellRender(value, dailyAvailable) {
    const listData = filteredData && getData(value);
    const hasInvoices = listData && listData.length > 0;

    const dayReady =
      listData &&
      hasInvoices &&
      listData.filter((d) => d.qrStatus !== QR_STATUS_COMPLETE).length === 0;
    return (
      <ul className="events">
        <li
          className={"statusOverbooked"}
          style={{ float: "right" }}
          key={`${value.date()}_overbooked`}
        >
          {hasInvoices &&
            listData &&
            configureOverbookedCell(value, dailyAvailable, listData)}
        </li>
        <li
          className={
            configureStatusCell(listData, dayReady, filters.inventory).className
          }
          key={value.date()}
        >
          {dayReady ||
          configureStatusCell(listData, dayReady, filters.inventory).status ===
            QR_STATUS_COMPLETE ? (
            <TextSubtitle
              text={
                <span style={{ color: "#FFF" }}>
                  <CheckCircleFilled style={{ color: "#FFF" }} /> All items
                  ready
                </span>
              }
            />
          ) : hasInvoices ? (
            configureStatusCell(listData, dayReady, filters.inventory)
              .status === "hasIssue" ? (
              <TextSubtitle
                text={
                  <span style={{ color: "#FFF" }}>
                    <FlagFilled style={{ color: "#FFF" }} /> Flagged issues
                  </span>
                }
              />
            ) : (
              <TextSubtitle
                text={
                  <span style={{ color: COLOR_YELLOW_0 }}>In progress</span>
                }
              />
            )
          ) : null}
        </li>
        {listData &&
          listData.map((item) => (
            <Tooltip
              title={`${item.customer.customerDisplayName} (#${item.invoiceNumber})`}
            >
              <li key={item.id}>
                <Badge
                  color={renderStatus(item.qrStatus)}
                  text={`${item.customer.customerDisplayName}`}
                />
              </li>
            </Tooltip>
          ))}
      </ul>
    );
  }

  function getMonthData(value) {
    if (value.month() === 8) {
      return 1394;
    }
  }

  function monthCellRender(value) {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  }

  const refreshInvoiceData = (invoice) => {
    // refresh invoices on open drawer
    const updated = drawer.data.map((i) => {
      if (i.id === invoice.id) {
        return invoice;
      }
      return i;
    });
    setDrawer({
      visible: drawer.visible,
      data: [...updated],
    });
    setTriggerRefresh(true);
  };

  const refreshInvoicesFromInventory = ({ invoices }) => {
    setDrawer({
      visible: drawer.visible,
      data: [...invoices],
    });
    setTriggerRefresh(true);
  };

  const configRefresh = () => {
    if (triggerRefresh) {
      refresh();
      setTriggerRefresh(false);
    }
  };

  const configureDailyAvailable = (selectedDate) => {
    const available = dailyAvailable.find((i) => {
      const availDate = new Date(i.date).getTime();
      const selected = new Date(selectedDate.date).getTime();
      return availDate === selected;
    });
    return available ? available : null;
  };

  return (
    <div>
      <Affix>
        <PageHeader
          className="PageHeader"
          backIcon={false}
          title="Quick Report"
        />
      </Affix>
      <div style={{ padding: "76px 20px 20px" }}>
        <Spin indicator={Spinner} spinning={fetching}>
          <Card
            size={"small"}
            title={
              <Space>
                <QrSelectFilter
                  filters={filters}
                  availableFilters={availableFilters}
                  placeholder={"All Categories"}
                  setFilters={setFilters}
                  filterType={"category"}
                />
                <QrSelectFilter
                  filters={filters}
                  availableFilters={availableFilters}
                  placeholder={"All Inventory"}
                  setFilters={setFilters}
                  filterType={"inventory"}
                />
              </Space>
            }
          >
            <Calendar
              onSelect={handleDayClicked}
              mode={"month"}
              onChange={(e) => {
                const sameMo = activeDay.month() === e.month();
                if (!sameMo) {
                  setActiveDay(e);
                  refresh();
                }
              }}
              defaultValue={moment()}
              dateCellRender={(v) => dateCellRender(v, dailyAvailable)}
              monthCellRender={monthCellRender}
            />
          </Card>
        </Spin>
      </div>
      <SelectedDayDrawer
        dailyAvailable={
          selectedDate.date && configureDailyAvailable(selectedDate)
        }
        selectedDate={selectedDate}
        drawer={drawer}
        setDrawer={setDrawer}
        invoiceList={drawer.data}
        inventoryList={drawer.data}
        refreshInvoiceData={refreshInvoiceData}
        configRefresh={configRefresh}
        triggerRefresh={triggerRefresh}
        refreshInvoices={refreshInvoicesFromInventory}
      />
    </div>
  );
};

export default QuickReport;
