import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  GoogleMap,
  LoadScript,
  useLoadScript,
  Polyline,
  LoadScriptNext,
} from "@react-google-maps/api";
import { ValidateOrgAddr } from "./helpers/validateOrgAddr";
import { useIsAuthenticated } from "../../../app/services/hooks/useIsAuthenticated";
import { useHistory } from "react-router-dom";
import RouteMarkerHome from "./markers/RouteMarkerHome";
import RouteMarker from "./markers/RouteMarker";
import { RouteContext } from "../NewRoute";
import polyline from "@mapbox/polyline";

const Map = ({ isLoaded }) => {
  const { orgData } = useIsAuthenticated();
  const { actions, state } = useContext(RouteContext);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  const history = useHistory();

  useEffect(() => {
    return () => {
      actions.setCoAddr({
        isValid: false,
        startAddrStr: "",
        startLat: 0,
        startLng: 0,
      });
    };
  }, []);

  const initialSetup = useCallback(async () => {
    if (!orgData || !isLoaded) return;
    const coords = await ValidateOrgAddr({
      orgData,
      setCoAddr: actions.setCoAddr,
      history,
    });
    setCenter({ lat: coords?.startLat, lng: coords?.startLng });
  }, [orgData, isLoaded, state.coAddr]);

  let ref = useRef();
  return (
    <LoadScriptNext
      onLoad={() => {
        if (state.coAddr.isValid) {
          return;
        } else {
          initialSetup();
        }
      }}
      googleMapsApiKey={"AIzaSyCk8zR40fhVRwZjl7TrXS1XyS04q40xapw"}
      libraries={["places", "geometry"]}
    >
      <GoogleMap
        id={"googlemap"}
        ref={ref} // bind the ref
        onCenterChanged={() => {
          const center = ref?.current?.props?.center ?? { lat: 0, lng: 0 };
          setCenter(center);
        }}
        mapContainerStyle={{
          width: "100%",
          height: "calc(103vh - 97px)",
        }}
        center={center}
        zoom={10}
      >
        <RouteMarkerHome />
        {state.initialStops &&
          state.initialStops.map(
            (s) =>
              s.addrValidated && (
                <RouteMarker key={`${s?.invoiceId}`} stop={s} />
              )
          )}
        {state.routes?.map((r) => {
          if (state.hiddenRoutes.includes(r?.id)) return;
          const color = r?.color ?? "#DDD";
          return r?.stops?.map((s) => {
            const polyOv = polyline.decode(s?.polylineStr, 5);
            const line = polyOv.map((i) => {
              return {
                lat: i?.[0],
                lng: i?.[1],
              };
            });
            return (
              <Polyline
                key={s?.invoiceId}
                options={{
                  geodesic: true,
                  strokeColor: color,
                  strokeOpacity: 0.75,
                  strokeWeight: 4,
                }}
                path={line}
              />
            );
          });
        })}
      </GoogleMap>
    </LoadScriptNext>
  );
};

export default Map;
