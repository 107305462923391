import React, { useEffect, useState } from "react";
import { useFirebase } from "react-redux-firebase";
import { saveAs } from "file-saver";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Modal,
  Form,
  Alert,
  Timeline,
  Spin,
  Space,
  Divider,
  message,
  Tabs,
} from "antd";

import {
  CheckCircleFilled,
} from "@ant-design/icons";
import TextBody from "../../app/system-components/typography/text/TextBody";
import { authSelector, refreshOrgState } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { inventoryQueries } from "../../app/services/firestore/queries/inventoryQueries";
import { useFirestore } from "react-redux-firebase";
import CustomerInfo from "./components/invoiceItemsAndDetails/CustomerInfo";
import RentalRange from "./components/RentalRange";
import ReceiveDetails from "./components/ReceiveDetails";
import ReturnDetails from "./components/ReturnDetails";
import { configureInvoice } from "../../app/utils/models/configure/configureInvoice";
import {
  RECEIVE_METHOD_DELIVERY,
  RECEIVE_METHOD_PICKUP,
  RETURN_METHOD_ONSITE,
  RETURN_METHOD_DROPOFF,
  ROW_TYPE_SUBTOTAL,
} from "../../app/utils/models/modelConstants/modelConstants";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { Spinner } from "../../app/ui/spinner";
import { ROUTE_INVOICES, ROUTE_RECEIVE_PAYMENT_ROOT } from "../../app/routes";
import notificationError from "../../app/system-components/toasters/notificationError";
import InvoiceContextMenuWrapper from "./components/contextWrapper/InvoiceContextMenuWrapper";
import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import { INVOICES_COLLECTION } from "../../app/utils/models/collections/collectionConstants";
import CreateNewItemModal from "./components/CreateNewItemModal";
import { setExistingInvoice } from "./helpers/setExistingInvoice";
import { validateInvoiceType } from "./helpers/validateInvoiceType";
import { capitalizeFirstLetter } from "../../app/utils/casing/capitalizeFirstLetter";
import moment from "moment";
import { UpdateDurationRowTotal } from "./components/invoiceTable/helpers/updateDurationRowTotal";
import { useUnload } from "../../app/services/hooks/useUnload";
import InvoicePaymentHistory from "./components/InvoicePaymentHistory";
import { useHistory } from "react-router-dom";
import { useModalMgr } from "../../app/services/hooks/useModalMgr";
import SendInvoiceModal from "./components/SendInvoiceModal";
import { useInvoiceHeader } from "../auth/useInvoiceHeader";
import { useInvoiceNumberListener } from "../../app/services/hooks/useInvoiceNumberListener";
import { venueQueries } from "../../app/services/firestore/queries/venueQueries";
import { useInvoiceEditor } from "./hooks/useInvoiceEditor";
import { useAvailableInvInvoice } from "../../app/services/hooks/useAvailableInvInvoice";
import { FormatToLocalCurrency } from "../../app/utils/currency/formatToLocalCurrency";
import SyncQuickBooksModal from "./components/SyncQuickBooksModal";
import InvoiceSendRecords from "./components/InvoiceSendRecords";
import { InvoiceEditHeader } from "./components/InvoiceEditHeader";
import { InvoiceActions } from "./components/invoiceItemsAndDetails/InvoiceActions";
import InternalNotesDrawer from "./components/InternalNotesDrawer";
import TabPane from "antd/lib/tabs/TabPane";

export const SendInvoiceContext = React.createContext();
export const InvoiceEditorContext = React.createContext();
export const AvailableInventoryContext = React.createContext();

const InvoiceDetail = ({ id, currentInvoice, cartItems }) => {

  // HOOKS
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const ModalMgr = useModalMgr();
  const InvoiceEditor = useInvoiceEditor();

  // USE STATE
  const [venues, setVenues] = useState([]);
  const [users, setUsers] = useState([]);
  const [isEdit, setIsEdit] = useState(!!id);
  const [customer, setCustomer] = useState(null);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [deliveryFeeTaxable, setDeliveryFeeTaxable] = useState(true);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [tabType, setTabType] = useState('invoice');  // invoice , payments , email
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [syncingInvoice, setSyncingInvoice] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    items: [],
    count: 0,
  });
  const [invoiceTotal, setInvoiceTotal] = useState({
    taxAmt: 0,
    taxRate: 0,
    subtotal: 0,
    total: 0,
    hasTotalDiscount: false,
    totalDiscountType: "none",
    totalDiscountPercent: 0,
    totalDiscountNumber: 0,
  });

  // NOTE: invoiceTaxRate.rate is only used to keep track of local component state in invoice summary.
  // invoiceTotal.taxRate is whats sent to firestore, along with invoiceTaxRate.taxDetails
  const [invoiceTaxRate, setInvoiceTaxRate] = useState({
    rate: 0,
    taxDetails: null,
  });
  const [isSalesInvoice, setIsSalesInvoice] = useState(false);
  const [rentalRange, setRentalRange] = useState(0);
  const [rentalDates, setRentalDates] = useState({
    receiveDate: null,
    returnDate: null,
  });
  const [customerAddressChecked, setCustomerAddressChecked] = useState({
    receive: true,
    return: true,
  });
  const [specifiedTimes, setSpecifiedTimes] = useState({
    receive: false,
    return: false,
  });
  const [receiveMethod, setReceiveMethod] = useState(RECEIVE_METHOD_DELIVERY);
  const [returnMethod, setReturnMethod] = useState(RETURN_METHOD_ONSITE);

  const [toggleNewInvModal, setToggleNewInvModal] = useState({
    visible: false,
    name: "",
    key: null,
  });
  const [form] = Form.useForm();
  const history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const { title, invoiceNumbers } = useInvoiceHeader(currentInvoice);
  const currentInvoiceNumber = useInvoiceNumberListener();
  const { newDocument, updateOrgDocument, updateDocument } = useFirestoreWrite();

  const InvoiceInventoryAvailability = useAvailableInvInvoice({
    startEndOps: {
      useTurnaround: true,
      rentalDateEnd: rentalDates.returnDate,
      rentalDateStart: rentalDates.receiveDate,
    },
    inventory,
    invoiceItems: selectedItems.items,
    initialInvoice: currentInvoice,
  });

  function syncClicked(item) {
    setShowSyncModal(true);
  }

  const handleSalesInvoiceToggle = (value) => {
    setIsSalesInvoice(value);
    handleReceiveMethod(RECEIVE_METHOD_PICKUP);
    handleReturnMethod(RETURN_METHOD_DROPOFF);

    form.setFieldsValue({
      receiveMethod: RECEIVE_METHOD_PICKUP,
      returnMethod: RETURN_METHOD_DROPOFF,
    });
  };

  useEffect(() => {
    if (!customer) return;
    InvoiceEditor.actions.setTaxableSettings(customer);
    // update for tax exemption
    const selectedTaxable = selectedItems.items.map((i) => {
      const exempt = customer?.isTaxExempt ?? false;

      return {
        ...i,
        selectedTaxable: exempt === true ? false : i?.selectedTaxable,
      };
    });
    setSelectedItems({ ...selectedItems, items: selectedTaxable });
  }, [customer]);

  // Fetch Org Users -> SalesReps
  useEffect(() => {
   if (orgData) {

    const loadUsers = async () => {
      const usersQuery = await firestore.collection('orgs').doc(orgData.id).collection('users').where('permissions', '!=', 'warehouse').get()
      const users = usersQuery.docs.map((snap) => snap.data()).map((data) => { return { name: `${data.firstName} ${data.lastName}`, id: data.id}})
      setUsers(users)
    }

    loadUsers();
   }

  }, [orgData]);

  useEffect(() => {
    if (orgData) {
      const fetchTags = async () => {
        const snapshot = await firestore.collection(`orgs/${orgData.id}/tags`).get();
        const fetchedTags = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTags(fetchedTags);
  
        if (currentInvoice) {
          const tagsQueryHook = currentInvoice.tagsQueryHook;
          setSelectedTags(tagsQueryHook);
        }
      };
  
      fetchTags();
    }
  }, [firestore, orgData, currentInvoice]);
  
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  
  useEffect(() => {
    if (inventory.length === 0) return;
    if (loading) return;
    if (typeof currentInvoice !== "undefined" && currentInvoice.length !== 0) {
      form.setFieldsValue({
        receiveVenueQueryHook: currentInvoice.receiveVenueQueryHook,
        returnVenueQueryHook: currentInvoice.returnVenueQueryHook,
      });
      setExistingInvoice({
        form,
        setIsSalesInvoice,
        setSelectedItems,
        currentInvoice,
        setInvoiceTotal,
        setReceiveMethod,
        setReturnMethod,
        setSpecifiedTimes,
        setRentalRange,
        setCustomer,
        setCustomerAddressChecked,
        setDeliveryFee,
        setDeliveryFeeTaxable,
        setVenues,
        setRentalDates,
      });
    }
  }, [currentInvoice, loading, venues]);

  //cart items?
  useEffect(() => {
    if (inventory.length === 0) return;
    if (loading) return;
    if (
      typeof cartItems !== "undefined" &&
      cartItems.selectedItems.items.length !== 0
    ) {
      const start = moment(cartItems.rentalDates.rentalDateStart);
      const end = moment(cartItems.rentalDates.rentalDateEnd);

      const dueDate = moment(cartItems.rentalDates.rentalDateStart);

      let range = end.diff(start, "days");
      const rr = range === 0 ? 1 : range;
      setRentalRange(rr);

      const updated = UpdateDurationRowTotal(cartItems.selectedItems, rr);
      setSelectedItems({
        items: updated,
        count: updated.length,
      });
      setRentalDates({
        receiveDate: start.startOf("day").format("MMM Do YYYY"),
        returnDate: end.endOf("day").format("MMM Do YYYY"),
      });
      form.setFieldsValue({
        rentalDates: [start, end],
        invoicePaymentDueDate: dueDate.endOf("day"),
        eventDate: dueDate.startOf("day"),
      });
    }
  }, [cartItems, loading]);

  useEffect(() => {
    if (!orgData || !fsOrgPrefix) return;
    if (loading) return;
    setLoading(true);
    setDeliveryFeeTaxable(orgData.deliveryFeeTaxable)

    loadVenues();
    inventoryQueries({ firestore }, fsOrgPrefix)
      .fetchInventory()
      .then((data) => {
        setInventory(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [fsOrgPrefix]);

  const loadVenues = () => {
    fsOrgPrefix &&
      venueQueries({ firestore }, fsOrgPrefix)
        .fetchVenues()
        .then((res) => {
          setVenues([...res]);
          // setLoading(false);
        });
  };

  const customerInfoChanged = (value, formItemName) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    if (customerAddressChecked.receive) {
      form.setFieldsValue({
        [`${RECEIVE_METHOD_DELIVERY}${capitalizeFirstLetter(formItemName)}`]:
          value,
      });
    }
    if (customerAddressChecked.return) {
      form.setFieldsValue({
        [`${RETURN_METHOD_ONSITE}${capitalizeFirstLetter(formItemName)}`]:
          value,
      });
    }
  };

  const handleReceiveMethod = (e) => {
    setReceiveMethod(e);
    if (e === "pickup" && orgData.companyOpenTime && orgData.companyCloseTime) {
      setSpecifiedTimes({
        receive: true,
        return: true,
      });
      form.setFieldsValue({
        hasSpecifiedReceiveTime: true,
        specifiedReceiveTime: moment(orgData.companyOpenTime.toDate()),
        specifiedReceiveTimeEnd: moment(orgData.companyCloseTime.toDate()),
      });
    }
    if (e === "delivery") {
      form.setFieldsValue({
        specifiedReceiveTime: null,
        specifiedReceiveTimeEnd: null,
      });
    }
  };

  const handleReturnMethod = (e) => {
    console.log(e);

    setReturnMethod(e);
    if (
      e === "dropOff" &&
      orgData.companyOpenTime &&
      orgData.companyCloseTime
    ) {
      setSpecifiedTimes({
        receive: true,
        return: true,
      });
      form.setFieldsValue({
        hasSpecifiedReturnTime: true,
        specifiedReturnTime: moment(orgData.companyOpenTime.toDate()),
        specifiedReturnTimeEnd: moment(orgData.companyCloseTime.toDate()),
      });
    }
    if (e === "onSite") {
      form.setFieldsValue({
        specifiedReturnTime: null,
        specifiedReturnTimeEnd: null,
      });
    }
  };

  function onManualSyncComplete(success) {
    setShowSyncModal(false);
    if (success) {
      notificationConfirm("Success!", "Sync complete");
    } else {
      notificationError(
        "Error",
        "Unable to sync invoice. Please contact Adelie support."
      );
    }
  }

  const handleComplete = async ({ type, redirect }) => {
    const items = [...selectedItems.items].filter(
      (i) => i.id || i.rowType === ROW_TYPE_SUBTOTAL
    );
    const cleanItems = {
      items: items,
      count: items.length,
    };
    setSelectedItems({ items: items, count: items.length });
    return validateInvoiceType({
      type,
      form: form,
      customer: customer,
      rentalRange: rentalRange,
      selectedItems: cleanItems,
      inventory: inventory,
      isEdit: isEdit,
      rentalDates: rentalDates,
      previousSavedInvoice: currentInvoice,
      setCustomerAddressChecked,
      customerAddressChecked,
      showOverbookedError:
        InvoiceInventoryAvailability.state.showOverbookedError,
    }).then(async ({ type, isEdit, hasError }) => {
      if (hasError) {
        message.warning("Missing required fields");
        return { hasError };
      }
      if (isEdit) {
        return await saveExistingInvoice(type, cleanItems, redirect);
      } else {
        return await createNewInvoice(type, cleanItems, redirect);
      }
    });
  };

  const saveExistingInvoice = (type, cleanItems, redirect) => {
    return new Promise((res) => {
      if (redirect) {
        setLoading(true);
      }
      const data = {
        customer: customer,
        customerAddressChecked: customerAddressChecked,
        rentalRange: rentalRange,
        selectedItems: cleanItems,
        invoiceTotal: invoiceTotal,
        taxMetaData: invoiceTaxRate.taxDetails
          ? invoiceTaxRate.taxDetails
          : null,
        formDetails: form.getFieldsValue(),
        type: type,
        deliveryFee: deliveryFee ? parseFloat(deliveryFee) : 0,
        deliveryFeeTaxable,
        selectedTags
      };
      console.log('data payload invoice', data)
      const payload = configureInvoice(orgData?.orgTimezone).newInvoice(
        data,
        userData.id,
        orgData,
        currentInvoice,
      );
      const { invoiceNumber, ...rest } = payload;
      updateDocument({
        data: {
          collection: INVOICES_COLLECTION,
          payload: {
            invoiceNumber: currentInvoice.invoiceNumber,
            ...rest,
          },
          id: id,
        },
      }).then(() => {
        if (redirect) {
          setLoading(false);
        }
        notificationConfirm(
          `${capitalizeFirstLetter(type)} updated: #${currentInvoice.invoiceNumber
          }`,
          ""
        );
        if (!redirect) return res({ invoiceId: currentInvoice.id });
        if (redirect === "RECEIVE_PAYMENT") {
          let state = { ...history.location.state };
          delete state.invoiceId;
          history.push({
            pathname: `${ROUTE_RECEIVE_PAYMENT_ROOT}${currentInvoice.customerQueryHook}`,
            state: { invoiceId: currentInvoice.id },
          });
          res();
        } else {
          history.push(redirect);
          res();
        }
      });
    });
  };

  const createNewInvoice = async (type, cleanItems, redirect) => {
    return new Promise((res) => {
      if (redirect) {
        setLoading(true);
      }
      const data = {
        customer: customer,
        customerAddressChecked: customerAddressChecked,
        rentalRange: rentalRange,
        selectedItems: cleanItems,
        invoiceTotal: invoiceTotal,
        taxMetaData: invoiceTaxRate.taxDetails
          ? invoiceTaxRate.taxDetails
          : null,
        formDetails: form.getFieldsValue(),
        type: type,
        deliveryFee: deliveryFee ? parseFloat(deliveryFee) : 0,
        deliveryFeeTaxable,
        currentInvoiceNumber,
      };
      const payload = configureInvoice(orgData?.orgTimezone).newInvoice(
        data,
        userData.id,
        orgData
      );

      newDocument({
        data: {
          collection: INVOICES_COLLECTION,
          payload: payload,
        },
      }).then((newInvoiceId) => {
        const nextInvoiceNumber = payload.invoiceNumber;
        updateOrgDocument({
          data: {
            payload: {
              currentInvoiceCount: nextInvoiceNumber,
            },
            id: orgData && orgData.id,
          },
        }).then(() => {
          dispatch(refreshOrgState({ firestore }, orgData)).then(() => {
            if (redirect) {
              setLoading(false);
            }
            notificationConfirm(
              `${capitalizeFirstLetter(type)} created: #${nextInvoiceNumber}`,
              ""
            );
            if (!redirect) return res({ invoiceId: newInvoiceId });
            if (redirect === "RECEIVE_PAYMENT") {
              history.push({
                pathname: `${ROUTE_RECEIVE_PAYMENT_ROOT}${customer.id}`,
                state: { invoiceId: newInvoiceId },
              });
              res();
            } else {
              history.push(redirect);
              res();
            }
          });
        });
      });
    });
  };

  const handleBackClicked = () => {
    Modal.confirm({
      title: "Exit without saving?",
      content: "",
      onOk() {
        history.push(ROUTE_INVOICES);
      },
      onCancel() { },
    });
  };

  const saveFile = async () => {
    // i18nString('key')

    if (currentInvoice) {
      setDownloadingPDF(true);
      const generateCall = firebase.functions().httpsCallable("createInvoice");
      generateCall({
        orgId: orgData?.id,
        invoiceId: currentInvoice.id,
        sendType: "invoice",
        settingsId: "invoiceEmailSettings",
      })
        .then((response) => {
          console.log(response);

          if (response.data.downloadUrl) {
            saveAs(response.data.downloadUrl, `${currentInvoice.id}.pdf`);
            setDownloadingPDF(false);
            notificationConfirm("Invoice Downloaded");
          }
        })
        .catch((error) => {
          console.log(error);
          setDownloadingPDF(false);
        });
    } else {
      notificationError("Please Save Invoice First");
    }
  };

  const [chatDrawer, setChatDrawer] = useState({
    visible: false,
    invoice: null,
  });

  const handleChatClick = (i) => {
    setChatDrawer({
      visible: true,
      invoice: i,
    });
  };

  const chatButton = (
    <Button onClick={() => {
      if (currentInvoice) {
        handleChatClick(currentInvoice)
      }
    }
    } type="primary">
      Internal Notes
    </Button>
  );

  return (
    <div>
      <InvoiceEditorContext.Provider value={InvoiceEditor}>
        <SendInvoiceContext.Provider value={ModalMgr}>
          <AvailableInventoryContext.Provider
            value={InvoiceInventoryAvailability}
          >
            <Spin spinning={loading} indicator={Spinner}>
              <InvoiceEditHeader
                title={title}
                tags={tags}
                selectedTags={selectedTags}
                invoiceNumbers={invoiceNumbers}
                handleBackClicked={handleBackClicked}
                id={id}
                customer={customer}
                currentInvoice={currentInvoice}
                handleComplete={handleComplete}
              />

              <div className={"main-body"} style={{ marginTop: "50px" }}>
                <div style={{ width: "100%" }}>
                  <Tabs
                    defaultActiveKey="invoice"
                    activeKey={tabType}
                    onChange={setTabType}
                    tabBarExtraContent={chatButton}
                  >
                    <TabPane tab="Order" key="invoice" />
                    <TabPane tab="Payment History" key="payments" />
                    <TabPane tab="Email Tracking" key="email" />
                  </Tabs>
                </div>

                {tabType === "invoice" && (
                  <div>
                    <div>
                      <Form
                        form={form}
                        layout="vertical"
                        className="invoice-container"
                      >
                        <Row justify="space-between" align="top" wrap={false}>
                          <Row justify="space-between" align="top" wrap={false}>
                            <Space align="top">
                              <CustomerInfo
                                form={form}
                                customer={customer}
                                // onCustomerInfoChanged={(value, formItemName) =>
                                //   customerInfoChanged(value, formItemName)
                                // }
                                setCustomer={setCustomer}
                                currentInvoice={currentInvoice}
                                currentId={id}
                              />
                            </Space>
                          </Row>
                          {users && (
                            <Col style={{ textAlign: "right" }}>
                              <RentalRange
                                users={users}
                                form={form}
                                onSalesInvoiceToggle={handleSalesInvoiceToggle}
                                currentInvoice={currentInvoice}
                                selectedItems={selectedItems}
                                rentalRange={rentalRange}
                                setRentalRange={setRentalRange}
                                rentalDates={rentalDates}
                                setRentalDates={setRentalDates}
                                invoiceTotal={invoiceTotal}
                                orgData={orgData}
                              />
                            </Col>
                          )}
                        </Row>
                        <br />
                        <InvoiceContextMenuWrapper
                          isSalesInvoice={isSalesInvoice}
                          currentInvoice={currentInvoice}
                          availableItems={inventory}
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                          invoiceTotal={invoiceTotal}
                          setInvoiceTotal={setInvoiceTotal}
                          rentalRange={rentalRange}
                          setToggleNewInvModal={setToggleNewInvModal}
                          customer={customer && customer}
                          invoiceTaxRate={invoiceTaxRate}
                          setInvoiceTaxRate={setInvoiceTaxRate}
                          deliveryFee={deliveryFee}
                          setDeliveryFee={setDeliveryFee}
                          deliveryFeeTaxable={deliveryFeeTaxable}
                          setDeliveryFeeTaxable={setDeliveryFeeTaxable}
                        />
                        <br />
                        {currentInvoice && (
                          <div>
                            <InvoiceActions
                              downloadingPDF={downloadingPDF}
                              saveFile={saveFile}
                              currentInvoice={currentInvoice}
                              orgData={orgData}
                              syncingInvoice={syncingInvoice}
                              syncClicked={syncClicked}
                            />

                            <div
                              style={{
                                textAlign: "right",
                                paddingRight: "58px",
                              }}
                            >
                              <TextBody
                                text={`Fulfilled: ${FormatToLocalCurrency(
                                  currentInvoice?.balanceFulfilled,
                                  orgData.countryCode,
                                  orgData.languageCode,
                                  orgData.currencyCode
                                )}`}
                              />
                              <br />
                              <Space>
                                <TextBody
                                  style={{ fontWeight: "bold" }}
                                  text={`Balance Remaining:  ${FormatToLocalCurrency(
                                    currentInvoice?.balanceRemaining,
                                    orgData.countryCode,
                                    orgData.languageCode,
                                    orgData.currencyCode
                                  )}`}
                                />
                                {currentInvoice?.balanceRemaining <= 0 && (
                                  <CheckCircleFilled
                                    style={{ color: "green" }}
                                  />
                                )}
                              </Space>

                              {currentInvoice?.estimateSignatures &&
                                currentInvoice?.estimateSignatures.length >
                                  0 && (
                                  <div>
                                    <Space>
                                      <TextBody
                                        style={{ fontWeight: "bold" }}
                                        text={`Signed For Estimate`}
                                      />

                                      <CheckCircleFilled
                                        style={{ color: "green" }}
                                      />
                                    </Space>
                                  </div>
                                )}

                              {currentInvoice?.paymentSignatures &&
                                currentInvoice?.paymentSignatures.length >
                                  0 && (
                                  <div>
                                    <Space>
                                      <TextBody
                                        style={{ fontWeight: "bold" }}
                                        text={`Signed For Invoice`}
                                      />

                                      <CheckCircleFilled
                                        style={{ color: "green" }}
                                      />
                                    </Space>
                                  </div>
                                )}
                            </div>
                            <Divider />
                          </div>
                        )}

                        <Card
                          style={{
                            display: isSalesInvoice ? "none" : "block",
                          }}
                          bordered={false}
                          size="small"
                          title={
                            <TextBody
                              style={{ fontWeight: "bold" }}
                              text={"Receive & Return Details"}
                            />
                          }
                        >
                          {rentalRange !== 0 && customer ? (
                            <Timeline
                              style={{ marginTop: "20px" }}
                              className="receive-return-timeline"
                            >
                              <ReceiveDetails
                                isSalesInvoice={isSalesInvoice}
                                customer={customer}
                                form={form}
                                venues={venues}
                                customerAddressChecked={customerAddressChecked}
                                handleReceiveMethod={handleReceiveMethod}
                                receiveMethod={receiveMethod}
                                setCustomerAddressChecked={
                                  setCustomerAddressChecked
                                }
                                rentalDates={rentalDates}
                                setSpecifiedTimes={setSpecifiedTimes}
                                specifiedTimes={specifiedTimes}
                                currentInvoice={currentInvoice}
                                id={id}
                              />
                              <ReturnDetails
                                isSalesInvoice={isSalesInvoice}
                                customer={customer}
                                venues={venues}
                                form={form}
                                customerAddressChecked={customerAddressChecked}
                                handleReturnMethod={handleReturnMethod}
                                returnMethod={returnMethod}
                                setCustomerAddressChecked={
                                  setCustomerAddressChecked
                                }
                                rentalDates={rentalDates}
                                setSpecifiedTimes={setSpecifiedTimes}
                                specifiedTimes={specifiedTimes}
                                currentInvoice={currentInvoice}
                                id={id}
                              />
                            </Timeline>
                          ) : (
                            <Alert
                              message="Select a rental range and customer before configuring."
                              type="warning"
                              showIcon
                            />
                          )}
                        </Card>

                        <br />
                        <Card
                          bordered={false}
                          size="small"
                          title={
                            <TextBody
                              style={{ fontWeight: "bold" }}
                              text={"Notes (Visible to customer on invoice)"}
                            />
                          }
                        >
                          <br />
                          <Form.Item name="notes" initialValue="">
                            <Input.TextArea
                              placeholder="Additional notes"
                              autoSize={{ minRows: 2, maxRows: 10 }}
                            />
                          </Form.Item>
                        </Card>
                      </Form>
                    </div>
                  </div>
                )}

                {tabType === "payments" && currentInvoice && (
                  <InvoicePaymentHistory invoiceId={currentInvoice?.id} />
                )}

                {tabType === "email" && currentInvoice && (
                  <InvoiceSendRecords invoiceId={currentInvoice?.id} />
                )}
              </div>
            </Spin>
            {/* <Modal
                  title="Create your first invoice"
                  open={
                    orgData &&
                    (orgData.currentInvoiceCount === null ||
                      typeof orgData.currentInvoiceCount === "undefined")
                  }
                  confirmLoading={savingInitialCount}
                  onOk={() => {
                    setSavingInitialCount(true);
                    orgWrites({ firestore }, orgData)
                      .updateOrgDoc(orgData.id, {
                        currentInvoiceCount: initialCount,
                      })
                      .then(() => {
                        dispatch(refreshOrgState({ firestore }, orgData));
                      })
                      .catch((err) => {
                        console.log(err);
                        notificationError(
                          "Could not set initial invoice count",
                          "Please contact Adelie support"
                        );
                      });
                  }}
                  onCancel={() => {
                    history.push(ROUTE_INVOICES);
                  }}
                >
                  <TextBody text={"Select a starting invoice number"} />
                  <br />
                  <br />
                  <InputNumber
                    onChange={(n) => {
                      setInitialCount(n);
                    }}
                    defaultValue={1000}
                    placeholder="1000"
                    min={0}
                    max={1000000}
                  />
                </Modal> */}
            <CreateNewItemModal
              toggleNewInvModal={toggleNewInvModal}
              setToggleNewInvModal={setToggleNewInvModal}
              loading={loading}
              onComplete={(invId) => {
                // refresh inventory
                setLoading(true);
                inventoryQueries({ firestore }, fsOrgPrefix)
                  .fetchInventory()
                  .then((data) => {
                    setInventory([...data]);
                    setLoading(false);
                    const selected = data.find((i) => i.id === invId);
                    const {
                      key,
                      editable,
                      selectedDuration,
                      selectedQty,
                      ...rest
                    } = selectedItems.items[toggleNewInvModal.key];
                    const item = {
                      ...selected,
                      ...rest,
                      name: selected.name,
                      editable: editable,
                      key: key,
                      selectedItemId: invId,
                      rates: selected.rates ? selected.rates : [],
                      selectedRate:
                        selected.rates && selected.rates[0]
                          ? selected.rates[0].rateName
                          : "Select rate",
                      selectedRateMeta:
                        selected.rates && selected.rates[0]
                          ? selected.rates[0]
                          : null,
                      selectedTaxable: selected.isTaxable,
                      selectedDuration: selectedDuration,
                      selectedQty: selectedQty,
                      selectedDescription: selected.description,
                    };
                    const newData = [...selectedItems.items];
                    newData.splice(toggleNewInvModal.key, 1, { ...item });
                    setSelectedItems({
                      items: newData,
                      count: selectedItems.items.length,
                    });
                    setToggleNewInvModal({
                      visible: false,
                      name: "",
                      key: null,
                    });
                  });
              }}
            />
            {currentInvoice && (
              <InternalNotesDrawer
                showTags={true}
                drawerView
                visible={chatDrawer.visible}
                handleCloseDrawer={setChatDrawer}
                setSelectedTags={setSelectedTags}
                invoice={currentInvoice}
                tags={tags}
                selectedTags={selectedTags}
                onComplete={() => {
                  setChatDrawer({
                    visible: false,
                    item: null,
                  });
                }}
              />
            )}
            {currentInvoice && (
              <div>
                <SyncQuickBooksModal
                  visible={showSyncModal}
                  onCancel={() => setShowSyncModal(false)}
                  onComplete={onManualSyncComplete}
                  invoice={currentInvoice}
                  orgData={orgData}
                />
              </div>
            )}
            <SendInvoiceModal venues={venues} />
          </AvailableInventoryContext.Provider>
        </SendInvoiceContext.Provider>
      </InvoiceEditorContext.Provider>
    </div>
  );
};

export default InvoiceDetail;
