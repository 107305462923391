import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Checkbox,
  Select,
  Button,
  Modal,
  TimePicker,
  Tooltip,
  InputNumber,
  Upload,
  message,
} from "antd";

import { configureGeneralSettingsUpdate } from "../../../app/utils/models/configure/configureOrgUpdate";
import { authSelector } from "../../auth/authSlice";
import { useSelector } from "react-redux";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { orgWrites } from "../../../app/services/firestore/writes/orgWrites";
import { useFirestore } from "react-redux-firebase";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import { orgQueries } from "../../../app/services/firestore/queries/orgQueries";

import { TimeMachine } from "../../../app/utils/time/timeMachine";

import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";

import TaxModal from "./TaxModal";
import { useFirestoreFetch } from "../../../app/services/hooks/fetch/useFirestoreFetch";
import { taxRateQueries } from "../../../app/services/firestore/queries/taxRateQueries";
import TaxEditModal from "./TaxEditModal";

import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { useFirebase } from "react-redux-firebase";
import { useFirestoreWrite } from "../../../app/services/hooks/fetch/useFirestoreWrite";
import { TAX_RATES_COLLECTION } from "../../../app/utils/models/collections/collectionConstants";

const GeneralInfo = () => {
  const firebase = useFirebase();

  const { orgData, fsOrgPrefix, userData } = useSelector(authSelector);
  const { data, fetching, refresh } = useFirestoreFetch(
    taxRateQueries().fetchTaxRates
  );

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [pageValues, setPageValues] = useState({
    companyName: "",
    companyAddress: "",
    showCompanyAddress: true,
    companyPhone: "",
    companyWebsite: "",
    webTaxName: "",
    adminEmail: "",
    useAdminEmailReply: false,
    webTransactionType: "",
    qbInvoiceDate: "",
    companyOpenTime: null,
    companyCloseTime: null,
    automaticallyConvertEstimate: true,
    showInvoiceItemImg: true,
    showItemName: true,
    showDuration: true,
    showRate: true,
    showDescription: true,
    showTotal: true,
    deliveryFeeTaxable: true,
    defaultDepositPercent: 100,
    qbBidirectionalSync: false,
    eventDateEnabled: false,
    salesInvoiceEnabled: false,
  });

  useEffect(() => {
    // load org data & set page state
    orgData && loadSettings();
  }, [orgData]);

  const loadSettings = async () => {
    setLoading(true);
    orgData &&
      orgQueries({ firestore }, fsOrgPrefix)
        .fetchOrgDoc(orgData.id)
        .then((data) => {
          setLoading(false);
          setPageValues({
            companyName: data.companyName,
            companyAddress: data.companyAddress,
            showCompanyAddress: data.showCompanyAddress,
            companyPhone: data.companyPhone,
            companyWebsite: data.companyWebsite,
            webTaxName: data.webTaxName,
            adminEmail: data.adminEmail,
            useAdminEmailReply: data.useAdminEmailReply,
            webTransactionType: data.webTransactionType,
            qbInvoiceDate: data.qbInvoiceDate,
            companyOpenTime: TimeMachine(data.companyOpenTime).moment,
            companyCloseTime: TimeMachine(data.companyCloseTime).moment,
            taxRate: data.taxRate,
            automaticallyConvertEstimate: data.automaticallyConvertEstimate,
            showInvoiceItemImg: data.showInvoiceItemImg,
            showItemName: data.showItemName,
            showDuration: data.showDuration,
            showTotal: data.showTotal,
            deliveryFeeTaxable: data.deliveryFeeTaxable,
            defaultDepositPercent: data.defaultDepositPercent,
            showRate: data.showRate,
            showDescription: data.showDescription,
            qbBidirectionalSync: data.qbBidirectionalSync,
            eventDateEnabled: data.eventDateEnabled,
            salesInvoiceEnabled: data.salesInvoiceEnabled,
          });
          form.setFieldsValue({
            companyName: data.companyName,
            companyAddress: data.companyAddress,
            showCompanyAddress: data.showCompanyAddress,
            companyPhone: data.companyPhone,
            companyWebsite: data.companyWebsite,
            webTaxName: data.webTaxName,
            adminEmail: data.adminEmail,
            useAdminEmailReply: data.useAdminEmailReply,
            webTransactionType: data.webTransactionType,
            qbInvoiceDate: data.qbInvoiceDate,
            companyOpenTime: TimeMachine(data.companyOpenTime).moment,
            companyCloseTime: TimeMachine(data.companyCloseTime).moment,
            taxRate: data.taxRate ? data.taxRate.id : null,
            automaticallyConvertEstimate: data.automaticallyConvertEstimate,
            showInvoiceItemImg: data.showInvoiceItemImg,
            showItemName: data.showItemName,
            showDuration: data.showDuration,
            showRate: data.showRate,
            showDescription: data.showDescription,
            showTotal: data.showTotal,
            deliveryFeeTaxable: data.deliveryFeeTaxable,
            defaultDepositPercent: data.defaultDepositPercent,
            qbBidirectionalSync: data.qbBidirectionalSync,
            eventDateEnabled: data.eventDateEnabled,
            salesInvoiceEnabled: data.salesInvoiceEnabled,
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
  };

  const onUpdate = (e) => {
    setPageValues({
      ...pageValues,
      [e.target.id]: e.target.value,
    });
  };

  const updateTaxRate = (e) => {
    const { taxRate, ...rest } = pageValues;
    setPageValues({
      ...rest,
      taxRate: { ...e },
    });
  };

  const confirmSave = () => {
    Modal.confirm({
      icon: <></>,
      title: "Save Settings?",
      onOk() {
        return new Promise(async (resolve, reject) => {
          onSaveClicked().then(() => {
            resolve();
          });
        }).catch((err) => {
          console.log(err);
        });
      },
      onCancel() {},
    });
  };

  const onSaveClicked = async () => {
    if (!userData.id || !fsOrgPrefix || !orgData.id)
      return notificationError(
        "Invalid permissions",
        "Unable to update at this time."
      );

    const payload = configureGeneralSettingsUpdate(
      pageValues,
      userData.id,
      orgData?.orgTimezone
    );

    // console.log('Payload', payload)
    orgWrites({ firestore }, fsOrgPrefix)
      .updateOrgDoc(orgData.id, payload)
      .then(() => {
        notificationConfirm("Settings saved", "");
        loadSettings();
      })
      .catch((err) => {
        console.log(err);
        notificationError("Something went wrong", "Please try again later");
      });
  };

  const updateStoreHours = ({ fieldName, moment }) => {
    setPageValues({
      ...pageValues,
      [fieldName]: moment,
    });
  };

  const { updateDocument, writing } = useFirestoreWrite();
  const [taxModal, setTaxModal] = useState({
    visible: false,
  });
  const [taxEditModal, setTaxEditModal] = useState({
    visible: false,
    rate: null,
  });

  const [taxList, setTaxList] = useState([]);
  const [defaultDepositPercent, setDefaultDepositPercent] = useState(100);

  const firestore = useFirestore();
  const handleTaxChange = (t) => {
    if (t === "create") {
      // launch modal
      setTaxModal({
        visible: true,
      });
    } else {
      findUpdateTaxPageValues(t);
    }
  };
  const validateTime = (rule, val) => {
    return new Promise((res, rej) => {
      if (val === null || typeof val === "undefined") {
        rej();
      }
      res();
    });
  };

  const autoConvertEstimateChanged = async (e) => {
    const { automaticallyConvertEstimate } = await form.validateFields();
    pageValues.automaticallyConvertEstimate = automaticallyConvertEstimate;
    onUpdate({
      target: {
        id: "automaticallyConvertEstimate",
        value: automaticallyConvertEstimate,
      },
    });
  };

  const showInvoiceItemImgChanged = async (e) => {
    const { showInvoiceItemImg } = await form.validateFields();
    pageValues.showInvoiceItemImg = showInvoiceItemImg;
    onUpdate({
      target: {
        id: "showInvoiceItemImg",
        value: showInvoiceItemImg,
      },
    });
  };

  const showItemNameChanged = async (e) => {
    const { showItemName } = await form.validateFields();
    pageValues.showItemName = showItemName;
    onUpdate({
      target: {
        id: "showItemName",
        value: showItemName,
      },
    });
  };

  const showDurationChanged = async (e) => {
    const { showDuration } = await form.validateFields();
    pageValues.showDuration = showDuration;
    onUpdate({
      target: {
        id: "showDuration",
        value: showDuration,
      },
    });
  };

  const showDescriptionChanged = async (e) => {
    const { showDescription } = await form.validateFields();
    pageValues.showDescription = showDescription;
    onUpdate({
      target: {
        id: "showDescription",
        value: showDescription,
      },
    });
  };

  const showRateChanged = async (e) => {
    const { showRate } = await form.validateFields();
    pageValues.showRate = showRate;
    onUpdate({
      target: {
        id: "showRate",
        value: showRate,
      },
    });
  };

  const showTotalChanged = async (e) => {
    const { showTotal } = await form.validateFields();
    pageValues.showTotal = showTotal;
    onUpdate({
      target: {
        id: "showTotal",
        value: showTotal,
      },
    });
  };

  const qbBidirectionalSyncChanged = async (e) => {
    const { qbBidirectionalSync } = await form.validateFields();
    pageValues.qbBidirectionalSync = qbBidirectionalSync;
    onUpdate({
      target: {
        id: "qbBidirectionalSync",
        value: qbBidirectionalSync,
      },
    });
  };

  const eventDateEnabledChanged = async (e) => {
    const res = e.target.checked;
    pageValues.eventDateEnabled = res;
    onUpdate({
      target: {
        id: "eventDateEnabled",
        value: res,
      },
    });
  };
  const deliveryFeeTaxableChanged = async (e) => {
    const res = e.target.checked;
    pageValues.deliveryFeeTaxable = res;
    onUpdate({
      target: {
        id: "deliveryFeeTaxable",
        value: res,
      },
    });
  };
  const salesInvoiceEnabledChanged = async (e) => {
    const res = e.target.checked;
    pageValues.salesInvoiceEnabled = res;
    onUpdate({
      target: {
        id: "salesInvoiceEnabled",
        value: res,
      },
    });
  };

  const showCompanyAddressChanged = async (e) => {
    const res = e.target.checked;
    console.log(res);
    pageValues.showCompanyAddress = res;
    onUpdate({
      target: {
        id: "showCompanyAddress",
        value: res,
      },
    });
  };

  const setUseAdminEmailReplyChanged = async (e) => {
    const res = e.target.checked;
    pageValues.useAdminEmailReply = res;
    onUpdate({
      target: {
        id: "useAdminEmailReply",
        value: res,
      },
    });
  };

  const handleTransactionTypeChange = async (e) => {
    const { webTransactionType } = await form.validateFields();
    onUpdate({
      target: {
        id: "webTransactionType",
        value: webTransactionType,
      },
    });
  };

  const handleQBInvoiceDateChange = async (e) => {
    const { qbInvoiceDate } = await form.validateFields();
    onUpdate({
      target: {
        id: "qbInvoiceDate",
        value: qbInvoiceDate,
      },
    });
  };

  useEffect(() => {
    setTaxList([...data]);
  }, [fetching]);

  useEffect(() => {
    if (orgData) {
      setDefaultDepositPercent(orgData.defaultDepositPercent);
      setImageUrl(orgData.logoUrl);
    }
  }, [orgData]);

  const findUpdateTaxPageValues = (t) => {
    const newData = data && [...data];
    const selected = newData.find((tax) => tax.id === t);
    if (selected) {
      updateTaxRate(selected);
    }
  };
  const onTaxCreated = (newPayload) => {
    refresh().then(() => {
      updateTaxRate(newPayload);
      setCreatedTaxRate(newPayload.id);
    });
  };
  const setCreatedTaxRate = (id) => {
    form.setFieldsValue({ taxRate: id });
    setTaxModal({ visible: false });
  };
  const onModalCancel = () => {
    form.setFieldsValue({ taxRate: null });
  };
  const handleEditModal = () => {
    const selected = form.getFieldValue("taxRate");
    const rate = selected && data.find((t) => t.id === selected);
    if (rate) {
      setTaxEditModal({
        visible: true,
        rate: rate,
      });
    } else {
      notificationError("No rate selected", "Must select a rate first");
    }
  };
  const handleDepositPercentageChange = (value) => {
    setDefaultDepositPercent(value);
    onUpdate({
      target: {
        id: "defaultDepositPercent",
        value: defaultDepositPercent,
      },
    });
  };

  const onTaxEdited = (newPayload) => {
    updateTaxRate(newPayload);
    setTaxEditModal({ visible: false, rate: null });
  };
  useEffect(() => {
    pageValues.taxRate &&
      form.setFieldsValue({ taxRate: pageValues.taxRate.id });
  }, [pageValues]);

  const renderOption = (t) => {
    return `${t.name} (${(t.rate * 100).toFixed(2)}%)${
      !t.isActive ? " - Deactivated" : ""
    }`;
  };

  const handleDeactivate = () => {
    const selected = form.getFieldValue("taxRate");
    const rate = selected && data.find((t) => t.id === selected);
    if (rate) {
      Modal.confirm({
        title: "Deactivate Tax?",
        icon: <ExclamationCircleOutlined />,
        content: "This rate will not be available for future invoices.",
        onOk() {
          return new Promise((resolve, reject) => {
            updateDocument({
              data: {
                payload: {
                  isActive: false,
                },
                collection: TAX_RATES_COLLECTION,
                id: rate.id,
              },
            }).then(() => {
              refresh().then((res) => {
                setTaxList([...res]);
                resolve();
              });
            });
          }).catch((err) => console.log(err));
        },
        onCancel() {},
      });
    } else {
      notificationError("No rate selected", "Must select a rate first");
    }
  };
  const handleReactivate = () => {
    /**
     * Reactivate Tax Rate
     */
    const selected = form.getFieldValue("taxRate");
    const rate = selected && [...taxList].find((t) => t.id === selected);
    if (rate) {
      Modal.confirm({
        title: "Reactivate Tax?",
        icon: <ExclamationCircleOutlined />,
        content: "This rate will now be available for future invoices.",
        onOk() {
          return new Promise((resolve, reject) => {
            updateDocument({
              data: {
                payload: {
                  isActive: true,
                },
                collection: TAX_RATES_COLLECTION,
                id: rate.id,
              },
            }).then(() => {
              refresh().then((res) => {
                console.log(res);
                setTaxList([...res]);
                resolve();
              });
            });
          }).catch((err) => console.log(err));
        },
        onCancel() {},
      });
    } else {
      notificationError("No rate selected", "Must select a rate first");
    }
  };
  const renderActionBtns = () => {
    const selected = form.getFieldValue("taxRate");
    const rate = selected && [...taxList].find((t) => t.id === selected);
    if (rate) {
      return (
        <div>
          {rate.isActive ? (
            <Button
              key={`${rate.id}_${rate.isActive}`}
              onClick={handleDeactivate}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              key={`${rate.id}_${rate.isActive}`}
              onClick={handleReactivate}
            >
              Reactivate
            </Button>
          )}
        </div>
      );
    }
  };

  const [imageUrl, setImageUrl] = useState("");
  const storage = firebase.storage();

  const handleImageUpload = async (file) => {
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`orgs/${orgData.id}/logo.png`);

    try {
      // Upload the file to Firebase Storage
      const snapshot = await imageRef.put(file);
      // Get the download URL
      const downloadUrl = await snapshot.ref.getDownloadURL();
      // Update the state with the download URL
      setImageUrl(downloadUrl);
      message.success("Image uploaded successfully!");
      // Update org document.
      firestore
        .collection("orgs")
        .doc(orgData.id)
        .update({ logoUrl: downloadUrl });
    } catch (error) {
      console.log(error);
      message.error("Failed to upload image.");
    }
  };

  return (
    <Card
      style={{ margin: "0 12px 12px" }}
      size="small"
      title="General"
      extra={
        <Button type="primary" onClick={confirmSave}>
          Save Changes
        </Button>
      }
    >
      <Form form={form}>
        <Row justify="start">
          <Col span={8} style={{ marginRight: "70px" }}>
            <TextSubtitle text={"COMPANY LOGO"} />

            <Form.Item>
              <Upload
                beforeUpload={handleImageUpload}
                showUploadList={false}
                listType="picture-card"
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="Company Logo"
                    style={{ width: "100px" }}
                  />
                ) : (
                  <div>
                    <UploadOutlined /> Click to Upload
                  </div>
                )}
              </Upload>
            </Form.Item>

            <TextSubtitle text={"COMPANY NAME"} />
            <br />
            <Form.Item name="companyName">
              <Input placeholder="Company name" onChange={onUpdate} />
            </Form.Item>
            <TextSubtitle text={"COMPANY ADDRESS"} />
            <br />
            <Form.Item name="companyAddress">
              <Input placeholder="Address" onChange={onUpdate} />
            </Form.Item>
            <Form.Item name="showCompanyAddress" valuePropName={"checked"}>
              <Checkbox
                onChange={showCompanyAddressChanged}
                defaultChecked={orgData?.showCompanyAddress}
              >
                Show company address on invoices.{" "}
              </Checkbox>{" "}
            </Form.Item>
            <TextSubtitle text={"COMPANY PHONE"} />
            <br />
            <Form.Item name="companyPhone">
              <Input placeholder="Company phone" onChange={onUpdate} />
            </Form.Item>
            <TextSubtitle text={"COMPANY WEBSITE"} />
            <br />
            <Form.Item name="companyWebsite">
              <Input placeholder="Company website" onChange={onUpdate} />
            </Form.Item>
            <TextSubtitle text={"INVOICE TAX RATE"} />
            <br />

            <Space size={0} direction={"vertical"}>
              <Space align={"start"}>
                <Form.Item name="taxRate" style={{ width: "400px" }}>
                  <Select
                    disabled={fetching}
                    placeholder={"Select Tax Rate"}
                    onSelect={handleTaxChange}
                  >
                    <Select.Option
                      key={"create"}
                      style={{ fontWeight: "bold" }}
                      value={"create"}
                    >
                      + Create Tax Rate
                    </Select.Option>
                    {taxList.map((t) => (
                      <Select.Option key={`${t.id}_${t.isActive}`} value={t.id}>
                        {t && renderOption(t)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {/*<Button onClick={handleEditModal}>Edit Rate</Button>*/}
                {renderActionBtns()}
              </Space>

              {orgData && orgData.integrations.qbOnline && (
                <TextSubtitle
                  text={"*Added tax rates will be synced to Quickbooks"}
                />
              )}
            </Space>

            <br />

            <br />
            <Space>
              <TextSubtitle text={"WEBSITE TAX NAME"} />
              <Tooltip title="If you're Adelie account is integrated with your website for rentals, Record the name of the tax rate that is being used on your website so we can match it with an applicable tax rate in Adelie and QuickBooks Online if applicable.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="webTaxName">
              <Input
                placeholder="Tax Name From Website (If Applicable)"
                onChange={onUpdate}
              />
            </Form.Item>
            <Space>
              <TextSubtitle text={"WEB TRANSACTION TYPE"} />
              <Tooltip title="If you're Adelie account is integrated with your website for rentals, choose what type of transaction should be created in Adelie when a web order is submitted.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="webTransactionType" style={{ width: "400px" }}>
              <Select
                disabled={fetching}
                placeholder={"Select Transaction Type"}
                onSelect={handleTransactionTypeChange}
              >
                <Select.Option
                  key={"Draft"}
                  style={{ fontWeight: "bold" }}
                  value={"Draft"}
                ></Select.Option>
                <Select.Option
                  key={"Estimate"}
                  style={{ fontWeight: "bold" }}
                  value={"Estimate"}
                ></Select.Option>
                <Select.Option
                  key={"Invoice"}
                  style={{ fontWeight: "bold" }}
                  value={"Invoice"}
                ></Select.Option>
              </Select>
            </Form.Item>
            <Space>
              <TextSubtitle text={"ESTIMATE SIGNATURE"} />
              <Tooltip title='When you request a signature via the "Save and Send Estimate" option, if checked, the invoice will be automatically turned into an Invoice type after the customer has signed the invoice.'>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item
              name="automaticallyConvertEstimate"
              valuePropName="checked"
            >
              <Checkbox
                onChange={autoConvertEstimateChanged}
                defaultChecked={true}
              >
                Automatically Convert Estimate To Invoice{" "}
              </Checkbox>
            </Form.Item>
            <Space>
              <TextSubtitle text={"INVOICE TEMPLATE"} />
              <Tooltip title="When you send an estimate/invoice to a customer, checking the following boxes will toggle the specified columns on/off.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="showInvoiceItemImg" valuePropName="checked">
              <Checkbox
                onChange={showInvoiceItemImgChanged}
                defaultChecked={false}
              >
                Show Item Images On Invoices{" "}
              </Checkbox>
            </Form.Item>
            <Form.Item name="showItemName" valuePropName="checked">
              <Checkbox onChange={showItemNameChanged} defaultChecked={false}>
                Show Item Name On Invoices{" "}
              </Checkbox>
            </Form.Item>
            <Form.Item name="showDescription" valuePropName="checked">
              <Checkbox
                onChange={showDescriptionChanged}
                defaultChecked={false}
              >
                Show Description On Invoices{" "}
              </Checkbox>
            </Form.Item>
            <Form.Item name="showRate" valuePropName="checked">
              <Checkbox onChange={showRateChanged} defaultChecked={false}>
                Show Rate On Invoices{" "}
              </Checkbox>
            </Form.Item>
            <Form.Item name="showDuration" valuePropName="checked">
              <Checkbox onChange={showDurationChanged} defaultChecked={false}>
                Show Duration On Invoices{" "}
              </Checkbox>
            </Form.Item>

            <Form.Item name="showTotal" valuePropName="checked">
              <Checkbox onChange={showTotalChanged} defaultChecked={false}>
                Show Total On Invoices{" "}
              </Checkbox>
            </Form.Item>

            <Form.Item name="deliveryFeeTaxable" valuePropName="checked">
              <Checkbox
                onChange={deliveryFeeTaxableChanged}
                defaultChecked={true}
              >
                Delivery Fee is Taxable{" "}
              </Checkbox>
            </Form.Item>

            <Space>
              <TextSubtitle text={"DEFAULT SECURITY DEPOSIT PERCENT"} />
              <Tooltip title="If you would like to apply a default security deposit amount to Invoice Payment links, you can set that as a percentage here.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>

            <Form.Item name={"defaultDepositPercent"}>
              <InputNumber
                value={defaultDepositPercent}
                onChange={handleDepositPercentageChange}
                formatter={(value) => `${value}%`}
                min={0}
                max={100}
              />
            </Form.Item>

            <Space>
              <TextSubtitle text={"QUICKBOOKS SYNC"} />
              <Tooltip title="By default, QuickBooks integrated accounts will sync one way from Adelie to QuickBooks. If you want bidirectional sync, check this box. Note that this increases the odds of discrepancies between Adelie and QuickBooks.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>

            <br />
            <Form.Item name="qbBidirectionalSync" valuePropName="checked">
              <Checkbox
                onChange={qbBidirectionalSyncChanged}
                defaultChecked={false}
              >
                Enable bidirectional sync with QuickBooks Online{" "}
              </Checkbox>
            </Form.Item>
            <Space>
              <TextSubtitle text={"QuickBooks Invoice Date"} />
              <Tooltip title="Specify which date field on the Adelie invoice you want to apply as the invoice date on the corresponding synced invoice in QuickBooks">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="qbInvoiceDate" style={{ width: "400px" }}>
              <Select
                disabled={fetching}
                placeholder={"Select Date Type"}
                onSelect={handleQBInvoiceDateChange}
              >
                <Select.Option
                  key={"Invoice Creation Date"}
                  style={{ fontWeight: "bold" }}
                  value={"Invoice Creation Date"}
                ></Select.Option>
                <Select.Option
                  key={"Rental Start Date"}
                  style={{ fontWeight: "bold" }}
                  value={"Rental Start Date"}
                ></Select.Option>
                <Select.Option
                  key={"Rental End Date"}
                  style={{ fontWeight: "bold" }}
                  value={"Rental End Date"}
                ></Select.Option>
                <Select.Option
                  key={"Invoice Due Date"}
                  style={{ fontWeight: "bold" }}
                  value={"Invoice Due Date"}
                ></Select.Option>
              </Select>
            </Form.Item>
            <Space>
              <TextSubtitle text={"EVENT DATE"} />
              <Tooltip title="Allow event date to be input on invoices">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="eventDateEnabled" valuePropName={"checked"}>
              <Checkbox
                onChange={eventDateEnabledChanged}
                defaultChecked={orgData?.eventDateEnabled}
              >
                Enable event date on invoices{" "}
              </Checkbox>{" "}
            </Form.Item>
            <Space>
              <TextSubtitle text={"SALES ONLY INVOICE"} />
              <Tooltip title="Checking this box will allow you to specify the type of transaction document that will be shown to your customer when creating/sending a new order.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="salesInvoiceEndabled" valuePropName={"checked"}>
              <Checkbox
                onChange={salesInvoiceEnabledChanged}
                defaultChecked={orgData?.salesInvoiceEnabled}
              >
                Allow sales only invoices{" "}
              </Checkbox>{" "}
            </Form.Item>
            <Space>
              <TextSubtitle text={"ADMIN EMAIL"} />
              <Tooltip title="Enter the email address that will receive important notifications regarding your account">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="adminEmail">
              <Input placeholder="Admin Email Address" onChange={onUpdate} />
            </Form.Item>

            <Space>
              <TextSubtitle text={"USE ADMIN EMAIL FOR ALL CORRESPONDENCE"} />
              <Tooltip title="Checking this box will make it so all customer replies will be sent to the admin email rather than individual employee user emails.">
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
            <br />
            <Form.Item name="useAdminEmailReply" valuePropName={"checked"}>
              <Checkbox
                onChange={setUseAdminEmailReplyChanged}
                defaultChecked={orgData?.useAdminEmailReply}
              >
                Use admin email for all email replies.{" "}
              </Checkbox>{" "}
            </Form.Item>
            <Space></Space>
          </Col>
          <Col flex={"160px"}>
            <Tooltip title='Store hours will auto-populate on rental orders where "Pickup" and/or "Customer drop off" are selected in the Receive and Return Details'>
              <span>
                <TextSubtitle text={"STORE OPEN TIME"} />
              </span>
            </Tooltip>
            <br />

            <Form.Item
              className="FormItemNoValidatonMargin"
              name="companyOpenTime"
              style={{ marginBottom: 0 }}
              initialValue={null}
            >
              <TimePicker
                placeholder="Open"
                style={{ width: "140px" }}
                use12Hours
                format="h:mm a"
                onChange={(moment) => {
                  updateStoreHours({
                    fieldName: "companyOpenTime",
                    moment,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col flex={"160px"}>
            <TextSubtitle text={"STORE CLOSE TIME"} />
            <Form.Item
              className="FormItemNoValidatonMargin"
              name="companyCloseTime"
              style={{ marginBottom: 0 }}
              initialValue={null}
            >
              <TimePicker
                placeholder="Open"
                style={{ width: "140px" }}
                use12Hours
                format="h:mm a"
                onChange={(moment) => {
                  updateStoreHours({
                    fieldName: "companyCloseTime",
                    moment,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <TaxModal
          setTaxModal={setTaxModal}
          visible={taxModal.visible}
          onTaxCreated={onTaxCreated}
          onCancel={onModalCancel}
        />
        <TaxEditModal
          setTaxEditModal={setTaxEditModal}
          taxEditModal={taxEditModal}
          onTaxEdited={onTaxEdited}
          refresh={refresh}
        />
      </Form>
    </Card>
  );
};

export default GeneralInfo;
