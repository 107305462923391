import { useIsAuthenticated } from "../useIsAuthenticated";
import notificationError from "../../../system-components/toasters/notificationError";
import { INVOICE } from "../../../utils/models/modelConstants/modelConstants";
import { invoices } from "../../firestore/queries/invoiceQueries";
import moment from "moment";
import { categoryQueries } from "../../firestore/queries/categoryQueries";
import onlyUnique from "../../../utils/filters/filterOnlyUnique";
import { inventoryQueries } from "../../firestore/queries/inventoryQueries";
import { DayWithinRange } from "../helpers/dayWithinRange";
import { ReduceStockByDay } from "../helpers/reduceStockByDay";

const { useState, useEffect } = require("react");
const { useFirestore } = require("react-redux-firebase");

export function useInvoicesInMonth(activeDay) {

  const startOfMonth = moment(activeDay).startOf("month").toDate();
  const endOfMonth = moment(activeDay).endOf("month").toDate();

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    inventory: null,
    category: null,
  });
  const [availableFilters, setAvailableFilters] = useState({
    inventory: [],
    category: [],
  });
  const [filteredData, setFilteredData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [dailyAvailable, setDailyAvailable] = useState([]);
  const [error, setError] = useState({
    hasError: false,
    error: null,
  });
  const [refresh, setRefresh] = useState({
    refresh: false,
    count: 0,
  });
  const { isAuthenticated, fsOrgPrefix, orgData } = useIsAuthenticated();
  const firestore = useFirestore();

  const refreshData = () => {
    setRefresh({
      refresh: true,
      count: 0,
    });
  };

  useEffect(() => {
    const startEndOpts = {
      rentalDateStart: startOfMonth,
      rentalDateEnd: endOfMonth,
    };

    if (!isAuthenticated) return;
    if (refresh.count > 0) return;
    if (!startEndOpts.rentalDateStart || !startEndOpts.rentalDateEnd) return;
    if (fetching) return;
    
    try {
      setFetching(true);
      setData([]);
      invoices(orgData?.orgTimezone)
        .fetchMonthlyInvoicesByStartDate(
          { firestore },
          fsOrgPrefix,
          startEndOpts
        )
        .then((res) => {
          /**
           * fetch inventory
           */
          setData(res.filter((invoice) => invoice.type === INVOICE));
          let invFilters = [];
          let catFilterIds = [];
          let catFilters = [];

          res.forEach((invoice) => {
            catFilterIds = [...invoice.categoriesQueryHook, ...catFilterIds];
            invoice.qrItems.forEach((qrItem) => {
              if (invFilters.some((i) => i.id === qrItem.id)) { 
                return;
              }
              invFilters.push({
                name: qrItem.name,
                id: qrItem.id,
              });
            });
          });

          if (catFilterIds.length !== 0) {
            categoryQueries({ firestore }, fsOrgPrefix)
              .fetchCategories()
              .then((res) => {
                const ids = catFilterIds.filter(onlyUnique);
                ids &&
                  ids.map((i) => {
                    const cat = res.find((c) => c.id === i);
                    if (!cat) console.error("cat is undefined", i, res);
                    else
                      catFilters.push({
                        name: cat.name,
                        id: cat.id,
                      });
                  });
                setAvailableFilters({
                  inventory: [...invFilters],
                  category: [...catFilters],
                });
                setFetching(false);
                setRefresh({ refresh: false, count: refresh.count + 1 });
              });
          } else {
            setAvailableFilters({
              inventory: [...invFilters],
              category: [...catFilters],
            });
            setFetching(false);
            setRefresh({ refresh: false, count: refresh.count + 1 });
          }
        });
    } catch (err) {
      console.log(err);
      notificationError(
        "Something went wrong",
        "Please try again or refresh the page"
      );
      setFetching(false);
      setError({
        hasError: true,
        error: err,
      });
      setRefresh({ refresh: false, count: refresh.count + 1 });
    }
  }, [isAuthenticated, refresh, activeDay]);

  useEffect(() => {
    if (!filters.inventory && !filters.category) {
      setFilteredData([...data]);

    } else {
      const filtered = [...data].filter((d) => {
        let filterInv = false;
        let filterCat = false;
        if (filters.inventory) {
          filterInv =
            d.selectedItemsQueryHook.includes(filters.inventory) ||
            d.bundleItemsQueryHook.includes(filters.inventory);
        } else {
          filterInv = true;
        }
        if (filters.category) {
          filterCat = d.categoriesQueryHook.includes(filters.category);
        } else {
          filterCat = true;
        }
        return filterInv && filterCat;
      });

      setFilteredData([...filtered]);
    }
  }, [filters, data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!fsOrgPrefix || !isAuthenticated) return;
    /**
     * Configure list of available per day
     */
    const start = new Date(startOfMonth);
    const end = new Date(endOfMonth);
    let dailyAvailable = [];

    const ids = availableFilters.inventory && availableFilters.inventory.map((i) => i.id);

    const items = await inventoryQueries(
      { firestore },
      fsOrgPrefix
    ).inventoryByIdList(ids);
    let loop = new Date(start);
    while (loop <= end) {
      /**
       * Calculate available items for each day
       * - if day is within invoice range add to day
       * - denote bundle item or item
       * - configure turnaround time
       */
      const dayDate = new Date(loop);
      const dayDateEnd = moment(dayDate).endOf("day");
      const dayInvoices = data.filter((i) => {
        return DayWithinRange({
          tStart: i.rentalDateStart,
          tEnd: i.rentalTurnaroundEnd,
          day: dayDateEnd.toDate().setMinutes(-1),
        });
      });
      
      const dailyStock = ReduceStockByDay({
        useTurnaround: true,
        inventory: items,
        invoicesInRange: dayInvoices,
        startDate: dayDate,
      });
      dailyAvailable.push({
        date: dayDate,
        stockList: dailyStock,
      });

      let newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }
    setDailyAvailable([...dailyAvailable]);
    
  }, [data, isAuthenticated, availableFilters]);

  return {
    data: data,
    fetching: fetching,
    error: error,
    availableFilters: availableFilters,
    filters: filters,
    setFilters: setFilters,
    filteredData: filteredData,
    dailyAvailable: dailyAvailable,
    setAvailableFilters: setAvailableFilters,
    refresh: refreshData,
  };
}
