import { useSelector } from "react-redux";
import { authSelector } from "../../../domains/auth/authSlice";
import { useEffect, useState } from "react";
import notificationError from "../../system-components/toasters/notificationError";

export function useIsAuthenticated() {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    return orgData && userData && fsOrgPrefix
      ? setIsAuthenticated(true)
      : setIsAuthenticated(false);
  }, [orgData, userData, fsOrgPrefix]);
  return {
    isAuthenticated: isAuthenticated,
    orgData: orgData,
    userData: userData,
    fsOrgPrefix: fsOrgPrefix,
  };
}
