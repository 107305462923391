import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Radio, Spin } from "antd";
import { Spinner } from "../../../app/ui/spinner";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import {
  USERTYPE_ADMIN,
  USERTYPE_OFFICE,
  USERTYPE_WAREHOUSE,
} from "../../../app/utils/models/modelConstants";
import { MailFilled, PhoneFilled } from "@ant-design/icons";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { configureEditUser } from "../../../app/utils/models/configure/configureEditUser";
import { useFirestore } from "react-redux-firebase";
import { userWrites } from "../../../app/services/firestore/writes/userWrites";

const EditUserModal = ({ onCancel, visible, user, onEditComplete }) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [saving, setSaving] = useState(false);
  const [form] = Form.useForm();

  const firestore = useFirestore();

  useEffect(() => {
    visible &&
      form.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email,
        permissions: user.permissions,
      });
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const handleFinish = () => {
    if (!userData || !orgData || !fsOrgPrefix || !user.id)
      return notificationError("Unable to edit", "Please try again later");

    form.validateFields().then(() => {
      setSaving(true);
      const payload = configureEditUser(
        form.getFieldsValue(),
        userData.id,
        orgData.id
      );
      try {
        userWrites({ firestore }, fsOrgPrefix)
          .updateUserDetails(user.id, payload)
          .then(() => {
            setSaving(false);
            onEditComplete();
            notificationConfirm("User updated", "");
          });
      } catch (err) {
        console.log(err);
        setSaving(false);
        //err toast
        notificationError("Something went wrong", "Please try again later");
      }
    });
  };

  return (
    <Modal
      destroyOnClose
      width={600}
      visible={visible}
      closeIcon={<></>}
      title="Edit User"
      footer={[
        <Button key="cancel" onClick={() => onCancel()} disabled={saving}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => handleFinish()}
          disabled={saving}
        >
          Update User
        </Button>,
      ]}
    >
      <div>
        <Spin spinning={saving} indicator={Spinner}>
          <Form form={form} layout="vertical" requiredMark={false}>
            <Form.Item
              name="permissions"
              label={<TextSubtitle text={"USER PERMISSIONS"} />}
              initialValue={"admin"}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={USERTYPE_ADMIN}>Admin</Radio.Button>
                <Radio.Button value={USERTYPE_OFFICE}>Office</Radio.Button>
                <Radio.Button value={USERTYPE_WAREHOUSE}>
                  Warehouse
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <br />
            <div style={{ paddingBottom: "8px" }}>
              <TextSubtitle text={"USER DETAILS"} />
            </div>
            <Form.Item name="firstName">
              <Input placeholder="First Name" disabled />
            </Form.Item>
            <Form.Item name="lastName">
              <Input placeholder="Last Name" disabled />
            </Form.Item>
            <Form.Item name="email">
              <Input
                placeholder="Email"
                disabled
                suffix={
                  <MailFilled style={{ opacity: "0.2", marginRight: "2px" }} />
                }
              />
            </Form.Item>
            <Form.Item name="phone">
              <Input
                placeholder="Phone"
                suffix={
                  <PhoneFilled style={{ opacity: "0.2", marginRight: "2px" }} />
                }
              />
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};

export default EditUserModal;
