import React, {useMemo, useState } from "react";

import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarExport, GridFilterOperator } from "@mui/x-data-grid-pro";
import { MenuItem, TextField, Button } from "@mui/material";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { authSelector } from "../auth/authSlice";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import {
  COLOR_RED_0,
  COLOR_BLUE_0,
  COLOR_PURPLE_0,
} from "./../../app/ui/colorConstants";

import notificationError from "./../../app/system-components/toasters/notificationError";
import notificationConfirm from "./../../app/system-components/toasters/notificationConfirm";
import { invoices } from "./../../app/services/firestore/queries/invoiceQueries";
import { useFirestoreWrite } from "./../../app/services/hooks/fetch/useFirestoreWrite";
import { INVOICES_COLLECTION } from "./../../app/utils/models/collections/collectionConstants";
import { ConfigureDuplicateInvoice } from "./../../app/utils/models/configure/configureDuplicateInvoice";
import { Link } from "react-router-dom";
import { ROUTE_EDIT_INVOICE } from "../../app/routes";
import { capitalizeFirstLetter } from "../../app/utils/casing/capitalizeFirstLetter";
import { CUSTOMER_DISPLAY_NAME } from "../../app/utils/models/modelConstants/modelConstants";
import convertTimeToDayString from "../../app/utils/time/convertTimeToDayString";
import { Space, Tag, Tooltip } from "antd";
import { ClockCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import convertToTime from "../../app/utils/time/convertToTime";
import { FormatToLocalCurrency } from "../../app/utils/currency/formatToLocalCurrency";
import { i18n } from "../../i18n";

import { ROUTE_RECEIVE_PAYMENT_ROOT } from "./../../app/routes";

import {
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import EmailIcon from "@mui/icons-material/Email";
import SaveIcon from "@mui/icons-material/Save";
import SyncIcon from "@mui/icons-material/Sync";
import MessageIcon from "@mui/icons-material/Message"
import { styled } from "@mui/system";

import {
  DataGridPro,
  GridToolbarFilterButton,
  useGridApiRef,
  
} from "@mui/x-data-grid-pro";
import InternalNotesDrawer from "./components/InternalNotesDrawer";
import InvoiceEmailDrawer from "./components/InvoiceEmailDrawer";
import { Typography } from "@mui/material";
// import SyncQuickBooksModal from "./components/SyncQuickBooksModal";

const InvoicesDataGrid = ({
  fetching,
  data,
  onDeleteComplete,
  onDuplicateComplete,
  history,
}) => {

  const CustomIconButton = styled(IconButton)(({ theme, customColor }) => ({
    color: customColor,
  }));

  // const [showSyncModal, setShowSyncModal] = useState(false);

  // function syncClicked(item) {
  //   setShowSyncModal(true);
  // }

  // function onManualSyncComplete(success) {
  //   setShowSyncModal(false);
  //   if (success) {
  //     notificationConfirm("Success!", "Sync complete");
  //   } else {
  //     notificationError(
  //       "Error",
  //       "Unable to sync invoice. Please contact Adelie support."
  //     );
  //   }
  // }

  const SyncButton = ({ status, row }) => {
    const syncColor = status === null ? COLOR_RED_0 : "green";

    return (
      <CustomIconButton customColor={syncColor}>
        <SyncIcon customColor={syncColor} />
      </CustomIconButton>
    );
  };

  const EmailButton = ({ status, row }) => {
    const color = status ? COLOR_RED_0 : "#3b5999";

    return (
      <CustomIconButton
        customColor={color}
        onClick={() => handleEmailsClick(row)}
      >
        <EmailIcon />
      </CustomIconButton>
    );
  };

  const [chatDrawer, setChatDrawer] = useState({
    visible: false,
    invoice: null,
  });

  const handleChatClick = (i) => {
    setChatDrawer({
      visible: true,
      invoice: i,
    });
  };

  const [emailDrawer, setEmailDrawer] = useState({
    visible: false,
    invoice: null,
  });
  const handleEmailsClick = (i) => {
    setEmailDrawer({
      visible: true,
      invoice: i,
    });
  };

  const apiRef = useGridApiRef();
  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
  const { deleteDocument, newDocument, updateOrgDocument } =
    useFirestoreWrite();
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = React.useState();
  const [contextMenu, setContextMenu] = React.useState(null);
  const firestore = useFirestore();

  const SUBMIT_FILTER_STROKE_TIME = 500;

  function TagsFilterInput(props) {
    const { item, applyValue, focusElementRef = null } = props;

    const [filterValueState, setFilterValueState] = React.useState(item.value ?? "");
  
    const handleFilterChange = (event) => {
      const query = event.target.value;
      setFilterValueState(query);
    };
  
    const applyFilter = () => {
      applyValue({ ...item, value: filterValueState });
    };
  
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        applyFilter();
      }
    };
  
    return (
      <TextField
        placeholder="tag..."
        label="Tag"
        variant="standard"
        value={filterValueState}
        onChange={handleFilterChange}
        onKeyPress={handleKeyPress}
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
    );
  }
  
  const tagsOperators = [
    {
      value: 'contains',
      getApplyFilterFn: (filterItem) => {

        if (!filterItem || !filterItem.value) {
          return;
        }

        return (params) => {
          const formattedValue = params.formattedValue.toLowerCase();
          const query = filterItem.value.toLowerCase()
          return formattedValue.includes(query);
        };
      },
      InputComponent: TagsFilterInput,
    },
  ];
  
  const muiInvoiceTableColumns = useMemo(() => {
    const headers = [
      {
        headerName: "Invoice #",
        field: "invoiceNumber",
        width: 100,
        type: "number",
        defaultSortOrder: "descend",
        valueGetter: (params) => params.value,
        renderCell: (params) => (
          <Link to={`${ROUTE_EDIT_INVOICE}${params.row.id}`}>
            {params.value}
          </Link>
        ),
      },
      {
        headerName: i18n("invoices.type"),
        field: "type",
        type: "string",
        width: 100,
        valueFormatter: (params) => capitalizeFirstLetter(params.value),
      },
      {
        headerName: "Tags",
        field: "tagsQueryHook",
        type: "string",
        width: 100,
        filterOperators: tagsOperators,
        valueFormatter: (params) => {
          const tagNames = params.value.map((tagId) => {
            const tag = orgData.tags.find((tag) => tag.id === tagId);
            return tag ? tag.name : "";
          });
          return tagNames.join(", ");
        },
        renderCell: (params) => {
          return (
            <div style={{ width: "100%", overflowX: "auto" }}>
              {params.value.map((tagId) => {
                if (orgData) {
                  const tag = orgData.tags.find((tag) => tag.id === tagId);
                  if (tag) {
                    return (
                      <Tag color={tag.color} key={tag.id}>
                        {tag.name}
                      </Tag>
                    );
                  } else {
                    return null;
                  }
                }
              })}
            </div>
          );
        },
      },
      
      {
        headerName: i18n("invoices.customer"),
        field: "customer",
        type: "string",
        width: 300,
        valueGetter: (params) =>
          params.row.customer &&
          params.row.customer[CUSTOMER_DISPLAY_NAME] &&
          params.row.customer[CUSTOMER_DISPLAY_NAME],
      },
      {
        headerName: "Sales Rep",
        field: "salesRepName",
        type: "string",
        width: 200,
        valueGetter: (params) => {
          return (params.row.salesRepName && params.row.salesRepName) ?? "--";
        },
      },
      {
        headerName: i18n("invoices.lastUpdated"),
        field: "updatedOn",
        type: "dateTime",
        width: 200,
        valueGetter: (params) => params.row.updatedOn?.toDate(),
        renderCell: (params) => {
          return (
            <Space>
              {convertTimeToDayString(params.row.updatedOn, "dash")}
            </Space>
          );
        },
      },
      {
        headerName: i18n("invoices.eventDate"),
        field: "eventDate",
        type: "dateTime",
        width: 200,
        valueGetter: (params) => params.row.eventDate?.toDate(),
        renderCell: (params) => {
          return (
            <Space>
              {convertTimeToDayString(params.row.eventDate, "dash")}
            </Space>
          );
        },
      },
      {
        headerName: "Created On",
        field: "createdOn",
        type: "dateTime",
        width: 200,
        valueGetter: (params) => params.row.createdOn?.toDate(),
        renderCell: (params) => {
          return (
            <Space>
              {convertTimeToDayString(params.row.createdOn, "dash")}
            </Space>
          );
        },
      },
      {
        headerName: i18n("invoices.rentalStart"),
        field: "rentalDateStart",
        type: "dateTime",
        width: 200,
        valueGetter: (params) => params.row.rentalDateStart?.toDate(),
        renderCell: (params) => {
          return (
            <Space>
              {convertTimeToDayString(params.row.rentalDateStart)}
              {params.row.specifiedReceiveTime && (
                <Tooltip title={convertToTime(params.row.specifiedReceiveTime)}>
                  <ClockCircleOutlined />
                </Tooltip>
              )}
            </Space>
          );
        },
      },
      {
        headerName: i18n("invoices.rentalEnd"),
        field: "rentalDateEnd",
        width: 200,
        type: "dateTime",
        valueGetter: (params) => params.row.rentalDateStart?.toDate(),
        renderCell: (params) => {
          return (
            <Space>
              {convertTimeToDayString(params.row.rentalDateEnd)}
              {params.row.specifiedReturnTime && (
                <Tooltip title={convertToTime(params.row.rentalDateEnd)}>
                  <ClockCircleOutlined />
                </Tooltip>
              )}
            </Space>
          );
        },
      },
      {
        headerName: i18n("invoices.days"),
        type: "number",
        field: "rentalRange",
      },
      {
        headerName: "Internal Notes",
        field: "id",
        type: "boolean",
        width: 100,
        renderCell: (params) => (
          <div>
            <CustomIconButton
              customColor={COLOR_BLUE_0}
              onClick={() => handleChatClick(params.row)}
            >
              <MessageIcon customColor={COLOR_BLUE_0} />
            </CustomIconButton>
          </div>
        ),
      },
      {
        headerName: i18n("invoices.email"),
        resizable: true,
        minWidth: 50,
        width: 200,
        field: "email",
        valueGetter: (params) =>
          params.row.customer &&
          params.row.customer.email &&
          params.row.customer.email,
      },
      {
        headerName: i18n("invoices.total"),
        field: "total",
        type: "number",
        width: 150,
        valueGetter: (params) => params.value,
        renderCell: (params) =>
          FormatToLocalCurrency(
            params.value,
            orgData.countryCode,
            orgData.languageCode,
            orgData.currencyCode
          ),
      },
      {
        headerName: "Items",
        field: "selectedItems",
        width: 150,
        renderCell: (params) => {
          if (params.value.count < 3) {
            return (
              <div>
                {params.value.items.map((item, index) => (
                  <div>
                    {item.name}: {item.selectedQty}
                  </div>
                ))}
              </div>
            );
          } else {
            return (
              <Tooltip
                title={
                  <div>
                    {params.value.items.map((item, index) => (
                      <Typography key={index}>
                        {item.name}: {item.selectedQty}
                      </Typography>
                    ))}
                  </div>
                }
              >
                <div>{params.value.count} items</div>
              </Tooltip>
            );
          }
        },
      },
      {
        headerName: i18n("invoices.balance"),
        field: "balanceRemaining",
        type: "number",
        width: 150,
        // sorter: (a, b) => a?.balanceRemaining - b?.balanceRemaining,
        renderCell: (params) => (
          <div>
            <Space direction="horizontal" style={{ alignItems: "center" }}>
              {FormatToLocalCurrency(
                params.value,
                orgData.countryCode,
                orgData.languageCode,
                orgData.currencyCode
              )}
              {params.row.balanceRemaining <= 0 && (
                <CheckCircleFilled style={{ color: "#048446" }} />
              )}
            </Space>
          </div>
        ),
      },
      {
        headerName: "Email Tracking",
        field: "hasSendRecords",
        type: "boolean",
        width: 100,
        renderCell: (params) => (
          <div>
            {params.row.hasSendRecords ? (
              <EmailButton
                status={params.row.emailDeliveryFailed}
                row={params.row}
              />
            ) : (
              <div>--</div>
            )}
          </div>
        ),
      },
      {
        headerName: i18n("invoices.status"),
        field: "invoiceStatus",
        width: 300,
        // sorter: (a, b) => a?.balanceRemaining - b?.balanceRemaining,
        renderCell: (params) => (
          <div>
            <Space size={[0, 8]} wrap>
              {/* <Space direction="horizontal" style={{ alignItems: "center" }}> */}
              <Tag color={params.row.isLoaded ? "#3b5999" : "default"}>
                Loaded
              </Tag>
              <Tag
                color={params.row.receivedByDelivery ? "#3b5999" : "default"}
              >
                Received
              </Tag>
              <Tag color={params.row.returnedByPickup ? "#3b5999" : "default"}>
                Returned
              </Tag>
            </Space>
          </div>
        ),
      },
    ];

    if (orgData && orgData.qboRealmId && orgData.qboRealmId !== '') {
      headers.push(
        {
          headerName: "QuickBooks Sync",
          field: "qbId",
          type: "boolean",
          width: 100,
          renderCell: (params) => (
            <div>
              {params.row.type === "invoice" ? (
                params.row.qbId !== null ? (
                  <SyncButton status={params.row.qbId} row={params.row} />
                ) : (
                  <div>
                    <CustomIconButton
                      customColor={COLOR_RED_0}
                      // onClick={syncClicked}
                    >
                      <SyncIcon customColor={COLOR_RED_0} />
                    </CustomIconButton>
                  </div>
                )
              ) : (
                <div>
                  <Tooltip title="Only invoices are synced to QuickBooks at this time.">
                    --
                  </Tooltip>
                </div>
              )}
            </div>
          ),
        },
      )
    }

    return headers
  }, [orgData]);

  const handleDuplicate = async () => {
    if (selectedRow) {
      setLoading(true);

      const dupInvoiceSnap = invoices(orgData?.orgTimezone).fetchInvoiceRawData(
        { firestore },
        fsOrgPrefix,
        { id: selectedRow && selectedRow }
      );

      dupInvoiceSnap
        .then((res) => {
          const payload = ConfigureDuplicateInvoice(res, userData, orgData);
          updateOrgDocument({
            data: {
              payload: {
                currentInvoiceCount: payload.invoiceNumber,
              },
              id: orgData && orgData.id,
            },
          })
            .then(() => {
              newDocument({
                data: { collection: INVOICES_COLLECTION, payload },
              })
                .then(() => {
                  setLoading(false);
                  onDuplicateComplete();
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              notificationError("Something went wrong");
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleDelete = () => {
    setContextMenu(null);
    Modal.confirm({
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      title: "Delete invoice?",
      content:
        "This cannot be undone! Please note, deleting an invoice will remove it from your account completely and it cannot be recovered. It will immediately affect all associated reporting tied to this transaction (ie. QuickReport, Available Inentory, Routes...etc.) All linked payments will remain and it may affect your books. Be sure to compare transactions in QuickBooks to ensure proper account balancing.",
      okType: "danger",
      onOk() {
        return new Promise(async (resolve, reject) => {
          if (!fsOrgPrefix) {
            return notificationError(
              "Something went wrong",
              "Please try again later"
            );
          } else {
            await deleteDocument({
              data: {
                collection: INVOICES_COLLECTION,
                id: selectedRow && selectedRow,
              },
            });
            resolve();
            onDeleteComplete();
            notificationConfirm("Invoice deleted", "");
          }
        }).catch((err) => {
          console.log(err);
          notificationError("Something went wrong", "Please try again later");
        });
      },
      onCancel() {},
    });
  };

  const handleReceivePayment = () => {
    const invoice = data.find((invoice) => invoice.id === selectedRow);

    if (invoice) {
      const customer = invoice.customerQueryHook;
      const invoiceId = invoice.id;
      history.push({
        pathname: `${ROUTE_RECEIVE_PAYMENT_ROOT}${customer}`,
        state: { invoiceId: invoiceId },
      });
    }
  };

  const handleView = () => {
    history.push(`${ROUTE_EDIT_INVOICE}${selectedRow && selectedRow}`);
  };



  // DATA GRID TOOL BARS AND PROFILES

  const gridStateReducer = (state, action) => {
    switch (action.type) {
      case 'createView': {
        const id = Math.random().toString();
      
        const updatedViews = {
          ...state.views,
          [id]: { label: state.newViewLabel, value: action.value },
        };
      
        return {
          ...state,
          activeViewId: id,
          newViewLabel: '',
          views: updatedViews,
        };
      }
      
      case 'deleteView': {
        const views = Object.fromEntries(
          Object.entries(state.views).filter(([id]) => id !== action.id),
        );
  
        let activeViewId;
        if (state.activeViewId !== action.id) {
          activeViewId = state.activeViewId;
        } else {
          const viewIds = Object.keys(state.views);
  
          if (viewIds.length === 0) {
            activeViewId = null;
          } else {
            activeViewId = viewIds[0];
          }
        }
  
        return {
          ...state,
          views,
          activeViewId,
        };
      }
  
      case 'setActiveView': {
        return {
          ...state,
          activeViewId: action.id,
          isMenuOpened: false,
        };
      }
  
      case 'setNewViewLabel': {
        return {
          ...state,
          newViewLabel: action.label,
        };
      }

      case 'setViews': {
        return {
          ...state,
          views: action.views,
        };
      }
  
      case 'togglePopper': {
        return {
          ...state,
          isMenuOpened: !state.isMenuOpened,
          menuAnchorEl: action.element,
        };
      }
  
      case 'closePopper': {
        return {
          ...state,
          isMenuOpened: false,
        };
      }

      case 'setLastSelectedView': {
        return {
          ...state,
          activeViewId: action.id,
        };
      }
  
  
      default: {
        return state;
      }
    }
  };

  const INITIAL_GRID_STATE = {
    views: JSON.parse(localStorage.getItem('GRID_PROFILES')) || {},
    newViewLabel: '',
    isMenuOpened: false,
    menuAnchorEl: null,
    activeViewId: null,
  };
  
  function ViewListItem(props) {
    const { view, viewId, selected, onDelete, onSelect, ...other } = props;
  
    return (
      <MenuItem selected={selected} onClick={() => onSelect(viewId)} {...other}>
        {view.label}
        <IconButton
          edge="end"
          aria-label="delete"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            onDelete(viewId);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </MenuItem>
    );
  }

  function NewViewListButton(props) {
    const { label, onLabelChange, onSubmit, isValid } = props;
    const [isAddingView, setIsAddingView] = React.useState(false);
  
    const handleSubmitForm = (e) => {
      onSubmit();
      setIsAddingView(false);
      e.preventDefault();
    };
  
    return (
      <React.Fragment>
        <Button
          style={{ color: COLOR_PURPLE_0 }}
          startIcon={<AddIcon />}
          size="small"
          onClick={() => setIsAddingView(true)}
        >
          Save New Filter
        </Button>
        <Dialog onClose={() => setIsAddingView(false)} open={isAddingView}>
          <form onSubmit={handleSubmitForm}>
            <DialogTitle>New custom filter.</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                value={label}
                onChange={onLabelChange}
                margin="dense"
                size="small"
                label="Custom view label"
                variant="standard"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button type="button" onClick={() => setIsAddingView(false)}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid}>
                Create Filter
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }

  function CustomToolbar() {
    const [state, dispatch] = React.useReducer(
      gridStateReducer,
      INITIAL_GRID_STATE
    );

    const createNewView = () => {
      dispatch({
        type: "createView",
        value: apiRef.current.exportState(),
      });
    };

    const handleNewViewLabelChange = (event) => {
      dispatch({ type: "setNewViewLabel", label: event.target.value });
    };

    const handleDeleteView = React.useCallback((viewId) => {
      dispatch({ type: "deleteView", id: viewId });
    }, []);

    const handleSetActiveView = (viewId) => {
      apiRef.current.restoreState(state.views[viewId].value);
      dispatch({ type: "setActiveView", id: viewId });
    };

    const handlePopperAnchorClick = (event) => {
      dispatch({ type: "togglePopper", element: event.currentTarget });
      event.stopPropagation();
    };

    const handleClosePopper = () => {
      dispatch({ type: "closePopper" });
    };

    const handleUpdateView = () => {
      // Get the updated view label and value from the form inputs
      const updatedViewValue = apiRef.current.exportState();

      const updatedViews = {
        ...state.views,
        [state.activeViewId]: {
          ...state.views[state.activeViewId], // Retain existing properties of the view
          label: state.views[state.activeViewId].label, // Update the label
          value: updatedViewValue, // Update the value
        },
      };

      // Update the state with the updated views
      dispatch({ type: "setViews", views: updatedViews });

      // Save the updated views to localStorage
      localStorage.setItem("GRID_VIEWS", JSON.stringify(updatedViews));
    };

    const isNewViewLabelValid = React.useMemo(() => {
      if (state.newViewLabel.length === 0) {
        return false;
      }

      return Object.values(state.views).every(
        (view) => view.label !== state.newViewLabel
      );
    }, [state.views, state.newViewLabel]);

    const canBeMenuOpened = state.isMenuOpened && Boolean(state.menuAnchorEl);
    const popperId = canBeMenuOpened ? "transition-popper" : undefined;

    const handleListKeyDown = (event) => {
      if (event.key === "Tab") {
        event.preventDefault();
        dispatch({ type: "closePopper" });
      } else if (event.key === "Escape") {
        dispatch({ type: "closePopper" });
      }
    };

    // Add a useEffect to set the last selected view on load
    React.useEffect(() => {
      const lastSelectedView = localStorage.getItem("LAST_SELECTED_VIEW");
      if (lastSelectedView && state.views[lastSelectedView]) {
        handleSetActiveView(lastSelectedView);
      }
    }, []);

    // Add a useEffect to save the last selected view
    React.useEffect(() => {
      if (state.activeViewId) {
        localStorage.setItem("LAST_SELECTED_VIEW", state.activeViewId);
      }
    }, [state.activeViewId]);

    React.useEffect(() => {
      localStorage.setItem("GRID_PROFILES", JSON.stringify(state.views));
    }, [state.views]);

    return (
      <GridToolbarContainer
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <GridToolbarColumnsButton style={{ color: COLOR_PURPLE_0 }} />
          <GridToolbarDensitySelector style={{ color: COLOR_PURPLE_0 }} />
          <GridToolbarExport style={{ color: COLOR_PURPLE_0 }} />
          <GridToolbarFilterButton style={{ color: COLOR_PURPLE_0 }} />
        </div>

        <div>
          <Button
            aria-describedby={popperId}
            type="button"
            size="small"
            id="custom-view-button"
            aria-controls={state.isMenuOpened ? "custom-view-menu" : undefined}
            aria-expanded={state.isMenuOpened ? "true" : undefined}
            aria-haspopup="true"
            onClick={handlePopperAnchorClick}
            style={{ color: COLOR_PURPLE_0 }}
          >
            SAVED FILTERS ({Object.keys(state.views).length})
          </Button>
          <ClickAwayListener onClickAway={handleClosePopper}>
            <Popper
              id={popperId}
              open={state.isMenuOpened}
              anchorEl={state.menuAnchorEl}
              role={undefined}
              transition
              placement="bottom-start"
              sx={{ zIndex: "modal" }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <MenuList
                      autoFocusItem={state.isMenuOpened}
                      id="custom-view-menu"
                      aria-labelledby="custom-view-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {Object.entries(state.views).map(([viewId, view]) => (
                        <ViewListItem
                          key={viewId}
                          view={view}
                          viewId={viewId}
                          selected={viewId === state.activeViewId}
                          onDelete={handleDeleteView}
                          onSelect={handleSetActiveView}
                        />
                      ))}
                    </MenuList>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </ClickAwayListener>

          <Button
            startIcon={<SaveIcon />}
            size="small"
            onClick={handleUpdateView}
            disabled={!state.activeViewId}
            style={{ color: COLOR_PURPLE_0 }}

          >
            Update Current Filter
          </Button>

          <NewViewListButton
            label={state.newViewLabel}
            onLabelChange={handleNewViewLabelChange}
            onSubmit={createNewView}
            isValid={isNewViewLabelValid}
          />
        </div>
      </GridToolbarContainer>
    );
  }

  function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />
    );
  }

  function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  const handleDoubleClickMenu = (params, event) => {
    setSelectedRow(params.id);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(event.currentTarget.getAttribute("data-id"));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <div style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px" }}>
      {orgData && data && (
        <div style={{ width: "100%", backgroundColor: "white" }}>
          <DataGridPro
            style={{ paddingLeft: "8px" }}
            autoHeight={true}
            apiRef={apiRef}
            pagination={CustomPagination}
            loading={fetching || loading}
            columns={muiInvoiceTableColumns}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              sorting: {
                sortModel: [{ field: "invoiceNumber", sort: "desc" }],
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            onRowDoubleClick={(params, event) =>
              handleDoubleClickMenu(params, event)
            }
            slotProps={{
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: "context-menu" },
              },
            }}
            rows={data}
          />
        </div>
      )}

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        <MenuItem onClick={handleView}>View</MenuItem>
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
        <MenuItem onClick={handleReceivePayment}>Receive Payment</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      {/* <SyncQuickBooksModal
        visible={showSyncModal}
        onCancel={() => setShowSyncModal(false)}
        onComplete={onManualSyncComplete}
        invoice={data.invoice}
        orgData={orgData}
      /> */}

      {orgData && (
        <InternalNotesDrawer
          showTags={false}
          drawerView
          visible={chatDrawer.visible}
          handleCloseDrawer={setChatDrawer}
          invoice={chatDrawer.invoice}
          tags={orgData.tags}
          onComplete={() => {
            setChatDrawer({
              visible: false,
              item: null,
            });
          }}
        />
      )}

      <InvoiceEmailDrawer
        drawerView
        visible={emailDrawer.visible}
        handleCloseDrawer={() =>
          setEmailDrawer({
            visible: false,
            item: null,
          })
        }
        invoice={emailDrawer.invoice}
        onComplete={() => {
          setEmailDrawer({
            visible: false,
            item: null,
          });
        }}
      />
    </div>
  );
};

export default InvoicesDataGrid;
