import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../domains/auth/authSlice";
import cartReducer from "../domains/inventory/cartSlice";
import invoiceFilterReducer from "../domains/invoices/invoiceSlice";
import { api } from "../app/services/api";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  cart: cartReducer,
  [api.reducerPath]: api.reducer,
  invoiceFilter: invoiceFilterReducer,
});

export default rootReducer;
