const UNDEFINED = "undefined";

export default function checkUser(userSnap) {
  const user = userSnap.data();

  const userId = () => {
    if (typeof userSnap.id !== UNDEFINED) {
      return userSnap.id;
    }
    return "";
  };
  const createdBy = () => {
    if (typeof user.createdBy !== UNDEFINED) {
      return user.createdBy;
    }
    return "";
  };
  const createdOn = () => {
    if (typeof user.createdOn !== UNDEFINED) {
      return user.createdOn;
    }
    return null;
  };
  const updatedBy = () => {
    if (typeof user.updatedBy !== UNDEFINED) {
      return user.updatedBy;
    }
    return "";
  };
  const updatedOn = () => {
    if (typeof user.updatedOn !== UNDEFINED) {
      return user.updatedOn;
    }
    return null;
  };
  const email = () => {
    if (typeof user.email !== UNDEFINED) {
      return user.email.toLowerCase();
    }
    return "";
  };
  const firstName = () => {
    if (typeof user.firstName !== UNDEFINED) {
      return user.firstName;
    }
    return "";
  };
  const lastName = () => {
    if (typeof user.lastName !== UNDEFINED) {
      return user.lastName;
    }
    return "";
  };
  const orgId = () => {
    if (typeof user.orgId !== UNDEFINED) {
      return user.orgId;
    }
    return "";
  };
  const permissions = () => {
    if (typeof user.permissions !== UNDEFINED) {
      return user.permissions;
    }
    return "";
  };
  const phone = () => {
    if (typeof user.phone !== UNDEFINED) {
      return user.phone;
    }
    return "";
  };
  const firstTimeLogin = () => {
    if (typeof user.firstTimeLogin !== UNDEFINED) {
      return user.firstTimeLogin;
    }
    return false;
  };

  return {
    id: userId(),
    createdBy: createdBy(),
    createdOn: createdOn(),
    updatedBy: updatedBy(),
    updatedOn: updatedOn(),
    email: email(),
    firstName: firstName(),
    lastName: lastName(),
    orgId: orgId(),
    permissions: permissions(),
    phone: phone(),
    firstTimeLogin: firstTimeLogin(),
  };
}
