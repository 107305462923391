import { INVOICE_DRAFT } from "../modelConstants/modelConstants";
import notificationError from "../../../system-components/toasters/notificationError";

export const ConfigureDuplicateInvoice = (snap, userData, orgData) => {
  if (!userData || !orgData) return notificationError("Something went wrong");
  const data = snap.data();

  /**
   * Removed / cleared from payload
   */
  delete data.paymentSignatures;
  delete data.estimateSignatures;

  const {
    id,
    type,
    updatedBy,
    updatedOn,
    status,
    invoiceNumber,
    createdBy,
    createdOn,
    lastUpdateUtcOffset,

    /**
     * Removed / cleared from payload
     */
    qbId,
    syncToken,
    lastSyncDate,
    lastSyncSuccess,
    qboInvoiceNumber,
    invoiceSource,
    updateSource,
    balanceRemaining,
    balanceFulfilled,
    receivedByDelivery,
    returnedByPickup,
    receivedByPickup,
    returnedByDropOff,
    isLoaded,
    isSigned,
    emailDeliveryFailed,
    hasSendRecords,
    salesRepName,
    paymentsQueryHook,
    /**
     *
     */

    ...rest
  } = data;

  return {
    type: INVOICE_DRAFT,
    updatedBy: userData.id,
    updatedOn: new Date(),
    status: null,
    invoiceNumber: orgData.currentInvoiceCount + 1,
    createdBy: userData.id,
    createdOn: new Date(),
    lastUpdateUtcOffset: new Date().getTimezoneOffset(),
    invoiceSource: "adelie",
    updateSource: "adelie",
    ...rest,
  };
};
