import {
  CUSTOMER_BILLING_ADDRESS,
  CUSTOMER_BILLING_CITY,
  CUSTOMER_BILLING_STATE,
  CUSTOMER_BILLING_ZIP,
  CUSTOMER_COMPANY_NAME,
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_FULL_BILLING_ADDRESS_STR,
  CUSTOMER_FULL_SHIPPING_ADDRESS_STR,
  CUSTOMER_SHIP_TO_BILLING,
  CUSTOMER_SHIPPING_ADDRESS,
  CUSTOMER_SHIPPING_CITY,
  CUSTOMER_SHIPPING_STATE,
  CUSTOMER_SHIPPING_ZIP,
  CUSTOMER_TAX_EXEMPT,
  CUSTOMER_TAX_RATE,
  CUSTOMER_TAX_RATE_DETAILS,
} from "../modelConstants/modelConstants";
import { checkUndefined, checkUndefinedCurrency } from "./checkUndefined";
import notificationError from "../../../system-components/toasters/notificationError";

export const checkCustomer = (snap) => {
  const data = snap.data();
  const exists = checkUndefined(data, null);
  if (!exists)
    return notificationError(
      "Record could not be found",
      "Please contact support for assistance"
    );
  return {
    id: snap.id,
    orgId: checkUndefined(data.orgId, ""),
    [CUSTOMER_COMPANY_NAME]: checkUndefined(data[CUSTOMER_COMPANY_NAME], ""),
    [CUSTOMER_BILLING_ADDRESS]: checkUndefined(
      data[CUSTOMER_BILLING_ADDRESS],
      ""
    ),
    [CUSTOMER_BILLING_CITY]: checkUndefined(data[CUSTOMER_BILLING_CITY], ""),
    [CUSTOMER_BILLING_STATE]: checkUndefined(data[CUSTOMER_BILLING_STATE], ""),
    [CUSTOMER_BILLING_ZIP]: checkUndefined(data[CUSTOMER_BILLING_ZIP], ""),
    [CUSTOMER_SHIPPING_ADDRESS]: checkUndefined(
      data[CUSTOMER_SHIPPING_ADDRESS],
      ""
    ),
    [CUSTOMER_SHIPPING_CITY]: checkUndefined(data[CUSTOMER_SHIPPING_CITY], ""),
    [CUSTOMER_SHIPPING_STATE]: checkUndefined(
      data[CUSTOMER_SHIPPING_STATE],
      ""
    ),
    [CUSTOMER_SHIPPING_ZIP]: checkUndefined(data[CUSTOMER_SHIPPING_ZIP], ""),
    [CUSTOMER_TAX_RATE]: checkUndefined(data[CUSTOMER_TAX_RATE], null),
    [CUSTOMER_TAX_RATE_DETAILS]: checkUndefined(
      data[CUSTOMER_TAX_RATE_DETAILS],
      null
    ),
    [CUSTOMER_DISPLAY_NAME]: checkUndefined(data[CUSTOMER_DISPLAY_NAME], ""),
    [CUSTOMER_FULL_BILLING_ADDRESS_STR]: checkUndefined(
      data[CUSTOMER_FULL_BILLING_ADDRESS_STR],
      ""
    ),
    [CUSTOMER_FULL_SHIPPING_ADDRESS_STR]: checkUndefined(
      data[CUSTOMER_FULL_SHIPPING_ADDRESS_STR],
      ""
    ),
    [CUSTOMER_TAX_EXEMPT]: checkUndefined(data[CUSTOMER_TAX_EXEMPT], false),
    [CUSTOMER_SHIP_TO_BILLING]: checkUndefined(
      data[CUSTOMER_SHIP_TO_BILLING],
      false
    ),
    email: checkUndefined(data.email, ""),
    firstName: checkUndefined(data.firstName, ""),
    lastName: checkUndefined(data.lastName, ""),
    middleName: checkUndefined(data.middleName, ""),
    mobile: checkUndefined(data.mobile, ""),
    otherContact: checkUndefined(data.otherContact, ""),
    phone: checkUndefined(data.phone, ""),
    suffix: checkUndefined(data.suffix, ""),
    title: checkUndefined(data.title, ""),
    createdBy: checkUndefined(data.createdBy, ""),
    createdOn: checkUndefined(data.createdOn, null),
    updatedBy: checkUndefined(data.updatedBy, ""),
    updatedOn: checkUndefined(data.updatedOn, null),
    isActive: checkUndefined(data.isActive, true),
    balance: checkUndefinedCurrency(data.balance, 0),
    credit: checkUndefinedCurrency(data.credit, 0),
    stripeAccount: checkUndefined(data.stripeAccount, null),
    additionalContacts: checkUndefined(data.additionalContacts, [])
  };
};
