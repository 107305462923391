import React, { useContext, useEffect, useState } from "react";
import { Button, Calendar, Spin } from "antd";
import { RouteContext } from "../NewRoute";
import {
  useGetInvoicesForRangeIntersectQuery,
  useGetInvoicesForRangeQuery,
  useGetRoutesForMonthQuery,
} from "../../../app/services/api";
import { useIsAuthenticated } from "../../../app/services/hooks/useIsAuthenticated";
import { useFirestore } from "react-redux-firebase";
import moment from "moment";
import { SecondsToDate } from "../../../app/utils/time/secondsToDate";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import {
  COLOR_DARK_BG_1,
  COLOR_GREEN_0,
  COLOR_YELLOW_0,
} from "../../../app/ui/colorConstants";
import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import {
  RECEIVE_METHOD_DELIVERY,
  RETURN_METHOD_ONSITE,
} from "../../../app/utils/models/modelConstants/modelConstants";

const SelectDate = () => {
  const firestore = useFirestore();
  const { fsOrgPrefix, orgData } = useIsAuthenticated();

  const [selectedMonth, setSelectedMonth] = useState({
    start: new moment().startOf("month"),
    end: new moment().endOf("month"),
  });
  const {
    data,
    isFetching: fetchingRoutes,
    refetch: refetchRoutes,
  } = useGetRoutesForMonthQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    monthStart: selectedMonth.start ?? null,
    monthEnd: selectedMonth.end ?? null,
    orgTimezone: orgData?.orgTimezone,
  });
  const {
    data: invoices,
    isFetching: fetchingInvoices,
    refetch: refetchInvoices,
  } = useGetInvoicesForRangeIntersectQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    dateStart: selectedMonth.start ?? null,
    dateEnd: selectedMonth.end ?? null,
    orgTimezone: orgData?.orgTimezone,
    useTurnaround: false,
    invoiceOnly: true,
  });
  const { state, actions } = useContext(RouteContext);

  useEffect(() => {
    refetchRoutes();
    refetchInvoices();
  }, []);

  const onPanelChange = (v) => {
    setSelectedMonth({
      start: new moment(v).startOf("month"),
      end: new moment(v).endOf("month"),
    });
  };
  const dateCellRender = (cell) => {
    const cellStart = new moment(cell).startOf("day");
    const cellEnd = new moment(cell).endOf("day");
    const inDay = data?.filter((route) => {
      const routeDay = new moment(SecondsToDate(route?.routeDate));
      const beforeEnd = cellEnd.isSameOrAfter(routeDay);
      const afterStart = cellStart.isSameOrBefore(routeDay);
      return afterStart && beforeEnd;
    });
    const invoicesInDay = invoices?.filter((invoice) => {
      const isDeliveryOrOnSitePickup =
        invoice?.receiveMethod === RECEIVE_METHOD_DELIVERY ||
        invoice?.returnMethod === RETURN_METHOD_ONSITE;
      const invoiceStart = new moment(SecondsToDate(invoice?.rentalDateStart));
      const invoiceEnd = new moment(SecondsToDate(invoice?.rentalDateEnd));
      const dropOffBeforeEnd = cellEnd.isSameOrAfter(invoiceStart);
      const dropOffAfterStart = cellStart.isSameOrBefore(invoiceStart);
      const pickupBeforeEnd = cellEnd.isSameOrAfter(invoiceEnd);
      const pickupAfterStart = cellStart.isSameOrBefore(invoiceEnd);
      const isDropoff = dropOffBeforeEnd && dropOffAfterStart;
      const isPickup = pickupBeforeEnd && pickupAfterStart;
      return isDeliveryOrOnSitePickup && (isDropoff || isPickup);
    });
    const completed = invoicesInDay?.filter((i) => {
      return inDay?.find((r) => {
        return r?.invoicesQueryHook.includes(i.id);
      });
    });
    const isComplete = completed?.length / invoicesInDay?.length === 1;
    return (
      <div onClick={actions.onSelectDay} style={{ height: "100%" }}>
        {invoicesInDay?.length !== 0 && !fetchingRoutes && !fetchingInvoices && (
          <div>
            Orders Routed:{" "}
            <span
              style={{
                fontWeight: "bold",
                color: isComplete ? COLOR_GREEN_0 : COLOR_YELLOW_0,
              }}
            >
              {completed?.length}/{invoicesInDay?.length}
            </span>
          </div>
        )}
        {inDay?.map((route) => {
          return (
            <div>
              {route?.isComplete ? (
                <CheckCircleFilled
                  style={{ color: COLOR_GREEN_0, marginRight: "4px" }}
                />
              ) : (
                <WarningFilled
                  style={{ color: COLOR_YELLOW_0, marginRight: "4px" }}
                />
              )}
              <TextSubtitle text={route?.name} />
              <br />
            </div>
          );
        })}
      </div>
    );
    const dayStr = cell.format("MMM Do YYYY");
    if (dayStr === state.date?.string) {
      return (
        <Button block type={"primary"} onClick={actions.onSelectDay}>
          Select
        </Button>
      );
    }
  };
  const monthCellRender = (m) => {};
  const onSelect = (v) => {
    actions.setDate({
      string: v.format("MMM Do YYYY"),
      moment: v,
    });
  };

  return (
    <div>
      <Spin spinning={fetchingRoutes || fetchingInvoices}>
        <Calendar
          style={{ padding: "12px" }}
          onSelect={onSelect}
          onPanelChange={onPanelChange}
          dateCellRender={dateCellRender}
          monthCellRender={monthCellRender}
        />
      </Spin>
    </div>
  );
};

export default SelectDate;
