import React from "react";
import { Row, Button, Tooltip } from "antd";
import { PrintInvoiceBOMComponent } from "../InvoicePdfViewer";
import {
  DownloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Invoice } from "../../../../app/interfaces/invoice";

export const InvoiceActions = ({
  downloadingPDF,
  saveFile,
  currentInvoice,
  orgData,
  syncingInvoice,
  syncClicked,
}: {
  downloadingPDF: boolean;
  saveFile: () => Promise<void>;
  currentInvoice: Invoice;
  orgData: any;
  syncingInvoice: boolean;
  syncClicked:  (item: any) => void;
}) => {
  return (
    <Row justify="start" style={{ marginBottom: "12px" }}>
      <div style={{ paddingRight: "8px" }}>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          loading={downloadingPDF}
          onClick={saveFile}
        >
          Download Invoice
        </Button>
      </div>
      <PrintInvoiceBOMComponent invoice={currentInvoice} />
      {/* check if connected to quickbooks and whether or not invoice needs to be synced based on presense of qbId */}
      {orgData.qboRealmId !== "" &&
        orgData.qboRealmId !== null &&
        currentInvoice.type === "invoice" && (
          <div style={{ paddingLeft: "8px" }}>
            <Tooltip title="Use this to manually sync invoices, payments and refunds to QuickBooks online if you notice that any of these did not sync automatically when you initially saved them in Adelie.">
              <Button
                type="primary"
                icon={<SyncOutlined />}
                loading={syncingInvoice}
                onClick={syncClicked}
                style={
                  !currentInvoice.qbId || currentInvoice.qbId === ""
                    ? {
                        background: "white",
                        border: "1px solid red",
                        color: "black",
                      }
                    : {
                        background: "white",
                        border: "1px solid green",
                        color: "black",
                      }
                }
              >
                Sync
              </Button>
            </Tooltip>
          </div>
        )}
    </Row>
  );
};
