import React, { createContext } from "react";
import { useRoute } from "../../app/services/hooks/useRoute";
import SelectDate from "./calendar/SelectDate";
import CreateRoutes from "./map/CreateRoutes";
import RouteBuilderHeader from "./components/RouteBuilderHeader";
import RouteAssignments from "./assignment/RouteAssignments";
import { Layout } from "antd";

export const RouteContext = createContext();
const { Content } = Layout;
const NewRoute = () => {
  const RouteBuilderProps = useRoute({
    isNew: true,
  });

  return (
    <RouteContext.Provider value={RouteBuilderProps}>
      <Content>
        <RouteBuilderHeader />
        {RouteBuilderProps.state.step === 0 && <SelectDate />}
        {RouteBuilderProps.state.step === 1 && <CreateRoutes />}
        {RouteBuilderProps.state.step === 2 && (
          <RouteAssignments
            date={RouteBuilderProps.state?.date?.moment ?? null}
          />
        )}
      </Content>
    </RouteContext.Provider>
  );
};

export default NewRoute;
