import { Button, Col, Row, Space } from "antd";
import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { SortableHandle } from "react-sortable-hoc";
import StopCellAssigned from "./StopCellAssigned";
import { COLOR_ORANGE_HIGHLIGHT } from "../../../../../app/ui/colorConstants";

export const routeColumns = ({ actions, state }) => {
  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));
  return [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: (item, record) => {
        const isSelected =
          record?.invoiceId === state.selectedStop?.invoiceId &&
          record?.type === state.selectedStop?.stop?.type;
        return {
          props: {
            style: { background: isSelected ? COLOR_ORANGE_HIGHLIGHT : "#FFF" },
          },
          children: <DragHandle />,
        };
      },
    },
    {
      title: "Customer",
      dataIndex: "displayName",
      render: (item, record) => {
        const isSelected =
          record?.invoiceId === state.selectedStop?.invoiceId &&
          record?.type === state.selectedStop?.stop?.type;
        return {
          props: {
            style: { background: isSelected ? COLOR_ORANGE_HIGHLIGHT : "#FFF" },
          },
          children: (
            <Row
              direction={"horizontal"}
              style={{ width: "100%" }}
              wrap={false}
              gutter={8}
              align={"middle"}
            >
              <Col flex={"none"}>
                <TextSubtitle text={`${record?.index + 1}`} />
              </Col>
              <Col flex={"auto"}>
                <StopCellAssigned
                  stop={record}
                  currentRouteId={state.currentRouteId}
                />
              </Col>
            </Row>
          ),
        };
      },
    },

    {
      title: "",
      dataIndex: "",
      width: "50px",
      render: (item, record) => {
        const isSelected =
          record?.invoiceId === state.selectedStop?.invoiceId &&
          record?.type === state.selectedStop?.stop?.type;
        return {
          props: {
            style: { background: isSelected ? COLOR_ORANGE_HIGHLIGHT : "#FFF" },
          },
          children: (
            <Button
              onClick={() => {
                actions.removeStop({
                  stop: record,
                  routeId: state.currentRouteId,
                });
              }}
              size={"small"}
              style={{
                borderColor: "transparent",
              }}
            >
              <CloseOutlined />
            </Button>
          ),
        };
      },
    },
  ];
};
