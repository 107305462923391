import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Row,
  Col,
  Spin,
  Space,
  Card,
  Tag,
  Tabs
} from "antd";
import TabPane from "antd/lib/tabs/TabPane";

import { Spinner } from "../../../app/ui/spinner";
import moment from "moment";
import NewItemDetails from "./InventoryItemForm/NewItemDetails";
import NewItemAdditional from "./InventoryItemForm/NewItemAdditional";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { useFirestore } from "react-redux-firebase";
import { configureInventory } from "../../../app/utils/models/configure/configureInventory";
import { inventoryWrites } from "../../../app/services/firestore/writes/inventoryWrites";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import convertTimeToString from "../../../app/utils/time/convertTimeToString";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import {
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_ID,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
  INV_BUNDLE_ITEM_TURNAROUND,
  INV_ITEM_EMBEDDED_SHOP_RATE_ID,
  INV_ITEM_SHOW_IN_SHOP,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { checkUndefinedOrNull } from "../../../app/utils/models/checkers/checkUndefined";
import { useInventoryManager } from "../../../app/services/hooks/useInventoryManager";
import {
  InventoryItem,
  InventoryItemFormData,
} from "../../../app/interfaces/inventoryItem";
import { InventoryCategory } from "../../../app/interfaces/inventoryCategory";
import MaintenanceDetails from "./InventoryItemForm/MaintenanceDetails";
import MaintenanceHistory from "./InventoryItemForm/MaintenanceHistory";
import { useFirestoreFetch } from "../../../app/services/hooks/fetch/useFirestoreFetch";
import { CheckMaintenanceRecord, MaintenanceQueries } from "../../../app/services/firestore/queries/maintenanceRecordQueries";
import { INVENTORY_COLLECTION } from "../../../app/utils/models/collections/collectionConstants";
import AddMaintenanceRecord from "./InventoryItemForm/AddMaintenanceRecord";

const ItemDrawer: FC<{
  visible: boolean;
  handleCloseDrawer: Dispatch<
    SetStateAction<{
      visible: boolean;
      item: InventoryItem | null;
    }>
  >;
  item: {
    visible: boolean;
    item: InventoryItem | null;
  };
  categories: InventoryCategory[];
  onComplete: () => void;
  drawerView: any;
}> = ({
  visible,
  handleCloseDrawer,
  item,
  categories,
  onComplete,
  drawerView,
}) => {

    const firestore = useFirestore();

    const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
    const [records, setRecords] = useState([]);
    const [fetchingMaintenanceRecords, setFetchingMaintenanceRecords] = useState(false);
    const [maintenanceRecords, setMaintenanceRecords] = useState<any[]>([]);
    const [showMaintenanceDetails, setShowMaintenanceDetails] = useState(false);


    const [form] = Form.useForm();
    const [tabType, setTabType] = useState('general');  // general , maintenance, history

    const toggleMaintenanceSwitch = (e: boolean) => {
      console.log(e)
      setShowMaintenanceDetails(e);
    };

    useEffect(() => {
      const fetchData = async () => {
        if (item && orgData) {
          setFetchingMaintenanceRecords(true);
          const unsubscribe = firestore
            .collection('orgs')
            .doc(`${orgData.id}`)
            .collection('inventory')
            .doc(item.item?.id)
            .collection("maintenanceRecords")
            .orderBy("servicedOn", "desc")
            .onSnapshot((snapshot) => {

              let data: any[] = [];
              snapshot.docs.forEach((s) => {
                data.push(CheckMaintenanceRecord(s, orgData.orgTimezone));
              });

              setMaintenanceRecords(data);
              setFetchingMaintenanceRecords(false);
            });

          return () => {
            unsubscribe();
          };
        }
      };

      fetchData();
    }, [firestore, item, orgData]);

    const [saving, setSaving] = useState(false);
    const [itemImg, setItemImg] = useState<{
      downloadURL: string;
      fileName: string;
    } | null>(null);
    const [storedBundleSelections, setStoredBundleSelections] = useState<
      InventoryItem[]
    >([]);
    const { disableEdit } = useInventoryManager(item && item.item);



    const loadStoredBundledSelections = (items: any[]) => {
      if (!items) return [];
      let storedSelections: any[] = [];
      let prevSelections: any[] = [];
      items.map((i) => {
        storedSelections.push({
          name: i[INV_BUNDLE_ITEM_NAME],
          id: i[INV_BUNDLE_ITEM_ID],
          description: i[INV_BUNDLE_ITEM_DESC],
          turnaround: i[INV_BUNDLE_ITEM_TURNAROUND],
        });
        prevSelections.push({
          [INV_BUNDLE_ITEM_NAME]: i[INV_BUNDLE_ITEM_NAME],
          [INV_BUNDLE_ITEM_ID]: i[INV_BUNDLE_ITEM_ID],
          [INV_BUNDLE_ITEM_DESC]: i[INV_BUNDLE_ITEM_DESC],
          [INV_BUNDLE_ITEM_QTY]: i[INV_BUNDLE_ITEM_QTY],
          [INV_BUNDLE_ITEM_TURNAROUND]: i[INV_BUNDLE_ITEM_TURNAROUND],
        });
      });
      setStoredBundleSelections(storedSelections);
      return prevSelections;
    };

    useEffect(() => {
      const invItem = item.item;

      if (invItem) {

        setShowMaintenanceDetails(invItem.hasMaintenanceDetails)

        const savedInitialDate =
          invItem.depreciation && invItem.depreciation.initialDate
            ? invItem.depreciation.initialDate.toDate()
            : null;
        form.setFieldsValue({
          name: invItem.name,
          type: invItem.type,
          description: invItem.description,
          categoriesQueryHook: invItem.categoriesQueryHook,
          rates: invItem.rates,
          stock: invItem.stock,
          turnaround: invItem.turnaround,
          initialValue:
            invItem.depreciation && invItem.depreciation.initialValue
              ? invItem.depreciation.initialValue
              : null,
          depreciationYears:
            invItem.depreciation && invItem.depreciation.depreciationYears
              ? invItem.depreciation.depreciationYears
              : null,
          initialDate: savedInitialDate ? moment(savedInitialDate) : null,
          notes: invItem.notes,
          isAvailable: invItem.isAvailable,
          isActive: invItem.isActive,
          hasMaintenanceDetails: invItem.hasMaintenanceDetails ? invItem.hasMaintenanceDetails : false,
          activeHours: invItem.maintenanceDetails ? invItem.maintenanceDetails.activeHours : 0,
          hoursBetweenServices: invItem.maintenanceDetails ? invItem.maintenanceDetails.hoursBetweenServices : 0,
          imageUploader: invItem.image
            ? [
              {
                name: invItem.image.fileName,
                thumbUrl: invItem.image.downloadURL,
                url: invItem.image.downloadURL,
              },
            ]
            : null,
          bundleItems: loadStoredBundledSelections(invItem.bundleItems),
          [INV_ITEM_SHOW_IN_SHOP]: !!invItem.showInShop,
          [INV_ITEM_EMBEDDED_SHOP_RATE_ID]: invItem.embeddedShopRateId,
        });
        if (invItem.image) {
          setItemImg({
            downloadURL: invItem.image?.downloadURL,
            fileName: invItem.image.fileName,
          });
        }
      }
      return () => setItemImg(null);
    }, [item]);

    const handleFinish = () => {
      form.validateFields().then(async () => {
        setSaving(true);
        const formValues = form.getFieldsValue();

        if (tabType === 'maintenance') {
          let maintenanceDetails = null;

          maintenanceDetails = {
            activeHours: formValues.activeHours ? formValues.activeHours : 0,
            hoursBetweenServices: formValues.hoursBetweenServices ? formValues.hoursBetweenServices : 0,
            timeBetweenServices: null,
            maintenanceNotes: null,
          }

          await firestore
            .collection(`${fsOrgPrefix}${INVENTORY_COLLECTION}`)
            .doc(item.item?.id)
            .update({
              maintenanceDetails
            })

          setSaving(false);


        } else {

          const {
            imageUploader,
            categoriesQueryHook,
            initialDate,
            bundleItems,
            ...rest
          } = formValues;

          const selectedCategories =
            categoriesQueryHook &&
            categoriesQueryHook.map((h: string) => {
              const cat = categories && categories.find((c) => c.id === h);

              const { id, orgId, name, description } = cat!;
              return {
                id: id,
                orgId: orgId,
                name: name,
                description: description,
              };
            });

          const configureBundles = () => {
            let items: any[] = [];
            let bundleItemsQueryHook: string[] = [];
            if (bundleItems) {
              bundleItems.map((i: any) => {
                const invItem = storedBundleSelections.find(
                  (s) => s.id === i[INV_BUNDLE_ITEM_ID]
                );
                const { name, id, description, turnaround } = invItem!;
                bundleItemsQueryHook.push(id);
                items.push({
                  [INV_BUNDLE_ITEM_ID]: id,
                  [INV_BUNDLE_ITEM_DESC]: description,
                  [INV_BUNDLE_ITEM_NAME]: name,
                  [INV_BUNDLE_ITEM_QTY]: i[INV_BUNDLE_ITEM_QTY],
                  [INV_BUNDLE_ITEM_TURNAROUND]: checkUndefinedOrNull(turnaround, 0),
                });
              });
            }
            return {
              bundleItems: items,
              bundleItemsQueryHook: bundleItemsQueryHook,
            };
          };

          const values: InventoryItemFormData = {
            categories: selectedCategories, // add categories
            categoriesQueryHook: categoriesQueryHook,
            image: itemImg,
            initialDate: initialDate ? initialDate.toDate() : null,
            bundleItems: configureBundles().bundleItems,
            bundleItemsQueryHook: configureBundles().bundleItemsQueryHook,
            embeddedShopRateId: form.getFieldValue(INV_ITEM_EMBEDDED_SHOP_RATE_ID),
            ...rest,
          };
          const payload = configureInventory().editInventoryItem(
            values,
            userData.id,
            orgData
          );

          console.log('payload', payload)

          const writer = inventoryWrites({ firestore }, fsOrgPrefix);
          if (writer && item.item && payload) {
            writer
              .updateInventory(item.item.id, payload)
              .then(() => {
                setSaving(false);
                notificationConfirm("Inventory item updated");
                form.resetFields();
                onComplete();
              })
              .catch((err) => {
                console.log(err);
                setSaving(false);
                notificationError("Something went wrong", "Please try again later");
              });
          }
        }
      });
    };

    const onImageUrl = (downloadURL: string, fileName: string) => {
      setItemImg({
        downloadURL: downloadURL,
        fileName: fileName,
      });
    };
    const onClose = () => {
      handleCloseDrawer({
        visible: false,
        item: null,
      });
      setItemImg(null);
      setTabType("general")
      form.resetFields();
    };

    const configureBodyStyle = (item: {
      item: InventoryItem | null;
      visible: boolean;
    }) => {
      const padding = { padding: "12px" };
      if (!item.item) return padding;
      return disableEdit
        ? { pointerEvents: "none", opacity: ".5", ...padding }
        : {};
    };

    return (
      <Drawer
        className="item-drawer"
        bodyStyle={configureBodyStyle(item)}
        destroyOnClose
        width={650}
        title={
          <Row wrap={false} style={{ width: "100%" }} align="middle">
            <Col flex={"auto"}>
              {item.item && item.item.name}
              {disableEdit && (
                <Tag color="volcano">
                  Bundle item must be edited in Quickbooks
                </Tag>
              )}
            </Col>
            {
              tabType === 'general' && (
                <Col flex={"145px"} style={{ textAlign: "right" }}>
                  <Space>
                    <Button onClick={onClose} disabled={saving}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      disabled={saving || disableEdit}
                      loading={saving}
                      onClick={handleFinish}
                    >
                      Save
                    </Button>
              </Space>
            </Col>
              )
            }
            
          </Row>
        }
        placement={"right"}
        closable={false}
        onClose={onClose}
        open={visible}
        key={"item"}
      >
        <>
          <div style={{ width: "100%" }}>
            <Tabs
              defaultActiveKey="general"
              activeKey={tabType}
              onChange={setTabType}
            >
              <TabPane tab="General" key="general" />
              {
                orgData && orgData.allowInventoryMaintenance && showMaintenanceDetails && (
                  <>
                    <TabPane tab="Maintenance" key="maintenance" />
                    <TabPane tab={`History (${maintenanceRecords && maintenanceRecords.length})`} key="history" />
                  </>
                )
              }
            </Tabs>
          </div>
        </>

        {tabType === "general" && (

          <Spin spinning={saving} indicator={Spinner}>
            <Form
              form={form}
              onFinish={handleFinish}
              layout="vertical"
              requiredMark={false}
            >
              {item.item && (
                <Card
                  size="small"
                  title={
                    <TextBody style={{ fontWeight: "bold" }} text={"General"} />
                  }
                  bordered={!drawerView}
                >
                  <NewItemDetails
                    form={form}
                    categories={categories}
                    onImageUrl={onImageUrl}
                    removeImg={() => setItemImg(null)}
                    defaultImg={itemImg}
                    storedSelections={storedBundleSelections}
                    setStoredSelections={setStoredBundleSelections}
                    duplicated={false}
                    saving={false}
                  />
                </Card>
              )}
              <br />
              {orgData && item.item && (
                <NewItemAdditional
                  drawerView
                  form={form}
                  item={item.item && item.item}
                  isEdit
                  visible={visible}
                  showMaintenanceSwitch={orgData.allowInventoryMaintenance}
                  showMaintenanceSwitchToggled={toggleMaintenanceSwitch}
                />
              )}

              {
                orgData && orgData.allowInventoryMaintenance && showMaintenanceDetails && item.item && (
                  <MaintenanceDetails
                    drawerView
                    form={form}
                    item={item.item && item.item}
                    isEdit
                    visible={visible}
                  />
                )
              }
            </Form>
            <Divider style={{ margin: "16px 0px" }} />
            {item.item && (
              <div>
                <TextSubtitle
                  text={`Last updated: ${convertTimeToString(item.item.updatedOn)}`}
                />
                <br />
                <TextSubtitle
                  text={`Created: ${convertTimeToString(item.item.createdOn)}`}
                />
              </div>
            )}
          </Spin>
        )}

        {tabType === "maintenance" && (
          <>
            {item.item && (
              <AddMaintenanceRecord item={item.item} />
            )}
            <Divider style={{ margin: "16px 0px" }} />
          </>
        )}

        {tabType === "history" && (
          <Spin spinning={saving} indicator={Spinner}>

            {item.item && (
              <MaintenanceHistory data={maintenanceRecords} fetching={fetchingMaintenanceRecords} item={item.item} />
            )}
            <Divider style={{ margin: "16px 0px" }} />

          </Spin>
        )}

      </Drawer>
    );
  };

export default ItemDrawer;
