import { ExtendedFirestoreInstance } from "react-redux-firebase";
import notificationError from "../../../system-components/toasters/notificationError";
import { INVENTORY_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { InventoryItem } from "../../../interfaces/inventoryItem";

export function inventoryWrites(
  { firestore }: { firestore: ExtendedFirestoreInstance },
  orgPrefix: string
) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${INVENTORY_COLLECTION}`);

  async function createInventory(payload: Partial<InventoryItem>) {
    const newRef = firestore
      .collection(`${orgPrefix}${INVENTORY_COLLECTION}`)
      .doc();
    return await newRef
      .set({
        id: newRef.id,
        ...payload,
      })
      .then(() => {
        return newRef.id;
      });
  }
  async function updateInventory(id: string, payload: Partial<InventoryItem>) {
    return await ref.doc(id).update(payload);
  }
  async function deleteInventory(id: string) {
    return await ref.doc(id).delete();
  }
  return {
    createInventory,
    updateInventory,
    deleteInventory,
  };
}
