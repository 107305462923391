import React, { useEffect, useState } from "react";
import { Affix, Button, Card, Form, Modal, PageHeader, Spin } from "antd";
import AddInventory from "../../admin-inventory/components/AddInventory";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { useFirestore } from "react-redux-firebase";
import { categoryQueries } from "../../../app/services/firestore/queries/categoryQueries";
import { sortBy } from "../../../app/utils/sorters/sortBy";
import {
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_ID,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { configureInventory } from "../../../app/utils/models/configure/configureInventory";
import { inventoryWrites } from "../../../app/services/firestore/writes/inventoryWrites";
import notificationConfirm from "../../../app/system-components/toasters/notificationConfirm";
import notificationError from "../../../app/system-components/toasters/notificationError";
import { Spinner } from "../../../app/ui/spinner";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import NewItemDetails from "../../admin-inventory/components/InventoryItemForm/NewItemDetails";
import NewItemAdditional from "../../admin-inventory/components/InventoryItemForm/NewItemAdditional";

const CreateNewItemModal = ({
  setToggleNewInvModal,
  toggleNewInvModal,
  onComplete,
  loading,
}) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [saving, setSaving] = useState(false);
  const [itemImg, setItemImg] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [duplicated, setDuplicated] = useState(false);
  const [storedBundleSelections, setStoredBundleSelections] = useState([]);

  const [form] = Form.useForm();
  const firestore = useFirestore();

  useEffect(() => {
    fsOrgPrefix && loadCategories();
  }, [fsOrgPrefix]);

  useEffect(() => {
    form && form.setFieldsValue({ name: toggleNewInvModal.name });
  }, [toggleNewInvModal.name]);

  const loadCategories = async () => {
    setLoadingCategories(true);
    const cats = await categoryQueries(
      { firestore },
      fsOrgPrefix
    ).fetchCategories();
    setCategories(sortBy(cats, "name"));
    setLoadingCategories(false);
  };

  const resetValues = () => {
    setStoredBundleSelections([]);
    form.setFieldsValue({ rates: [] });
    form.resetFields();
    setItemImg(null);
  };

  const handleFinish = ({ isDuplicate }) => {
    console.log(form.getFieldsValue());
    form.validateFields().then(() => {
      setSaving(true);
      const formValues = form.getFieldsValue();
      const {
        imageUploader,
        categoriesQueryHook,
        initialDate,
        bundleItems,
        ...rest
      } = formValues;

      // get other category data to save
      const selectedCategories =
        categoriesQueryHook &&
        categoriesQueryHook.map((h) => {
          const cat = categories && categories.find((c) => c.id === h);
          const { id, orgId, name, description } = cat;
          return {
            id: id,
            orgId: orgId,
            name: name,
            description: description,
          };
        });
      // map storedBundleSelections
      const configureBundles = () => {
        let items = [];
        let bundleItemsQueryHook = [];
        if (bundleItems) {
          bundleItems.map((i) => {
            const invItem = storedBundleSelections.find(
              (s) => s.id === i[INV_BUNDLE_ITEM_ID]
            );
            const { name, id, description } = invItem;
            bundleItemsQueryHook.push(id);
            items.push({
              [INV_BUNDLE_ITEM_ID]: id,
              [INV_BUNDLE_ITEM_DESC]: description,
              [INV_BUNDLE_ITEM_NAME]: name,
              [INV_BUNDLE_ITEM_QTY]: i[INV_BUNDLE_ITEM_QTY],
            });
          });
        }
        return {
          bundleItems: items,
          bundleItemsQueryHook: bundleItemsQueryHook,
        };
      };

      const values = {
        categories: selectedCategories, // add categories
        categoriesQueryHook: categoriesQueryHook,
        image: itemImg,
        initialDate: initialDate ? initialDate.toDate() : null,
        bundleItems: configureBundles().bundleItems,
        bundleItemsQueryHook: configureBundles().bundleItemsQueryHook,
        ...rest,
      };

      const payload = configureInventory().newInventoryItem(
        values,
        userData.id,
        orgData
      );
      inventoryWrites({ firestore }, fsOrgPrefix)
        .createInventory(payload)
        .then((invId) => {
          setSaving(false);
          notificationConfirm("Inventory item created");
          if (isDuplicate) {
            form.setFieldsValue({
              name: `${payload.name} Copy`,
            });
          } else {
            resetValues();
            onComplete(invId);
          }
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
          notificationError("Something went wrong", "Please try again later");
        });
    });
  };

  const onImageUrl = (downloadURL, fileName) => {
    setItemImg({
      downloadURL: downloadURL,
      fileName: fileName,
    });
  };
  return (
    <Modal
      width={848}
      title="Create new item"
      visible={toggleNewInvModal.visible}
      confirmLoading={saving || loading}
      onOk={() => {
        handleFinish({ isDuplicate: false });
      }}
      onCancel={() => {
        resetValues();

        setToggleNewInvModal({
          visible: false,
          name: "",
          key: null,
        });
      }}
    >
      <div>
        <div style={{ margin: "0" }}>
          <Spin spinning={saving || loadingCategories} indicator={Spinner}>
            <Form form={form} layout="vertical" requiredMark={false}>
              <div className="add-inventory" style={{ width: "800px" }}>
                <Card
                  size="small"
                  title={
                    <TextBody style={{ fontWeight: "bold" }} text={"General"} />
                  }
                >
                  <NewItemDetails
                    categories={categories}
                    form={form}
                    duplicated={duplicated}
                    storedSelections={storedBundleSelections}
                    setStoredSelections={setStoredBundleSelections}
                    onImageUrl={onImageUrl}
                    removeImg={() => setItemImg(null)}
                    defaultImg={itemImg}
                    saving={saving}
                  />
                </Card>
                <br />
                <NewItemAdditional form={form} />
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    </Modal>
  );
};

export default CreateNewItemModal;
