import { useEffect, useState } from "react";
import moment from "moment";
import { invoices } from "../../../app/services/firestore/queries/invoiceQueries";
import { useIsAuthenticated } from "../../../app/services/hooks/useIsAuthenticated";
import { useFirestore } from "react-redux-firebase";
import Nifty from "../../../app/utils/Nifty";
import { TimeMachine } from "../../../app/utils/time/timeMachine";

export function useInvoicesFilter({
  initialFilters,
  initialSearch,
  callbackFn,
}) {
  // populate initial values from redux
  const { initialFilterValues, initialSearchValues } = loadInitialValues({
    initialFilters,
    initialSearch,
  });

  // hooks
  const { orgData, fsOrgPrefix } = useIsAuthenticated();
  const firestore = useFirestore();

  // state
  const [fetchedInvoices, setFetchedInvoices] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [lastFetchedRange, setLastFetchedRange] = useState(null);
  const [searchValue, setSearchValue] = useState(initialSearchValues);
  const [isActive, setIsActive] = useState(false);
  const [filters, setFilters] = useState(initialFilterValues);
  const [showModal, setShowModal] = useState(false);
  /**
   * Actions
   * ***
   * **
   * *
   */
  useEffect(() => {
    // filter when search value changes
    const currInvoices = filterInvoices(filters, fetchedInvoices, searchValue);
    setInvoiceData(filterOnSearchValues(currInvoices, searchValue));
    callbackFn &&
      callbackFn({
        ...filters,
        searchValue,
      });
  }, [searchValue]);

  useEffect(async () => {
    // configure active state
    setIsActive(!filterIsDefault(filters));
    if (fetching || !orgData) return;
    // determine if refetch is needed
    const rangeIsQualified = refreshRange(filters.range, lastFetchedRange);
    if (rangeIsQualified) {
      // run filter func
      setInvoiceData(filterInvoices(filters, fetchedInvoices, searchValue));
      callbackFn &&
        callbackFn({
          ...filters,
          searchValue,
        });
    } else {
      // fetch then run filter func
      const data = await fetchInvoicesInRange();
      setInvoiceData(filterInvoices(filters, data, searchValue));
      callbackFn &&
        callbackFn({
          ...filters,
          searchValue,
        });
      // save last fetched range
      setLastFetchedRange(filters.range);
    }
  }, [filters, fetchedInvoices, orgData]);

  const fetchInvoicesInRange = async () => {
    setFetching(true);
    const data = await invoices(orgData?.orgTimezone).fetchInvoicesInRange(
      { firestore },
      fsOrgPrefix,
      {
        rentalDateStart: filters.range.startDate ?? null,
        rentalDateEnd: filters?.range?.endDate ?? null,
        useTurnaround: false,
      }
    );
    setFetching(false);
    setLastFetchedRange({
      ...filters.range,
    });
    setFetchedInvoices(data);
    return data;
  };

  // change range
  const onRangeChanged = (range) => {
    const start = range?.[0] ? moment(range[0])?.startOf("day") : null;
    const end = range?.[1] ? moment(range[1])?.endOf("day") : null;
    if (start === null || end === null)
      return setFilters({
        ...filters,
        range: {
          id: "today",
          startDate: moment().startOf("day").toDate(),
          endDate: moment().endOf("day").toDate(),
        },
      });
    const r = btnRanges.find((i) => {
      const equalStart = start.isSame(moment(i.startDate));
      const equalEnd = end.isSame(moment(i.endDate));
      return equalStart && equalEnd;
    });
    setFilters({
      ...filters,
      range: {
        id: r?.id ?? "custom",
        startDate: start.toDate(),
        endDate: end.toDate(),
      },
    });
  };

  const onButtonRangeChanged = (rangeId) => {
    const r = Nifty.FindObject(btnRanges, "id", rangeId);
    setFilters({
      ...filters,
      range: {
        id: rangeId,
        startDate: r.startDate,
        endDate: r.endDate,
      },
    });
  };

  // clear filters
  const resetFilters = () => {
    // remove filters
    setFilters({
      range: Nifty.FindObject(btnRanges, "id", "default"),
      statuses: defaultStatuses,
      types: defaultTypes,
    });
    setIsActive(false);
  };

  const onStatusChanged = (statuses) => {
    // status toggle changed
    setFilters({
      ...filters,
      statuses,
    });
  };

  const configureRangeText = (filters) => {
    if (!filters || !filters?.range?.startDate || !filters?.range?.endDate)
      return "";
    return `${moment(filters?.range?.startDate).format(
      "MMM Do YYYY"
    )} - ${moment(filters?.range?.endDate).format("MMM Do YYYY")}`;
  };

  const onTypesChanged = (types) => {
    // types changed
    setFilters({
      ...filters,
      types,
    });
  };

  /**
   * Export state and actions
   * ***
   * **
   * *
   */
  return {
    state: {
      filters,
      fetchedInvoices,
      invoiceData,
      isActive,
      searchValue,
      fetching,
      showModal,
      rangeText: configureRangeText(filters),
    },
    actions: {
      onRangeChanged,
      onButtonRangeChanged,
      onStatusChanged,
      onTypesChanged,
      setSearchValue,
      setShowModal,
      resetFilters,
      fetchInvoicesInRange,
    },
  };
}

/**
 * Helpers (Functions, defaults etc)
 * ***
 * **
 * *
 */

const refreshRange = (newRange, lastFetchedRange) => {
  if (!lastFetchedRange) return false;
  return (
    startDateIsAfterPrevious(newRange.startDate, lastFetchedRange.startDate) &&
    endDateIsBeforePrevious(newRange.endDate, lastFetchedRange.endDate)
  );
};

const filterOnSearchValues = (invoices, searchValue) => {
  const lcValue = `${searchValue?.toLowerCase()}`;
  // filter invoices based on customer, invoice number,email
  return invoices?.filter((i) => {
    const customerNameMatch = `${i?.customer?.customerDisplayName}`
      ?.toLowerCase()
      ?.includes(searchValue);
    const emailMatch = `${i?.customer?.email}`
      ?.toLowerCase()
      ?.includes(searchValue);
    const numberMatch = `${i?.invoiceNumber}`?.includes(searchValue);
    const resArray = [customerNameMatch, emailMatch, numberMatch];
    return resArray.includes(true);
  });
};

const btnRanges = [
  {
    id: "default",
    startDate: moment().startOf("day").subtract(2, "months").toDate(),
    endDate: moment().add(12, "month").toDate(),
  },
  {
    id: "today",
    startDate: moment().startOf("day").toDate(),
    endDate: moment().endOf("day").toDate(),
  },
  {
    id: "tomorrow",
    startDate: moment().add(1, "day").startOf("day").toDate(),
    endDate: moment().add(1, "day").endOf("day").toDate(),
  },
  {
    id: "yesterday",

    startDate: moment().subtract(1, "day").startOf("day").toDate(),
    endDate: moment().subtract(1, "day").endOf("day").toDate(),
  },
  {
    id: "currentWeek",
    startDate: moment().startOf("week").toDate(),
    endDate: moment().endOf("week").toDate(),
  },
  {
    id: "nextWeek",
    startDate: moment().add(1, "week").startOf("week").toDate(),
    endDate: moment().add(1, "week").endOf("week").toDate(),
  },
  {
    id: "currentMonth",
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate(),
  },
  {
    id: "nextMonth",
    startDate: moment().add(1, "month").startOf("month").toDate(),
    endDate: moment().add(1, "month").endOf("month").toDate(),
  },
];

const filterInvoices = (filters, invoices, searchValue) => {
  // map through invoices
  const rangeStart = {
    key: "rentalDateStart",
    condition: ">=",
    value: TimeMachine(filters?.range?.startDate).firestoreDate,
  };
  const rangeEnd = {
    key: "rentalDateStart",
    condition: "<=",
    value: TimeMachine(filters?.range?.endDate).firestoreDate,
  };
  const invoicesInRange = Nifty.FindObjectsWithMatchingConditions(
    invoices,
    [rangeStart, rangeEnd],
    "and"
  );
  const filteredRange = filterStatuses(invoicesInRange, filters);
  return filterOnSearchValues(filteredRange, searchValue);
};

const startDateIsAfterPrevious = (newStart, oldStart) => {
  // determines if the new start date is after previous one
  return moment(newStart).isSameOrAfter(moment(oldStart));
};

const endDateIsBeforePrevious = (newEnd, oldEnd) => {
  // determines if the end date is before previous one
  return moment(newEnd).isSameOrBefore(moment(oldEnd));
};

const filterStatuses = (invoices, filters) => {
  let statuses = [];
  let typeFilter = [];
  const statusConditions = [
    {
      name: "hasEstimateSignatures",
      key: "estimateSignatures",
      condition: "array-length-greater-than",
      value: 0,
    },
    {
      name: "hasPaymentSignatures",
      key: "paymentSignatures",
      condition: "array-length-greater-than",
      value: 0,
    },
    {
      name: "outstanding",
      key: "balanceRemaining",
      condition: ">",
      value: 0,
    },
    {
      name: "complete",
      key: "balanceRemaining",
      condition: "<=",
      value: 0,
    },
  ];

  const types = [
    {
      name: "invoice",
      key: "type",
      condition: "==",
      value: "invoice",
    },
    {
      name: "estimate",
      key: "type",
      condition: "==",
      value: "estimate",
    },
    {
      name: "draft",
      key: "type",
      condition: "==",
      value: "draft",
    },
  ];

  filters.statuses.map((f) => {
    const c = Nifty.FindObject(statusConditions, "name", f);
    c && statuses.push(c);
  });
  filters.types.map((t) => {
    const type = Nifty.FindObject(types, "name", t);
    type && typeFilter.push(type);
  });
  const statusMatch = Nifty.FindObjectsWithMatchingConditions(
    invoices,
    statuses
  );
  return Nifty.FindObjectsWithMatchingConditions(statusMatch, typeFilter);
};

const filterIsDefault = (filters) => {
  const rangeIsDefault = filters.range?.id === "default";
  const statusIsDefault = statusDefault(filters);
  const typesIsDefault = typesDefault(filters);
  return rangeIsDefault && statusIsDefault && typesIsDefault;
};

const statusDefault = (filters) => {
  let isDefault = [];
  defaultStatuses.map((s) => {
    isDefault.push(filters.statuses.includes(s));
  });
  return !isDefault.includes(false);
};
const typesDefault = (filters) => {
  let isDefault = [];
  defaultTypes.map((s) => {
    isDefault.push(filters.types.includes(s));
  });
  return !isDefault.includes(false);
};

// default statuses
const defaultStatuses = [
  "outstanding",
  "complete",
  "hasEstimateSignatures",
  "hasPaymentSignatures",
];
// default types
const defaultTypes = ["invoice", "estimate", "draft"];

const loadInitialValues = ({ initialFilters, initialSearch }) => {
  const initialRange =
    initialFilters?.range?.startDate === null ||
    initialFilters?.range?.endDate === null
      ? Nifty.FindObject(btnRanges, "id", "default")
      : initialFilters.range;
  const initialStatuses = initialFilters?.statuses ?? defaultStatuses;
  const initialSearchValues = initialSearch ? initialSearch : "";
  return {
    initialFilterValues: {
      range: initialRange,
      statuses: initialStatuses,
      types: initialFilters?.types ?? defaultTypes,
    },
    initialSearchValues,
  };
};
