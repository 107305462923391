import notificationError from "../../../system-components/toasters/notificationError";
import { INVOICES_COLLECTION } from "../../../utils/models/collections/collectionConstants";
import { orgWrites } from "./orgWrites";

export function invoiceWrites({ firestore }, orgPrefix) {
  if (!orgPrefix)
    return notificationError(
      "Organization error",
      "Cannot determine organization"
    );
  const ref = firestore.collection(`${orgPrefix}${INVOICES_COLLECTION}`);

  async function createInvoice(payload, orgId) {
    const newRef = firestore
      .collection(`${orgPrefix}${INVOICES_COLLECTION}`)
      .doc();
    return await orgWrites({ firestore }, orgPrefix)
      .updateOrgDoc(orgId, { currentInvoiceCount: payload.invoiceNumber })
      .then(() => {
        newRef.set({
          id: newRef.id,
          ...payload,
        });
      })
      .catch((err) => {
        console.log(err);
        notificationError("Something went wrong", "Please try again later");
      });
  }
  async function updateInvoice(id, payload) {
    return await ref.doc(id).update(payload);
  }
  async function deleteInvoice(id) {
    return await ref.doc(id).delete();
  }
  return {
    createInvoice: createInvoice, // create invoice and add to org count
    updateInvoice: updateInvoice, // update invoice
    deleteInvoice: deleteInvoice, // delete invoice
  };
}
