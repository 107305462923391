import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Timeline,
  TimePicker,
} from "antd";
import Checkbox from "antd/es/checkbox/Checkbox";
import { authSelector } from "../../auth/authSlice";
import { useSelector } from "react-redux";
import {
  CUSTOMER_DISPLAY_NAME,
  CUSTOMER_FULL_SHIPPING_ADDRESS_STR,
  CUSTOMER_SHIPPING_ADDRESS,
  CUSTOMER_SHIPPING_CITY,
  CUSTOMER_SHIPPING_STATE,
  CUSTOMER_SHIPPING_ZIP,
  RECEIVE_METHOD_DELIVERY,
  RECEIVE_METHOD_PICKUP,
  VENUE_NAME,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { capitalizeFirstLetter } from "../../../app/utils/casing/capitalizeFirstLetter";
import LocationTag from "./LocationTag";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { useFirestore } from "react-redux-firebase";
import { getObjFromId } from "../helpers/getObjFromId";

const { Option } = Select;
const ReceiveDetails = ({
  isSalesInvoice,
  rentalDates,
  setSpecifiedTimes,
  specifiedTimes,
  form,
  receiveMethod,
  setCustomerAddressChecked,
  customerAddressChecked,
  handleReceiveMethod,
  customer,
  loadVenues,
  venues,
  currentInvoice,
  id,
}) => {
  const { orgData, userData, fsOrgPrefix } = useSelector(authSelector);
  const [loading, setLoading] = useState(false);
  const [venueInput, setVenueInput] = useState("");
  const [receiveVenue, setReceiveVenue] = useState(null);
  const validateTime = (rule, val) => {
    return new Promise((res, rej) => {
      if (
        specifiedTimes.receive &&
        (val === null || typeof val === "undefined")
      ) {
        rej();
      }
      res();
    });
  };

  useEffect(() => {
    if (customerAddressChecked.receive && customer) {
      const {
        customerDisplayName,
        customerShippingAddress,
        customerShippingCity,
        customerShippingState,
        customerShippingZip,
      } = customer;
      form.setFieldsValue({
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_DISPLAY_NAME)}`]:
          customerDisplayName,
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_SHIPPING_ADDRESS)}`]:
          customerShippingAddress,
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_SHIPPING_CITY)}`]:
          customerShippingCity,
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_SHIPPING_STATE)}`]:
          customerShippingState,
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_SHIPPING_ZIP)}`]:
          customerShippingZip,
      });
    }
  }, [customerAddressChecked, customer]);

  const handleReceiveVenueSelected = (e) => {
    const receiveVenue = getObjFromId(e, venues, customer);
    if (receiveVenue) {
      // save info to state
      const venueContactName = receiveVenue?.venueContactName ?? null;
      const venueAddress = receiveVenue?.venueAddress ?? null;
      const venueCity = receiveVenue?.venueCity ?? null;
      const venueState = receiveVenue?.venueState ?? null;
      const venueZip = receiveVenue?.venueZip ?? null;
      const venueNotes = receiveVenue?.venueNotes ?? null;
      const customerDisplayName = customer?.customerDisplayName ?? null;
      form.setFieldsValue({
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_DISPLAY_NAME)}`]:
          venueContactName ? venueContactName : customerDisplayName,
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_SHIPPING_ADDRESS)}`]:
          venueAddress,
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_SHIPPING_CITY)}`]:
          venueCity,
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_SHIPPING_STATE)}`]:
          venueState,
        [`${receiveMethod}${capitalizeFirstLetter(CUSTOMER_SHIPPING_ZIP)}`]:
          venueZip,
        [`notes`]: venueNotes,
      });
    }
  };

  return (
    <Timeline.Item style={{ paddingBottom: "60px" }}>
      <div>
        <Space style={{ alignItems: "center" }}>
          <span style={{ fontWeight: "bold" }}>{rentalDates.receiveDate}</span>
          Customer receives items by:
          <Form.Item
            name="receiveMethod"
            style={{ marginBottom: 0 }}
            initialValue={RECEIVE_METHOD_DELIVERY}
          >
            <Select
              defaultValue={RECEIVE_METHOD_DELIVERY}
              onChange={handleReceiveMethod}
              style={{ width: "100px" }}
            >
              <Option
                key={RECEIVE_METHOD_DELIVERY}
                value={RECEIVE_METHOD_DELIVERY}
              >
                Delivery
              </Option>
              <Option key={RECEIVE_METHOD_PICKUP} value={RECEIVE_METHOD_PICKUP}>
                Pickup
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="hasSpecifiedReceiveTime"
            initialValue={false}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox
              style={{ marginLeft: "12px" }}
              onChange={(e) => {
                setSpecifiedTimes({
                  receive: e.target.checked,
                  return: specifiedTimes.return,
                });
                if (!e.target.checked) {
                  form.setFieldsValue({
                    specifiedReceiveTime: null,
                    specifiedReceiveTimeEnd: null,
                  });
                }
              }}
            >
              Specify time
            </Checkbox>
          </Form.Item>
          <Form.Item
            className="FormItemNoValidatonMargin"
            name="specifiedReceiveTime"
            style={{ marginBottom: 0 }}
            initialValue={null}
            rules={[{ validator: validateTime }]}
          >
            {specifiedTimes.receive ? (
              <TimePicker
                placeholder="Start"
                style={{ width: "140px" }}
                use12Hours
                format="h:mm a"
              />
            ) : null}
          </Form.Item>
          <Form.Item
            className="FormItemNoValidatonMargin"
            name="specifiedReceiveTimeEnd"
            style={{ marginBottom: 0 }}
            initialValue={null}
          >
            {specifiedTimes.receive ? (
              <TimePicker
                placeholder="End (Optional)"
                style={{ width: "140px" }}
                use12Hours
                format="h:mm a"
              />
            ) : null}
          </Form.Item>
          {specifiedTimes.receive && (
            <TextSubtitle text={"(Use end time for ranges)"} />
          )}
        </Space>
      </div>

      {receiveMethod === RECEIVE_METHOD_DELIVERY &&
      !customerAddressChecked.receive ? (
        <div>
          <Checkbox
            style={{ marginTop: "20px" }}
            checked={customerAddressChecked.receive}
            defaultChecked={customerAddressChecked.receive}
            onChange={(e) => {
              setCustomerAddressChecked({
                receive: e.target.checked,
                return: customerAddressChecked.return,
              });
            }}
          >
            Use customer shipping address
          </Checkbox>
          {/* <div style={{ margin: "10px 0px 10px 0px" }}>
            Select a saved venue or enter a custom shipping address.
          </div> */}
          <Form.Item
            style={{ margin: "20px 0px 30px 0px" }}
            name="receiveVenueQueryHook"
            className="NewInvoiceVenueForm"
          >
            <Select
              loading={loading}
              onSearch={setVenueInput}
              allowClear
              onClear={() => {
                setReceiveVenue(null);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
              onSelect={handleReceiveVenueSelected}
              showSearch
              onFocus={loadVenues}
              placeholder={"Select existing venue"}
              style={{ width: "416px" }}
            >
              {venues &&
                venues.map((c) => (
                  <Option value={c.id} key={c.id}>
                    {c[VENUE_NAME]}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <div>
            <Form.Item
              className="NewInvoiceCustomerForm"
              name={`${receiveMethod}${capitalizeFirstLetter(
                CUSTOMER_DISPLAY_NAME
              )}`}
              initialValue=""
              rules={[
                {
                  required: true,
                  message: "Name required",
                },
              ]}
            >
              <Input
                placeholder="Contact name"
                style={{ width: "416px" }}
                disabled={customerAddressChecked.receive}
              />
            </Form.Item>
            <Form.Item
              initialValue=""
              className="NewInvoiceCustomerForm"
              name={`${receiveMethod}${capitalizeFirstLetter(
                CUSTOMER_SHIPPING_ADDRESS
              )}`}
              rules={[
                {
                  required: true,
                  message: "Address required",
                },
              ]}
            >
              <Input
                placeholder="Customer address"
                style={{ width: "416px" }}
                disabled={customerAddressChecked.receive}
              />
            </Form.Item>
            <Row wrap={false} gutter={8}>
              <Col>
                <Form.Item
                  initialValue=""
                  className="NewInvoiceCustomerForm"
                  name={`${receiveMethod}${capitalizeFirstLetter(
                    CUSTOMER_SHIPPING_CITY
                  )}`}
                  rules={[
                    {
                      required: true,
                      message: "City required",
                    },
                  ]}
                >
                  <Input
                    placeholder="City"
                    style={{ width: "200px" }}
                    disabled={customerAddressChecked.receive}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  initialValue=""
                  className="NewInvoiceCustomerForm"
                  name={`${receiveMethod}${capitalizeFirstLetter(
                    CUSTOMER_SHIPPING_STATE
                  )}`}
                  rules={[
                    {
                      required: true,
                      message: "State required",
                    },
                  ]}
                >
                  <Input
                    placeholder="State"
                    style={{ width: "100px" }}
                    disabled={customerAddressChecked.receive}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  initialValue=""
                  className="NewInvoiceCustomerForm"
                  name={`${receiveMethod}${capitalizeFirstLetter(
                    CUSTOMER_SHIPPING_ZIP
                  )}`}
                  rules={[
                    {
                      required: true,
                      message: "Zip required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Zip"
                    style={{ width: "100px" }}
                    disabled={customerAddressChecked.receive}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      ) : receiveMethod === RECEIVE_METHOD_DELIVERY &&
        customerAddressChecked.receive ? (
        <div>
          <Checkbox
            style={{ marginTop: "20px" }}
            checked={customerAddressChecked.receive}
            defaultChecked={customerAddressChecked.receive}
            onChange={(e) => {
              setCustomerAddressChecked({
                receive: e.target.checked,
                return: customerAddressChecked.return,
              });
            }}
          >
            Use customer shipping address
          </Checkbox>
          <br />
          <LocationTag
            contactName={customer && customer[CUSTOMER_DISPLAY_NAME]}
            address={customer && customer[CUSTOMER_FULL_SHIPPING_ADDRESS_STR]}
          />
        </div>
      ) : (
        <LocationTag address={orgData && orgData.companyAddress} />
      )}
    </Timeline.Item>
  );
};

export default ReceiveDetails;
