import { Button, Divider, Dropdown, Menu, Space } from "antd";
import {
  INVOICE,
  INVOICE_DRAFT,
  INVOICE_ESTIMATE,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { COLOR_GREEN_0 } from "../../../app/ui/colorConstants";
import notificationError from "../../../app/system-components/toasters/notificationError";
import {
  ROUTE_INVOICES,
  ROUTE_RECEIVE_PAYMENT_ROOT,
} from "../../../app/routes";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useInvoiceHeaderBtns } from "../../../app/services/hooks/useInvoiceHeaderBtns";
import { CaretDownOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { SendInvoiceContext } from "../InvoiceDetail";
import { useSelector } from "react-redux";
import { authSelector } from "../../auth/authSlice";
import { inventoryQueries } from "../../../app/services/firestore/queries/inventoryQueries";
import { useFirestore } from "react-redux-firebase";
import { invoices } from "../../../app/services/firestore/queries/invoiceQueries";

const InvoiceHeaderBtns = ({
  currentInvoice,
  customerId,
  customer,
  handleDelete,
  handleComplete,
  history,
  id,
}) => {
  const { show } = useInvoiceHeaderBtns(currentInvoice);
  const { orgData, fsOrgPrefix } = useSelector(authSelector);
  const modal = useContext(SendInvoiceContext);
  const firestore = useFirestore();

  const saveMenu = () => {
    return (
      <Menu>
        {show.draft && (
          <Menu.Item
            key="saveDraft"
            onClick={() =>
              handleComplete({ type: INVOICE_DRAFT, redirect: ROUTE_INVOICES })
            }
          >
            Save as Draft
          </Menu.Item>
        )}
        {show.estimate && (
          <Menu.Item
            key="saveEstimate"
            onClick={() =>
              handleComplete({
                type: INVOICE_ESTIMATE,
                redirect: ROUTE_INVOICES,
              })
            }
          >
            Save as Estimate
          </Menu.Item>
        )}

        {show.invoice && (
          <Menu.Item
            key="saveInvoice"
            onClick={() =>
              handleComplete({ type: INVOICE, redirect: ROUTE_INVOICES })
            }
          >
            Save as Invoice
          </Menu.Item>
        )}
        <Divider style={{ margin: "12px 0" }} />
        {show.estimate && (
          <Menu.Item
            key="saveSendEstimate"
            onClick={async () => {
              const res = await handleComplete({
                type: INVOICE_ESTIMATE,
                redirect: null,
              });
              const refreshedInvoice = await invoices(
                orgData?.orgTimezone
              ).fetchInvoice({ firestore }, fsOrgPrefix, {
                id: res?.invoiceId,
              });
              if (res?.hasError) return;
              modal.loadData({
                customerId: customer?.id,
                orgData,
                invoice: refreshedInvoice,
                isNew: !currentInvoice,
                sendType: "estimate",
                settingsId: "estimateEmailSettings",
              });
              modal.show();
            }}
          >
            Save and Send Estimate PDF
          </Menu.Item>
        )}
        {show.invoice && (
          <Menu.Item
            key="saveSendInvoice"
            onClick={async () => {
              const res = await handleComplete({
                type: INVOICE,
                redirect: null,
              });
              if (res?.hasError) return;
              const refreshedInvoice = await invoices(
                orgData?.orgTimezone
              ).fetchInvoice({ firestore }, fsOrgPrefix, {
                id: res?.invoiceId,
              });
              modal.loadData({
                customerId: customer?.id,
                orgData,
                invoice: refreshedInvoice,
                isNew: !currentInvoice,
                sendType: "invoice",
                settingsId: "invoiceEmailSettings",
              });
              modal.show();
            }}
          >
            Save and Send Invoice PDF
          </Menu.Item>
        )}

        {show.signatureRequest && (
          <Menu.Item
            key="sendSignatureRequest"
            onClick={async () => {
              const res = await handleComplete({
                type: currentInvoice.type,
                redirect: null,
              });
              if (res?.hasError) return;
              const refreshedInvoice = await invoices(
                orgData?.orgTimezone
              ).fetchInvoice({ firestore }, fsOrgPrefix, {
                id: res?.invoiceId,
              });
              modal.loadData({
                customerId: customer?.id,
                orgData,
                invoice: refreshedInvoice,
                isNew: !currentInvoice,
                sendType: "signatureRequest",
                settingsId: "invoiceEmailSettings",
              });
              modal.show();
            }}
          >
            Send Signature Request
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Space>
      {id && (
        <Button type="danger" onClick={() => handleDelete(id)}>
          Delete
        </Button>
      )}
      <Dropdown overlay={saveMenu()} trigger={["click"]}>
        <Button>
          Save <CaretDownOutlined />
        </Button>
      </Dropdown>
      <Divider type={"vertical"} />
      <Button
        style={{
          backgroundColor: COLOR_GREEN_0,
          borderColor: COLOR_GREEN_0,
          color: "#FFF",
        }}
        onClick={() => {
          handleComplete({ type: INVOICE, redirect: "RECEIVE_PAYMENT" });
        }}
      >
        {currentInvoice && currentInvoice.type === INVOICE ? (
          <span>
            Receive payment <ArrowRightOutlined />
          </span>
        ) : (
          <span>
            Save & Receive Payment <ArrowRightOutlined />
          </span>
        )}
      </Button>
    </Space>
  );
};

export default InvoiceHeaderBtns;
