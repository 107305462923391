import { useIsAuthenticated } from "./useIsAuthenticated";
import { useFirebase } from "react-redux-firebase";
import { useState } from "react";
import notificationError from "../../system-components/toasters/notificationError";

export function usePayments() {
  const { orgData, fsOrgPrefix } = useIsAuthenticated();
  const firebase = useFirebase();
  const [fetchingIntent, setFetchingIntent] = useState(false);
  const [paymentsPending, setPaymentsPending] = useState(false);
  
  const handleStripePaymentForAmt = async (payloadData) => {
    if (!payloadData.total) return notificationError("Amount required");
    const cents = payloadData.total;
    const payload = {
      orgId: orgData?.id,
      total: cents,
      currency: "usd",
      customer: payloadData.customer,
    };
    setFetchingIntent(true);
    try {
      const intent = firebase.functions().httpsCallable("setupPaymentIntent");
      const intentRes = await intent(payload);
      setFetchingIntent(false);
      return intentRes.data;
    } catch (err) {
      console.log(err);
      setFetchingIntent(false);
      return null;
    }
  };
  return { handleStripePaymentForAmt, fetchingIntent };
}
