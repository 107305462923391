import React from "react";
import { ItemTag } from "../../../../../app/system-components/tags/Tags";
import { Card } from "antd";

const InvoiceItemsCard = ({ items }) => {
  return (
    <Card
      title={"Invoice Items"}
      bordered={false}
      size={"small"}
      bodyStyle={{ padding: "8px" }}
    >
      {items?.map((item) => {
        return (
          <div>
            <ItemTag name={item?.name} qty={item?.qty} />
          </div>
        );
      })}
    </Card>
  );
};

export default InvoiceItemsCard;
