import { useEffect, useState } from "react";
import {
    List,
    Space,
    Divider,
    Typography
} from "antd"; import NumberFormat from "react-number-format";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import TextBody from "../../../../app/system-components/typography/text/TextBody";

const { Text } = Typography;

const MaintenanceHistory = ({ fetching, data, item }) => {

    const [totalMaintenanceCost, setTotalMaintenanceCost] = useState(0)
    const [totalPartsCost, setTotalPartsCost] = useState(0)
    const [totalLaborCost, setTotalLaborCost] = useState(0)



    useEffect(() => {
        // Calculate total cost of maintenance for this item.
        const totalServiceCost = data.reduce((total, record) => {
            return total + (record.costOfParts || 0) + (record.costOfLabor); // Add each serviceCost to the total
        }, 0);
        setTotalMaintenanceCost(totalServiceCost)

        const totalPartsSum = data.reduce((total, record) => {
            return total + (record.costOfParts || 0); // Add each serviceCost to the total
        }, 0);
        setTotalPartsCost(totalPartsSum)

        const totalLaborSum = data.reduce((total, record) => {
            return total + (record.costOfLabor); // Add each serviceCost to the total
        }, 0);
        setTotalLaborCost(totalLaborSum)
    }, [data])

    /*
     const maintenanceRecord = {
          itemId: item?.id,
          id: firelikeId(),
          servicedOn: values.servicedOn.format(), // Convert DatePicker value to string
          servicedBy: values.servicedBy,
          costOfService: values.costOfService,
          notes: values.maintenanceNotes,
          createdBy: userData.id,
          createdOn: new Date(),
          attachments: [],
        };
    */

    const formattedDate = (date) => {
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        return date?.toLocaleDateString(undefined, options);
    };

    return (
        <>
           

            <div>
                Total Parts Cost: { }
                <Text type="danger">
                    ${totalPartsCost.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}
                </Text>
            </div>

            <div>
                Total Labors Cost: { }
                <Text type="danger">
                    ${totalLaborCost.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}
                </Text>
            </div>

            <div>
                Overall Service Cost: { }
                <Text type="danger">
                    ${totalMaintenanceCost.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}
                </Text>
            </div>

            <Divider />

            <List
                loading={fetching}
                locale={{ emptyText: "No records" }}
                size="small"
                bordered={false}
                dataSource={data}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            title={
                                <Space>
                                    <TextBody text={"Date: "} />
                                    {formattedDate(item.servicedOn.toDate())}
                                </Space>
                            }
                            description={
                                <Space direction={"vertical"} size={0}>
                                    <Space>

                                        <TextSubtitle text={"Cost of Parts: "} />
                                        <NumberFormat
                                            style={{ fontWeight: "semi-bold" }}
                                            value={item?.costOfParts.toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />

                                        <TextSubtitle text={"Cost of Labor: "} />
                                        <NumberFormat
                                            style={{ fontWeight: "semi-bold" }}
                                            value={item?.costOfLabor.toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />

                                    </Space>
                                    <TextSubtitle text={`Serviced By: ${item.servicedBy}`} />
                                    <TextSubtitle
                                        text={`Notes: ${item.notes}`}
                                    />

                                </Space>
                            }
                        />

                    </List.Item>
                )}
            />
        </>
    );
};

export default MaintenanceHistory;
