import { Moment } from "moment";
import { createContext } from "react";

export interface EmbeddedCheckoutQuestion {
  question: string;
  isRequired: boolean;
  index: number;
}
export type EmbeddedCheckoutOrderTypes = "wishlist" | "order";
export interface EmbeddedCheckoutBlackoutDate {
  blackoutStart: Moment;
  blackoutEnd: Moment;
  willCallAllowed: boolean;
}

export interface EmbeddedSettingsState {
  orgId: string;
  checkoutQuestions: EmbeddedCheckoutQuestion[];
  primaryColor: null | string;
  currencySymbol: string;
  closedDateSelectedMessage: string;
  checkoutSettings: {
    type: "wishlist" | "order";
    allowOverbooked: boolean;
  };
  blackoutDates: EmbeddedCheckoutBlackoutDate[];
  taxRateId: string | null;
  daysOfOperation: {
    Sunday: boolean;
    Monday: boolean;
    Tuesday: boolean;
    Wednesday: boolean;
    Thursday: boolean;
    Friday: boolean;
    Saturday: boolean;
  };
  maxCheckoutDays: number;
  displayInventoryAvailability: boolean;
}

export interface EmbeddedSettingsActions {
  loadInitialState: (data: EmbeddedSettingsState) => void;
  state: EmbeddedSettingsState;
  checkoutQuestions: {
    create: () => void;
    remove: (props: any) => void;
    toggleRequired: (props: any) => void;
    moveUp: (props: any) => void;
    moveDown: (props: any) => void;
    update: (props: any) => void;
  };
  checkoutSettings: {
    changeType: (props: any) => void;
    toggleOverbooked: (props: any) => void;
  };
  blackoutDates: {
    onDateChange: (props: any) => void;
    addBlackoutDate: (props: any) => void;
    removeBlackoutDate: (props: any) => void;
  };
  changePrimaryColor: (newColor: string) => void;
  changeCurrencySymbol: (newSymbol: string) => void;
  taxRate: {
    setRate: (rateId: string) => void;
  };
  setDayOfOperation: (
    dayOfWeek:
      | "Sunday"
      | "Monday"
      | "Tuesday"
      | "Wednesday"
      | "Thursday"
      | "Friday"
      | "Saturday",
    enabled: boolean
  ) => void;
  setMaxCheckoutDays: (d: number) => void;
  setClosedDateSelectedMessage: (newMessage: string) => void;
  setDisplayInventory: (display: boolean) => void;
}

export interface EmbeddedSettingsContextInterface {
  state: EmbeddedSettingsState;
  actions: EmbeddedSettingsActions;
  save: () => Promise<void>;
  busy: boolean;
}

export const defaultSettingsState: EmbeddedSettingsState = {
  orgId: "",
  checkoutQuestions: [],
  primaryColor: "000000",
  checkoutSettings: {
    type: "wishlist",
    allowOverbooked: false,
  },
  blackoutDates: [],
  taxRateId: null,
  daysOfOperation: {
    Sunday: true,
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
  },
  maxCheckoutDays: 0,
  currencySymbol: "$",
  closedDateSelectedMessage: "",
  displayInventoryAvailability: true
};

const uselessDefaultContextValue: EmbeddedSettingsContextInterface = {
  state: defaultSettingsState,
  actions: {
    loadInitialState: function (data: any): void {
      throw new Error("Function not implemented.");
    },
    state: defaultSettingsState,
    checkoutQuestions: {
      create: function (): void {
        throw new Error("Function not implemented.");
      },
      remove: function (props: any): void {
        throw new Error("Function not implemented.");
      },
      toggleRequired: function (props: any): void {
        throw new Error("Function not implemented.");
      },
      moveUp: function (props: any): void {
        throw new Error("Function not implemented.");
      },
      moveDown: function (props: any): void {
        throw new Error("Function not implemented.");
      },
      update: function (props: any): void {
        throw new Error("Function not implemented.");
      },
    },
    checkoutSettings: {
      changeType: function (props: any): void {
        throw new Error("Function not implemented.");
      },
      toggleOverbooked: function (props: any): void {
        throw new Error("Function not implemented.");
      },
    },
    blackoutDates: {
      onDateChange: function (props: any): void {
        throw new Error("Function not implemented.");
      },
      addBlackoutDate: function (props: any): void {
        throw new Error("Function not implemented.");
      },
      removeBlackoutDate: function (props: any): void {
        throw new Error("Function not implemented.");
      },
    },
    taxRate: {
      setRate: function (rateId: string): void {
        throw new Error("Function not implemented.");
      },
    },
    setDayOfOperation: function (
      dayOfWeek: "Sunday" |
        "Monday" |
        "Tuesday" |
        "Wednesday" |
        "Thursday" |
        "Friday" |
        "Saturday",
      enabled: boolean
    ): void {
      throw new Error("Function not implemented.");
    },
    setMaxCheckoutDays: function (d: number): void {
      throw new Error("Function not implemented.");
    },
    changePrimaryColor: function (newColor: string): void {
      throw new Error("Function not implemented.");
    },
    changeCurrencySymbol: function (newSymbol: string): void {
      throw new Error("Function not implemented.");
    },
    setClosedDateSelectedMessage: function (newMessage: string): void {
      throw new Error("Function not implemented.");
    },
    setDisplayInventory: function (display: boolean): void {
      throw new Error("Function not implemented.");
    }
  },
  save: function (): Promise<void> {
    throw new Error("Function not implemented.");
  },
  busy: false,
};

export const AdminEmbeddedContext =
  createContext<EmbeddedSettingsContextInterface>(uselessDefaultContextValue);
