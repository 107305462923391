import React, { useState } from "react";
import {
    List,
    Space,
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    Switch,
    Spin,
    Divider,
    message
} from "antd"; import NumberFormat from "react-number-format";
import { COLOR_LIGHT_GRAY, COLOR_TEXT_GRAY_0, COLOR_TEXT_GRAY_1 } from "../../../../app/ui/colorConstants";
import { capitalizeFirstLetter } from "../../../../app/utils/casing/capitalizeFirstLetter";
import TextSubtitle from "../../../../app/system-components/typography/text/TextSubtitle";
import TextBody from "../../../../app/system-components/typography/text/TextBody";
import { formatDateOnRead } from "../../../../app/utils/time/formatTimezoneOffset";
import { formatDateToMonthDay } from "../../../../app/utils/time/convertTimeToDayString";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { useIsAuthenticated } from "../../../../app/services/hooks/useIsAuthenticated";
import { firelikeId } from "../../../../app/utils/models/checkers/firelikeid";
import { Spinner } from "../../../../app/ui/spinner";
import { Spa } from "@mui/icons-material";
import { checkUndefinedOrNull } from "../../../../app/utils/models/checkers/checkUndefined";

const AddMaintenanceRecord = ({ item }) => {
    const firestore = useFirestore();

    /*
     const maintenanceRecord = {
          itemId: item?.id,
          id: firelikeId(),
          servicedOn: values.servicedOn.format(), // Convert DatePicker value to string
          servicedBy: values.servicedBy,
          costOfService: values.costOfService,
          notes: values.maintenanceNotes,
          createdBy: userData.id,
          createdOn: new Date(),
          attachments: [],
        };
        */
    const [maintenanceRecordForm] = Form.useForm();

    const { userData } = useIsAuthenticated();
    const [savingRecord, setSavingRecord] = useState(false)

    const formattedDate = (date) => {
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        return date?.toLocaleDateString(undefined, options);
    };


    const handleSaveMaintenanceRecord = async () => {
        setSavingRecord(true)
        maintenanceRecordForm.validateFields()
            .then(async (values) => {
                // Create a JSON object from the form values
                const maintenanceRecord = {
                    itemId: item?.id,
                    id: firelikeId(),
                    servicedOn: values.servicedOn.format(), // Convert DatePicker value to string
                    servicedBy: values.servicedBy,
                    costOfService: checkUndefinedOrNull(values.costOfService, 0),
                    costOfLabor: checkUndefinedOrNull(values.costOfLabor, 0),
                    costOfParts: checkUndefinedOrNull(values.costOfParts, 0),
                    notes: values.maintenanceNotes,
                    createdBy: userData.id,
                    createdOn: new Date(),
                    attachments: [],
                };

                await firestore.collection(`orgs/${item?.orgId}/inventory/`).doc(item?.id).collection("maintenanceRecords").doc(maintenanceRecord.id).set(maintenanceRecord);
                setSavingRecord(false)

                // Clear the form fields
                maintenanceRecordForm.resetFields();
                message.success("Maintenance record added.")
            })
            .catch((error) => {
                setSavingRecord(false)
                console.error("Validation error:", error);
            });
    };

    return (
        <>            {/* New section for creating maintenance records */}
            <Card
                size="small"
                title={<TextBody style={{ fontWeight: "bold" }} text={"Create New Maintenance Record"} />}
                bordered={false}
            >
                <Spin spinning={savingRecord} indicator={Spinner}>
                    <Form form={maintenanceRecordForm}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="servicedOn"
                                    label={<TextSubtitle text={"Serviced On"} />}
                                    rules={[{ required: true, message: "Please select a date" }]}
                                >
                                    <DatePicker style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="servicedBy"
                                    label={<TextSubtitle text={"Serviced By"} />}
                                    rules={[{ required: true, message: "Please enter name of service provider" }]}
                                >
                                    <Input style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                            <Form.Item
                                    label={<TextSubtitle text={"Cost of Parts"} />}
                                    name={"costOfParts"}
                                    rules={[{ required: true, message: "Cost of Parts Required" }]}
                                >
                                    <InputNumber
                                        placeholder={"0"}
                                        style={{ width: "120px" }}
                                        formatter={(value) =>
                                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={<TextSubtitle text={"Cost of Labor"} />}
                                    name={"costOfLabor"}
                                    rules={[{ required: true, message: "Rate required" }]}
                                >
                                    <InputNumber
                                        placeholder={"0"}
                                        style={{ width: "120px" }}
                                        formatter={(value) =>
                                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="maintenanceNotes" label={<TextSubtitle text={"Notes"} />}>
                                    <Input.TextArea rows={3} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" onClick={handleSaveMaintenanceRecord}>
                                        Save Maintenance Record
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Card>
        </>
    );
};

export default AddMaintenanceRecord;
