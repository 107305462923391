import React, { useContext, useEffect, useState } from "react";
import { Badge, Space } from "antd";
import { SalesReportContext } from "../SalesReport";
import {
  COLOR_BLUE_0,
  COLOR_PURPLE_0,
} from "../../../../app/ui/colorConstants";

const SalesReportFilterTags = () => {
  const { state, actions } = useContext(SalesReportContext);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (state.fetchingCategories || state.fetchingInventory) return;
    let list = [];
    if (state.selectedInvItem) {
      const item = state.inventory?.find(
        (c) => c?.id === state.selectedInvItem
      );
      if (!item) return;
      list.push({
        id: item?.id,
        text: item?.name,
        color: COLOR_BLUE_0,
      });
    }
    if (state.itemFilters?.length !== 0) {
      const filters = state?.itemFilters?.map((id) => {
        console.log("state", id);
        const filterItem = state?.categories?.find((c) => c?.id === id);
        if (!filterItem)
          return {
            id: null,
            text: "",
          };
        return {
          id,
          text: filterItem?.name ?? "",
          color: COLOR_PURPLE_0,
        };
      });
      filters?.map((i) => list.push(i));
    }
    setTags(list.filter((i) => i?.id !== null));
  }, [state.selectedInvItem, state.itemFilters]);
  return (
    <div>
      {tags?.map((t) => {
        return (
          <div style={{ marginRight: "12px", display: "inline-flex" }}>
            <Space direction={"horizontal"} size={0}>
              <Badge dot color={t?.color} />
              <span>{t?.text}</span>
            </Space>
          </div>
        );
      })}
    </div>
  );
};

export default SalesReportFilterTags;
