import React from "react";
import {
  Affix,
  Button,
  Col,
  Divider,
  PageHeader,
  Row,
  Space,
  Spin,
  Statistic,
} from "antd";
import { ROUTE_CREATE_INVOICE } from "../../../app/routes";
import { useSalesReport } from "../../../app/services/hooks/useSalesReport";
import DateRangePicker from "./components/DateRangePicker";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import SalesStatistic from "./components/SalesStatistic";
import SalesChart from "./components/SalesChart";
import SalesInvoices from "./components/SalesInvoices";

export const SalesReportContext = React.createContext();
const SalesReport = () => {
  const { state, actions } = useSalesReport();
  return (
    <div>
      <SalesReportContext.Provider value={{ state, actions }}>
        <Spin spinning={state.loading}>
          <Affix>
            <PageHeader className="PageHeader" title="Sales Report" />
          </Affix>
          <div style={{ margin: "16px", paddingTop: "56px" }}>
            <Row justify={"space-between"} wrap={false} align={"top"}>
              <Col span={12}>
                <DateRangePicker />
              </Col>
              <Col span={12}>
                <SalesStatistic />
              </Col>
            </Row>
            <Divider />
            <SalesChart />
            <br />
            <SalesInvoices />
          </div>
        </Spin>
      </SalesReportContext.Provider>
    </div>
  );
};

export default SalesReport;
