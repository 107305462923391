import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

const config = require(`./${process.env.REACT_APP_ENVIRONMENT}`);

firebase.initializeApp(config.firebaseConfig);
firebase.firestore();

export default firebase;
