import { useInvoicesInRange } from "./fetch/useInvoicesInRange";
import { useFirestore } from "react-redux-firebase";
import { useGetInventoryQuery } from "../api";
import { useIsAuthenticated } from "./useIsAuthenticated";
import { useEffect, useState } from "react";
import Nifty from "../../utils/Nifty";

export function useAvailableInventoryPaginated({
  startEndOpts,
  filterInventoryIds,
  invoiceItems,
  ignoreInvoicesForAvailability,
}) {
  const firestore = useFirestore();
  const { fsOrgPrefix } = useIsAuthenticated();
  const InventoryDb = useGetInventoryQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
  });
  const InvoicesInRange = useInvoicesInRange(
    startEndOpts,
    InventoryDb?.data ?? [],
    invoiceItems,
    ignoreInvoicesForAvailability
  );

  return {
    availableInventory: InvoicesInRange?.data?.filter((i) =>
      filterInventoryIds?.includes(i.id)
    ),
    fullInventory: InvoicesInRange?.data ?? [],
    fetchingInvoices: InvoicesInRange.fetching || InventoryDb.fetching,
    refreshInvoices: InvoicesInRange.refresh,
  };
}
