import { Menu, Layout, Typography, Modal } from "antd";
import "../../App.less";
import { i18n } from "../../i18n";
import { COLOR_DARK_BG_1 } from "../../app/ui/colorConstants";
import {
  AppstoreOutlined,
  CalendarOutlined,
  CodeSandboxOutlined,
  CompassOutlined,
  FileTextOutlined,
  PieChartOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  ROUTE_ADMIN_INTEGRATIONS,
  ROUTE_ADMIN_INVENTORY,
  ROUTE_ADMIN_SETTINGS_GENERAL,
  ROUTE_ADMIN_SETTINGS_EMAIL,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_VENUES,
  ROUTE_INVENTORY,
  ROUTE_INVOICES,
  ROUTE_QUICK_REPORT,
  ROUTE_ROUTES,
  ROUTE_ADMIN_CUSTOMERS,
  ROUTE_ADMIN_TRUCKS,
  ROUTE_REPORTS_SALES,
  ROUTE_REPORTS_USAGE,
  ROUTE_REPORTS_DEPRECIATION,
  ROUTE_REPORTS_TAX,
  ROUTE_ADMIN_EMBEDDED_CHECKOUT,
} from "../../app/routes";
import ProfileDropdown from "./components/ProfileDropdown";
import { useSidebar } from "../../app/services/hooks/useSidebar";
import { useState } from "react";
import { useIsAuthenticated } from "../../app/services/hooks/useIsAuthenticated";

const { Sider } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

const Sidebar = () => {
  const { highlighted, blockSidebar } = useSidebar();
  const { orgData } = useIsAuthenticated();

  const history = useHistory();

  const [collapsed, setCollapsed] = useState(false);

  const handleClick = (route) => {
    if (!blockSidebar) {
      history.push(route);
    } else {
      // modal
      Modal.confirm({
        title: "Exit without saving?",
        content: "",
        onOk() {
          history.push(route);
        },
        onCancel() { },
      });
    }
  };
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      breakpoint="xl"
      width={256}
      style={{
        // height: "100vh",
        backgroundColor: COLOR_DARK_BG_1,
      }}
    >
      <div style={{ height: "calc(100vh - 100px)", overflowY: "auto" }}>

        <Menu
          style={{
            backgroundColor: COLOR_DARK_BG_1,
          }}
          selectedKeys={highlighted}
          defaultOpenKeys={["subMenu"]}
          mode={"inline"}
          theme={"dark"}
          inlineCollapsed={collapsed}
        >
          <Menu.Item
            key={"company_header"}
            className="CompanyHeader"
            style={{
              height: "100px",
            }}
            icon={collapsed ? <UserOutlined /> : ""}
          >
            <Title level={4} style={{ color: "#FFF", pointerEvents: "none" }}>
              Adelie Logistics
            </Title>
            <ProfileDropdown history={history} />
          </Menu.Item>
          <Menu.Item
            onClick={() => handleClick(ROUTE_INVENTORY)}
            key={ROUTE_INVENTORY}
            icon={<CodeSandboxOutlined />}
          >
            {i18n("sidebar.availability")}
          </Menu.Item>
          <Menu.Item
            onClick={() => handleClick(ROUTE_INVOICES)}
            key={ROUTE_INVOICES}
            icon={<FileTextOutlined />}
          >
            {i18n("sidebar.invoices")}
          </Menu.Item>
          <Menu.Item
            onClick={() => handleClick(ROUTE_QUICK_REPORT)}
            key={ROUTE_QUICK_REPORT}
            icon={<CalendarOutlined />}
          >
            {i18n("sidebar.prepare")}
          </Menu.Item>
          <Menu.Item
            onClick={() => handleClick(ROUTE_ROUTES)}
            key={ROUTE_ROUTES}
            icon={<CompassOutlined />}
          >
            {i18n("sidebar.routes")}
          </Menu.Item>
          <SubMenu
            key="reportsSubMenu"
            icon={<PieChartOutlined />}
            title={i18n("sidebar.reports.title")}
          >
            <Menu.Item
              onClick={() => handleClick(ROUTE_REPORTS_SALES)}
              key={ROUTE_REPORTS_SALES}
            >
              {i18n("sidebar.reports.sales")}
            </Menu.Item>
            <Menu.Item
              onClick={() => handleClick(ROUTE_REPORTS_TAX)}
              key={ROUTE_REPORTS_TAX}
            >
              {i18n("sidebar.reports.tax")}
            </Menu.Item>
            <Menu.Item
              onClick={() => handleClick(ROUTE_REPORTS_USAGE)}
              key={ROUTE_REPORTS_USAGE}
            >
              {i18n("sidebar.reports.usage")}
            </Menu.Item>
            <Menu.Item
              onClick={() => handleClick(ROUTE_REPORTS_DEPRECIATION)}
              key={ROUTE_REPORTS_DEPRECIATION}
            >
              {i18n("sidebar.reports.depreciation")}
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="subMenu"
            icon={<AppstoreOutlined />}
            title={i18n("sidebar.admin.title")}
          >
            <Menu.Item
              onClick={() => handleClick(ROUTE_ADMIN_USERS)}
              key={ROUTE_ADMIN_USERS}
            >
              {i18n("sidebar.admin.users")}
            </Menu.Item>
            <Menu.Item
              onClick={() => handleClick(ROUTE_ADMIN_INVENTORY)}
              key={ROUTE_ADMIN_INVENTORY}
            >
              {i18n("sidebar.admin.inventory")}
            </Menu.Item>
            <Menu.Item
              onClick={() => handleClick(ROUTE_ADMIN_CUSTOMERS)}
              key={ROUTE_ADMIN_CUSTOMERS}
            >
              {i18n("sidebar.admin.customers")}
            </Menu.Item>
            <Menu.Item
              onClick={() => handleClick(ROUTE_ADMIN_VENUES)}
              key={ROUTE_ADMIN_VENUES}
            >
              {i18n("sidebar.admin.venues")}
            </Menu.Item>
            <Menu.Item
              onClick={() => handleClick(ROUTE_ADMIN_TRUCKS)}
              key={ROUTE_ADMIN_TRUCKS}
            >
              {i18n("sidebar.admin.trucks")}
            </Menu.Item>
            <Menu.Item
              onClick={() => handleClick(ROUTE_ADMIN_INTEGRATIONS)}
              key={ROUTE_ADMIN_INTEGRATIONS}
            >
              {i18n("sidebar.admin.integrations")}
            </Menu.Item>
          </SubMenu>

          <SubMenu
            key="settingsSubMenu"
            icon={<SettingOutlined />}
            title={i18n("sidebar.settings.title")}
          >
            <Menu.Item
              onClick={() => handleClick(ROUTE_ADMIN_SETTINGS_GENERAL)}
              key={ROUTE_ADMIN_SETTINGS_GENERAL}
            >
              {i18n("sidebar.settings.general")}
            </Menu.Item>

            <Menu.Item
              onClick={() => handleClick(ROUTE_ADMIN_SETTINGS_EMAIL)}
              key={ROUTE_ADMIN_SETTINGS_EMAIL}
            >
              {i18n("sidebar.settings.email")}
            </Menu.Item>

            {
              orgData && orgData.embeddedCheckoutEnabled &&
              <Menu.Item
                onClick={() => handleClick(ROUTE_ADMIN_EMBEDDED_CHECKOUT)}
                key={ROUTE_ADMIN_EMBEDDED_CHECKOUT}
              >
                {i18n("sidebar.settings.embeddedCheckout")}
              </Menu.Item>
            }

          </SubMenu>
        </Menu>
      </div>
    </Sider>
  );
};

export default Sidebar;
