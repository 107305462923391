export const firebaseConfig = {
  apiKey: "AIzaSyD3hRBvFETmi0pEtX2xwCqIZF49Zaw9ZOc",
  authDomain: "adelie-logistics-production.firebaseapp.com",
  projectId: "adelie-logistics-production",
  storageBucket: "adelie-logistics-production.appspot.com",
  messagingSenderId: "1005547537970",
  appId: "1:1005547537970:web:b0bd0cd477dcef2c19de2b",
};
  
  export const algolia_invoices = {
    appid: "W8IP9A0GB1",
    searchKey: "8dfb527b4425118f1dc8c533ea2b6b56",
    index: "invoices",
  };
  
  export const algolia_inventory = {
    appid: "W8IP9A0GB1",
    searchKey: "8dfb527b4425118f1dc8c533ea2b6b56",
    index: "inventory",
  };
  