import { useEffect, useState } from "react";
import notificationConfirm from "../../system-components/toasters/notificationConfirm";
import notificationError from "../../system-components/toasters/notificationError";

export function usePaymentConfirmed() {
  const [intentId, setIntentId] = useState("");
  const showPaymentConfirmation = () => {
    const location = window.location.href;
    const hadPayment = location.includes("?payment_intent=");
    if (!hadPayment) return;

    const parseObj = new URL(location);

    const paymentIntent = parseObj.searchParams.get("payment_intent");
    if (intentId === paymentIntent) return;

    const status = parseObj.searchParams.get("redirect_status");
    setIntentId(paymentIntent);

    if (status === "succeeded") return notificationConfirm("Payment completed");
    return notificationError("Something went wrong", "please try again");
  };
  return { showPaymentConfirmation };
}
