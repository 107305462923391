import React, { useContext, useEffect, useRef, useState } from "react";
import { EditableContext } from "../rows/EditableRow";
import { ROW_TYPE_SUBTOTAL } from "../../../../../app/utils/models/modelConstants/modelConstants";
import { COLOR_TEXT_GRAY_1 } from "../../../../../app/ui/colorConstants";
import { Form, Modal } from "antd";
import { renderFieldType } from "../helpers/renderFieldType";
import { renderChildrenCells } from "../helpers/renderChildrenCells";
import { saveEditedCells } from "../helpers/saveEditedCells";
import { AvailableInventoryContext } from "../../../InvoiceDetail";

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputType,
  selectedItems,
  availableItems,
  setToggleNewInvModal,
  rentalRange,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  const [typedItem, setTypedItem] = useState("");
  const { state } = useContext(AvailableInventoryContext);
  const AvailableInventory = useContext(AvailableInventoryContext);

  useEffect(() => {
    if (editing && record.rowType !== ROW_TYPE_SUBTOTAL) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (record.rowType === ROW_TYPE_SUBTOTAL) return;
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
    setTypedItem("");
  };

  const save = async (i, dataIndex) => {
    const isSameItem = i === record?.id;
    if (i === "createNewItem")
      return setToggleNewInvModal({
        visible: true,
        name: typedItem,
        key: record.key,
      });
    try {
      const values = await form.validateFields();
      toggleEdit();
      let item = record;
      const props = {
        values: values,
        availableItems: availableItems,
        i: i ? i : null,
        dataIndex: dataIndex,
      };
      if (dataIndex === "selectedItemId" && !isSameItem) {
        saveEditedCells(
          props,
          item,
          handleSave,
          rentalRange
        ).saveItemSelected();
      } else if (dataIndex === "selectedQty") {
        saveEditedCells(props, item, handleSave).saveSelectedQty();
      } else if (dataIndex === "selectedRate") {
        saveEditedCells(props, item, handleSave).saveSelectedRate();
      } else if (dataIndex === "selectedDuration") {
        saveEditedCells(props, item, handleSave).saveSelectedDuration();
      } else if (dataIndex === "selectedDescription") {
        saveEditedCells(props, item, handleSave).saveSelectedDescription();
      }
    } catch (err) {
      console.log("Save failed:", err);
    }
  };
  const saveCustomRate = async (rate, dataIndex) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      let item = record;
      const props = {
        values: values,
        availableItems: availableItems,
        i: rate ? rate : null,
        dataIndex: dataIndex,
      };
      saveEditedCells(props, item, handleSave).saveCustomRate();
    } catch (err) {
      console.log("Save failed:", err);
    }
  };

  const renderChildren = (children, dataIndex, record, stock) => {
    // handle non editing state
    if (dataIndex === "selectedRate") {
      return renderChildrenCells(children, record).renderSelectedRate();
    } else if (dataIndex === "selectedDescription") {
      return renderChildrenCells(children, record).renderSelectedDescription(
        state.availableStock
      );
    } else if (dataIndex === "selectedDuration") {
      return renderChildrenCells(children, record).renderSelectedDuration();
    } else if (dataIndex === "selectedQty") {
      const id = record?.id;
      const amt = stock?.[id] ?? 0;
      const isNeg = amt < 0;
      return renderChildrenCells(children, record).renderSelectedQty(
        id,
        amt,
        isNeg
      );
    } else {
      return children;
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
          padding: 0,
        }}
        name={dataIndex}
      >
        {dataIndex &&
          renderFieldType(
            dataIndex,
            inputRef,
            save,
            availableItems,
            selectedItems,
            record,
            saveCustomRate,
            typedItem,
            setTypedItem
          )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 0,
        }}
        onClick={toggleEdit}
      >
        {renderChildren(children, dataIndex, record, state.availableStock)}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
