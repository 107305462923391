import notificationError from "../../../../app/system-components/toasters/notificationError";
import { SortStopIndexes } from "./sortStopIndexes";
import { AppendPrevLatLng } from "./appendPrevLatLng";
import { HasSamePolyline } from "./hasSamePolyline";
import moment from "moment";
import onlyUnique from "../../../../app/utils/filters/filterOnlyUnique";
import { TimeMachine } from "../../../../app/utils/time/timeMachine";

export const RoutePayload = (route) => {
  const data = {
    id: route?.id ?? "",
    startLat: route?.startLat ?? 0,
    startLng: route?.startLng ?? 0,
    startAddrStr: route?.startAddrStr ?? "",
    invoicesQueryHook: route?.invoicesQueryHook ?? [], // invoiceIds
    employeesQueryHook: route?.employeesQueryHook ?? [], // employee query hooks
    vehiclesQueryHook: route?.vehiclesQueryHook ?? [], // vehicle query hooks
    name: route?.name ?? "",
    color: route?.color ?? "",
    stops: route?.stops ?? [], // array of stops
    hasWarnings: route?.hasWarnings ?? false,
    hasErrors: route?.hasErrors ?? false,
    notes: route?.notes ?? "",
    loadDate: route?.loadDate ?? null, // date of load
    mobileLoadDate: TimeMachine(route?.loadDate).date?.toISOString() ?? null,
    assignedEmployees: route?.assignedEmployees ?? [],
    assignedVehicles: route?.assignedVehicles ?? [],
    routeDate: route?.routeDate ?? null,
    mobileRouteDate: TimeMachine(route?.routeDate).date?.toISOString() ?? null,
    isComplete: route?.isComplete ?? false,
  };
  const payloadErr = (msg) => {
    return notificationError("Error with route data", msg);
  };
  const updateValue = ({ key, value }) => {
    /**
     * Update single value and return updated payload
     */
    if (!key || !value) return payloadErr("Key or value not specified");
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === key) {
        payload[k] = value;
      } else {
        payload[k] = v;
      }
    }
    return payload;
  };
  const updateValues = ({ list }) => {
    /**
     * Update values and return updated payload
     * list must be array of { key:str, value:any }
     */
    if (!list) return payloadErr("No list specified");
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      const update = list.find((i) => i.key === k);
      if (!update) {
        payload[k] = v;
      } else {
        payload[k] = update.value;
      }
    }
    return payload;
  };
  const updateStartCoordinates = (lat, lng) => {
    /**
     * Update Start Data lat lng
     */
    let payload = {};
    for (const [k, v] of Object.entries(data)) {
      if (k === "startLat") {
        payload[k] = lat;
      }
      if (k === "startLng") {
        payload[k] = lng;
      }
      payload[k] = v;
    }
    return payload;
  };
  const appendStop = (stop) => {
    const { stops, invoicesQueryHook, ...rest } = route;
    const exists = stops?.find((s) => {
      return s?.invoiceId === stop?.invoiceId && s?.type === stop?.type;
    });

    if (exists) return payloadErr("Stop with Invoice ID already exists");
    const uptdStops = [...stops, stop];
    return {
      stops: AppendPrevLatLng({
        stops: SortStopIndexes(uptdStops),
        routeStartLat: data?.startLat,
        routeStartLng: data?.startLng,
      }),
      invoicesQueryHook: [...invoicesQueryHook, stop?.invoiceId].filter(
        onlyUnique
      ),
      ...rest,
    };
  };
  const removeStop = (stopInvoiceId, stop) => {
    if (!stopInvoiceId) return payloadErr("Missing stop invoice ID");
    const { stops, invoicesQueryHook, ...rest } = route;
    const exists = stops.find(
      (s) => s?.invoiceId === stopInvoiceId && s?.type === stop?.type
    );
    if (!exists) return payloadErr("Stop does not exist in route");
    const filteredStops = stops
      .map((s) => {
        if (s.invoiceId === stopInvoiceId && stop?.type === s?.type)
          return null;
        return s;
      })
      .filter((s) => s !== null);
    // const queryHook = invoicesQueryHook.filter(onlyUnique);
    const stopsIndexed = SortStopIndexes(filteredStops);
    const queryHook = stopsIndexed?.map((s) => s?.invoiceId).filter(onlyUnique);
    return {
      stops: AppendPrevLatLng({
        stops: stopsIndexed,
        routeStartLat: data?.startLat,
        routeStartLng: data?.startLng,
      }),
      invoicesQueryHook: queryHook,
      ...rest,
    };
  };
  const resortStops = (updatedStops) => {
    const { stops, ...rest } = route;
    const sortedStops = SortStopIndexes(updatedStops);
    return {
      stops: AppendPrevLatLng({
        stops: sortedStops,
        routeStartLat: route?.startLat,
        routeStartLng: route?.startLng,
      }),
      ...rest,
    };
  };
  const updateStop = ({ stop }) => {
    const { stops, ...rest } = route;
    let i = -1;
    const currentStop = stops?.find((s, index) => {
      i = index;
      return s.invoiceId === stop?.invoiceId && s?.type === stop?.type;
    });
    const uptdStops = stops?.map((s) => {
      if (s?.invoiceId === stop?.invoiceId && s?.type === stop?.type) {
        const { updatePolyline, ...stopRest } = stop;
        return {
          updatePolyline: !HasSamePolyline(currentStop, stop),
          ...stopRest,
        };
      } else {
        return s;
      }
    });
    return {
      stops: uptdStops,
      ...rest,
    };
  };

  const updateUserAssignment = ({ id, roles, users }) => {
    if (!id) return notificationError("No ID specified");
    const currentlyAssigned = route?.assignedEmployees ?? [];
    const user = users?.find((u) => u.id === id);
    if (!user)
      return notificationError("Invalid user", "Could not find data for user");
    const filtered = currentlyAssigned.filter((u) => u?.id !== id);
    const payload = {
      name: `${user?.firstName} ${user?.lastName}`,
      id,
      roles,
    };
    if (roles?.length === 0) {
      const { assignedEmployees, employeesQueryHook, ...rest } = route;
      const updatedRemoved = assignedEmployees?.filter((e) => e?.id !== id);
      return {
        assignedEmployees: updatedRemoved,
        employeesQueryHook: updatedRemoved?.map((e) => e?.id),
        ...rest,
      };
    } else {
      const updatedAssignedEmployees = [...filtered, payload];
      const { assignedEmployees, employeesQueryHook, ...rest } = route;
      return {
        assignedEmployees: updatedAssignedEmployees,
        employeesQueryHook: updatedAssignedEmployees?.map((e) => e?.id),
        ...rest,
      };
    }
  };

  const updateLoadDate = (momentDate, firestore, orgData) => {
    // this one specifically outputs only for state. payload done in hook func
    if (!momentDate || !orgData) {
      const { loadDate, isComplete, mobileLoadDate, ...rest } = route;
      return {
        loadDate: null,
        mobileLoadDate: null,
        isComplete: false,
        ...rest,
      };
    } else {
      const date = new moment(momentDate).toDate();
      const { loadDate, mobileLoadDate, ...rest } = route;
      return {
        loadDate: date,
        mobileLoadDate: date.toISOString().substring(0, 10),
        ...rest,
      };
    }
  };
  const updateNotes = (updatedNotes) => {
    const { notes, ...rest } = route;
    return {
      notes: updatedNotes,
      ...rest,
    };
  };

  const updateComplete = (complete) => {
    const { isComplete, ...rest } = route;
    return {
      isComplete: complete,
      ...rest,
    };
  };

  return {
    updateValues,
    updateValue,
    appendStop,
    removeStop,
    resortStops,
    updateStop,
    updateUserAssignment,
    updateLoadDate,
    updateNotes,
    updateComplete,
  };
};
