import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import SelectRouteHeader from "./SelectRouteHeader";
import SelectRouteColor from "./SelectRouteColor";

const EditRouteModal = ({
  visible,
  data,
  handleDelete,
  handleFinish,
  onCancel,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (!visible) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        name: data?.name ?? "",
        color: data?.color ?? null,
      });
    }
  }, [visible]);

  return (
    <Modal
      destroyOnClose
      width={400}
      visible={visible}
      closeIcon={<></>}
      title="Edit Route"
      footer={[
        <Button
          key="delete"
          type="danger"
          style={{ float: "left" }}
          onClick={() => {
            handleDelete();
          }}
          // loading={deleting}
          // disabled={saving || deleting}
        >
          Delete
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            onCancel();
          }}
          // disabled={saving}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form.validateFields().then(() => {
              const name = form.getFieldValue("name");
              const color = form.getFieldValue("color");
              handleFinish({ name, color });
            });
          }}
          // disabled={saving}
          // loading={saving}
        >
          Save
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item
          id={"name"}
          name="name"
          style={{ marginBottom: "12px" }}
          rules={[
            {
              required: true,
              message: "Name required",
            },
          ]}
        >
          <Input placeholder="Route Name" />
        </Form.Item>
        <Form.Item name={"color"} id={"color"}>
          <SelectRouteColor route={data && data} form={form} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRouteModal;
