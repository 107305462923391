import {
  COLOR_DARK_BG_0,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
  COLOR_TEXT_GRAY_0,
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
} from "../../../../../app/ui/colorConstants";
import React from "react";
import {
  INV_BUNDLE_ITEM,
  INV_BUNDLE_ITEM_DESC,
  INV_BUNDLE_ITEM_ID,
  INV_BUNDLE_ITEM_NAME,
  INV_BUNDLE_ITEM_QTY,
  INV_ITEM_RATE_NAME,
  INV_ITEM_RATE_RANGE,
} from "../../../../../app/utils/models/modelConstants/modelConstants";
import { InfoCircleOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Col, Popover, Row, Space, Tooltip } from "antd";
import { CategoryTag } from "../../../../../app/system-components/tags/Tags";
import TextBody from "../../../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";

export const renderChildrenCells = (children, record) => {
  const grayText = (text) => (
    <span style={{ color: COLOR_TEXT_GRAY_1 }}>{text}</span>
  );

  const renderSelectedRate = () => {
    const displayRate =
      record.rates &&
      record.rates.find((r) => r[INV_ITEM_RATE_NAME] === children[1]);
    if (displayRate) {
      return children[1] ? (
        <span>
          {displayRate[INV_ITEM_RATE_NAME]} (${displayRate.rate}
          {record.type === "sale"
            ? null
            : ` per ${displayRate[INV_ITEM_RATE_RANGE]}`}
          )
        </span>
      ) : (
        grayText("Select rate")
      );
    } else {
      return record.id && record.rates.length === 0
        ? grayText("No available rates")
        : grayText("-");
    }
  };

  const renderSelectedDescription = (availableItems) => {
    let containsUnavail = false;
    if (record && record.type === INV_BUNDLE_ITEM) {
      record.bundleItems.map((b) => {
        const amt = availableItems[b[INV_BUNDLE_ITEM_ID]];
        if (amt < 0) {
          containsUnavail = true;
        }
      });
    }
    const bundleItems = (record) => {
      return (
        <div>
          {record.bundleItems.map((b) => {
            const item = availableItems[b[INV_BUNDLE_ITEM_ID]];
            const bundleItemQty = b?.bundleItemQty ?? 0;
            return (
              <div>
                <Space>
                  <CategoryTag
                    style={{
                      backgroundColor: !item
                        ? COLOR_LIGHT_GRAY
                        : item >= bundleItemQty
                        ? COLOR_LIGHT_GRAY
                        : COLOR_RED_0,
                      margin: "2px 4px 2px 0px",
                      color: !item
                        ? COLOR_DARK_BG_0
                        : item >= bundleItemQty
                        ? COLOR_DARK_BG_0
                        : "#FFF",
                      borderColor: "transparent",
                    }}
                    text={`${b[INV_BUNDLE_ITEM_QTY]}x`}
                  />
                  <TextBody text={b[INV_BUNDLE_ITEM_NAME]} />
                  <TextSubtitle text={b[INV_BUNDLE_ITEM_DESC]} />
                </Space>
              </div>
            );
          })}
          <br />
          {containsUnavail && (
            <Space>
              <TextSubtitle
                text={"Items are unavailable during the selected range"}
                style={{ color: COLOR_RED_0, fontSize: "13px" }}
              />
            </Space>
          )}
        </div>
      );
    };
    return record.selectedDescription !== "" ? (
      <Row wrap={false} justify="space-between" align="middle" gutter={8}>
        <Col>{record.selectedDescription}</Col>
        <Col>
          {record.type === INV_BUNDLE_ITEM ? (
            <Popover
              placement="right"
              title={"Items in bundle"}
              content={() => bundleItems(record)}
              trigger="hover"
            >
              <CategoryTag
                style={{ margin: 0, fontSize: "13px", cursor: "pointer" }}
                text={
                  <span>
                    {containsUnavail ? (
                      <ExclamationCircleFilled
                        style={{ color: COLOR_RED_0, marginRight: "8px" }}
                      />
                    ) : null}
                    Bundle
                  </span>
                }
              />
            </Popover>
          ) : null}
        </Col>
      </Row>
    ) : (
      grayText("Item description")
    );
  };
  const renderSelectedQty = (id, amt, isNeg) => {
    return (
      <span>
        {`${children[1] ? children[1] : 0}`}
        {id && (
          <Tooltip
            title={`Available: ${amt}`}
            color={isNeg ? "#ff0000" : COLOR_DARK_BG_0}
          >
            <InfoCircleOutlined
              style={{
                float: "right",
                marginTop: "4px",
                color: isNeg ? COLOR_RED_0 : COLOR_TEXT_GRAY_0,
              }}
            />
          </Tooltip>
        )}
      </span>
    );
  };
  const renderSelectedDuration = () => {
    const isSaleItem = record.type === "sale";
    if (isSaleItem) {
      return "N/A";
    }

    const displayRate =
      record.rates &&
      record.rates.find((r) => r[INV_ITEM_RATE_NAME] === record.selectedRate);
    if (displayRate) {
      return children[1] ? (
        <span>{`${children[1]} (${displayRate[INV_ITEM_RATE_RANGE]}s)`}</span>
      ) : (
        grayText("0")
      );
    } else {
      return grayText("-");
    }
  };

  return {
    renderSelectedRate: renderSelectedRate,
    renderSelectedQty: renderSelectedQty,
    renderSelectedDuration: renderSelectedDuration,
    renderSelectedDescription: renderSelectedDescription,
  };
};
