import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import HeadingSecondary from "../../app/system-components/typography/headings/HeadingSecondary";
import { retrieveStripeAccount } from "../../app/services/cloud-functions/retrieveStripeAccount";
import { useIsAuthenticated } from "../../app/services/hooks/useIsAuthenticated";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { CheckCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { COLOR_GREEN_0, COLOR_PURPLE_0 } from "../../app/ui/colorConstants";
import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import HeadingTertiary from "../../app/system-components/typography/headings/HeadingTertiary";
import { refreshOrgState } from "../auth/authSlice";
import { useDispatch } from "react-redux";
import { ROUTE_ADMIN_INTEGRATIONS } from "../../app/routes";
import { useHistory } from "react-router-dom";

const PaymentSetupRefresh = ({ visible, closeAndRefresh }) => {
  const { orgData } = useIsAuthenticated();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const history = useHistory();
  const [verifying, setVerifying] = useState(false);
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const { writing, updateOrgDocument } = useFirestoreWrite();
  useEffect(() => {
    if (!orgData || !visible) return;
    setVerifying(true);
    retrieveStripeAccount({ firebase }, { orgId: orgData.id }).then(
      ({ data }) => {
        setDetailsSubmitted(data?.details_submitted ?? false);
        const payload = {
          stripeDetailsSubmitted: data?.details_submitted ?? false,
        };
        updateOrgDocument({
          data: { payload, id: orgData.id },
        }).then(() => {
          setVerifying(false);
        });
      }
    );
  }, [orgData]);
  return (
    <Modal
      destroyOnClose
      width={400}
      visible={visible}
      closeIcon={<></>}
      bodyStyle={{ textAlign: "center", padding: "4em" }}
      footer={null}
    >
      {verifying ? (
        <LoadingOutlined style={{ fontSize: "32px", color: COLOR_PURPLE_0 }} />
      ) : detailsSubmitted ? (
        <CheckCircleFilled style={{ color: COLOR_GREEN_0, fontSize: "32px" }} />
      ) : null}
      <br />
      <HeadingTertiary
        text={
          verifying
            ? "Verifying.."
            : detailsSubmitted
            ? "Verified"
            : "Setup incomplete"
        }
      />
      <br />
      <Button
        size={"large"}
        type={"primary"}
        disabled={verifying || !orgData}
        onClick={() => {
          closeAndRefresh();
        }}
      >
        {detailsSubmitted ? "Complete" : "Close"}
      </Button>
    </Modal>
  );
};

export default PaymentSetupRefresh;
