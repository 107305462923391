import React, { useEffect } from "react";
import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Tag,
} from "antd";
import {
  COLOR_BLUE_0,
  COLOR_GREEN_0,
  COLOR_LIGHT_BLUE,
  COLOR_LIGHT_GRAY,
  COLOR_RED_0,
  COLOR_TEXT_GRAY_1,
  COLOR_TEXT_GRAY_2,
} from "../../../app/ui/colorConstants";
import TextBody from "../../../app/system-components/typography/text/TextBody";
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
  FlagFilled,
  WarningFilled,
} from "@ant-design/icons";
import TextSubtitle from "../../../app/system-components/typography/text/TextSubtitle";
import { useUpdateQrItem } from "../../../app/services/hooks/useUpdateQrItem";
import { checkUndefined } from "../../../app/utils/models/checkers/checkUndefined";
import StockCounter from "./modules/stockCounter";

const { TextArea } = Input;
const InventoryCompletedTracker = ({
  item,
  invoice,
  refreshInvoiceData,
  invoices,
  dailyAvailable,
}) => {
  const {
    updateFlag,
    flagged,
    issueDescription,
    hasBundleChildren,
    notes,
    completed,
    qtyRented,
    updateNotes,
    updateCompleted,
    updateBundle,
  } = useUpdateQrItem({
    invoice: invoice,
    item: item,
    invoiceCallback: refreshInvoiceData,
  });

  const handleIssueClicked = () => {
    updateFlag({
      flag: !flagged,
    });
  };

  const handleIssueDesc = (e) => {
    updateFlag({
      flag: flagged,
      description: e.target.value,
    });
  };

  const handleNote = (e) => {
    updateNotes({
      notes: e.target.value,
    });
  };
  //
  const handleCompletedCount = (c) => {
    const count = Number(c.target.value);
    const safeCount = count > qtyRented ? qtyRented : count;
    if (safeCount === completed) return;
    if (!hasBundleChildren) {
      updateCompleted({
        count: safeCount,
      });
    } else {
      showPromiseConfirm(safeCount);
    }
  };

  const handleCompletedCountEnter = () => {
    inputRef.current.blur();
  };

  function showPromiseConfirm(c) {
    Modal.confirm({
      title: "Update all bundle items?",
      icon: <ExclamationCircleOutlined />,
      content: "This will re-calculate all completed bundle items",
      onOk() {
        return new Promise((resolve, reject) => {
          // hook
          updateBundle({
            count: c,
          }).then(() => {
            resolve();
          });
        }).catch((err) => console.log(err));
      },
      onCancel() {},
    });
  }

  const inputRef = React.useRef();
  const issueDescInput = React.useRef();

  const configureFlagCopy = (item) => {
    if (flagged)
      return (
        <Tag
          style={{
            borderColor: "transparent",
            backgroundColor: `rgba(0, 0, 0, .12)`,
            color: "#FFF",
          }}
        >{`${item.name} in #${item.invoiceNumber}${
          item.isBundleChild ? " (Bundle)" : ""
        }`}</Tag>
      );
    return `Flag ${item.name} in #${item.invoiceNumber}${
      item.isBundleChild ? " (Bundle)" : ""
    }`;
  };
  const configureOverbooked = (item, dailyAvailable) => {
    const available = checkUndefined(
      dailyAvailable && dailyAvailable.stockList.find((i) => i.id === item.id),
      null
    );
    if (!available) return null;
    const total = available.stock ? available.stock : 0;
    const availableStock = available.availableStock
      ? available.availableStock
      : 0;
    const overbookedAmt = available.unavailableStock
      ? total - available.unavailableStock
      : total;
    return (
      <Space>
        <Tag style={{ borderColor: "transparent" }}>
          {available.stock
            ? `Available: ${availableStock}/${total}`
            : "No stock info available"}
        </Tag>
        {overbookedAmt < 0 ? (
          <Tag
            style={{
              backgroundColor: COLOR_RED_0,
              color: "#FFF",
              borderColor: "transparent",
            }}
          >
            <WarningFilled style={{ color: "#FFD54F", marginRight: "4px" }} />
            Overbooked: {overbookedAmt}
          </Tag>
        ) : null}
      </Space>
    );
  };
  return (
    <div style={{ marginBottom: "12px" }}>
      <Row align={"middle"} justify={"space-between"} wrap={false}>
        <Col>
          <Button
            key={item.id}
            style={{
              borderColor: "transparent",
              backgroundColor: flagged ? COLOR_RED_0 : COLOR_LIGHT_GRAY,
              color: flagged ? "#FFF" : "#0F0F11",
            }}
            onClick={() => handleIssueClicked()}
          >
            <FlagFilled style={{ color: flagged ? "#FFF" : "#0F0F11" }} />
            {configureFlagCopy(item)}
          </Button>
        </Col>
        {flagged ? (
          <Col flex={"auto"} style={{ marginLeft: "0" }}>
            <Input
              ref={issueDescInput}
              onBlur={handleIssueDesc}
              onPressEnter={() => {
                issueDescInput.current.blur();
              }}
              placeholder={"Why was this item flagged?"}
              defaultValue={issueDescription}
              style={{
                color: COLOR_RED_0,
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                marginLeft: "-4px",
                borderColor: "transparent",
                fontWeight: "bold",
              }}
            />
          </Col>
        ) : null}
        <Col>
          <Space>
            {dailyAvailable && item && (
              <StockCounter item={item} dailyAvailable={dailyAvailable} />
            )}
            <InputNumber
              ref={inputRef}
              onPressEnter={handleCompletedCountEnter}
              min={0}
              max={item.qtyRented}
              key={`${item.id}_${completed}`}
              defaultValue={completed}
              placeholder={"Items"}
              onBlur={(c) => handleCompletedCount(c)}
            />{" "}
            <TextBody
              style={{ fontSize: "14px" }}
              text={` / ${item.qtyRented}`}
            />
            {completed === qtyRented && (
              <CheckCircleFilled
                style={{ color: COLOR_GREEN_0, marginRight: "8px" }}
              />
            )}
          </Space>
        </Col>
      </Row>

      <div style={{ width: "100%", marginTop: "8px" }}>
        <Space direction={"vertical"} style={{ width: "100%" }} align={"top"}>
          <TextArea
            key={`${item.id}_${notes}`}
            onBlur={handleNote}
            defaultValue={notes}
            placeholder={"Add notes"}
            bordered={false}
            style={{ borderColor: "transparent", width: "100%" }}
            autoSize={true}
          />
        </Space>
      </div>
    </div>
  );
};

export default InventoryCompletedTracker;
