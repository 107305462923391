import React from "react";
import { Col, InputNumber, Row, Select, Space } from "antd";
import TextBody from "../../../../../app/system-components/typography/text/TextBody";
import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";
import { COLOR_TEXT_GRAY_1 } from "../../../../../app/ui/colorConstants";
import getSymbolFromCurrency from 'currency-symbol-map'

const { Option } = Select;
const FinalSubtotal = ({ invoiceTotal, updateFinalDiscount, orgData }) => {
  const configurePayload = (payload) => {
    updateFinalDiscount(payload, invoiceTotal.subtotal);
  };

  const handleChange = (e) => {
    if (e === "none") {
      configurePayload({
        totalDiscountPercent: 0,
        totalDiscountNumber: 0,
        totalDiscountType: e,
        hasTotalDiscount: false,
      });
    } else {
      configurePayload({
        totalDiscountPercent: 0,
        totalDiscountNumber: 0,
        totalDiscountType: e,
        hasTotalDiscount: true,
      });
    }
  };
  const renderPercentInput = () => {
    return (
      <Space style={{ alignItems: "center" }}>
        <InputNumber
          onStep={(e) => {
            const discount = (e.target.value ? e.target.value : 0) / 100;
            configurePayload({
              totalDiscountPercent: parseFloat(discount.toFixed(4)),
              totalDiscountNumber: 0,
              totalDiscountType: invoiceTotal.totalDiscountType,
              hasTotalDiscount: true,
            });
          }}
          onBlur={(e) => {
            const discount = (e.target.value ? e.target.value : 0) / 100;

            configurePayload({
              totalDiscountPercent: parseFloat(discount.toFixed(4)),
              totalDiscountNumber: 0,
              totalDiscountType: invoiceTotal.totalDiscountType,
              hasTotalDiscount: true,
            });
          }}
          onPressEnter={(e) => {
            const discount = (e.target.value ? e.target.value : 0) / 100;

            configurePayload({
              totalDiscountPercent: parseFloat(discount.toFixed(4)),
              totalDiscountNumber: 0,
              totalDiscountType: invoiceTotal.totalDiscountType,
              hasTotalDiscount: true,
            });
          }}
          value={parseFloat(
            invoiceTotal.totalDiscountPercent * 100
          ).toLocaleString("us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
          max={100}
          style={{ width: "80px", lineHeight: "30px" }}
        />
        <TextBody text={"%"} />
      </Space>
    );
  };

  const convertDecimalCurrency = (valueStr) => {
    if (!valueStr) return parseFloat("0.00");
    return parseFloat(parseFloat(valueStr).toFixed(2));
  };

  const renderNumberInput = () => {
    return (
      <Space style={{ alignItems: "center" }}>
        <TextBody text={getSymbolFromCurrency(orgData.currencyCode)} />
        <InputNumber
          onStep={() => {
            configurePayload({
              totalDiscountPercent: 0,
              totalDiscountNumber: invoiceTotal.totalDiscountNumber + 1,
              totalDiscountType: invoiceTotal.totalDiscountType,
              hasTotalDiscount: true,
            });
          }}
          onBlur={(e) => {
            configurePayload({
              totalDiscountPercent: 0,
              totalDiscountNumber: convertDecimalCurrency(e.target.value),
              totalDiscountType: invoiceTotal.totalDiscountType,
              hasTotalDiscount: true,
            });
          }}
          onPressEnter={(e) => {
            configurePayload({
              totalDiscountPercent: 0,
              totalDiscountNumber: convertDecimalCurrency(e.target.value),
              totalDiscountType: invoiceTotal.totalDiscountType,
              hasTotalDiscount: true,
            });
          }}
          value={invoiceTotal.totalDiscountNumber}
          style={{ width: "80px", lineHeight: "30px" }}
        />
      </Space>
    );
  };

  return (
    <Row wrap={false} justify="space-between" align="middle">
      <Col>
        <TextBody style={{ fontWeight: "bold" }} text={"Final Subtotal"} />
      </Col>
      <Col style={{ margin: "0 8px" }}>
        <Space>
          <div>
            <TextSubtitle text={"DISCOUNT:"} />
            <Select
              value={invoiceTotal.totalDiscountType}
              onChange={handleChange}
              style={{ width: "120px", margin: "0 16px 0 12px" }}
              placeholder={"Add Discount"}
            >
              <Option value="none" key={"none"}>
                None
              </Option>
              <Option value="percent" key={"percent"}>
                Percentage
              </Option>
              <Option value="number" key={"number"}>
                Number
              </Option>
            </Select>
            {invoiceTotal.totalDiscountType === "percent"
              ? renderPercentInput()
              : invoiceTotal.totalDiscountType === "number"
              ? renderNumberInput()
              : null}
          </div>
        </Space>
      </Col>
    </Row>
  );
};

export default FinalSubtotal;
