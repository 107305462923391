import React, { useState } from "react";
import { Modal, Select } from "antd";
import { useFirestoreFetch } from "../../../../../app/services/hooks/fetch/useFirestoreFetch";
import { taxRateQueries } from "../../../../../app/services/firestore/queries/taxRateQueries";

const TaxModal = ({ visible, handleOk, handleCancel }) => {
  const {
    data: taxRates,
    refresh,
    fetching,
  } = useFirestoreFetch(taxRateQueries().fetchActiveTaxRates);
  const [rate, setRate] = useState(null);

  const handleSelect = (rateId) => {
    const rate = taxRates && taxRates.find((r) => r.id === rateId);
    if (rate) {
      setRate(rate);
    }
  };
  return (
    <Modal
      destroyOnClose={true}
      title="Tax Rates"
      visible={visible}
      onOk={() => {
        handleOk(rate);
      }}
      onCancel={() => {
        setRate(null);
        handleCancel();
      }}
      okText={"Apply"}
      okButtonProps={{ disabled: !rate }}
    >
      <Select
        allowClear={true}
        style={{ width: "100%" }}
        loading={fetching}
        placeholder={"Select tax rate"}
        onSelect={handleSelect}
        onClear={() => handleSelect(null)}
      >
        {taxRates.map((r) => (
          <Select.Option key={r.id} value={r.id}>
            {r.name} ({(r.rate * 100).toFixed(2)}%)
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

export default TaxModal;
