import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Space } from "antd";
import { RouteSelect } from "./RouteSelect";
import {
  COLOR_GRAY_BORDER,
  COLOR_TEXT_GRAY_1,
} from "../../../../../app/ui/colorConstants";
import { PlusOutlined } from "@ant-design/icons";
import { RouteContext } from "../../../NewRoute";

const SelectRouteHeader = () => {
  const { actions, state } = useContext(RouteContext);
  return (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#FFF",
      }}
    >
      Routes:
      {state.routes?.map((r) => (
        <RouteSelect route={r} />
      ))}
      <Button
        style={{
          marginTop: "24px",
          borderColor: "transparent",
          backgroundColor: COLOR_GRAY_BORDER,
        }}
        key={"add_route"}
        onClick={() => actions.addRoute()}
      >
        <PlusOutlined style={{ color: COLOR_TEXT_GRAY_1 }} /> New Route
      </Button>
    </div>
  );
};

export default SelectRouteHeader;
