import React from "react";
import TextBody from "../../../../../app/system-components/typography/text/TextBody";
import { Badge, Col, Row, Space } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { GenericTag } from "../../../../../app/system-components/tags/RoutesTags";

import {
  COLOR_GREEN_0,
  COLOR_YELLOW_0,
} from "../../../../../app/ui/colorConstants";
import TextSubtitle from "../../../../../app/system-components/typography/text/TextSubtitle";
import { TimeTag } from "../../../../../app/system-components/tags/RoutesTags";
import ReceiveReturnMethodTag from "./ReceiveReturnMethodTag";
import { STOP_TYPE_WAYPOINT } from "../../../../../app/utils/models/modelConstants/modelConstants";
import convertTimeToDayString from "../../../../../app/utils/time/convertTimeToDayString";
import { i18n } from "../../../../../i18n";


const StopCellHeader = ({
  hideEdit,
  hideDots,
  stop,
  invoiceNumber,
  inRoutes,
  timeWindow,
  typeLabel,
}) => {
  return (
    <Row
      style={{ display: "inline-flex", width: "100%" }}
      wrap={false}
      id={invoiceNumber}
      justify={"space-between"}
      align={"top"}
    >
      <Col>
        <Space direction={"vertical"} size={0}>
          <Space direction={"horizontal"} size={4}>
            {stop?.type !== STOP_TYPE_WAYPOINT ? (
              <TextBody
                text={`#${stop?.invoice?.invoiceNumber ?? "Unknown"}`}
              />
            ) : null}
            {typeLabel && (
              <ReceiveReturnMethodTag
                typeLabel={typeLabel}
                stop={stop}
                hideEdit={hideEdit}
              />
            )}
            {timeWindow.hasWindow && <TimeTag text={timeWindow?.text} />}
          </Space>

          <Space size={30}>
            <TextBody text={stop?.displayName ?? ""} />

            {stop?.invoice?.eventDate && (
              <Space size={0}>
                <GenericTag text={i18n('invoices.eventDate')} />{" "}
                <TextBody
                  text={`${convertTimeToDayString(stop?.invoice?.eventDate)}`}
                />
              </Space>
            )}
          </Space>

          <Space>
            {stop?.addrValidated ? null : (
              <WarningFilled style={{ color: COLOR_YELLOW_0 }} />
            )}
            <TextSubtitle text={stop.address} />
          </Space>
        </Space>
      </Col>
      <Col style={{ float: "right" }}>
        <Space
          direction={"vertical"}
          style={{ float: "right", textAlign: "right", display: "block" }}
        >
          {!hideDots && (
            <div
              style={{
                float: "right",
                display: "inline-block",
                height: "12px",
              }}
            >
              <Space direction={"horizontal"} size={0}>
                <div>
                  {inRoutes?.map((i) => (
                    <Badge dot color={i.color} />
                  ))}
                </div>
              </Space>
            </div>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default StopCellHeader;
