import React, { useContext } from "react";
import { Card, Select } from "antd";
import { SalesReportContext } from "../SalesReport";
import SelectReportItems from "./SelectReportItems";
import {
  Bar,
  Cell,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import {
  COLOR_BLUE_0,
  COLOR_GREEN_0,
  COLOR_LIGHT_GRAY,
  COLOR_TEXT_GRAY_1,
} from "../../../../app/ui/colorConstants";
import {
  EmptyTable,
  EmptyTableText,
} from "../../../invoices/components/invoiceTable/modules/EmptyTable";
import { abbreviateNumber } from "../../../../app/services/hooks/helpers/reports/abbrNumber";
import ItemTooltip from "./ItemTooltip";

const SalesChart = () => {
  const { state, actions } = useContext(SalesReportContext);
  console.log("STATECHARTDATE", state.chartData);
  return (
    <Card
      size={"small"}
      title={"Sales by Inventory"}
      extra={<SelectReportItems />}
      style={{ height: "700px" }}
    >
      {state.chartData?.length !== 0 ? (
        <ResponsiveContainer width={"100%"} height={624}>
          <BarChart
            data={state.chartData}
            margin={{
              top: 20,
              right: 20,
              left: 0,
              bottom: 5,
            }}
          >
            <Tooltip cursor={{ fill: "none" }} content={ItemTooltip} />
            <XAxis
              interval={0}
              dataKey={"name"}
              tick={{
                angle: 25,
                textAnchor: "start",
                dominantBaseline: "ideographic",
              }}
              tickFormatter={(value, index) => {
                const limit = 28; // put your maximum character
                if (value.length < limit) return value;
                return `${value.substring(0, limit)}...`;
              }}
              height={100}
            />
            <YAxis
              tickFormatter={(v) => `$${abbreviateNumber(v)}`}
              style={{
                fontSize: ".8rem",
                fontWeight: "600",
              }}
            />
            <Bar
              style={{
                cursor: "pointer",
              }}
              dataKey={"filteredTotal"}
              stackId={"id"}
              fill={COLOR_GREEN_0}
              // radius={[4, 4, 0, 0]}
            >
              {state.chartData.map((entry, index) => {
                const invItemId = entry?.inventoryId ?? null;
                const isSelected = invItemId === state.selectedInvItem;
                return (
                  <Cell
                    fill={isSelected ? COLOR_BLUE_0 : COLOR_GREEN_0}
                    onClick={() => {
                      const invItemId = entry?.inventoryId ?? null;
                      invItemId && invItemId === state.selectedInvItem
                        ? actions.changeSelectedInvItem(null)
                        : actions.changeSelectedInvItem(invItemId);
                    }}
                    cursor="pointer"
                    key={`cell-${index}`}
                  />
                );
              })}
            </Bar>
            <Bar
              dataKey={"total"}
              stackId={"id"}
              fill={COLOR_LIGHT_GRAY}
              // radius={[4, 4, 0, 0]}
            >
              {state.chartData.map((entry, index) => (
                <Cell
                  onClick={() => {
                    const invItemId = entry?.inventoryId ?? null;
                    invItemId && invItemId === state.selectedInvItem
                      ? actions.changeSelectedInvItem(null)
                      : actions.changeSelectedInvItem(invItemId);
                  }}
                  cursor="pointer"
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
            <Legend
              iconType={"circle"}
              formatter={(value, entry) => {
                if (value === "filteredTotal")
                  return (
                    <span style={{ color: "#000" }}>
                      Sales matching category
                    </span>
                  );
                return (
                  <span style={{ color: "#000" }}>Sales not in category</span>
                );
              }}
              verticalAlign="top"
              height={36}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <EmptyTableText text={"No sales data"} />
      )}
    </Card>
  );
};

export default SalesChart;
